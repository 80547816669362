import { Dispatch, SetStateAction } from "react";
import { Typography, TextField, Box, Grid, Autocomplete, FormControl, Skeleton, InputLabel, Select, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { Container } from "../../../commonStyles";
import { ratings, sources, realtorTypes, statuses, ethnicities } from "../../../utils/constant";
import { useSelector } from "react-redux";
import { selectProjectTags } from "../../../features/projectSetting/projectSettingSlice";

const RegistrantSection = (props: ChildProps) => {
  const {
    projectId,
    registrantStart,
    setRegistrantStart,
    registrantEnd,
    setRegistrantEnd,
    beBackStart,
    setBeBackStart,
    beBackEnd,
    setBeBackEnd,
    email,
    setEmail,
    primaryPhone,
    setPrimaryPhone,
    postalCode,
    setPostalCode,
    ethnicity,
    setEthnicity,
    rating,
    setRating,
    source,
    setSource,
    status,
    setStatus,
    realtorType,
    setRealtorType,
    tags,
    setTags,
    connected,
    setConnected,
    subscribed,
    setSubscribed,
    loading,
  } = props;

  const projectTags = useSelector(selectProjectTags);

  return (
    <Container>
      {loading ? (
        <Typography variant="h1">
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Typography>
      ) : (
        <>
          <Typography variant="h2" component="div" gutterBottom>
            <strong>Registrant</strong>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ display: "flex" }}>
                  <DateTimePicker
                    label="Registrant Created Date"
                    value={registrantStart ? new Date(registrantStart!) : null}
                    onChange={(newValue) => {
                      setRegistrantStart(newValue);
                    }}
                    maxDate={registrantEnd ? new Date(registrantEnd!) : null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Box sx={{ alignSelf: "center", mx: 1 }}>to</Box>
                  <DateTimePicker
                    label="Date End"
                    value={registrantEnd ? new Date(registrantEnd!) : null}
                    onChange={(newValue) => {
                      setRegistrantEnd(newValue);
                    }}
                    minDate={registrantStart ? new Date(registrantStart!) : null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ display: "flex" }}>
                  <DateTimePicker
                    label="Be Back Date"
                    value={beBackStart ? new Date(beBackStart!) : null}
                    onChange={(newValue) => {
                      setBeBackStart(newValue);
                    }}
                    maxDate={beBackEnd ? new Date(beBackEnd!) : null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Box sx={{ alignSelf: "center", mx: 1 }}>to</Box>
                  <DateTimePicker
                    label="Date End"
                    value={beBackEnd ? new Date(beBackEnd!) : null}
                    onChange={(newValue) => {
                      setBeBackEnd(newValue);
                    }}
                    minDate={beBackStart ? new Date(beBackStart!) : null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  type="text"
                  variant="outlined"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  sx={{ backgroundColor: "#fff" }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  name="primaryPhone"
                  label="Phone Number"
                  type="text"
                  variant="outlined"
                  value={primaryPhone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrimaryPhone(e.target.value)}
                  sx={{ backgroundColor: "#fff" }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  name="postalCode"
                  label="Postal Code"
                  type="text"
                  variant="outlined"
                  value={postalCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
                  sx={{ backgroundColor: "#fff" }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                multiple
                options={ratings}
                freeSolo={false}
                value={rating}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => setRating(value)}
                renderInput={(params) => <TextField {...params} label="Ratings" />}
              />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                multiple
                options={ethnicities}
                freeSolo={false}
                value={ethnicity}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => setEthnicity(value)}
                renderInput={(params) => <TextField {...params} label="Ethnicities" />}
              />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                multiple
                options={sources}
                freeSolo={false}
                value={source}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => setSource(value)}
                renderInput={(params) => <TextField {...params} label="Sources" />}
              />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                multiple
                options={statuses}
                freeSolo={false}
                value={status}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => setStatus(value)}
                renderInput={(params) => <TextField {...params} label="Statuses" />}
              />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                multiple
                options={realtorTypes}
                freeSolo={false}
                value={realtorType}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => setRealtorType(value)}
                renderInput={(params) => <TextField {...params} label="Realtor Type" />}
              />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                multiple
                options={["No Tags", ...projectTags]}
                freeSolo={false}
                value={tags}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => setTags(value)}
                renderInput={(params) => <TextField {...params} label="Tags" />}
              />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <FormControl sx={{ width: '100%'}}>
                <InputLabel id="demo-simple-select-label">Connected</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name={"connected"}
                  label={"Connected"}
                  value={connected}
                  onChange={(e) => setConnected(e.target.value)}
                >
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
              <FormControl sx={{ width: '100%'}}>
                <InputLabel id="demo-simple-select-label">Subscribed</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name={"subscribed"}
                  label={"Connected"}
                  value={subscribed}
                  onChange={(e) => setSubscribed(e.target.value)}
                >
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

interface ChildProps {
  projectId: string;
  registrantStart: Date | null;
  setRegistrantStart: Dispatch<SetStateAction<Date | null>>;
  registrantEnd: Date | null;
  setRegistrantEnd: Dispatch<SetStateAction<Date | null>>;
  beBackStart: Date | null;
  setBeBackStart: Dispatch<SetStateAction<Date | null>>;
  beBackEnd: Date | null;
  setBeBackEnd: Dispatch<SetStateAction<Date | null>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  primaryPhone: string;
  setPrimaryPhone: Dispatch<SetStateAction<string>>;
  postalCode: string;
  setPostalCode: Dispatch<SetStateAction<string>>;
  ethnicity: string[];
  setEthnicity: Dispatch<SetStateAction<string[]>>;
  rating: string[];
  setRating: Dispatch<SetStateAction<string[]>>;
  source: string[];
  setSource: Dispatch<SetStateAction<string[]>>;
  status: string[];
  setStatus: Dispatch<SetStateAction<string[]>>;
  realtorType: string[];
  setRealtorType: Dispatch<SetStateAction<string[]>>;
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  connected: string;
  setConnected: any;
  subscribed: string;
  setSubscribed: any;
  loading: boolean;
}

export default RegistrantSection;
