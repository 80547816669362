/* Dependency Imports */
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Link, Tooltip, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Settings as SettingsIcon, ArrowRight, PersonAdd, Email } from "@mui/icons-material";
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SendIcon from "@mui/icons-material/Send";
import { addDays, subDays } from "date-fns";

/* Project Imports */
import StandardTable from "../tables/StandardTable";
import { useSelectUser } from "../../features/auth/authHooks";
import { useAppDispatch } from "../../app/hooks";
import { setProject } from "../../features/project/projectSlice";
import { showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { IAppointment } from "../../types/project";
import { convertAllDates, timeZoneDate } from "../../utils/function";

const Dashboard = () => {
  /* Redux */
  const storeDispatch = useAppDispatch();
  const user = useSelectUser();

  const [dashboardData, setDashboardData] = useState<any[]>([]);
  const [appointmentData, setAppointmentData] = useState<IAppointment[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [value, setValue] = useState<number>(0);

  /* Hooks */
  const navigate = useNavigate();

  useEffect(() => {
    storeDispatch(setProject(null));
  }, [storeDispatch]);

  useEffect(() => {
    if (user?.type === "Sales" || user?.type === "Manager") {
      if (value === 0) {
        getAppointments({
          variables: {
            salesRepId: user?._id,
            projectIds: user?.projectAccess.map((project: any) => project.project._id),
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: new Date(addDays(new Date().setHours(0, 0, 0, 0), 365)),
            perPage: 10,
            page: pageNumber + 1,
            cancelled: false,
            sort: "DATE_ASC",
          },
        });
      } else if (value === 1) {
        getAppointments({
          variables: {
            salesRepId: user?._id,
            projectIds: user?.projectAccess.map((project: any) => project.project._id),
            startDate: new Date(subDays(new Date().setHours(0, 0, 0, 0), 365)),
            endDate: new Date(new Date().setHours(0, 0, 0, 0)),
            perPage: 10,
            page: pageNumber + 1,
            cancelled: false,
            sort: "DATE_DESC",
          },
        });
      } else if (value === 2) {
        getAppointments({
          variables: {
            salesRepId: user?._id,
            projectIds: user?.projectAccess.map((project: any) => project.project._id),
            startDate: new Date(subDays(new Date().setHours(0, 0, 0, 0), 30)),
            endDate: new Date(addDays(new Date().setHours(0, 0, 0, 0), 365)),
            perPage: 10,
            page: pageNumber + 1,
            cancelled: true,
            sort: "DATE_DESC",
          },
        });
      }
    }
  }, [value, pageNumber]);

  const [getAppointments, { loading: salesLoading }] = useLazyQuery(GETAPPOINTMENTS, {
    onCompleted: (data) => {
      setAppointmentData(data.appointmentPagination.items);
      setCount(data.appointmentPagination.count);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const { loading } = useQuery(GETDASHBOARDCOUNTS, {
    variables: { projects: user?.projectAccess.map((projectAccess) => projectAccess.project._id) },
    onCompleted: (data) => {
      setDashboardData(data.getDashboardCounts);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [downloadGlobalReport] = useLazyQuery(DOWNLOADREPORT, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const downloadTraffic = () => {
    storeDispatch(showSuccessSnackbar("An email will be sent to you shortly."));
    downloadGlobalReport();
  };

  const appointmentColumns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: (rowData: IAppointment) => {
          return (
            <Box
              sx={{ fontWeight: rowData.registrant ? 700 : 500, cursor: rowData.registrant ? "pointer" : "auto" }}
              onClick={() =>
                rowData.registrant ? navigate(`/dashboard/project/${rowData.project?._id}/registrant/${rowData.registrant._id}`) : null
              }
            >
              {rowData.purchaserInfo.firstName} {rowData.purchaserInfo.lastName}
            </Box>
          );
        },
      },
      {
        Header: "Appointment Date",
        accessor: (rowData: IAppointment) => convertAllDates(timeZoneDate(rowData.date), "PPpp"),
      },
      {
        Header: "Project",
        accessor: (rowData: IAppointment) => (rowData.project ? rowData.project?.name : ""),
      },
      {
        Header: "Location",
        accessor: (rowData: IAppointment) => rowData.location,
      },
      {
        Header: "Confirmed",
        accessor: (rowData: IAppointment) => (rowData.confirmed ? "Yes" : "No"),
      },
      {
        Header: "Cancelled",
        accessor: (rowData: IAppointment) => (rowData.cancelled ? "Yes" : "No"),
      },
    ];
  }, [pageNumber, value]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Project",
        accessor: "project", // accessor is the "key" in the data
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (user?.type !== "Marketing") {
            return (
              <Link href={`/dashboard/project/${original._id}`} sx={{ textDecoration: "none", color: "#000" }}>
                <strong>{value}</strong>
              </Link>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: "Registrants",
        accessor: "registrants",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (user?.type !== "Marketing") {
            return (
              <Link href={`/dashboard/project/${original._id}/list/all-all?row=15&page=1`} sx={{ textDecoration: "none", color: "#000" }}>
                {value}
              </Link>
            );
          } else return value;
        },
      },
      {
        Header: "Daily Registrants",
        accessor: "newRegistrants",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (user?.type !== "Marketing") {
            return (
              <Link href={`/dashboard/project/${original._id}/list/all-daily?row=15&page=1`} sx={{ textDecoration: "none", color: "#000" }}>
                {value}
              </Link>
            );
          } else return value;
        },
      },
      {
        Header: "Steps Overdue",
        accessor: "stepDue",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (user?.type !== "Marketing") {
            return (
              <Link href={`/dashboard/project/${original._id}/actions`} sx={{ textDecoration: "none", color: "#000" }}>
                {value}
              </Link>
            );
          } else return value;
        },
      },
      {
        Header: "First Step Due Today",
        accessor: "shortTermDue",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (user?.type !== "Marketing") {
            return (
              <Link
                href={`/dashboard/project/${original._id}/list/first-short-term-step-due?row=15&page=1`}
                sx={{ textDecoration: "none", color: "#000" }}
              >
                {value}
              </Link>
            );
          } else return value;
        },
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }: any) => {
          if (user?.type !== "Developer" && user?.type !== "Marketing") {
            return (
              <Box>
                <Tooltip title="Project Settings">
                  <IconButton
                    size="large"
                    onClick={() => navigate(`/dashboard/project/${original._id}/settings`)}
                    sx={{
                      "& svg": {
                        color: "primary.main",
                        transition: "0.2s",
                        transform: "translateX(0) rotate(0)",
                      },
                      "&:hover, &:focus": {
                        bgcolor: "unset",
                        "& svg:first-of-type": {
                          transform: "translateX(-4px) rotate(-20deg)",
                        },
                        "& svg:last-of-type": {
                          right: 0,
                          opacity: 1,
                        },
                      },
                    }}
                  >
                    <SettingsIcon />
                    <ArrowRight sx={{ position: "absolute", right: 4, opacity: 0 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Registrant">
                  <IconButton size="large" onClick={() => navigate(`/dashboard/project/${original._id}/settings`, { state: { tab: 4 } })}>
                    <PersonAdd sx={{ color: "primary.main" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          } else if (user?.type === "Developer") {
            return (
              <Button href={`/dashboard/project/${original._id}`} color="primary" variant="contained">
                View Project
              </Button>
            );
          }
        },
      },
    ],
    [navigate, user?.type]
  );

  /* Functions */

  return (
    <DashboardContainer>
      <Box sx={{ width: "100%", mb: 1.5, textAlign: "right" }}>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", flexDirection: { xs: "column", sm: "row" } }}>
          {user?.type === "Manager" ? (
            <>
              <Button
                onClick={() => downloadTraffic()}
                color="success"
                variant="contained"
                sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 }, width: { xs: "150px", sm: "max-content" } }}
              >
                <SendIcon /> &nbsp;Email Global Report
              </Button>
              <Button
                sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 }, width: { xs: "150px", sm: "max-content" } }}
                href="/dashboard/reporting"
                color="primary"
                variant="contained"
              >
                <AssessmentIcon /> &nbsp;General Reporting
              </Button>
            </>
          ) : null}
          {user?.type !== "Developer" && user?.type !== "Marketing" ? (
            <Button sx={{ width: { xs: "150px", sm: "max-content" } }} href="/settings" color="primary" variant="contained">
              <SettingsIcon /> &nbsp;Settings
            </Button>
          ) : null}
          {user?.type === "Marketing" || user?.type === "Developer" ? (
            <Button sx={{ width: { xs: "150px", sm: "max-content" } }} href="/email-templates" color="primary" variant="contained">
              <Email /> &nbsp;Email Templates
            </Button>
          ) : null}
        </Box>
      </Box>
      {(user?.type === "Sales" || user?.type === "Manager") && (appointmentData.length || value) ? (
        <Box sx={{ my: 2 }}>
          <Tabs
            sx={{ backgroundColor: "#00142a" }}
            variant="scrollable"
            value={value}
            onChange={(e: any, value) => {
              setPageNumber(0);
              setValue(value);
            }}
            aria-label="tabs"
          >
            {["Upcoming", "Past", "Cancelled"].map((tab: string, index: number) => {
              return <Tab key={index} label={tab} />;
            })}
          </Tabs>
          <StandardTable data={appointmentData} loading={salesLoading} columns={appointmentColumns} title="Appointments" />
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 0} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber + 1}</span>
            <Button disabled={pageNumber + 1 === Math.ceil(count / 10)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </Box>
      ) : null}
      <StandardTable data={dashboardData} loading={loading} columns={columns} />
    </DashboardContainer>
  );
};

/* Styles */
export const DashboardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

/* Types */

/* GQL */
const GETDASHBOARDCOUNTS = gql`
  query getDashboardCounts($projects: [MongoID]!) {
    getDashboardCounts(projects: $projects) {
      _id
      project
      registrants
      newRegistrants
      stepDue
      shortTermDue
    }
  }
`;

const DOWNLOADREPORT = gql`
  query globalReport {
    globalReport
  }
`;

const GETAPPOINTMENTS = gql`
  query getFilteredAppointments(
    $page: Int
    $perPage: Int
    $salesRepId: MongoID
    $projectIds: [MongoID!]
    $startDate: String
    $endDate: String
    $cancelled: Boolean
    $sort: SortFindManyAppointmentInput
  ) {
    appointmentPagination(
      page: $page
      perPage: $perPage
      filter: {
        salesRep: $salesRepId
        projectIds: $projectIds
        dateGreaterThanEqual: $startDate
        dateLessThanEqual: $endDate
        cancelled: $cancelled
      }
      sort: $sort
    ) {
      count
      items {
        _id
        project {
          _id
          name
        }
        user {
          firstName
          lastName
        }
        date
        location
        registrant {
          _id
        }
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        status
        type
        confirmed
        cancelled
        salesRep {
          _id
          firstName
          lastName
          fullName
          email
        }
        virtual
      }
    }
  }
`;

export default Dashboard;
