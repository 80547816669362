import { Box, TextField } from "@mui/material";

export const customDisplay = (project: string, custom: string, setCustom: any, custom1: any, setCustom1: any, custom2: any, setCustom2: any) => {
  if (project === "646e965a0be1b1b0f611190d") {
    return (
      <>
        <Box sx={{ my: 1 }}>Please enter your licence plate for free parking</Box>
        <TextField
          fullWidth
          label="Licence Plate"
          variant="outlined"
          value={custom}
          onChange={(e) => setCustom(e.target.value)}
          inputProps={{ maxLength: 8 }}
        />
        <Box sx={{ my: 1 }}>Vehicle Type (e.g. Toyota etc ... )</Box>
        <TextField fullWidth label="Vehicle Type (e.g. Toyota etc ... )" variant="outlined" value={custom1} onChange={(e) => setCustom1(e.target.value)} />
        <Box sx={{ my: 1 }}>Vehicle Model (e.g. Prius etc ... )</Box>
        <TextField fullWidth label="Vehicle Model (e.g. Prius etc ... )" variant="outlined" value={custom2} onChange={(e) => setCustom2(e.target.value)} />
      </>
    );
  }
};
