import { useState, useEffect } from "react";
import { Typography, Box, Button, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyQuery, useMutation, gql } from "@apollo/client";

import { ITextTemplate } from "../../../types/textTemplate";
import { IProject } from "../../../types/project";
import { FlexBetween, SettingContainer } from "../../../commonStyles";
import { convertAllDates } from "../../../utils/function";
import { allTokenValues } from "../../../utils/constant";
import StandardTable from "../../tables/StandardTable";
import { selectTextTemplateCount, setTemplates } from "../../../features/textTemplate/textTemplateSlice";
import { useTextTemplatesQuery, useSelectTextTemplates } from "../../../features/textTemplate/textTemplateHooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectUser } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { GlobalModal } from "../../../features/modal/Modal";
import { handleModal } from "../../../features/modal/modalSlice";

const TextMessaging = (props: ChildProps) => {
  const { userId } = props;
  const user = useSelector(selectUser);
  const textTemplates = useSelectTextTemplates();
  const count = useSelector(selectTextTemplateCount);
  const storeDispatch = useAppDispatch();

  const [textTemplate, setTextTemplate] = useState<ITextTemplate | null>(null);
  const [phoneNumbers, setPhoneNumbers] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [sms, setSms] = useState<string>("");
  const [textValue, setTextValue] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [step, setStep] = useState<boolean>(false);

  const [getTexts, { loading }] = useTextTemplatesQuery(pageNumber, 15, "_ID_DESC", userId ? userId : null);

  const [createSms] = useMutation(CREATESMS, {
    onCompleted: (data) => {
      let updatedTextTemplates = [data.textTemplateCreateOne.record, ...textTemplates];
      storeDispatch(setTemplates({ items: updatedTextTemplates, count: updatedTextTemplates.length }));
      storeDispatch(showSuccessSnackbar("Text Template Created!"));
      storeDispatch(handleModal(false));
      updateUserId({
        variables: { _id: user?._id, record: { texts: updatedTextTemplates.map((textTemplate: ITextTemplate) => textTemplate._id) } },
      });
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateSms] = useMutation(UPDATESMS, {
    onCompleted: (data) => {
      let updatedTextTemplates = textTemplates.map((textTemplate: ITextTemplate) => {
        if (textTemplate._id === data.textTemplateUpdateById.record._id) {
          return data.textTemplateUpdateById.record;
        } else return textTemplate;
      });
      storeDispatch(setTemplates({ items: updatedTextTemplates, count: updatedTextTemplates.length }));
      storeDispatch(showSuccessSnackbar("Text Template Updated!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [deleteSMS] = useMutation(DELETESMS, {
    onCompleted: () => {},
  });

  useEffect(() => {
    getTexts();
  }, [pageNumber, getTexts]);

  const [getTextTemplates] = useLazyQuery(GETTEXTTEMPLATES, {
    onCompleted: (data) => {
      storeDispatch(setTemplates(data.textTemplatePagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [updateUserId] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getTextTemplates({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "_ID_DESC" } });
  };

  const deleteTemplate = (data: ITextTemplate) => {
    deleteSMS({ variables: { _id: data._id } });
    updateUserId({
      variables: { _id: user?._id, record: { texts: textTemplates.filter((text: ITextTemplate) => text._id !== data._id) } },
    }).then((res) => {
      let updatedTextTemplates = textTemplates.filter((textTemplate: ITextTemplate) => textTemplate._id !== data._id);
      storeDispatch(setTemplates({ items: updatedTextTemplates, count: updatedTextTemplates.length }));
      storeDispatch(showSuccessSnackbar("Text Template Updated!"));
    });
  };

  const columns = [
    {
      Header: "Template Name",
      accessor: (rowData: ITextTemplate) => {
        return (
          <strong
            style={{ cursor: "pointer" }}
            onClick={() => {
              setName(rowData.name);
              setStep(rowData.step);
              setSms(rowData.text);
              setSelectedProject(rowData.project);
              setTextTemplate(rowData);
              storeDispatch(handleModal(true));
            }}
          >
            {rowData.name}
          </strong>
        );
      },
    },
    {
      Header: "Text Message",
      accessor: (rowData: ITextTemplate) => {
        return rowData.text;
      },
    },
    {
      Header: "Project",
      accessor: (rowData: ITextTemplate) => {
        return rowData.project ? rowData.project.name : "";
      },
    },
    {
      Header: "Created Date",
      accessor: (rowData: ITextTemplate) => {
        return convertAllDates(rowData.createdAt, "PPpp");
      },
    },
    {
      Header: "Last Updated",
      accessor: (rowData: ITextTemplate) => {
        return convertAllDates(rowData.updatedAt, "PPpp");
      },
    },
    {
      Header: " ",
      accessor: (rowData: ITextTemplate) => {
        return <DeleteIcon sx={{ cursor: "pointer" }} color="error" onClick={() => deleteTemplate(rowData)} />;
      },
    },
  ];

  const handleInsertText = (e: any) => {
    let textToInsert = textValue;
    let cursorPosition = e.target.selectionStart;
    let textBeforeCursorPosition = e.target.value.substring(0, cursorPosition);
    let textAfterCursorPosition = e.target.value.substring(cursorPosition, e.target.value.length);
    e.target.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
    setSms(e.target.value);
    setTextValue("");
  };

  const submitSms = (e: any) => {
    e.preventDefault();
    if (textTemplate) {
      updateSms({
        variables: {
          _id: textTemplate._id,
          record: {
            name: name,
            text: sms,
            project: selectedProject?._id,
            step: userId ? false : step,
            user: userId ? userId : null,
          },
        },
      });
    } else {
      createSms({
        variables: {
          record: {
            name: name,
            text: sms,
            project: selectedProject?._id,
            step: userId ? false : step,
            user: userId ? userId : null,
          },
        },
      });
    }
  };

  return (
    <SettingContainer>
      <GlobalModal>
        <form onSubmit={submitSms}>
          <Box>
            <Typography variant="h5">
              <strong>{textTemplate ? "Edit" : "Create"} SMS Template</strong>
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <TextField
              title={"Name"}
              name={"name"}
              required
              placeholder="Enter Text Template Name"
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Box>
          {!userId ? (
            <Box>
              <FormControl fullWidth sx={{ my: 1 }}>
                <InputLabel>Project</InputLabel>
                <Select
                  name={"project"}
                  label={"Project"}
                  value={selectedProject?._id}
                  required
                  onChange={(e) => {
                    let selectedProject = user?.projectAccess.find((projects: any) => projects.project._id === e.target.value);
                    if (selectedProject) {
                      setSelectedProject(selectedProject.project);
                    }
                  }}
                >
                  {user?.projectAccess.map((project, index: number) => (
                    <MenuItem key={index} value={project.project._id}>
                      {project.project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : null}
          <Box>
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel>Token Value</InputLabel>
              <Select name={"textValue"} label={"Token Value"} value={textValue} onChange={(e) => setTextValue(e.target.value)}>
                {allTokenValues.map((value: string, index: number) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {textValue ? (
              <Box sx={{ my: 0.5 }}>
                <em>Please click where you would like to place the token</em>
              </Box>
            ) : null}
          </Box>
          <Box>
            <TextField
              title={"SMS"}
              name={"sms"}
              placeholder="Enter SMS"
              fullWidth
              multiline
              rows={3}
              value={sms}
              onClick={(e) => {
                handleInsertText(e);
              }}
              onChange={(e) => {
                setSms(e.target.value);
              }}
            />
          </Box>
          {!userId ? (
            <FormControlLabel id={"step"} control={<Checkbox checked={step} onChange={() => setStep(!step)} />} label={"Step Template"} />
          ) : null}
          {textTemplate ? (
            <Box>
              <TextField
                title={"Phone Numbers (1xxxxxxxxx)"}
                name={"name"}
                placeholder="Enter Phone Numbers (Ex 14162346476, 16478852306)"
                fullWidth
                value={phoneNumbers}
                onChange={(e) => {
                  setPhoneNumbers(e.target.value);
                }}
              />
            </Box>
          ) : null}
          <FlexBetween sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                storeDispatch(handleModal(false));
                setSms("");
                setTextValue("");
              }}
            >
              Cancel
            </Button>
            <Box>
              <Button variant="contained" color="success" type="submit">
                Submit
              </Button>
            </Box>
          </FlexBetween>
        </form>
      </GlobalModal>
      <FlexBetween>
        <Typography variant="h2" sx={{ display: "flex" }}>
          <Box sx={{ alignSelf: "center" }}>
            <strong>{userId ? "Your" : ""} SMS Templates</strong>
          </Box>
        </Typography>
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setName("");
              setSelectedProject(null);
              setSms("");
              setStep(false);
              setTextTemplate(null);
              storeDispatch(handleModal(true));
            }}
          >
            Create SMS Template
          </Button>
        </Box>
      </FlexBetween>
      <Box sx={{ mt: 2 }}>
        {loading ? (
          <></>
        ) : (
          <>
            <StandardTable
              data={textTemplates}
              columns={columns}
              loading={loading}
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
            />
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
                {"<"}
              </Button>
              <span>{pageNumber}</span>
              <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
                {">"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </SettingContainer>
  );
};

interface ChildProps {
  userId: string | null;
}

const GETTEXTTEMPLATES = gql`
  query textTemplatePagination(
    $filter: FilterFindManyTextTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyTextTemplateInput
  ) {
    textTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        name
        text
        step
        project {
          _id
          name
        }
        _id
        createdAt
        updatedAt
      }
      count
    }
  }
`;

const CREATESMS = gql`
  mutation textTemplateCreateOne($record: CreateOneTextTemplateInput!) {
    textTemplateCreateOne(record: $record) {
      record {
        _id
        name
        text
        project {
          _id
          name
        }
        step
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATESMS = gql`
  mutation textTemplateUpdateById($_id: MongoID!, $record: UpdateByIdTextTemplateInput!) {
    textTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        text
        project {
          _id
          name
        }
        step
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETESMS = gql`
  mutation textTemplateRemoveById($_id: MongoID!) {
    textTemplateRemoveById(_id: $_id) {
      recordId
    }
  }
`;

const UPDATEUSER = gql`
  mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
    userUpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export default TextMessaging;
