import { gql, useMutation } from "@apollo/client";
import { useState, useEffect, useMemo } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Typography, Box, FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { FlexBetween } from "../../commonStyles";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { handleModal } from "../../features/modal/modalSlice";
import { convertAllDates, numToCurrency } from "../../utils/function";
import { useNavigate } from "react-router";
import { useTextTemplatesQuery } from "../../features/textTemplate/textTemplateHooks";
import { selectTextTemplates } from "../../features/textTemplate/textTemplateSlice";
import { ITextTemplate } from "../../types/textTemplate";

const MassText = (props: ChildProps) => {
  const { id, selectedRegistrants, project } = props;
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useSelector(selectUser);
  const textTemplates = useSelector(selectTextTemplates);
  const [name, setName] = useState<string>("");
  const [textTemplate, setTextTemplate] = useState<ITextTemplate | null>(null);
  const [textDate, setTextDate] = useState<Date | null>(null);
  
  const [getTexts] = useTextTemplatesQuery(1, 100, "_ID_DESC");

  const [saveTextSchedule] = useMutation(SAVETEXTSCHEDULE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Scheduled Text Saved!"));
      storeDispatch(handleModal(false));
      navigate(`/dashboard`);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Access Forbidden"));
    },
  });

  useEffect(() => {
    getTexts();
    // eslint-disable-next-line
  }, []);

  const handleTextTemplate = (value: string) => {
    let selectedTextTemplate = textTemplates.find((textTemplate: ITextTemplate) => textTemplate._id === value)
    if (selectedTextTemplate) {
      setTextTemplate(selectedTextTemplate);
    }
  }

  const handleSmsSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!textDate) return storeDispatch(showErrorSnackbar("Date for Mass Mail is required"));
    if (!textTemplate) return storeDispatch(showErrorSnackbar("Template is required"));

    let allRegistrants = selectedRegistrants;

    let template = textTemplates.find((template: ITextTemplate) => template._id === textTemplate._id);

    if (template) {
      saveTextSchedule({
        variables: {
          record: {
            project: project ? project : template?.project._id,
            name: name,
            textTemplate: textTemplate._id,
            user: auth?._id,
            textDate,
            count: selectedRegistrants.length,
            type: id === "custom" ? "custom" : id === "purchasers" ? "purchaser" : "registrant",
            registrants: id === "custom" ? [] : id === "purchasers" ? [] : allRegistrants,
            purchasers: id === "purchasers" ? allRegistrants : [],
          },
        },
      });
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Send Mass Text</strong>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="h3" component="div" gutterBottom>
        <strong>
          Selected: <Box sx={{ display: "inline", color: "success.main" }}>{selectedRegistrants.length}</Box>
        </strong>
      </Typography>
      <Box>
        <strong>
          Approximate Cost:{" "}
          <Box sx={{ display: "inline", color: "error.main" }}>{textTemplate ? numToCurrency.format(Math.ceil(textTemplate?.text.length / 150) * 0.01348 * selectedRegistrants.length) : numToCurrency.format(selectedRegistrants.length * 0.01348)}USD</Box>
        </strong>
      </Box>
      <Box>
        <TextField
          title={"Name"}
          name={"name"}
          fullWidth
          value={name}
          label={"Name"}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />
      </Box>
      <form onSubmit={handleSmsSubmit}>
        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="SMS Date"
              value={textDate}
              minDate={textDate}
              disablePast
              onChange={(newValue) => {
                setTextDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>SMS Template</InputLabel>
            <Select
              required
              name={"SmsTemplate"}
              label={"SMS Template"}
              value={textTemplate ? textTemplate._id : ''}
              onChange={(e) => handleTextTemplate(e.target.value)}
            >
              {textTemplates.map((template, i) => (
                <MenuItem key={i} value={template._id}>
                  {template.name} ({convertAllDates(template.createdAt, "PP")})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FlexBetween sx={{ mt: 2 }}>
          <Button type="submit" color="success" variant="contained">
            Schedule
          </Button>
          <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
            Cancel
          </Button>
        </FlexBetween>
      </form>
    </div>
  );
};

interface ChildProps {
  id: string;
  selectedRegistrants: string[];
  project?: string | null;
}

const SAVETEXTSCHEDULE = gql`
  mutation textScheduleCreateOne($record: CreateOneTextScheduleInput!) {
    textScheduleCreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export default MassText;
