import { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  Typography,
  TextField,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/hooks";
import { selectUser } from "../../features/auth/authSlice";
import { convertAllDates } from "../../utils/function";
import { showSuccessSnackbar, showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { Container, FlexBetween, FlexEnd } from "../../commonStyles";
import { IRegistrant } from "../../types/registrant";
import { IComment } from "../../types/comment";
import {
  addComment,
  selectComments,
  setComments,
  updateComment,
  selectCount,
  selectSubComments,
  selectSubCount,
  setSubComments,
  deleteComment,
  restoreComment,
} from "../../features/comments/commentSlice";
import { defaultNotes } from "../../utils/constant";

const Notes = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const comments = useSelector(props.step ? selectSubComments : selectComments);
  const count = useSelector(props.step ? selectSubCount : selectCount);
  const { registrant } = props;

  const [addView, setAddView] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>("");
  const [editComment, setEditComment] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [showDeleted, setShowDeleted] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<IComment | null>(null);
  const [confirmRestore, setConfirmRestore] = useState<IComment | null>(null);
  const [showDefaultNotes, setShowDefaultNotes] = useState<boolean>(false);

  // Queries/Mutations

  const { loading } = useQuery(GETCOMMENTS, {
    variables: {
      filter: { registrant: registrant._id, step: props.step, deleted: showDeleted ? undefined : false },
      page: pageNumber,
      perPage: 5,
      sort: "PINNED",
    },
    onCompleted: (data) => {
      if (props.step) {
        storeDispatch(setSubComments(data.commentPagination));
      } else {
        storeDispatch(setComments(data.commentPagination));
      }
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
    fetchPolicy: "network-only",
  });

  const [editSelectedComment] = useMutation(UPDATECOMMENT, {
    onCompleted: (data) => {
      storeDispatch(updateComment(data.commentUpdateById.record));
      storeDispatch(showSuccessSnackbar("Note Updated!"));
      setAddView(false);
      setEditComment("");
      setNewComment("");
      setShowDefaultNotes(false);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [editPin] = useMutation(UPDATECOMMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Note Updated!"));
      setAddView(false);
      setEditComment("");
      setNewComment("");
      setShowDefaultNotes(false);

      let updateComments = [...comments];

      if (data.commentUpdateById.record.pinned) {
        updateComments = comments
          .map((comment: IComment) => {
            if (comment._id === data.commentUpdateById.record._id) {
              return {
                ...comment,
                pinned: true,
              };
            } else return comment;
          })
          .sort((a: any, b: any) => b.pinned - a.pinned);
      } else {
        updateComments = comments
          .map((comment: IComment) => {
            if (comment._id === data.commentUpdateById.record._id) {
              return {
                ...comment,
                pinned: false,
              };
            } else return comment;
          })
          .sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
      }

      storeDispatch(setComments({ items: updateComments, count: updateComments.length }));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [deleteSelectedComment] = useMutation(UPDATECOMMENT, {
    onCompleted: (data) => {
      if (showDeleted) {
        storeDispatch(updateComment(data.commentUpdateById.record));
      } else {
        storeDispatch(deleteComment(data.commentUpdateById.record));
      }
      storeDispatch(showSuccessSnackbar("Note Deleted!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [restoreSelectedComment] = useMutation(UPDATECOMMENT, {
    onCompleted: (data) => {
      storeDispatch(restoreComment(data.commentUpdateById.record));
      storeDispatch(showSuccessSnackbar("Note Restored!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [registrantUpdateById] = useMutation(UPDATEREGISTRANT, {
    onCompleted: (data) => {},
    onError: (err) => {
    },
  });

  const [createComment] = useMutation(CREATECOMMENT, {
    onCompleted: (data) => {
      storeDispatch(addComment(data.commentCreateOne.record));
      storeDispatch(showSuccessSnackbar("Note Created!"));
      registrantUpdateById({
        variables: {
          _id: registrant._id,
          record: {
            comments: [...comments.map((comment: IComment) => comment._id), data.commentCreateOne.record._id],
          },
        },
      });
      setAddView(false);
      setNewComment("");
      setShowDefaultNotes(false);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleSave = (comment: IComment | null) => {
    if (newComment.length < 10) return storeDispatch(showErrorSnackbar("Note is too short"));

    if (!comment) {
      createComment({
        variables: {
          record: {
            user: user?._id,
            project: registrant.project._id,
            type: "general",
            comment: newComment,
            registrant: registrant._id,
            step: props.step || null,
            pinned: false,
            deleted: false,
          },
        },
      });
    } else {
      editSelectedComment({
        variables: {
          _id: comment._id,
          record: {
            user: user?._id,
            project: registrant.project._id,
            type: "general",
            comment: newComment,
            registrant: registrant._id,
          },
        },
      });
    }
  };

  const handleComment = (comment: IComment | null, type: string) => {
    if (type === "edit" && comment) {
      setNewComment(comment.comment);
      setEditComment(comment._id);
      setAddView(false);
    } else if (type === "add") {
      setNewComment("");
      setEditComment("");
      setAddView(true);
    }
  };

  const handlePin = (comment: IComment | null, pin: boolean) => {
    editPin({
      variables: {
        _id: comment?._id,
        record: {
          pinned: pin,
        },
      },
    });
  };

  const handleDelete = (comment: IComment) => {
    deleteSelectedComment({
      variables: {
        _id: comment._id,
        record: {
          deleted: true,
        },
      },
    });
  };

  const handleRestore = (comment: IComment) => {
    restoreSelectedComment({
      variables: {
        _id: comment._id,
        record: {
          deleted: false,
        },
      },
    });
  };

  const handleClose = () => {
    setConfirmDelete(null);
    setConfirmRestore(null);
  };

  const handleDefaultNotes = () => {
    if (!newComment) {
      setShowDefaultNotes(true);
    }
  };

  const handleDefaultNotesBlur = (e: any) => {
    e.preventDefault();
    if (!newComment) {
      setShowDefaultNotes(false);
    }
  };

  const noteComponent = (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <strong>Notes</strong>
            {user?.type === "Manager" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDeleted}
                    onChange={() => {
                      setPageNumber(1);
                      setShowDeleted(!showDeleted);
                    }}
                    sx={{ ml: 3 }}
                  />
                }
                label="Show Deleted"
              />
            )}
          </Box>
          {!addView ? (
            <>
              {!editComment ? (
                <Button variant="contained" color="primary" onClick={() => handleComment(null, "add")}>
                  Add Note
                </Button>
              ) : null}
            </>
          ) : (
            <div>
              <ArrowBackIcon onClick={() => setAddView(false)} sx={{ mr: 1, cursor: "pointer", color: "primary.main" }} fontSize="small" />
              <SaveIcon onClick={() => handleSave(null)} fontSize="small" sx={{ cursor: "pointer", color: "success.main" }} />
            </div>
          )}
        </FlexBetween>
      </Typography>
      {addView && !editComment ? (
        <Box sx={{ mb: 2, position: "relative" }}>
          <TextField
            aria-label="minimum height"
            multiline
            minRows={3}
            onFocus={() => handleDefaultNotes()}
            onBlur={(e) => handleDefaultNotesBlur(e)}
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
            sx={{ width: "100%", zIndex: 9 }}
          />
          {showDefaultNotes && !newComment ? (
            <Box
              sx={{
                position: "absolute",
                top: 30,
                left: 30,
                border: "1px solid #000",
                backgroundColor: "#dddee9",
                p: 1,
                borderRadius: "3px 20px 20px 3px",
                borderBottomLeftRadius: "20px",
                zIndex: 10,
              }}
            >
              {[...user?.notes!, ...defaultNotes].map((string: string) => {
                return (
                  <Box id={string} onMouseDown={() => setNewComment(string)} sx={{ cursor: "pointer", zIndex: 100000 }}>
                    {string}
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      ) : null}
      {loading ? null : comments.length ? (
        <div>
          {comments.map((comment: IComment, index: number) => {
            if (editComment === comment._id) {
              return (
                <Box key={index} sx={{ position: "relative" }}>
                  <TextField
                    aria-label="minimum height"
                    multiline
                    minRows={3}
                    onFocus={() => handleDefaultNotes()}
                    onBlur={(e) => handleDefaultNotesBlur(e)}
                    onChange={(e) => setNewComment(e.target.value)}
                    value={newComment}
                    sx={{
                      width: "100%",
                      mb: 2,
                      "& .MuiInputBase-root": {
                        px: 1,
                        pt: 1,
                        pb: 3,
                      },
                    }}
                  />
                  {showDefaultNotes && !newComment ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 30,
                        left: 30,
                        border: "1px solid #000",
                        backgroundColor: "#dddee9",
                        p: 1,
                        borderRadius: "3px 20px 20px 3px",
                        borderBottomLeftRadius: "20px",
                        zIndex: 10,
                      }}
                    >
                      {[...user?.notes!, ...defaultNotes].map((string: string) => {
                        return (
                          <Box id={string} onMouseDown={() => setNewComment(string)} sx={{ cursor: "pointer", zIndex: 100000 }}>
                            {string}
                            <Divider />
                          </Box>
                        );
                      })}
                    </Box>
                  ) : null}
                  <Box sx={{ position: "absolute", bottom: 12, right: 5 }}>
                    <ArrowBackIcon
                      onClick={() => setEditComment("")}
                      sx={{ mr: 1, cursor: "pointer", color: "primary.main" }}
                      fontSize="small"
                    />
                    <SaveIcon onClick={() => handleSave(comment)} fontSize="small" sx={{ cursor: "pointer", color: "success.main" }} />
                  </Box>
                </Box>
              );
            } else
              return (
                <Box
                  key={index}
                  sx={{
                    p: 1,
                    border: "1px solid #000",
                    borderRadius: "8px",
                    background: comment.deleted ? "#ffcdd2" : comment.pinned ? "#b1e3b3" : "#dddee9",
                    mb: 2,
                  }}
                >
                  <Box sx={{ fontSize: "14px", mb: 2 }}>{comment.comment}</Box>
                  <FlexEnd sx={{ fontSize: "12px" }}>
                    <Box sx={{ flexDirection: "column" }}>
                      <Box sx={{ textAlign: "right" }}>
                        <em>{comment.user.fullName}</em>
                        {comment.pinned ? (
                          <PushPinIcon
                            onClick={() => handlePin(comment, false)}
                            sx={{ cursor: "pointer", ml: 1, transform: "rotate(180deg)", "-webkit-transform": "rotate(180deg)" }}
                            fontSize="inherit"
                          />
                        ) : (
                          <PushPinIcon onClick={() => handlePin(comment, true)} sx={{ cursor: "pointer", ml: 1 }} fontSize="inherit" />
                        )}
                        {comment.user._id === user?._id ? (
                          <>
                            <EditIcon onClick={() => handleComment(comment, "edit")} sx={{ cursor: "pointer", ml: 1 }} fontSize="inherit" />
                            {!comment.deleted && (
                              <DeleteIcon
                                onClick={() => setConfirmDelete(comment)}
                                sx={{ cursor: "pointer", ml: 0.5 }}
                                fontSize="inherit"
                              />
                            )}
                          </>
                        ) : null}
                        {comment.deleted && user?.type === "Manager" && (
                          <RestoreIcon onClick={() => setConfirmRestore(comment)} sx={{ cursor: "pointer", ml: 0.5 }} fontSize="inherit" />
                        )}
                      </Box>
                      <div>
                        <em>{convertAllDates(comment.createdAt, "PPpp")}</em>
                      </div>
                      {comment.step ? (
                        <Box sx={{ textAlign: "right" }}>
                          <em>{comment.step.name}</em>
                        </Box>
                      ) : null}
                    </Box>
                  </FlexEnd>
                </Box>
              );
          })}
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 5)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </div>
      ) : !addView ? (
        <div>There are currently no notes.</div>
      ) : null}
      <Dialog open={Boolean(confirmDelete) || Boolean(confirmRestore)} onClose={handleClose}>
        {(confirmDelete || confirmRestore) && (
          <>
            <DialogTitle>Confirm {confirmDelete ? "Delete" : "Restore"}</DialogTitle>
            <DialogContent>
              Are you sure you want to {confirmDelete ? "delete" : "restore"} the note "{confirmDelete?.comment}
              {confirmRestore?.comment}"?
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <FlexBetween sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    if (confirmDelete) {
                      handleDelete(confirmDelete);
                    } else if (confirmRestore) {
                      handleRestore(confirmRestore);
                    }
                    handleClose();
                  }}
                >
                  {confirmDelete ? "Delete" : "Restore"}
                </Button>
                <Button variant="contained" color="info" onClick={handleClose}>
                  Cancel
                </Button>
              </FlexBetween>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );

  return props.step ? noteComponent : <Container sx={{ height: "100%" }}>{noteComponent}</Container>;
};

interface ChildProps {
  registrant: IRegistrant;
  step?: string;
}

const CREATECOMMENT = gql`
  mutation commentCreateOne($record: CreateOneCommentInput!) {
    commentCreateOne(record: $record) {
      record {
        _id
        comment
        registrant {
          _id
        }
        step {
          _id
        }
        pinned
        user {
          _id
          fullName
        }
        type
        deleted
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATECOMMENT = gql`
  mutation commentUpdateById($_id: MongoID!, $record: UpdateByIdCommentInput!) {
    commentUpdateById(_id: $_id, record: $record) {
      record {
        _id
        comment
        registrant {
          _id
        }
        step {
          _id
        }
        pinned
        user {
          _id
          fullName
        }
        type
        deleted
        createdAt
        updatedAt
      }
    }
  }
`;

const GETCOMMENTS = gql`
  query commentPagination($filter: FilterFindManyCommentInput, $page: Int!, $perPage: Int!, $sort: SortFindManyCommentInput) {
    commentPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        comment
        registrant {
          _id
        }
        step {
          _id
          name
        }
        pinned
        user {
          _id
          fullName
        }
        type
        deleted
        createdAt
        updatedAt
      }
      count
    }
  }
`;

const UPDATEREGISTRANT = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export default Notes;
