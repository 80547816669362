import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Typography, Box, Grid, Card, Skeleton } from "@mui/material";

import { humanDuration } from "../../../utils/function";
import { IProject } from "../../../types/project";

const Analytics = (props: ChildProps) => {
  const { project, dateStart, dateEnd } = props;
  const [dailyAnalytics, setDailyAnalytics] = useState([1, 2, 3, 4]);
  const [realTimeAnalytics, setRealTimeAnalytics] = useState<IRealTimeAnalytics | null>(null);

  const { loading } = useQuery(GETANALYTICS, {
    skip: !project,
    variables: { project: project?._id, dateStart: dateStart, dateEnd: dateEnd },
    onCompleted: (data) => {
      setDailyAnalytics(data.getAnalytics.dailyInteractions);
      setRealTimeAnalytics(data.getAnalytics.realTimeInteractions);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  return (
    <div>
      <Typography sx={{ mt: 2 }} variant="h2" component="div" gutterBottom>
        <strong>Google Analytics</strong>
      </Typography>
      <Typography sx={{ my: 2 }} variant="h3" component="div" gutterBottom>
        <strong>Daily Analytics (Updates Daily)</strong>
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {dailyAnalytics.map((analytics: any, index: number) => {
          return (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Card sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", width: "100%", p: 2 }}>
                  {loading ? (
                    <Box sx={{ width: "100%" }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h3" component="div" gutterBottom>
                        <strong>{analytics.name}</strong>
                      </Typography>
                      {analytics.name === "Clicks" ? (
                        <div>Total Clicks: {parseInt(analytics.totalTime)}</div>
                      ) : (
                        <div>Total Time Spent: {humanDuration(parseInt(analytics.totalTime))}</div>
                      )}
                      <div>Unique Users: {analytics.distinctUsers}</div>
                      {analytics.name !== "Clicks" ? (
                        <div>Average Time Spent: {humanDuration(parseInt(analytics.totalTime) / parseInt(analytics.distinctUsers))}</div>
                      ) : (
                        <div>
                          Average Clicks:{" "}
                          {analytics.totalTime !== "0" ? parseInt(analytics.totalTime) / parseInt(analytics.distinctUsers) : "0"}
                        </div>
                      )}
                    </Box>
                  )}
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Typography sx={{ my: 2 }} variant="h3" component="div" gutterBottom>
        <strong>Realtime Analytics (Within 30 Minutes)</strong>
      </Typography>
      <div>Current Users: {realTimeAnalytics ? realTimeAnalytics.activeUsers : "0"}</div>
      <div>Total Clicks: {realTimeAnalytics ? realTimeAnalytics.totalClicks : "0"}</div>
    </div>
  );
};

interface ChildProps {
  project: IProject | null;
  dateStart: Date | null;
  dateEnd: Date | null;
}

interface IRealTimeAnalytics {
  activeUsers: string;
  totalClicks: string;
}

const GETANALYTICS = gql`
  query getAnalytics($project: MongoID!, $dateStart: Date!, $dateEnd: Date!) {
    getAnalytics(project: $project, dateStart: $dateStart, dateEnd: $dateEnd) {
      dailyInteractions {
        name
        totalTime
        distinctUsers
      }
      realTimeInteractions {
        totalClicks
        activeUsers
      }
    }
  }
`;

export default Analytics;
