import { useState, Dispatch, SetStateAction } from "react";
import { Typography, Box, Button, TablePagination } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gql, useLazyQuery, useSubscription } from "@apollo/client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/hooks";
import StandardTable from "../tables/StandardTable";
import { IRegistrant } from "../../types/registrant";
import {
  selectRegistrantCount,
  selectRegistrantList,
  selectProjectQuestions,
  updateRegistrants,
} from "../../features/projectSetting/projectSettingSlice";
import { selectProject } from "../../features/project/projectSlice";
import { FlexBetween } from "../../commonStyles";
import { downloadExcel, downloadPdf, normalToCamel } from "../../utils/function";
import { GlobalModal } from "../../features/modal/Modal";
import { handleModal } from "../../features/modal/modalSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { IUser } from "../../types/user";
import CreateRegistrant from "../projectSettings/CreateRegistrant";
import { selectUser } from "../../features/auth/authSlice";
import MassMail from "./MassMail";
import RegistrantFilter from "./RegistrantFilter";
import MassText from "./MassText";

const RegistrantTable = (props: ChildProps) => {
  const {
    purchasers,
    selectedRegistrants,
    handleGlobalFilterValue,
    columns,
    loading,
    id,
    name,
    pageNumber,
    perPage,
    getPurchasers,
    purchaserCount,
    handleQueryParams,
    handlePerRows,
    realtorTypeFilter,
    statusFilter,
    ratingFilter,
    salesRepFilter,
    sourceFilter,
    subscribedFilter,
    connectedFilter,
    startDateFilter,
    endDateFilter,
    query,
  } = props;

  const { projectid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const registrants = useSelector(selectRegistrantList);
  const auth = useSelector(selectUser);
  const count = useSelector(selectRegistrantCount);
  const questions = useSelector(selectProjectQuestions);
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  let navigate = useNavigate();

  const [modalType, setModalType] = useState<string>("");

  const [showFilter, setShowFilter] = useState<boolean>(window.innerWidth < 600 ? false : true);

  const [getRegistrants, { loading: downloadLoading }] = useLazyQuery(GETREGISTRANTSFROMLIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("An email will be sent to you shortly"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("There was an error emailing list"));
    },
  });

  const [getMailRegistrants, { loading: mailDownloadLoading }] = useLazyQuery(GETREGISTRANTSFROMMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("An email will be sent to you shortly"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("There was an error emailing list"));
    },
  });

  useSubscription(REGISTRANTSUBSCRIPTION, {
    onSubscriptionData: (data) => {
      const updatedRegistrants = data.subscriptionData.data.updatedRegistrants;
      for (let updatedRegistrant of updatedRegistrants) {
        const curIndex = registrants.findIndex((r) => r._id === updatedRegistrant._id);
        if (curIndex === -1) continue;
        let oldRegistrant = registrants[curIndex];
        if (oldRegistrant.rating !== updatedRegistrant.rating) {
          storeDispatch(updateRegistrants({ value: updatedRegistrant.rating, name: "rating", data: oldRegistrant }));
          oldRegistrant = { ...oldRegistrant, rating: updatedRegistrant.rating };
        }
        if (oldRegistrant.salesRep?._id !== updatedRegistrant.salesRep?._id) {
          storeDispatch(updateRegistrants({ value: updatedRegistrant.salesRep, name: "salesRep", data: oldRegistrant }));
          oldRegistrant = { ...oldRegistrant, salesRep: updatedRegistrant.salesRep };
        }
      }
    },
  });

  const download = async (type: string) => {
    if (!query) {
      await getRegistrants({
        variables: {
          project: projectid,
          listId: id,
          page: pageNumber,
          perPage: 100000,
          search: "",
          filter: {
            realtorType: !realtorTypeFilter || realtorTypeFilter === "any" ? null : realtorTypeFilter,
            status: !statusFilter || statusFilter === "any" ? null : statusFilter,
            rating: !ratingFilter || ratingFilter === "any" ? null : ratingFilter,
            salesRep: !salesRepFilter || salesRepFilter === "any" ? null : (salesRepFilter as any)._id,
            source: !sourceFilter || sourceFilter === "any" ? null : sourceFilter,
            subscribed: !subscribedFilter || subscribedFilter === "any" ? null : subscribedFilter,
            connected: !connectedFilter || connectedFilter === "any" ? null : connectedFilter,
            startDate: startDateFilter,
            endDate: endDateFilter,
            searchStart: searchParams.get("start") ? new Date(parseInt(searchParams.get("start")!, 10)) : null,
            searchEnd: searchParams.get("end") ? new Date(parseInt(searchParams.get("end")!, 10)) : null,
            user: searchParams.get("user") ? searchParams.get("user") : null,
          },
          registrants: selectedRegistrants,
          comments: true,
        },
      });
    } else {
      await getMailRegistrants({
        variables: {
          project: projectid,
          mailId: id,
          query: normalToCamel(query!),
          page: pageNumber,
          perPage: 100000,
          search: "",
          registrants: selectedRegistrants,
          download: true,
        },
      });
    }
  };

  const purchaserDownload = async (type: string) => {
    const { data } = await getPurchasers({ variables: { projects: [projectid], page: pageNumber, perPage: 50000, search: "" } });

    let headers = columns
      .filter((column: any) => typeof column.Header === "string")
      .map((column: any) => {
        return {
          label: column.Header,
          id: normalToCamel(column.Header),
        };
      });

    let allRegistrants = data.getPurchasers.purchasers.map((purchaser: any) => {
      return {
        firstName: purchaser._id.purchaser.firstName,
        lastName: purchaser._id.purchaser.lastName,
        email: purchaser._id.purchaser.email,
        phone: purchaser._id.purchaser.primaryPhone,
        suite: purchaser.suite,
        totalNotes: purchaser.registrant ? purchaser.registrant.comments.length : 0,
        salesRep: purchaser.registrant ? (purchaser.registrant.salesRep ? purchaser.registrant.salesRep.fullName : "") : "",
        connected: purchaser.registrant ? (purchaser.registrant.connected ? "Connected" : "Not Connected") : "",
      };
    });

    let columnWidths = data.getPurchasers.purchasers.map((registrant: IRegistrant) => {
      if (type === "excel") {
        return {
          firstName: 15,
          lastName: 15,
          email: 20,
          phone: 15,
          suite: 15,
          totalNotes: 15,
          salesRep: 15,
          connected: 15,
        };
      } else
        return {
          firstName: 200,
          lastName: 200,
          email: 200,
          phone: 200,
          suite: 200,
          totalNotes: 200,
          salesRep: 200,
          connected: 200,
        };
    });

    let sheetTitle = ["Registrants"];
    let fileName = id === "purchasers" ? "Purchasers" : "Registrants";

    if (type === "excel") {
      downloadExcel([allRegistrants], [headers], [], [columnWidths], sheetTitle, fileName);
    } else {
      downloadPdf([allRegistrants], [headers], [], columnWidths, sheetTitle, fileName);
    }
  };

  const handleModalView = (type: string) => {
    if (type === "mail" || type === "text") {
      if (!selectedRegistrants.length) return storeDispatch(showErrorSnackbar("No Registrants Selected"));
      setModalType(type);
    } else {
      setModalType(type);
    }
    storeDispatch(handleModal(true));
  };

  return (
    <div>
      <GlobalModal>
        {modalType === "text" ? (
          <MassText id={id!} selectedRegistrants={selectedRegistrants} project={project ? project._id : null} />
        ) : null}
        {modalType === "mail" ? <MassMail id={id!} selectedRegistrants={selectedRegistrants} project={project!} /> : null}
        {modalType === "registrant" ? <CreateRegistrant project={project} questions={questions} /> : null}
      </GlobalModal>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween
          sx={{
            "@media (max-width: 600px)": {
              flexDirection: "column",
              mb: 2,
            },
          }}
        >
          <Box
            sx={{
              mb: 2,
              display: "flex",
            }}
          >
            <ArrowBackIcon
              onClick={() => navigate(`/dashboard/project/${project?._id}`)}
              sx={{ mr: 1, cursor: "pointer", color: "primary.main" }}
              fontSize="small"
            />
            <Box>
              <strong>{id?.includes("-") ? id?.replace("-", " ").toLocaleUpperCase() : name}</strong>
            </Box>
          </Box>
          <Box>
            <Button
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  display: "inline-block",
                },
                display: "none",
                mr: 1,
              })}
              color="primary"
              variant="contained"
              onClick={() => setShowFilter(!showFilter)}
            >
              Show Filter
            </Button>
            {auth?.type !== "Developer" ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("mail")} color="primary" variant="contained">
                Mass Mail
              </Button>
            ) : null}
            {auth?.type === "Manager" ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("text")} color="primary" variant="contained">
                Mass Text
              </Button>
            ) : null}
            {id !== "purchasers" ? (
              <>
                <Button onClick={() => handleModalView("registrant")} sx={{ mr: 1 }} color="primary" variant="contained">
                  Create
                </Button>
              </>
            ) : null}
          </Box>
        </FlexBetween>
      </Typography>
      {showFilter && id !== "purchasers" && realtorTypeFilter ? <RegistrantFilter /> : null}
      {selectedRegistrants.length ? (
        <Typography variant="h3" component="div" gutterBottom>
          <strong>
            Selected: <Box sx={{ display: "inline", color: "success.main" }}>{selectedRegistrants.length}</Box>
          </strong>
        </Typography>
      ) : null}
      <StandardTable
        data={id !== "purchasers" ? registrants : purchasers}
        columns={columns}
        loading={loading || downloadLoading || mailDownloadLoading}
        handleGlobalFilterValue={handleGlobalFilterValue}
        count={purchaserCount ? purchaserCount : count}
        download={id === "purchasers" ? purchaserDownload : download}
        user={auth}
      />
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <TablePagination
          component="div"
          count={purchaserCount ? purchaserCount : count}
          page={pageNumber - 1}
          onPageChange={handleQueryParams}
          rowsPerPage={perPage!}
          rowsPerPageOptions={[15, 25, 50, 100]}
          onRowsPerPageChange={handlePerRows}
        />
      </Box>
    </div>
  );
};

interface ChildProps {
  handleGlobalFilterValue: any;
  selectedRegistrants: any;
  columns: any;
  loading: any;
  id: string | undefined;
  name: string;
  pageNumber: number;
  purchasers: any;
  setPageNumber: Dispatch<SetStateAction<number>>;
  getPurchasers?: any;
  purchaserCount?: number;
  query?: any;
  handleQueryParams?: any;
  handlePerRows?: any;
  perPage?: number | undefined;
  realtorTypeFilter?: string;
  setRealtorTypeFilter?: Dispatch<SetStateAction<string>>;
  statusFilter?: string;
  setStatusFilter?: Dispatch<SetStateAction<string>>;
  ratingFilter?: string;
  setRatingFilter?: Dispatch<SetStateAction<string>>;
  salesRepFilter?: IUser | string;
  setSalesRepFilter?: Dispatch<SetStateAction<IUser | string>>;
  sourceFilter?: string;
  setSourceFilter?: Dispatch<SetStateAction<string>>;
  subscribedFilter?: boolean | string;
  setSubscribedFilter?: Dispatch<SetStateAction<boolean | string>>;
  connectedFilter?: boolean | string;
  setConnectedFilter?: Dispatch<SetStateAction<boolean | string>>;
  startDateFilter?: Date | null;
  endDateFilter?: Date | null;
}

const GETREGISTRANTSFROMMAIL = gql`
  query getRegistrantsFromMail(
    $project: MongoID
    $mailId: MongoID!
    $query: String!
    $page: Float!
    $perPage: Float!
    $search: String
    $registrants: [MongoID]
    $download: Boolean
  ) {
    getRegistrantsFromMail(
      project: $project
      mailId: $mailId
      query: $query
      page: $page
      perPage: $perPage
      search: $search
      registrants: $registrants
      download: $download
    ) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        primaryPhone
        rating
        source
        realtorType
        subscribed
        createdAt
      }
    }
  }
`;

const GETREGISTRANTSFROMLIST = gql`
  query getRegistrantsFromList(
    $project: MongoID
    $listId: MongoID!
    $page: Float!
    $perPage: Float!
    $search: String
    $filter: RegistrantsFromListFilter
    $registrants: [MongoID]
    $comments: Boolean
  ) {
    getRegistrantsFromList(
      project: $project
      listId: $listId
      page: $page
      perPage: $perPage
      search: $search
      filter: $filter
      registrants: $registrants
      comments: $comments
    ) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        primaryPhone
        postalCode
        rating
        ethnicity
        statuses {
          name
          createdAt
        }
        process {
          name
          currentProcess
          steps {
            completed
            dueDate
            name
          }
        }
        source
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        realtorType
        subscribed
        connected
        createdAt
        count {
          createdAt
        }
        comments {
          comment
          user {
            fullName
          }
          createdAt
        }
        questions {
          questionId {
            _id
            question
          }
          answer
        }
        salesQuestions {
          questionId {
            _id
            question
          }
          answer
        }
      }
    }
  }
`;

const REGISTRANTSUBSCRIPTION = gql`
  subscription updatedRegistrants {
    updatedRegistrants {
      _id
      rating
      salesRep {
        _id
        firstName
        lastName
        fullName
      }
    }
  }
`;

export default RegistrantTable;
