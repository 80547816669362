import { useState, useMemo, useEffect } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { Box, Button, Typography, FormControl, TextField, InputLabel, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";

import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../app/hooks";
import StandardTable from "../tables/StandardTable";
import { IMailSchedule, ITextSchedule } from "../../types/mailSchedule";
import { convertAllDates } from "../../utils/function";
import { GlobalModal } from "../../features/modal/Modal";
import { handleModal } from "../../features/modal/modalSlice";
import { FlexBetween } from "../../commonStyles";
import { selectUser } from "../../features/auth/authSlice";
import BasicTabs from "../common/BasicTabs";
import MassMail from "../common/MassMail";

const ScheduleMailing = () => {
  const storeDispatch = useAppDispatch();
  const [scheduledMailing, setScheduledMailing] = useState<IMailSchedule[]>([]);
  const [textMailing, setTextMailing] = useState<ITextSchedule[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [modalView, setModalView] = useState("");
  const [selectedMail, setSelectedMail] = useState<IMailSchedule | null>(null);
  const [view, setView] = useState(0);
  const [type, setType] = useState<string>("eBlast");

  const user = useSelector(selectUser);

  const [getMail, { loading }] = useLazyQuery(GETMAILSCHEDULES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setScheduledMailing(data.mailSchedulePagination.items);
      setCount(data.mailSchedulePagination.count);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getText, { loading: textLoading }] = useLazyQuery(GETTEXTSCHEDULES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setTextMailing(data.textSchedulePagination.items);
      setCount(data.textSchedulePagination.count);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getStepMail, { loading: steploading }] = useLazyQuery(GETSTEPSCHEDULES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setScheduledMailing(data.stepSchedulePagination.items);
      setCount(data.stepSchedulePagination.count);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [deleteText] = useMutation(REMOVETEXTSCHEDULE, {
    onCompleted: (data) => {
      setTextMailing(textMailing.filter((text: ITextSchedule) => text._id !== data.textScheduleRemoveById.record._id));
      setCount(count - 1);
      storeDispatch(showSuccessSnackbar("Scheduled Text Deleted!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [deleteMail] = useMutation(REMOVEMAILSCHEDULE, {
    onCompleted: (data) => {
      setScheduledMailing(scheduledMailing.filter((mail: IMailSchedule) => mail._id !== data.mailScheduleRemoveById.record._id));
      storeDispatch(showSuccessSnackbar("Scheduled Mail Deleted!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [deleteStepMail] = useMutation(REMOVESTEPMAILSCHEDULE, {
    onCompleted: (data) => {
      setScheduledMailing(scheduledMailing.filter((mail: IMailSchedule) => mail._id !== data.stepScheduleRemoveById.record._id));
      storeDispatch(showSuccessSnackbar("Scheduled Mail Deleted!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [updateMail] = useMutation(UPDATEMAILSCHEDULE, {
    onCompleted: async (data) => {
      let mailings: IMailSchedule[] = await scheduledMailing.map((mail: IMailSchedule) => {
        if (mail._id === data.mailScheduleUpdateById.record._id) {
          return data.mailScheduleUpdateById.record;
        } else return mail;
      });
      setScheduledMailing(mailings);
      storeDispatch(showSuccessSnackbar("Scheduled Mail Updated!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [updateStepMail] = useMutation(UPDATESTEPMAILSCHEDULE, {
    onCompleted: async (data) => {
      let mailings: IMailSchedule[] = await scheduledMailing.map((mail: IMailSchedule) => {
        if (mail._id === data.stepScheduleUpdateById.record._id) {
          return data.stepScheduleUpdateById.record;
        } else return mail;
      });
      setScheduledMailing(mailings);
      storeDispatch(showSuccessSnackbar("Scheduled Mail Updated!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useEffect(() => {
    switch (type) {
      case "eBlast":
        if (view) {
          getMail({
            variables: {
              filter: { sent: true, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
              page: 1,
              perPage: 15,
              sort: "_ID_DESC",
            },
          });
        } else {
          getMail({
            variables: {
              filter: { sent: false, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
              page: 1,
              perPage: 15,
              sort: "_ID_DESC",
            },
          });
        }
        break;
      case "step":
        if (view) {
          getStepMail({
            variables: {
              filter: { sent: true, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
              page: pageNumber,
              perPage: 15,
              sort: "_ID_DESC",
            },
          });
        } else {
          getStepMail({
            variables: {
              filter: { sent: false, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
              page: pageNumber,
              perPage: 15,
              sort: "_ID_DESC",
            },
          });
        }
        break;
      case "text":
        if (view) {
          getText({
            variables: {
              filter: { sent: true, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
              page: pageNumber,
              perPage: 15,
              sort: "_ID_DESC",
            },
          });
        } else {
          getText({
            variables: {
              filter: { sent: false, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
              page: pageNumber,
              perPage: 15,
              sort: "_ID_DESC",
            },
          });
        }
        break;
      default:
        throw new Error();
    }
  }, [pageNumber, getMail, getStepMail, getText, user?.projectAccess, type, view]);

  // Table

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getMail({
      variables: {
        filter: { sent: false, projectIds: user?.projectAccess.map((access: any) => access.project._id) },
        page: 1,
        perPage: 15,
        sort: "_ID_DESC",
      },
    });
  };

  const handleMailModal = (view: string, mailSchedule: IMailSchedule | null, modal: boolean) => {
    setSelectedMail(mailSchedule);
    setModalView(view);
    storeDispatch(handleModal(modal));
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: (rowData: any) => {
          if (type === "eBlast") {
            if (rowData.emailTemplate) {
              return (
                <strong style={{ cursor: "pointer" }} onClick={() => handleMailModal("edit", rowData, true)}>
                  {rowData.emailTemplate.name}
                </strong>
              );
            } else return "";
          } else {
            return (
              <strong style={{ cursor: "pointer" }} onClick={() => handleMailModal("edit", rowData, true)}>
                {rowData.registrant.firstName} {rowData.registrant.lastName}
              </strong>
            );
          }
        },
      },
      {
        Header: "Subject",
        accessor: (rowData: any) => {
          if (type === "eBlast") {
            if (rowData.emailTemplate) {
              return rowData.emailTemplate.subject;
            } else return "";
          } else {
            return rowData.subject;
          }
        },
      },
      {
        Header: "Sender Email",
        accessor: (rowData: any) => {
          return rowData.senderEmail ? rowData.senderEmail : rowData.user ? rowData.user.email : rowData.project.email;
        },
      },
      {
        Header: "Sender Name",
        accessor: (rowData: any) => {
          return rowData.senderName;
        },
      },
      {
        Header: "Project",
        accessor: (rowData: any) => {
          return rowData.project ? rowData.project.name : "Custom";
        },
      },
      {
        Header: "Number of People",
        accessor: (rowData: any) => {
          if (type === "eBlast") {
            return rowData.count
              ? rowData.count
              : rowData.registrants.length
              ? rowData.registrants.length
              : rowData.purchasers.length
              ? rowData.purchasers.length
              : rowData.custom.length;
          } else return "1";
        },
      },
      {
        Header: "Scheduled Date",
        accessor: (rowData: any) => {
          return convertAllDates(rowData.mailDate, "PPpp");
        },
      },
      {
        Header: " ",
        accessor: (rowData: IMailSchedule) => {
          if (!view) {
            return <DeleteIcon onClick={() => handleMailModal("delete", rowData, true)} sx={{ cursor: "pointer", color: "error.main" }} />;
          } else return "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, view, scheduledMailing]);

  const textColumns = useMemo(() => {
    return [
      {
        Header: "Campaign Name",
        accessor: (rowData: any) => rowData.name,
      },
      {
        Header: "Email Template Name",
        accessor: (rowData: any) => rowData.textTemplate.name,
      },
      {
        Header: "Text",
        accessor: (rowData: any) => rowData.textTemplate.text,
      },
      {
        Header: "Project",
        accessor: (rowData: any) => {
          return rowData.project ? rowData.project.name : "Custom";
        },
      },
      {
        Header: "Number of People",
        accessor: (rowData: any) => {
          return rowData.count
            ? rowData.count
            : rowData.registrants.length
            ? rowData.registrants.length
            : rowData.purchasers.length
            ? rowData.purchasers.length
            : rowData.custom.length;
        },
      },
      {
        Header: "Scheduled Date",
        accessor: (rowData: any) => {
          return convertAllDates(rowData.textDate, "PPpp");
        },
      },
      {
        Header: " ",
        accessor: (rowData: IMailSchedule) => {
          if (!view) {
            return (
              <DeleteIcon
                onClick={() => {
                  deleteText({ variables: { _id: rowData._id } });
                }}
                sx={{ cursor: "pointer", color: "error.main" }}
              />
            );
          } else return "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, view, scheduledMailing]);

  const tabs = useMemo(() => {
    return [
      {
        label: "Pending",
        component: (
          <StandardTable
            data={type === "text" ? textMailing : scheduledMailing}
            columns={type === "text" ? textColumns : columns}
            loading={type === "text" ? textLoading : type === "eBlast" ? loading : steploading}
            handleGlobalFilterValue={handleGlobalFilterValue}
            count={count}
          />
        ),
      },
      {
        label: "Sent",
        component: (
          <StandardTable
            data={type === "text" ? textMailing : scheduledMailing}
            columns={type === "text" ? textColumns : columns}
            loading={type === "text" ? textLoading : type === "eBlast" ? loading : steploading}
            handleGlobalFilterValue={handleGlobalFilterValue}
            count={count}
          />
        ),
      },
    ];
  }, [scheduledMailing, textMailing]);

  const setValue = (value: number) => {
    setView(value);
  };

  const handleView = () => {
    if (selectedMail) {
      switch (modalView) {
        case "edit":
          return (
            <div>
              <Typography variant="h2" component="div" gutterBottom>
                <strong>Edit {selectedMail.emailTemplate.name}</strong>
              </Typography>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    fullWidth
                    name="senderName"
                    label="Sender Name"
                    type="text"
                    variant="outlined"
                    value={selectedMail.senderName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedMail({ ...selectedMail, senderName: e.target.value })}
                  />
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl required fullWidth variant="outlined">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Mail Date"
                      value={selectedMail.mailDate}
                      disablePast
                      onChange={(newValue) => {
                        setSelectedMail({ ...selectedMail, mailDate: newValue });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <FlexBetween sx={{ mt: 2, flexWrap: "wrap" }}>
                <Button
                  onClick={() => {
                    if (type === "eBlast") {
                      updateMail({
                        variables: {
                          _id: selectedMail._id,
                          record: { mailDate: selectedMail.mailDate, senderName: selectedMail.senderName },
                        },
                      });
                    } else {
                      updateStepMail({
                        variables: {
                          _id: selectedMail._id,
                          record: { mailDate: selectedMail.mailDate, senderName: selectedMail.senderName },
                        },
                      });
                    }
                  }}
                  sx={{ mr: 2 }}
                  color="success"
                  variant="contained"
                >
                  Submit
                </Button>
                <Button onClick={() => handleMailModal("", null, false)} color="info" variant="contained">
                  Cancel
                </Button>
              </FlexBetween>
            </div>
          );
        case "delete":
          return (
            <div>
              <Typography variant="h2" component="div" gutterBottom>
                <strong>Delete {selectedMail.emailTemplate.name}</strong>
              </Typography>
              <p>
                Are you sure you want to delete <strong>{selectedMail.emailTemplate.name}</strong> scheduled mailing for{" "}
                <strong>{convertAllDates(selectedMail.mailDate, "PPpp")}</strong>?
              </p>
              <FlexBetween sx={{ mt: 2, flexWrap: "wrap" }}>
                <Button
                  onClick={() => {
                    if (type === "eBlast") {
                      deleteMail({ variables: { _id: selectedMail._id } });
                    } else {
                      deleteStepMail({ variables: { _id: selectedMail._id } });
                    }
                  }}
                  sx={{ mr: 2 }}
                  color="success"
                  variant="contained"
                >
                  Confirm
                </Button>
                <Button onClick={() => handleMailModal("", null, false)} color="info" variant="contained">
                  Cancel
                </Button>
              </FlexBetween>
            </div>
          );
        default:
          return;
      }
    } else {
      if (modalView === "custom") {
        return (
          <Box>
            <MassMail id={"custom"} selectedRegistrants={[]} project={null} />
          </Box>
        );
      }
    }
  };

  return (
    <div>
      <GlobalModal>{handleView()}</GlobalModal>
      <FlexBetween>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>Scheduled Media</strong>
        </Typography>
        {user?.type !== "Developer" && user?.type !== "Marketing" ? (
          <Box>
            <Button onClick={() => handleMailModal("custom", null, true)} variant="contained" color="primary">
              Create Custom List
            </Button>
          </Box>
        ) : null}
      </FlexBetween>
      <FormControl
        sx={{
          width: {
            xs: "100%",
            md: "150px",
          },
          mb: 2,
        }}
      >
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name={"answerType"}
          label={"Type"}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={"eBlast"}>EBlast</MenuItem>
          <MenuItem value={"step"}>Step</MenuItem>
          <MenuItem value={"text"}>SMS</MenuItem>
        </Select>
      </FormControl>
      <BasicTabs tabs={tabs} sub={true} value={view} setValue={setValue} color={"#e5e5e5"} />
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {"<"}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
          {">"}
        </Button>
      </Box>
    </div>
  );
};

const GETMAILSCHEDULES = gql`
  query mailSchedulePagination(
    $filter: FilterFindManyMailScheduleInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyMailScheduleInput
  ) {
    mailSchedulePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        senderName
        senderEmail
        user {
          fullName
          email
        }
        mailDate
        count
        type
        custom {
          firstName
          email
        }
        purchasers {
          _id
        }
        project {
          _id
          name
          email
        }
        emailTemplate {
          _id
          name
          subject
        }
      }
      count
    }
  }
`;

const GETTEXTSCHEDULES = gql`
  query textSchedulePagination(
    $filter: FilterFindManyTextScheduleInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyTextScheduleInput
  ) {
    textSchedulePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        name
        user {
          fullName
          email
        }
        textDate
        count
        type
        purchasers {
          _id
        }
        project {
          _id
          name
          email
        }
        textTemplate {
          _id
          name
          text
        }
      }
      count
    }
  }
`;

const REMOVEMAILSCHEDULE = gql`
  mutation mailScheduleRemoveById($_id: MongoID!) {
    mailScheduleRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const REMOVESTEPMAILSCHEDULE = gql`
  mutation stepScheduleRemoveById($_id: MongoID!) {
    stepScheduleRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const REMOVETEXTSCHEDULE = gql`
  mutation textScheduleRemoveById($_id: MongoID!) {
    textScheduleRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const UPDATEMAILSCHEDULE = gql`
  mutation mailScheduleUpdateById($_id: MongoID!, $record: UpdateByIdMailScheduleInput!) {
    mailScheduleUpdateById(_id: $_id, record: $record) {
      record {
        _id
        senderName
        user {
          fullName
          email
        }
        mailDate
        purchasers {
          _id
        }
        custom {
          firstName
          email
        }
        count
        type
        project {
          _id
          name
          email
        }
        emailTemplate {
          _id
          name
          subject
        }
      }
    }
  }
`;

const UPDATESTEPMAILSCHEDULE = gql`
  mutation stepScheduleUpdateById($_id: MongoID!, $record: UpdateByIdStepScheduleInput!) {
    stepScheduleUpdateById(_id: $_id, record: $record) {
      record {
        _id
        senderName
        senderEmail
        subject
        sent
        user {
          fullName
          email
        }
        mailDate
        project {
          _id
          name
          email
        }
        emailTemplate {
          _id
          name
          subject
        }
      }
    }
  }
`;

const GETSTEPSCHEDULES = gql`
  query stepSchedulePagination(
    $filter: FilterFindManyStepScheduleInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyStepScheduleInput
  ) {
    stepSchedulePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        senderName
        senderEmail
        subject
        sent
        user {
          fullName
          email
        }
        mailDate
        registrant {
          _id
          firstName
          lastName
        }
        project {
          _id
          name
          email
        }
        emailTemplate {
          _id
          name
          subject
        }
      }
      count
    }
  }
`;

export default ScheduleMailing;
