import React, { useReducer } from "react";
import { Outlet } from "react-router";

const RegistrantNotificationContext = React.createContext<any>(null);

export const registrantsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [action.payload, ...state];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "DELETE":
      if (state.length) {
        return state.filter((state: any, index: number) => state.registrantId !== action.payload._id);
      } else return state;
    default:
      throw new Error();
  }
};

const RegistrantNotificationProvider = (props: any) => {
  const [registrantsState, registrantsDispatch] = useReducer(registrantsReducer, []);

  return (
    <RegistrantNotificationContext.Provider
      value={{
        registrantsDispatch,
        registrantsState,
      }}
    >
      {props.children}
    </RegistrantNotificationContext.Provider>
  );
};

export { RegistrantNotificationContext, RegistrantNotificationProvider };
