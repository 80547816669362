import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Typography, TextField, Box, Autocomplete, FormControl, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";

import { Container } from "../../../commonStyles";
import { IQuestion, IChoice } from "../../../types/question";
import { selectProjectQuestions } from "../../../features/projectSetting/projectSettingSlice";

const QuestionSection = (props: ChildProps) => {
  const { questions, setQuestions, loading } = props;
  const projectQuestions = useSelector(selectProjectQuestions);

  const handleQuestionChange = (value: string[], questionId: string) => {
    let selectedQuestion = questions.find((question: any) => question.questionId === questionId || question.questionId._id === questionId);

    if (selectedQuestion) {
      let allQuestions = questions.map((question: any) => {
        if (questionId === question.questionId || questionId === question.questionId._id) {
          return {
            ...question,
            answer: value,
            questionId: question.questionId,
          };
        } else return question;
      });
      setQuestions(allQuestions);
    } else {
      setQuestions([
        ...questions,
        {
          answer: value,
          questionId: questionId,
        },
      ]);
    }
  };

  const getDefaultValues = (question: IQuestion) => {
    let selectedQuestion = questions.find((selected: any) => selected.questionId._id === question._id);
    if (selectedQuestion) {
      return selectedQuestion.answer;
    } else return;
  };

  const handleQuestions = (question: IQuestion, i: number) => {
    if (question.type !== "text") {
      return (
        <FormControl fullWidth>
          <Autocomplete
            multiple
            options={question.choices.map((choice: IChoice) => choice.choice)}
            freeSolo={false}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: any) => option}
            value={getDefaultValues(question)}
            onChange={(e, value: any) => handleQuestionChange(value, question._id)}
            renderInput={(params) => <TextField {...params} label={question.question} />}
          />
        </FormControl>
      );
    }
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Questions</strong>
      </Typography>
      {loading ? (
        <Typography variant="h1">
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Typography>
      ) : (
        <div>
          {projectQuestions.map((question: IQuestion, i: number) => {
            if (!question.subQuestion) {
              return (
                <Box sx={{ mt: 2 }} key={i}>
                  <Box sx={{ mt: 2 }}>{handleQuestions(question, i)}</Box>
                </Box>
              );
            } else return null;
          })}
        </div>
      )}
    </Container>
  );
};

interface ChildProps {
  questions: IQuestionAnswer[];
  setQuestions: Dispatch<SetStateAction<IQuestionAnswer[]>>;
  loading: boolean;
}

interface IQuestionAnswer {
  answer: string[];
  questionId: string;
}

export default QuestionSection;
