import { Box, IconButton, Snackbar as MuiSnackbar, SnackbarContent } from "@mui/material";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { removeNotification, selectNotifications } from "./notificationSlice";
import CloseIcon from "@mui/icons-material/Close";
import TextsmsIcon from "@mui/icons-material/Textsms";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router";

export function Notifications() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notifications = useAppSelector(selectNotifications);

  const [numNotifications, setNumNotifications] = useState<number>(0);
  const [timeouts, setTimeouts] = useState<any[]>([]);

  useEffect(() => {
    if (notifications.length === 0) {
      setNumNotifications(0);
      return;
    }
    const latestNotification = notifications[notifications.length - 1];
    const curId = latestNotification.id;

    if (notifications.length > numNotifications) {
      const timeout = setTimeout(() => {
        dispatch(removeNotification(curId));
        setTimeouts((old) => old.filter((t) => t !== timeout));
      }, latestNotification.duration);
      setTimeouts((old) => [...old, timeout]);
    }
    if (notifications.length > 10) {
      dispatch(removeNotification(notifications[0].id));
      clearTimeout(timeouts[0]);
      setTimeouts((old) => old.slice(1));
      setNumNotifications(10);
      return;
    }
    setNumNotifications(notifications.length);
  }, [notifications, numNotifications, dispatch, timeouts]);

  return (
    <>
      {notifications.map((notification, index) => {
        const top = document.documentElement.clientHeight - 50 - 58 * index;
        return (
          <MuiSnackbar
            key={index}
            sx={{ top, zIndex: notifications.length - index, height: "30px" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            onClose={() => {
              clearTimeout(timeouts[index]);
              setTimeouts((old) => [...old.slice(0, index), ...old.slice(index + 1, old.length)]);
              dispatch(removeNotification(notification.id));
            }}
            autoHideDuration={60000}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: notification.colour,
                borderRadius: "5px",
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                cursor: "pointer",
              }}
              onClick={() => {
                if (notification.link) navigate(notification.link);
              }}
            >
              {notification.type === "registrant" && <PersonIcon sx={{ color: "white", ml: 1 }} />}
              {notification.type === "call" && <CallIcon sx={{ color: "white", ml: 1 }} />}
              {notification.type === "text" && <TextsmsIcon sx={{ color: "white", ml: 1 }} />}
              <SnackbarContent
                message={notification.message}
                sx={{ backgroundColor: notification.colour, boxShadow: "none" }}
                action={
                  <IconButton
                    sx={{ color: "inherit" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      clearTimeout(timeouts[index]);
                      setTimeouts((old) => [...old.slice(0, index), ...old.slice(index + 1, old.length)]);
                      dispatch(removeNotification(notification.id));
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
            </Box>
          </MuiSnackbar>
        );
      })}
    </>
  );
}
