import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { ITextTemplate } from "../../types/textTemplate";
import { IProject } from "../../types/project";

const initialState: ITextTemplateState = {
  textTemplates: [],
  count: 0,
};

export const textTemplateSlice = createSlice({
  name: "textTemplate",
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.textTemplates = action.payload.items;
      state.count = action.payload.count;
    },
    addTemplate: (state, action) => {
      state.textTemplates.push(action.payload);
    },
    updateTemplate: (state, action) => {
      state.textTemplates[state.textTemplates.findIndex((template) => template._id === action.payload._id)] = action.payload;
    },
  },
});

export const { setTemplates, addTemplate, updateTemplate } = textTemplateSlice.actions;

export const selectTextTemplates = (state: RootState) => state.textTemplate.textTemplates;
export const selectTextTemplateCount = (state: RootState) => state.textTemplate.count;

interface ITextTemplateState {
  textTemplates: ITextTemplate[];
  count: number;
}

export interface ITemplates {
  _id: string;
  name: string;
  text: string;
  step: boolean;
  project: IProject;
  createdAt: Date;
  updatedAt: Date;
}

export default textTemplateSlice.reducer;
