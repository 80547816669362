import { Typography } from "@mui/material";
import { Container } from "../../../commonStyles";
import Reporting from "../../userProfile/reporting/Reporting";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { IProject } from "../../../types/project";

const ProjectReport = (props: ChildProps) => {
  const { project } = props;
  const user = useSelector(selectUser);
  return (
    <Container>
      <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
        <strong>Daily Report</strong>
      </Typography>
      <Reporting user={user!} global={true} selectedProject={project!} filter={true} />
    </Container>
  );
};

interface ChildProps {
  project: IProject | null;
}

export default ProjectReport;
