import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import { Typography, Box, TextField, Button, Divider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { subDays } from "date-fns";
import { useSelector } from "react-redux";

import { GlobalModal } from "../../../features/modal/Modal";
import { FlexBetween } from "../../../commonStyles";
import StandardTable from "../../tables/StandardTable";
import { useAppDispatch, useWindowSize } from "../../../app/hooks";
import { handleModal } from "../../../features/modal/modalSlice";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectProject } from "../../../features/project/projectSlice";
import { convertAllDates, numToCurrency, downloadExcel, downloadPdf, normalToCamel } from "../../../utils/function";
import Analytics from "./Analytics";
import { breakpoints } from "../../../theme";

const Tracking = () => {
  const { projectid } = useParams();
  const project = useSelector(selectProject);
  let navigate = useNavigate();

  const storeDispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [dateStart, setDateStart] = useState<Date | null>(subDays(new Date(), 6));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [interactions, setInteractions] = useState<any[]>([]);
  const [realtors, setRealtors] = useState<any[]>([]);
  const [sharedUnits, setSharedUnits] = useState<any[]>([]);

  const windowSize = useWindowSize();

  const [getInteractions, { loading }] = useLazyQuery(GETINTERACTIONS, {
    onCompleted: (data) => {
      setInteractions(data.getInteractions.interactionTotals ? [data.getInteractions.interactionTotals] : []);
      setRealtors(data.getInteractions.realtors);
      setCount(data.getInteractions.realtors.length);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useEffect(() => {
    getInteractions({ variables: { project: projectid, dateStart, dateEnd, page: pageNumber } });
    // eslint-disable-next-line
  }, [pageNumber]);

  const overallColumns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: (rowData: any) => {
          return <strong>{project?.name}</strong>;
        },
      },
      {
        Header: "Total",
        accessor: (rowData: any) => <strong>{rowData.totalClicks}</strong>,
      },
      {
        Header: "Hotlist",
        accessor: (rowData: any) => rowData.hotlistCount,
      },
      {
        Header: "All Floor Plans",
        accessor: (rowData: any) => rowData.allFloorPlansCount,
      },
      {
        Header: "Renderings",
        accessor: (rowData: any) => rowData.renderingsCount,
      },
      {
        Header: "Neighbourhood Photography",
        accessor: (rowData: any) => rowData.photoCount,
      },
      {
        Header: "Aerial Photos",
        accessor: (rowData: any) => rowData.aerialPhotoCount,
      },
      {
        Header: "Logos",
        accessor: (rowData: any) => rowData.logosCount,
      },
      {
        Header: "Brochure",
        accessor: (rowData: any) => rowData.brochureCount,
      },
      {
        Header: "RDS Instagram",
        accessor: (rowData: any) => rowData.rdsInstaCount,
      },
      {
        Header: `${project?.name} Instagram`,
        accessor: (rowData: any) => rowData.projectInstaCount,
      },
      {
        Header: `${project?.name} Site`,
        accessor: (rowData: any) => rowData.projectSiteCount,
      },
    ];
  }, [project]);

  const realtorColumns = useMemo(() => {
    const handleUnits = (units: any[]) => {
      setSharedUnits(units);
      storeDispatch(handleModal(true));
    };

    return [
      {
        Header: "User",
        accessor: (rowData: any) => {
          return (
            <strong>
              {rowData.user ? rowData.user.fullName : "Public"} {rowData.user ? null : `(${rowData.uniqueUsers})`}
            </strong>
          );
        },
      },
      {
        Header: "Last Clicked",
        accessor: (rowData: any) => {
          return <strong>{convertAllDates(rowData.updatedAt, "PPpp")}</strong>;
        },
      },
      {
        Header: "Total",
        accessor: (rowData: any) => {
          let clicks = rowData.totalClicks + rowData.sharedUnits.reduce((acc: any, val: any) => acc + val.units.length, 0);
          return (
            <div>
              <strong>
                {clicks} {rowData.user ? null : rowData.user ? null : `(${clicks / rowData.uniqueUsers})`}
              </strong>
            </div>
          );
        },
      },
      {
        Header: "Hotlist",
        accessor: (rowData: any) => rowData.hotlistCount,
      },
      {
        Header: "All Floor Plans",
        accessor: (rowData: any) => rowData.allFloorPlansCount,
      },
      {
        Header: "Renderings",
        accessor: (rowData: any) => rowData.renderingsCount,
      },
      {
        Header: "Neighbourhood Photography",
        accessor: (rowData: any) => rowData.photoCount,
      },
      {
        Header: "Aerial Photos",
        accessor: (rowData: any) => rowData.aerialPhotoCount,
      },
      {
        Header: "Logos",
        accessor: (rowData: any) => rowData.logosCount,
      },
      {
        Header: "Brochure",
        accessor: (rowData: any) => rowData.brochureCount,
      },
      {
        Header: "RDS Instagram",
        accessor: (rowData: any) => rowData.rdsInstaCount,
      },
      {
        Header: `${project?.name} Instagram`,
        accessor: (rowData: any) => rowData.projectInstaCount,
      },
      {
        Header: `${project?.name} Site`,
        accessor: (rowData: any) => rowData.projectSiteCount,
      },
      {
        Header: `Shared Units`,
        accessor: (rowData: any) => {
          if (rowData.sharedUnits.reduce((acc: any, val: any) => acc + val.units.length, 0)) {
            return (
              <strong style={{ cursor: "pointer" }} onClick={() => handleUnits(rowData.sharedUnits)}>
                {rowData.sharedUnits.reduce((acc: any, val: any) => acc + val.units.length, 0)}
              </strong>
            );
          } else return 0;
        },
      },
    ];
  }, [project, storeDispatch]);

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getInteractions({ variables: { project: projectid, dateStart, dateEnd, page: pageNumber, search: value } });
  };

  const handleDate = (day: number) => {
    setDateStart(subDays(new Date(), day));
    setDateEnd(new Date());
    getInteractions({ variables: { project: projectid, dateStart: subDays(new Date(), day), dateEnd: new Date(), page: 1 } });
  };

  const download = (type: string) => {
    let headers = realtorColumns
      .filter((column: any) => typeof column.Header === "string")
      .map((column: any) => {
        return {
          label: column.Header,
          id: normalToCamel(column.Header),
        };
      });

    let data = realtors.map((realtor: any) => {
      return {
        user: realtor.user ? realtor.user.fullName : `Public (${realtor.uniqueUsers})`,
        aerialPhotos: realtor.aerialPhotoCount,
        neighbourhoodPhotography: realtor.photoCount,
        brochure: realtor.brochureCount,
        allFloorPlans: realtor.allFloorPlansCount,
        hotlist: realtor.hotlistCount,
        logos: realtor.logosCount,
        rdsInstagram: realtor.rdsInstaCount,
        [`${project?.name.toLocaleLowerCase()}Instagram`]: realtor.projectInstaCount,
        [`${project?.name.toLocaleLowerCase()}Site`]: realtor.projectSiteCount,
        renderings: realtor.renderingsCount,
        total: realtor.totalClicks,
        sharedUnits: realtor.sharedUnits.length,
      };
    });

    let columnWidths = realtors.map((realtor: any) => {
      if (type === "excel") {
        return {
          user: 15,
          aerialPhotos: 15,
          neighbourhoodPhotography: 15,
          brochure: 15,
          allFloorPlans: 15,
          hotlist: 15,
          logos: 15,
          rdsInstagram: 15,
          [`${project?.name.toLocaleLowerCase()}Instagram`]: 15,
          [`${project?.name.toLocaleLowerCase()}Site`]: 15,
          renderings: 15,
          total: 15,
          sharedUnits: 15,
        };
      } else
        return {
          user: 200,
          aerialPhotos: 200,
          neighbourhoodPhotography: 200,
          brochure: 200,
          allFloorPlans: 200,
          hotlist: 200,
          logos: 200,
          rdsInstagram: 200,
          [`${project?.name.toLocaleLowerCase()}Instagram`]: 200,
          [`${project?.name.toLocaleLowerCase()}Site`]: 200,
          renderings: 200,
          total: 200,
          sharedUnits: 200,
        };
    });

    let sheetTitle = ["Tracking"];

    if (type === "excel") {
      downloadExcel([data], [headers], [], [columnWidths], sheetTitle, "Tracking");
    } else {
      downloadPdf([data], [headers], [], columnWidths, sheetTitle, "Tracking");
    }
  };

  return (
    <div>
      <div>
        <GlobalModal>
          <Typography sx={{ mt: 2 }} variant="h2" component="div" gutterBottom>
            <strong>Shared Units</strong>
          </Typography>
          {sharedUnits.map((shared: any) => {
            return (
              <Box sx={{ my: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <strong>Shared At: {convertAllDates(shared.createdAt, "PPpp")}</strong>
                </Box>
                {shared.units.map((unit: any) => {
                  return (
                    <div>
                      Suite {unit.suite} - {unit.modelType} - {unit.unitType} - {numToCurrency.format(unit.basePrice)}
                    </div>
                  );
                })}
                <Divider sx={{ mt: 2 }} />
              </Box>
            );
          })}
        </GlobalModal>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FlexBetween sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column-reverse", md: "row" } }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {windowSize.width && windowSize.width >= breakpoints.md && (
                <ArrowBackIcon
                  onClick={() => navigate(`/dashboard/project/${projectid}`)}
                  sx={{ mr: 2, cursor: "pointer", color: "primary.main", alignSelf: "center" }}
                  fontSize="small"
                />
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <DatePicker
                  label="Date Start"
                  value={dateStart ? new Date(dateStart!) : null}
                  onChange={(newValue) => {
                    setDateStart(newValue);
                  }}
                  maxDate={dateEnd ? new Date(dateEnd!) : null}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Box sx={{ mx: 1, mb: 1 }}>to</Box>
                <DatePicker
                  label="Date End"
                  value={dateEnd ? new Date(dateEnd!) : null}
                  onChange={(newValue) => {
                    setDateEnd(newValue);
                  }}
                  minDate={dateStart ? new Date(dateStart!) : null}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  onClick={() => getInteractions({ variables: { project: projectid, dateStart, dateEnd, page: pageNumber } })}
                  sx={{ ml: 2, mt: { xs: 2, md: 0 } }}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "max-content" },
                mb: { xs: 4, md: 0 },
                display: "flex",
                height: "max-content",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {windowSize.width && windowSize.width < breakpoints.md && (
                <ArrowBackIcon
                  onClick={() => navigate(`/dashboard/project/${projectid}`)}
                  sx={{ width: "10%", cursor: "pointer", color: "primary.main", alignSelf: "center" }}
                  fontSize="small"
                />
              )}
              <Button
                onClick={() => handleDate(0)}
                sx={{
                  ml: windowSize.width && windowSize.width <= 300 ? 1 : 2,
                  width: { xs: "30%", md: "max-content" },
                  height: { xs: "35px", md: "max-content" },
                }}
                color="primary"
                variant="contained"
              >
                Today
              </Button>
              <Button
                onClick={() => handleDate(6)}
                sx={{
                  ml: windowSize.width && windowSize.width <= 300 ? 1 : 2,
                  width: { xs: "30%", md: "max-content" },
                  height: { xs: "35px", md: "max-content" },
                }}
                color="primary"
                variant="contained"
              >
                Last 7 days
              </Button>
              <Button
                onClick={() => handleDate(29)}
                sx={{
                  ml: windowSize.width && windowSize.width <= 300 ? 1 : 2,
                  width: { xs: "30%", md: "max-content" },
                  height: { xs: "35px", md: "max-content" },
                }}
                color="primary"
                variant="contained"
              >
                Last 30 days
              </Button>
            </Box>
          </FlexBetween>
        </LocalizationProvider>
      </div>
      <div>
        <Typography sx={{ mt: 2 }} variant="h2" component="div" gutterBottom>
          <strong>Overall Tracking</strong>
        </Typography>
        <Box sx={{ mt: 2 }}>
          <StandardTable data={interactions} columns={overallColumns} loading={loading} />
        </Box>
        <Typography sx={{ mt: 2 }} variant="h2" component="div" gutterBottom>
          <strong>User Tracking</strong>
        </Typography>
        <Box sx={{ mt: 2 }}>
          <StandardTable
            data={realtors}
            columns={realtorColumns}
            loading={loading}
            handleGlobalFilterValue={handleGlobalFilterValue}
            count={count}
            download={download}
          />
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </Box>
        <Box>
          <Analytics project={project} dateStart={dateStart} dateEnd={dateEnd} />
        </Box>
      </div>
    </div>
  );
};

const GETINTERACTIONS = gql`
  query getInteractions($project: MongoID!, $dateStart: Date, $dateEnd: Date, $page: Int!, $search: String) {
    getInteractions(project: $project, dateStart: $dateStart, dateEnd: $dateEnd, page: $page, search: $search) {
      interactionTotals {
        _id
        aerialPhotoCount
        photoCount
        brochureCount
        allFloorPlansCount
        hotlistCount
        logosCount
        projectInstaCount
        projectSiteCount
        rdsInstaCount
        renderingsCount
        totalClicks
      }
      realtors {
        _id
        updatedAt
        user {
          fullName
        }
        aerialPhotoCount
        photoCount
        brochureCount
        allFloorPlansCount
        hotlistCount
        logosCount
        projectInstaCount
        projectSiteCount
        rdsInstaCount
        renderingsCount
        totalClicks
        uniqueUsers
        sharedUnits {
          units {
            _id
            suite
            modelType
            unitType
            basePrice
          }
          createdAt
        }
      }
    }
  }
`;

export default Tracking;
