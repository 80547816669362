import React from "react";
import { IUser } from "../../types/user";
import { SettingContainer } from "../../commonStyles";
import TextMessaging from "../settings/textMessaging/TextMessaging";

const Texts = (props: ChildProps) => {
  const { user } = props;
  return (
    <SettingContainer>
      <TextMessaging userId={user?._id!} />
    </SettingContainer>
  );
};

interface ChildProps {
  user: IUser | null;
}

export default Texts;
