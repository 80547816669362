import { useContext } from "react";
import { useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Box, Grid, FormControl, MenuItem, Select, InputLabel, TextField } from "@mui/material";
import { camelToNormal } from "../../utils/function";
import { RegistrantFilterContext } from "../../context/RegistrantFilterContext";
import { ratings, realtorTypes, sources, statuses } from "../../utils/constant";
import { selectUsers } from "../../features/user/userSlice";
import { capitalizeFirstLetterEachWord, capitalizeFirstLetter } from "../../utils/function";

const RegistrantFilter = () => {
  const {
    realtorTypeFilter,
    setRealtorTypeFilter,
    statusFilter,
    setStatusFilter,
    ratingFilter,
    setRatingFilter,
    salesRepFilter,
    setSalesRepFilter,
    sourceFilter,
    setSourceFilter,
    subscribedFilter,
    setSubscribedFilter,
    connectedFilter,
    setConnectedFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = useContext(RegistrantFilterContext);

  const users = useSelector(selectUsers);
  const realtorTypeOptions = ["any", ...realtorTypes];
  const statusOptions = ["any", ...statuses];
  const ratingOptions = ["any", ...ratings];
  const salesRepOptions = ["any", "none", ...users.slice()];
  const sourceOptions = ["any", ...sources];
  const subscribedOptions = ["any", true, false];
  const connectedOptions = ["any", true, false];

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Registrant Created Date Start"
              value={startDateFilter ? new Date(startDateFilter!) : null}
              onChange={(newValue) => {
                setStartDateFilter(newValue);
              }}
              maxDate={endDateFilter ? new Date(endDateFilter) : null}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Registrant Created Date End"
              value={endDateFilter ? new Date(endDateFilter) : null}
              onChange={(newValue) => {
                setEndDateFilter(newValue);
              }}
              minDate={startDateFilter ? new Date(startDateFilter) : null}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Realtor Type</InputLabel>
            <Select
              value={realtorTypeFilter}
              label="Realtor Type"
              onChange={(e) => (setRealtorTypeFilter ? setRealtorTypeFilter(e.target.value) : null)}
            >
              {realtorTypeOptions.map((realtorTypeOption: string, index: number) => {
                return (
                  <MenuItem key={index} value={realtorTypeOption}>
                    {camelToNormal(realtorTypeOption)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select value={statusFilter} label="Status" onChange={(e) => (setStatusFilter ? setStatusFilter(e.target.value) : null)}>
              {statusOptions.map((statusOption: string, index: number) => {
                return (
                  <MenuItem key={index} value={statusOption}>
                    {camelToNormal(statusOption)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Rating</InputLabel>
            <Select value={ratingFilter} label="Rating" onChange={(e) => (setRatingFilter ? setRatingFilter(e.target.value) : null)}>
              {ratingOptions.map((ratingOption: string, index: number) => {
                return (
                  <MenuItem key={index} value={ratingOption}>
                    {capitalizeFirstLetter(ratingOption)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Sales Rep</InputLabel>
            <Select
              value={salesRepFilter}
              label="Sales Rep"
              onChange={(e) => (setSalesRepFilter ? setSalesRepFilter(e.target.value) : null)}
            >
              {salesRepOptions.map((salesRepOption: any, index: number) => {
                return (
                  <MenuItem key={index} value={salesRepOption}>
                    {salesRepOption === "any" && "Any"}
                    {salesRepOption === "none" && "None"}
                    {salesRepOption?._id && `${salesRepOption?.firstName} ${salesRepOption?.lastName}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Source</InputLabel>
            <Select value={sourceFilter} label="Source" onChange={(e) => (setSourceFilter ? setSourceFilter(e.target.value) : null)}>
              {sourceOptions.map((sourceOption: string, index: number) => {
                return (
                  <MenuItem key={index} value={sourceOption}>
                    {capitalizeFirstLetterEachWord(sourceOption)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Subscribed</InputLabel>
            <Select
              value={subscribedFilter}
              label="Subscribed"
              onChange={(e) => (setSubscribedFilter ? setSubscribedFilter(e.target.value) : null)}
            >
              {subscribedOptions.map((subscribedOption: any, index: number) => {
                return (
                  <MenuItem key={index} value={subscribedOption}>
                    {subscribedOption === "any" && "Any"}
                    {subscribedOption === true && "Yes"}
                    {subscribedOption === false && "No"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Connected</InputLabel>
            <Select
              value={connectedFilter}
              label="Connected"
              onChange={(e) => (setConnectedFilter ? setConnectedFilter(e.target.value) : null)}
            >
              {connectedOptions.map((connectedOption: any, index: number) => {
                return (
                  <MenuItem key={index} value={connectedOption}>
                    {connectedOption === "any" && "Any"}
                    {connectedOption === true && "Yes"}
                    {connectedOption === false && "No"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegistrantFilter;
