import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";
import { IComment } from "../../types/comment";

interface commentState {
  comments: IComment[];
  count: number;
  subComments: IComment[];
  subCount: number;
}

const initialState: commentState = {
  comments: [],
  count: 0,
  subComments: [],
  subCount: 0,
};

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    addComment: (state, action) => {
      state.comments.unshift(action.payload);
      state.count = state.count + 1;
      state.subComments.unshift(action.payload);
      state.subCount = state.subCount + 1;
    },
    updateComment: (state, action) => {
      let comment = state.comments.findIndex((comment: IComment) => comment._id === action.payload._id);
      if (comment >= 0) {
        // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
        state.comments[comment] = action.payload;
      }
      let subComment = state.subComments.findIndex((comment: IComment) => comment._id === action.payload._id);
      if (subComment >= 0) {
        state.subComments[subComment] = action.payload;
      }
    },
    setComments: (state, action: PayloadAction<any>) => {
      state.comments = action.payload.items;
      state.count = action.payload.count;
    },
    setSubComments: (state, action: PayloadAction<any>) => {
      state.subComments = action.payload.items;
      state.subCount = action.payload.count;
    },
    deleteComment: (state, action) => {
      let comment = state.comments.findIndex((comment: IComment) => comment._id === action.payload._id);
      if (comment >= 0) {
        state.comments.splice(comment, 1);
        state.count = state.count - 1;
      }
      let subComment = state.subComments.findIndex((comment: IComment) => comment._id === action.payload._id);
      if (subComment >= 0) {
        state.subComments.splice(subComment, 1);
        state.subCount = state.subCount - 1;
      }
    },
    restoreComment: (state, action) => {
      let comment = state.comments.findIndex((comment: IComment) => comment._id === action.payload._id);
      if (comment >= 0) {
        state.comments[comment] = action.payload;
      } else {
        state.comments.unshift(action.payload);
        state.count = state.count + 1;
      }
      let subComment = state.subComments.findIndex((comment: IComment) => comment._id === action.payload._id);
      if (subComment >= 0) {
        state.subComments[subComment] = action.payload;
      } else {
        state.subComments.unshift(action.payload);
        state.subCount = state.subCount + 1;
      }
    },
  },
});

export const { setComments, addComment, updateComment, setSubComments, deleteComment, restoreComment } = commentSlice.actions;

export const selectComments = (state: RootState) => state.comments.comments;
export const selectCount = (state: RootState) => state.comments.count;
export const selectSubComments = (state: RootState) => state.comments.subComments;
export const selectSubCount = (state: RootState) => state.comments.subCount;

export default commentSlice.reducer;
