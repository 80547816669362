import { Paper, Box, Typography } from "@mui/material";
import { ICampaign, IEmail } from "../../../types/campaign";

import { camelToNormal } from "../../../utils/function";
import { DeleteForever } from "@mui/icons-material";
import { FlexEnd } from "../../../commonStyles";

const CampaignCard = (props: ChildProps) => {
  const { campaign, deleteCampaign, handleCampaignView } = props;
  return (
    <Paper
      elevation={3}
      sx={{
        transition: "transform 0.5s ease-in-out",
        "&:hover": {
          transform: "scale(1.03)",
        },
      }}
    >
      <Box>
        <Box onClick={() => handleCampaignView("edit", campaign)} sx={{ p: 2, cursor: "pointer" }}>
          <Typography variant={"h3"} sx={{ mb: 2 }}>
            <strong>{campaign.name}</strong>
          </Typography>
          <Box>
            Active: <strong style={{ color: campaign.active ? "green" : "red" }}>{campaign.active ? "True" : "False"}</strong>
          </Box>
          <Box>
            User Types: <strong>{campaign.userTypes.map((userType: string) => camelToNormal(userType)).join(", ")}</strong>
          </Box>
          <Box>
            Source Type: <strong>{campaign.sourceTypes.map((sourceType: string) => camelToNormal(sourceType)).join(", ")}</strong>
          </Box>
          <Box sx={{ mt: 1 }}>
            Email(s):{" "}
            <strong>
              {campaign.emails.map((email: IEmail) => {
                return (
                  <Box>
                    {email.emailTemplate.name} - After {email.numberOfDays} Days
                  </Box>
                );
              })}
            </strong>
          </Box>
        </Box>
        <FlexEnd sx={{ p: 1 }}>
          <DeleteForever sx={{ color: "red", alignSelf: "center", cursor: "pointer" }} onClick={() => deleteCampaign(campaign)} />
        </FlexEnd>
      </Box>
    </Paper>
  );
};

interface ChildProps {
  campaign: ICampaign;
  deleteCampaign: any;
  handleCampaignView: any;
}

export default CampaignCard;
