import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Typography, Skeleton, Grid, Box, Divider, Button } from "@mui/material";
import { useParams, Link } from "react-router-dom";

import { Container, FlexBetween } from "../../../commonStyles";
import TextStats from "./TextStats";

const Mailing = () => {
  const { projectid } = useParams();
  const [texts, setTexts] = useState([]);

  const { loading } = useQuery(GETTEXTCAMPAIGN, {
    fetchPolicy: "cache-and-network",
    skip: !projectid,
    variables: { project: projectid, page: 0, limit: 2 },
    onCompleted: (data) => {
      setTexts(data.getTextStats.items);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  let array = new Array(2).fill(0);

  return (
    <Container>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
          <strong>Text Campaign</strong>
        </Typography>
        {texts.length ? (
          <Button component={Link} to={`/dashboard/project/${projectid}/text/all-text`} color="primary" variant="contained">
            View All
          </Button>
        ) : null}
      </FlexBetween>
      <Divider />
      {!loading ? (
        texts.length ? (
          texts.map((text, index) => {
            return (
              <div key={index}>
                <TextStats text={text} />
                <Divider />
              </div>
            );
          })
        ) : (
          <Box sx={{ mt: 2 }}>There are currently no text campaigns sent out</Box>
        )
      ) : (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {array.map((number: any, index: number) => {
              return (
                <Grid item xs={12} lg={6} key={index}>
                  <Container sx={{ height: "100%" }}>
                    <Skeleton height={40} animation="wave" />
                    <Skeleton height={200} variant="circular" animation="wave" />
                  </Container>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

const GETTEXTCAMPAIGN = gql`
  query getTextStats($project: MongoID!, $page: Int!, $limit: Int!) {
    getTextStats(project: $project, page: $page, limit: $limit) {
      count
      items {
        textSchedule {
          name
          _id
        }
        campaignId
        startDate
        state
        sent
        total
      }
    }
  }
`;

export default Mailing;
