import { useState, useEffect, useCallback } from "react";
import { InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import { Box } from "@mui/material";
import Map from "./Map";

const ClusterMap = ({ markerLocations, setMapUrl }: ClusterMapProps) => {
  const [map, setMap] = useState<any>(null);
  const [showInfoWindows, setShowInfoWindows] = useState<boolean[]>(new Array(markerLocations.length).fill(false));
  const [markerData, setMarkerData] = useState<any[]>([]);

  const getMapUrl = useCallback(() => {
    let url = "https://maps.googleapis.com/maps/api/staticmap?";
    url += "size=500x300&";
    url += `key=${process.env.REACT_APP_REACT_MAPS}&`;

    if (!markerLocations.length) {
      const centerLat = map.center.lat();
      const centerLng = map.center.lng();
      const zoom = map.zoom;
      url += `center=${centerLat},${centerLng}&zoom=${zoom}`;
      return url;
    }

    url += "markers=|";
    for (let marker of markerLocations) {
      url += `${marker.lat},${marker.lng}|`;
    }
    url.slice(0, url.length);
    return url;
  }, [markerLocations, map]);

  const mapFitBounds = useCallback(() => {
    if (!map) return;

    const bounds = new google.maps.LatLngBounds();
    markerLocations.map((location) => bounds.extend(new google.maps.LatLng(location.lat, location.lng)));
    map.fitBounds(bounds);
    setMarkerData([]);
  }, [map, markerLocations]);

  useEffect(() => {
    if (map) {
      mapFitBounds();
    }
  }, [map, mapFitBounds]);

  useEffect(() => {
    if (map && setMapUrl) {
      setMapUrl(getMapUrl());
    }
  }, [map, setMapUrl, getMapUrl]);

  const handleMarkerClick = (e: any) => {
    let markers = e.getMarkers();
    markers = markers.map((model: any) => {
      return {
        lat: model.position.lat(),
        lng: model.position.lng(),
      };
    });

    let markerData = markerLocations.filter((marker: any) => markers.some((o2: any) => marker.lat === o2.lat && marker.lng === o2.lng));

    const results = Object.values(
      markerData.reduce((obj: any, item: any) => {
        obj[item.label] = obj[item.label] || { ...item, label: item.label, count: 0 };
        obj[item.label].count++;
        return obj;
      }, {})
    ).sort((a: any, b: any) => b.count - a.count);

    setMarkerData(results);
  };

  return (
    <>
      <Map setMap={setMap} apiKey={process.env.REACT_APP_REACT_MAPS}>
        <MarkerClusterer zoomOnClick={false} onClick={(e) => handleMarkerClick(e)}>
          {(clusterer) => (
            <>
              {markerLocations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  clusterer={clusterer}
                  onClick={() => {
                    setShowInfoWindows((showInfoWindows) => {
                      let newShowInfoWindows = showInfoWindows.slice();
                      newShowInfoWindows[index] = true;
                      return newShowInfoWindows;
                    });
                  }}
                />
              ))}
            </>
          )}
        </MarkerClusterer>
        {markerData.length ? (
          <Box
            sx={{
              position: "absolute",
              p: 1,
              backgroundColor: "#00142a",
              color: "#fff",
              border: "1px solid #000",
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            {markerData.map((marker: any) => {
              return (
                <div>
                  {marker.label} - {marker.count}
                </div>
              );
            })}
          </Box>
        ) : null}
        {markerLocations.map((location, index) => (
          <>
            {showInfoWindows[index] ? (
              <InfoWindow
                key={index}
                position={{ lat: location.lat, lng: location.lng }}
                onCloseClick={() => {
                  setShowInfoWindows((showInfoWindows) => {
                    let newShowInfoWindows = showInfoWindows.slice();
                    newShowInfoWindows[index] = false;
                    return newShowInfoWindows;
                  });
                }}
              >
                <div>
                  <strong>{location.label}</strong>
                </div>
              </InfoWindow>
            ) : (
              <></>
            )}
          </>
        ))}
      </Map>
    </>
  );
};

export default ClusterMap;

interface ClusterMapProps {
  markerLocations: locations[];
  setMapUrl?: (url: string) => void;
}

interface locations {
  lat: number;
  lng: number;
  label: string;
}
