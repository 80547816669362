const customJS = `
function formatHtml(html) {
    const temp = document.createElement("div");
    temp.innerHTML = html;
    const imageNodes = temp.querySelectorAll("img");
    for (let imageNode of imageNodes) {
        const imageAttributes = imageNode.attributes;
        let widthAttribute;
        let heightAttribute;
        for (let i = 0; i < imageAttributes.length; ++i) {
            if (imageAttributes[i].nodeName === "width") widthAttribute = imageAttributes[i].nodeValue;
            if (imageAttributes[i].nodeName === "height") heightAttribute = imageAttributes[i].nodeValue;
        }
        if (widthAttribute && widthAttribute.length > 0 && imageNode.style.width.length === 0)
            imageNode.style.width = widthAttribute + "px";
        if (heightAttribute && heightAttribute.length > 0 && imageNode.style.height.length === 0)
            imageNode.style.height = heightAttribute + "px";
        if (imageNode.style.display.length === 0) imageNode.style.display = "block";
        if (imageNode.parentElement && imageNode.parentElement.tagName === "TD") {
            imageNode.parentElement.style.lineHeight = "0px";
            imageNode.parentElement.style.fontSize = "0px";
        }
        if (
            imageNode.parentElement &&
            imageNode.parentElement.parentElement &&
            imageNode.parentElement.parentElement.tagName === "TD" &&
            imageNode.parentElement.tagName === "A"
        ) {
            imageNode.parentElement.parentElement.style.lineHeight = "0px";
            imageNode.parentElement.parentElement.style.fontSize = "0px";
        }
    }
    return temp.innerHTML;
}
window.unlayer.registerTool({
    name: "html_format_tool",
    label: "Formatted HTML",
    icon: "fa-file-code",
    supportedDisplayModes: ["web", "email"],
    options: {
        html: {
        title: "HTML",
        position: 1,
        options: {
            "shouldFormat": {
                "label" : "Format HTML",
                "defaultValue" : true,
                "widget" : "toggle"
            },
            "html": {
                "label": "HTML",
                "defaultValue": "<div>This is a Formatted HTML Block</div>",
                "widget": "html"
            },
        }
        }
    },
    values: { containerPadding: "10px" },
    renderer: {
        Viewer: window.unlayer.createViewer({
            render(values) {
                if (values.shouldFormat) {
                    return formatHtml(values.html);
                }
                return values.html;
            },
        }),
        exporters: {
            web: function (values) {
                if (values.shouldFormat) {
                    return formatHtml(values.html);
                }
                return values.html;
            },
            email: function (values) {
                if (values.shouldFormat) {
                    return formatHtml(values.html);
                }
                return values.html;
            },
        },
        head: {
        css: function (values) {},
        js: function (values) {},
        },
    },
    });
`;
export default customJS;
