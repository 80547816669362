import { Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectUser } from "../../features/auth/authSlice";
import { useUserQuery } from "../../features/auth/authHooks";
import { CircularProgress } from "@mui/material";

const UserAuth = ({ component: Component, children, ...rest }: any) => {
  const user = useSelector(selectUser);
  const { data, loading } = useUserQuery(user?._id ? true : false);

  return loading ? <></> : user ? children : <div></div>;
};
export default UserAuth;
