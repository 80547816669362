import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

const initialState: DialogState = {
  open: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    handleDialog: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { handleDialog } = dialogSlice.actions;

export const selectDialogOpen = (state: RootState) => state.dialog.open;

interface DialogState {
  open: boolean;
}

export default dialogSlice.reducer;
