import { useQuery, gql } from "@apollo/client";

import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import { setProcesses, setSteps } from "./processSlice";

export const useStepsQuery = (page: number, perPage: number) => {
  const dispatch = useAppDispatch();

  return useQuery(GETSTEPS, {
    variables: { page: page, perPage: perPage },
    onCompleted: (data) => {
      dispatch(setSteps(data.stepTemplatePagination));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useProcessQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROCESS, {
    variables: { page: 1, perPage: 15, sort: "_ID_DESC" },
    onCompleted: (data) => {
      dispatch(setProcesses(data.processTemplatePagination));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETSTEPS = gql`
  query stepTemplatePagination($page: Int!, $perPage: Int!) {
    stepTemplatePagination(page: $page, perPage: $perPage) {
      items {
        _id
        name
        default
        type
        emailTemplate {
          name
          _id
        }
        createdAt
      }
      count
    }
  }
`;

const GETPROCESS = gql`
  query processTemplatePagination($page: Int!, $perPage: Int!) {
    processTemplatePagination(page: $page, perPage: $perPage) {
      items {
        _id
        type
        name
        default
        userType
        sourceType
        viewType
        stepTemplates {
          _id
          name
        }
        timeBetweenSteps
        createdAt
      }
      count
    }
  }
`;
