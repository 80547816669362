import { useQuery, gql } from "@apollo/client";

import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import { setQuestions } from "./questionSlice";

export const useQuestionsQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery(GETQUESTIONS, {
    variables: { page: 1, perPage: 15 },
    onCompleted: (data) => {
      dispatch(setQuestions(data.questionPagination));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETQUESTIONS = gql`
  query questionPagination($page: Int!, $perPage: Int!) {
    questionPagination(page: $page, perPage: $perPage) {
      items {
        _id
        name
        question
        subQuestion
      }
      count
    }
  }
`;
