import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import { Link } from "react-router-dom";
import { IUser } from "../../../types/user";
import StandardTable from "../../tables/StandardTable";
import { IPhoneTotal } from "./Reporting";
import { downloadExcel, downloadPdf, convertAllDates, camelToNormal } from "../../../utils/function";
import { useAppDispatch } from "../../../app/hooks";
import { handleModal } from "../../../features/modal/modalSlice";
import { GlobalModal } from "../../../features/modal/Modal";

const ReportTables = (props: ChildProps) => {
  const { phoneTotals, user, dateStart, loading } = props;
  const storeDispatch = useAppDispatch();
  const [missed, setMissed] = useState<any[]>([]);

  const handleMissed = (data: any) => {
    if (data.callsNotReturned.length) {
      setMissed(data.callsNotReturned);
      storeDispatch(handleModal(true));
    }
  };

  const missedColumns = [
    {
      Header: "Date",
      accessor: (rowData: any) => {
        return <strong style={{ cursor: "pointer" }}>{convertAllDates(rowData.startDate, "PPpp")}</strong>;
      },
    },
    {
      Header: "Client Phone Number",
      accessor: (rowData: any) => rowData.customerPhone,
    },
    {
      Header: "User Phone Number",
      accessor: (rowData: any) => (rowData.businessPhone ? rowData.businessPhone : "N/A"),
    },
  ];

  const projectColumns = [
    {
      Header: "Name",
      accessor: (rowData: any) => {
        return (
          <Link style={{ color: "#000", textDecoration: "none" }} to={`/dashboard/project/${rowData.id}`}>
            <strong style={{ cursor: "pointer" }}>{rowData.name}</strong>
          </Link>
        );
      },
    },
    {
      Header: "Outbound Calls",
      accessor: (rowData: any) => rowData.outboundTotal,
    },
    {
      Header: "Outbound Calls Business",
      accessor: (rowData: any) => rowData.outboundBusiness,
    },
    {
      Header: "Outbound Calls Non Business",
      accessor: (rowData: any) => rowData.outboundNonBusiness,
    },
    {
      Header: "Incoming Calls",
      accessor: (rowData: any) => rowData.incomingTotal,
    },
    {
      Header: "Incoming Business",
      accessor: (rowData: any) => rowData.incomingBusiness,
    },
    {
      Header: "Incoming Non Business",
      accessor: (rowData: any) => rowData.incomingNonBusiness,
    },
    {
      Header: "Incoming Answered Business",
      accessor: (rowData: any) => rowData.incomingAnsweredBusiness,
    },
    {
      Header: "Incoming Answered Non Business",
      accessor: (rowData: any) => rowData.incomingAnsweredNonBusiness,
    },
    {
      Header: "Incoming Missed Business",
      accessor: (rowData: any) => rowData.incomingMissedBusiness,
    },
    {
      Header: "Incoming Missed Non Business",
      accessor: (rowData: any) => rowData.incomingMissedNonBusiness,
    },
    {
      Header: "Average Duration Outbound(s)",
      accessor: (rowData: any) => rowData.averageDurationOutbound,
    },
    {
      Header: "Average Duration Inbound(s)",
      accessor: (rowData: any) => rowData.averageDurationIncoming,
    },
    {
      Header: "Calls Not Returned",
      accessor: (rowData: any) => (
        <Box sx={{ cursor: "pointer" }} onClick={() => handleMissed(rowData)}>
          <strong>{rowData.callsNotReturned.length}</strong>
        </Box>
      ),
    },
    {
      Header: "Calls Returned",
      accessor: (rowData: any) => {
        let averageSpeed = 0;
        if (rowData.callsReturned.length) {
          averageSpeed = rowData.callsReturned.reduce(function (r: any, a: any) {
            return r + +differenceInMinutes(new Date(a.callBackDate), new Date(a.startDate));
          }, 0);
        }

        return (
          <Box>
            <strong>
              {rowData.callsReturned.length}{" "}
              {rowData.callsReturned.length ? `(${(averageSpeed / rowData.callsReturned.length).toFixed(2)} mins)` : ""}
            </strong>
          </Box>
        );
      },
    },
  ];

  const missedDownload = (type: string, data: any) => {
    let headers: any[] = [];
    for (const [key] of Object.entries(missed[0])) {
      headers.push({
        label: camelToNormal(key),
        id: key,
      });
    }

    let columnWidths = phoneTotals.map(() => {
      if (type === "excel") {
        return {
          name: 15,
          customerPhone: 15,
          businessPhone: 15,
        };
      } else
        return {
          name: 200,
          customerPhone: 200,
          businessPhone: 200,
        };
    });

    let sheetTitle = [`${convertAllDates(`${new Date(dateStart)}`, "PP")} Call Activity`];

    if (type === "excel") {
      downloadExcel([missed], [headers], [], [columnWidths], sheetTitle, sheetTitle[0]);
    } else {
      downloadPdf([missed], [headers], [], columnWidths, sheetTitle, sheetTitle[0]);
    }
  };

  const download = (type: string, data: any) => {
    let headers: any[] = [];
    for (const [key] of Object.entries(phoneTotals[0])) {
      if (key !== "id") {
        headers.push({
          label: camelToNormal(key),
          id: key,
        });
      }
    }

    let columnWidths = phoneTotals.map(() => {
      if (type === "excel") {
        return {
          name: 15,
          outboundTotal: 15,
          outboundBusiness: 15,
          outboundNonBusiness: 15,
          incomingTotal: 15,
          incomingBusiness: 15,
          incomingNonBusiness: 15,
          incomingAnsweredBusiness: 15,
          incomingAnsweredNonBusiness: 15,
          incomingMissedBusiness: 15,
          incomingMissedNonBusiness: 15,
          averageDurationOutbound: 15,
          averageDurationIncoming: 15,
          callsNotReturned: 15,
          callsReturned: 15,
        };
      } else
        return {
          name: 200,
          outboundTotal: 200,
          outboundBusiness: 200,
          outboundNonBusiness: 200,
          incomingTotal: 200,
          incomingBusiness: 200,
          incomingNonBusiness: 200,
          incomingAnsweredBusiness: 200,
          incomingAnsweredNonBusiness: 200,
          incomingMissedBusiness: 200,
          incomingMissedNonBusiness: 200,
          averageDurationOutbound: 200,
          averageDurationIncoming: 200,
          callsNotReturned: 200,
          callsReturned: 200,
        };
    });

    let sheetTitle = [`${convertAllDates(`${new Date(dateStart)}`, "PP")} Call Activity`];

    let phoneData = phoneTotals.map((phone: IPhoneTotal) => {
      return {
        ...phone,
        callsNotReturned: phone.callsNotReturned.length,
        callsReturned: `${phone.callsReturned.length}`,
      };
    });

    if (type === "excel") {
      downloadExcel([phoneData], [headers], [], [columnWidths], sheetTitle, sheetTitle[0]);
    } else {
      downloadPdf([phoneData], [headers], [], columnWidths, sheetTitle, sheetTitle[0]);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <GlobalModal>
        <Box>
          <Typography sx={{ mb: 1 }} variant="h2">
            <strong>Calls Not Returned</strong>
          </Typography>
          <StandardTable columns={missedColumns} data={missed} user={user} download={missedDownload} />
        </Box>
      </GlobalModal>
      <Grid container spacing={2}>
        <Grid item xl={12} sm={12}>
          <StandardTable title="Call Rail" loading={loading} user={user} download={download} columns={projectColumns} data={phoneTotals} />
        </Grid>
      </Grid>
    </Box>
  );
};

interface ChildProps {
  user: IUser | null;
  phoneTotals: IPhoneTotal[];
  dateStart: Date;
  dateEnd: Date;
  loading: boolean;
}
export default ReportTables;
