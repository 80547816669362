import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { Typography, Box, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectProject } from "../../../features/project/projectSlice";
import { IProcess, IStep } from "../../../types/process";
import StandardTable from "../../tables/StandardTable";
import { camelToNormal } from "../../../utils/function";
import { selectSales, selectOnline } from "../../../features/projectSetting/projectSettingSlice";
import { IUser } from "../../../types/user";

const ProcessTable = () => {
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);

  const projectSales = useSelector(selectSales);
  const projectOnline = useSelector(selectOnline);

  const [processes, setProcesses] = useState<IProcess[]>([]);

  const [getProcesses] = useLazyQuery(GETPROCESSES, {
    onCompleted: (data) => {
      setProcesses(data.processPagination.items);
      setCount(data.processPagination.count);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [updateRegistrantProcess] = useMutation(UPDATEREGISTRANTPROCESS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Add Sales Rep to Process!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleRepChange = async (value: any, name: string, data: any) => {
    await updateRegistrantProcess({
      variables: {
        user: value,
        registrant: data.registrant._id,
        type: name,
      },
    });
  };

  useEffect(() => {
    if (project?._id) {
      getProcesses({
        variables: {
          filter: {
            project: project?._id,
            users: [],
            currentProcess: true,
          },
          page: pageNumber,
          perPage: 15,
          sort: "SOURCETYPE",
        },
      });
    }
  }, [project, pageNumber]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Registrant",
        accessor: (rowData: IProcess) => {
          if (rowData.registrant) {
            return (
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to={`/dashboard/project/${project?._id}/registrant/${rowData.registrant._id}`}
              >
                <strong>{rowData.registrant.fullName}</strong>
              </Link>
            );
          }
        },
      },
      {
        Header: "Process Type",
        accessor: (rowData: IProcess) => rowData.name,
      },
      {
        Header: "Type",
        accessor: (rowData: IProcess) => camelToNormal(rowData.sourceType),
      },
      {
        Header: "Status",
        accessor: (rowData: IProcess) => {
          if (rowData.steps.every((step: IStep) => step.completed)) {
            return "Process Completed";
          }
        },
      },
      {
        Header: "1st Step Completed By",
        accessor: (rowData: IProcess) => (rowData.steps[0].user ? rowData.steps[0].user.fullName : "N/A"),
      },
      {
        Header: "Current Online Sales Rep",
        accessor: (rowData: IProcess) => (rowData.registrant.onlineSalesRep ? rowData.registrant.onlineSalesRep.fullName : "N/A"),
      },
      {
        Header: "Current Onsite Sales Rep",
        accessor: (rowData: IProcess) => (rowData.registrant.salesRep ? rowData.registrant.salesRep.fullName : "N/A"),
      },
      {
        Header: "Assign User",
        accessor: (rowData: IProcess) => {
          if (rowData.sourceType === "onsite") {
            return (
              <FormControl sx={{ width: "200px" }} fullWidth>
                <InputLabel id="demo-simple-select-label">Onsite Rep</InputLabel>
                <Select
                  labelId="salesRep"
                  name="salesRep"
                  label="Onsite Rep"
                  onChange={(e) => handleRepChange(e.target.value!, e.target.name, rowData)}
                >
                  {projectSales.map((user: IUser, index: number) => {
                    return (
                      <MenuItem key={index} value={user._id}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          } else if (rowData.sourceType === "online") {
            return (
              <FormControl sx={{ width: "200px" }} fullWidth>
                <InputLabel id="demo-simple-select-label">Online Rep</InputLabel>
                <Select
                  labelId="onlineSalesRep"
                  name="onlineSalesRep"
                  label="Online Rep"
                  onChange={(e) => handleRepChange(e.target.value!, e.target.name, rowData)}
                >
                  {projectOnline.map((user: IUser, index: number) => {
                    return (
                      <MenuItem key={index} value={user._id}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          }
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSales, projectOnline]);

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getProcesses({
      variables: {
        project: project?._id,
        page: pageNumber,
        perPage: 10,
      },
    });
  };

  return (
    <Box>
      <FlexBetween>
        <Box sx={{ display: "flex" }}>
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            sx={{ mr: 1, cursor: "pointer", color: "primary.main", alignSelf: "center" }}
            fontSize="small"
          />
          <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
            <strong>Unassigned Processes</strong>
          </Typography>
        </Box>
      </FlexBetween>
      <Box></Box>
      <Box sx={{ mt: 2 }}>
        <StandardTable count={count} handleGlobalFilterValue={handleGlobalFilterValue} columns={columns} data={processes} />
      </Box>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {"<"}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil(count / 10)} onClick={() => setPageNumber(pageNumber + 1)}>
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

const GETPROCESSES = gql`
  query processPagination($filter: FilterFindManyProcessInput, $page: Int!, $perPage: Int!, $sort: SortFindManyProcessInput) {
    processPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        project {
          _id
          name
        }
        processType
        sourceType
        userType
        name
        currentProcess
        default
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            _id
            name
          }
          user {
            firstName
            lastName
            fullName
            email
          }
          name
        }
        users {
          _id
          firstName
          lastName
          email
        }
        timeBetweenSteps
        registrant {
          _id
          firstName
          lastName
          fullName
          salesRep {
            _id
            firstName
            lastName
            fullName
          }
          onlineSalesRep {
            _id
            firstName
            lastName
            fullName
          }
          email
        }
        createdAt
      }
      count
    }
  }
`;

const UPDATEREGISTRANTPROCESS = gql`
  mutation updateRegistrantProcess($user: MongoID!, $registrant: MongoID!, $type: String!) {
    updateRegistrantProcess(user: $user, registrant: $registrant, type: $type) {
      registrant {
        _id
        rating
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        onlineSalesRep {
          _id
          firstName
          lastName
          fullName
        }
      }
      process {
        _id
        currentProcess
        default
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          name
        }
        users {
          _id
          fullName
        }
        timeBetweenSteps
        processType
        name
      }
    }
  }
`;

export default ProcessTable;
