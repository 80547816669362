import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useMutation, gql } from "@apollo/client";

import { Typography, Button, Box, Grid, Skeleton } from "@mui/material";
import { SettingContainer, FlexBetween } from "../../../commonStyles";
import { IProject } from "../../../types/project";
import { ICampaign } from "../../../types/campaign";
import CampaignCard from "./CampaignCard";
import EditCampaign from "./EditCampaign";
import { selectProject } from "../../../features/project/projectSlice";
import { useAppDispatch } from "../../../app/hooks";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";

const Campaign = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [campaignView, setCampaignView] = useState<string>("main");
  const [campaign, setCampaign] = useState<ICampaign | null>(null);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);

  const { loading } = useQuery(GETCAMPAIGNS, {
    variables: { filter: { project: project?._id } },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setCampaigns(data.campaignMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [deleteCampaign] = useMutation(DELETECAMPAIGN, {
    onCompleted: (data) => {
      setCampaigns(campaigns.filter((campaign: ICampaign) => campaign._id !== data.campaignRemoveById.record._id));
      storeDispatch(showSuccessSnackbar("Campaign Deleted!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleCampaignView = (view: string, campaign: ICampaign | null = null) => {
    setCampaign(campaign);
    setCampaignView(view);
  };

  const removeCampaign = (campaign: ICampaign) => {
    deleteCampaign({
      variables: {
        _id: campaign._id,
      },
    });
  };

  return (
    <SettingContainer>
      {campaignView === "main" ? (
        <Box>
          <FlexBetween>
            <Typography variant="h2" component="div" gutterBottom>
              <strong>Campaigns</strong>
            </Typography>
            <Button onClick={() => handleCampaignView("edit")} variant="contained" color="primary">
              Create Campaign
            </Button>
          </FlexBetween>
          {!loading ? (
            campaigns.length ? (
              <Grid container spacing={2}>
                {campaigns.map((campaign: ICampaign, index: number) => {
                  return (
                    <Grid item sm={4}>
                      <CampaignCard
                        key={index}
                        campaign={campaign}
                        handleCampaignView={handleCampaignView}
                        deleteCampaign={removeCampaign}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box>There are currently no campaigns.</Box>
            )
          ) : (
            <Grid container spacing={2}>
              {Array.from(Array(4).keys()).map((item, index) => {
                return (
                  <Grid item xs={12} sm={4} key={index} sx={{ alignSelf: "center" }}>
                    <Skeleton height={200} animation="wave" />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      ) : (
        <EditCampaign campaign={campaign} campaigns={campaigns} setCampaigns={setCampaigns} handleCampaignView={handleCampaignView} />
      )}
    </SettingContainer>
  );
};

interface ChildProps {
  project: IProject | null;
}

const GETCAMPAIGNS = gql`
  query campaignMany($filter: FilterFindManyCampaignInput) {
    campaignMany(filter: $filter, limit: 10000) {
      _id
      project {
        _id
      }
      name
      sourceTypes
      active
      emails {
        condition
        numberOfDays
        emailTemplate {
          _id
          name
        }
        dateStart
      }
      userTypes
    }
  }
`;

const DELETECAMPAIGN = gql`
  mutation campaignRemoveById($_id: MongoID!) {
    campaignRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

export default Campaign;
