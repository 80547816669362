import { useState, useMemo } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { IRegistrant } from "../../types/registrant";
import BasicTabs from "../common/BasicTabs";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Container, FlexBetween } from "../../commonStyles";
import Texts from "./Texts";
import StandardTable from "../tables/StandardTable";
import { capitalizeFirstLetter, convertAllDates } from "../../utils/function";
import { useSelector } from "react-redux";
import { selectCalls, selectTexts, setTexts } from "../../features/registrants/registrantsSlice";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { selectUser } from "../../features/auth/authSlice";
import { usePhoneQuery } from "../../features/registrants/registrantHooks";
import { IText } from "../../types/callRail";

const Messages = ({ registrant }: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const calls = useSelector(selectCalls);
  const texts = useSelector(selectTexts);
  const [value, setValue] = useState(0);
  const [textMessages, setTextMessages] = useState<IText | null>(null);

  usePhoneQuery(registrant ? registrant._id : "", "registrant", user?.type!);

  useQuery(TEXTMESSAGES, {
    variables: { filter: { primaryPhone: registrant.primaryPhone } },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      let textMessageObject: IText | null = null;
      if (data.textMessageMany.length) {
        textMessageObject = {
          messages: data.textMessageMany.map((text: any) => {
            return {
              content: text.text,
              direction: text.type,
              created_at: text.createdAt,
              registrant: registrant,
              user: user,
            };
          }),
          lastMessage: data.textMessageMany[data.textMessageMany.length - 1].createdAt,
          name: registrant.project.name,
          phoneNumber: registrant.primaryPhone,
        };
      }
      setTextMessages(textMessageObject);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  const [downloadAudio] = useLazyQuery(DOWNLOADAUDIO, {
    onCompleted: (data) => {
      const element = document.createElement("a");
      element.href = data.downloadAudio;
      element.target = "_blank";
      element.click();
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [callRegistrant, { loading }] = useLazyQuery(CALLREGISTRANT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(data.callRegistrant));
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleCall = () => {
    if (!registrant.subscribed) return storeDispatch(showErrorSnackbar("Registrant is unsubscribed"));

    let phone = registrant.primaryPhone.replace(/\D/g, "");
    if (phone.length < 10 && phone.length > 11) {
      return storeDispatch(showErrorSnackbar("Only US/Canadian Phone Numbers are allowed"));
    }

    callRegistrant({ variables: { project: registrant.project._id, customerPhone: parseInt(phone, 10) } });
  };

  const handleTexts = (data: any) => {
    let updatedMessages = data.messages;
    if (textMessages) {
      updatedMessages = [
        ...textMessages.messages,
        {
          content: data.messages[1].content,
          direction: data.messages[1].direction,
          created_at: data.messages[1].created_at,
          registrant: registrant,
          user: user,
        },
      ];
    }
    setTextMessages({
      messages: updatedMessages,
      lastMessage: data.lastMessage,
      name: registrant.project.name,
      phoneNumber: registrant.primaryPhone,
    });
    storeDispatch(setTexts([data]));
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Date",
        accessor: (rowData: any) => convertAllDates(rowData.start_time, "PPpp"),
      },
      {
        Header: "Answered",
        accessor: (rowData: any) => (rowData.answered ? <CheckIcon color="success" /> : <CloseIcon color="error" />),
      },
      {
        Header: "Direction",
        accessor: (rowData: any) => capitalizeFirstLetter(rowData.direction),
      },
      {
        Header: "Duration(s)",
        accessor: (rowData: any) => (rowData.duration ? rowData.duration : rowData.recording_duration),
      },
      {
        Header: "Listen",
        accessor: (rowData: any) => {
          if (rowData.recording_player) {
            return (
              <Button variant="contained" color="primary" rel="noopener noreferrer" target="_blank" href={rowData.recording_player}>
                Listen
              </Button>
            );
          } else return;
        },
      },
      {
        Header: "Download",
        accessor: (rowData: any) => {
          if (rowData.recording) {
            return (
              <Button variant="contained" color="primary" onClick={() => downloadAudio({ variables: { link: rowData.recording } })}>
                Download
              </Button>
            );
          } else return;
        },
      },
    ];
  }, [downloadAudio]);

  const tabs = [
    {
      label: "Calls",
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable data={calls} columns={columns} />
        </Box>
      ),
    },
    {
      label: "Texts",
      component: <Texts data={textMessages ? textMessages : texts[0]} handleTexts={handleTexts} registrant={registrant} />,
    },
  ];

  return (
    <Container sx={{ height: "100%" }}>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>Messages/Phone Calls</strong>
        </Typography>
        {loading || !registrant.subscribed ? null : (
          <Button onClick={() => handleCall()} color="primary" variant="contained" endIcon={<PhoneIcon />}>
            Call
          </Button>
        )}
      </FlexBetween>
      {loading ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
            <CircularProgress sx={{ m: "0 auto" }} />
            <Box sx={{ mt: 2 }}>Calling Registrant...</Box>
          </Box>
        </Box>
      ) : (
        <BasicTabs color="#00142a" tabs={tabs} sub={false} value={value} setValue={setValue} />
      )}
    </Container>
  );
};

interface ChildProps {
  registrant: IRegistrant;
}

const DOWNLOADAUDIO = gql`
  query downloadAudio($link: String!) {
    downloadAudio(link: $link)
  }
`;

const CALLREGISTRANT = gql`
  query callRegistrant($project: MongoID!, $customerPhone: Float!) {
    callRegistrant(project: $project, customerPhone: $customerPhone)
  }
`;

const TEXTMESSAGES = gql`
  query textMessageMany($filter: FilterFindManyTextMessageInput) {
    textMessageMany(filter: $filter, limit: 10000) {
      _id
      type
      text
      project {
        _id
      }
      user {
        fullName
      }
      registrant {
        project {
          _id
        }
        primaryPhone
        fullName
      }
      primaryPhone
      createdAt
    }
  }
`;

export default Messages;
