import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import counterReducer from "../features/counter/counterSlice";
import snackbarReducer from "../features/snackbar/snackbarSlice";
import authReducer from "../features/auth/authSlice";
import modalReducer from "../features/modal/modalSlice";
import questionReducer from "../features/question/questionSlice";
import projectReducer from "../features/project/projectSlice";
import userReducer from "../features/user/userSlice";
import processReducer from "../features/process/processSlice";
import projectSettingReducer from "../features/projectSetting/projectSettingSlice";
import emailTemplateReducer from "../features/emailTemplate/emailTemplateSlice";
import registrantsReducer from "../features/registrants/registrantsSlice";
import commentsReducer from "../features/comments/commentSlice";
import dialogReducer from "../features/dialog/dialogSlice";
import notificationsReducer from "../features/notifications/notificationSlice";
import textTemplateReducer from "../features/textTemplate/textTemplateSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user"],
  blacklist: ["auth"],
};

let rootReducers = combineReducers({
  counter: counterReducer,
  snackbar: snackbarReducer,
  auth: authReducer,
  modal: modalReducer,
  question: questionReducer,
  project: projectReducer,
  user: userReducer,
  process: processReducer,
  projectSetting: projectSettingReducer,
  emailTemplate: emailTemplateReducer,
  textTemplate: textTemplateReducer,
  registrants: registrantsReducer,
  comments: commentsReducer,
  dialog: dialogReducer,
  notifications: notificationsReducer,
});

export type RootReducer = ReturnType<typeof rootReducers>;

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return rootReducers(undefined, action);
  }
  return rootReducers(state, action);
};

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
