import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";
import { IUser } from "../../types/user";

interface userState {
  users: IUser[];
  count: number;
}

const initialState: userState = {
  users: [],
  count: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.users.unshift(action.payload);
    },
    updateUser: (state, action) => {
      let user = state.users.findIndex((user: IUser) => user._id === action.payload._id);
      if (user >= 0) {
        // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
        state.users[user] = action.payload;
      }
    },
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload.items;
      state.count = action.payload.count;
    },
  },
});

export const { setUsers, addUser, updateUser } = userSlice.actions;

export const selectUsers = (state: RootState) => state.user.users;
export const selectCount = (state: RootState) => state.user.count;

export default userSlice.reducer;
