import { useRef, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { Button, Box } from "@mui/material";
import { useLazyQuery, gql } from "@apollo/client";

import { capitalizeFirstLetter, convertAllDates, dataURItoBlob } from "../../utils/function";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";

const MediaRecord = (props: ChildProps) => {
  const { title } = props;
  const storeDispatch = useAppDispatch();
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream, clearBlobUrl } = useReactMediaRecorder({ video: true });

  const [uploadImage] = useLazyQuery(UPLOADIMAGE, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const VideoPreview = ({ stream, mediaBlobUrl }: { stream: MediaStream | null; mediaBlobUrl: any }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);
    return (
      <video id="video" height={480} width={640} src={mediaBlobUrl} ref={stream && stream.active ? videoRef : null} autoPlay controls />
    );
  };

  const recording = () => {
    clearBlobUrl();
    startRecording();
  };

  const downloadRecording = async () => {
    if (!mediaBlobUrl) return storeDispatch(showErrorSnackbar("No Recording to Download!"));

    // Create Thumbnail
    const canvas: any = document.createElement("canvas");
    const videoPlayer: any = document.getElementById("video");

    canvas.width = 640;
    canvas.height = 480;
    canvas?.getContext("2d")?.drawImage(videoPlayer, 0, 0, 640, 480);
    canvas.getContext("2d").strokeStyle = 'black';
    canvas?.getContext("2d")?.beginPath();
    //I'm making sure the circle will always fit - therefore Math.min
    canvas?.getContext("2d")?.arc(canvas.width/2, canvas.height/2, Math.min(60, canvas.width/2-20, canvas.height/2-20), 0, 2 * Math.PI, false);
    canvas.getContext("2d").fillStyle = 'black';
    canvas?.getContext("2d")?.fill();
    canvas.getContext("2d").lineWidth = 1;
    canvas.getContext("2d").strokeStyle = 'black';
    canvas?.getContext("2d")?.stroke();
    canvas?.getContext("2d")?.closePath();

    canvas.getContext("2d").strokeStyle = 'white';
    canvas.getContext("2d").fillStyle = 'white';
    canvas?.getContext("2d")?.beginPath();
    canvas.getContext("2d").lineWidth = 1;
    canvas.getContext("2d").moveTo(290, 200);
    canvas.getContext("2d").lineTo(290, 290);
    canvas.getContext("2d").lineTo(370, 240);
    canvas.getContext("2d").fill();
    let canvasUrl = canvas.toDataURL();

    let imageName = `${title} Image - ${convertAllDates(new Date(), "PP")}`;
    let videoName = `${title} Video - ${convertAllDates(new Date(), "PP")}`;

    let blob = await fetch(mediaBlobUrl).then((res) => res.blob());
    let imageBlob = dataURItoBlob(canvasUrl);

    await uploadImage({ variables: { file: imageBlob, name: imageName, destination: "global/recorded-images/" } });
    await uploadImage({ variables: { file: blob, name: videoName, destination: "global/recorded-videos/" } });

    const videoImage = document.createElement("a");
    videoImage.href = canvasUrl;
    videoImage.download = imageName;
    videoImage.click();

    const element = document.createElement("a");
    element.href = mediaBlobUrl!;
    element.download = videoName;
    element.click();
  };

  return (
    <Box>
      <Box>
        Recording Status: <strong>{capitalizeFirstLetter(status)}</strong>
      </Box>
      <VideoPreview stream={previewStream} mediaBlobUrl={mediaBlobUrl} />
      <Box></Box>
      <Box sx={{ display: "flex" }}>
        <Button color="primary" variant="contained" onClick={recording} sx={{ mr: 2 }}>
          Start Recording
        </Button>
        <Button color="primary" variant="contained" onClick={stopRecording} sx={{ mr: 2 }}>
          Stop Recording
        </Button>
        {mediaBlobUrl ? (
          <Button color="success" variant="contained" onClick={downloadRecording}>
            Download Recording
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

interface ChildProps {
  title: string;
}

const UPLOADIMAGE = gql`
  query uploadImage($file: Upload!, $name: String!, $destination: String!) {
    uploadImage(file: $file, name: $name, destination: $destination) {
      url
    }
  }
`;

export default MediaRecord;
