import React from "react";
import { Box, Typography, FormControlLabel, FormGroup, Checkbox } from "@mui/material";

import { camelToNormal } from "../../../../utils/function";

const ReportOptions = (props: ChildProps) => {
  const { data, constants, handleCheckbox, title } = props;

  return (
    <Box>
      <Box>
        <Box sx={{ display: "flex" }}>
          <FormControlLabel
            sx={{ alignSelf: "center", ml: 0, fontWeight: "bold" }}
            onClick={(e) => e.stopPropagation()}
            control={<Checkbox checked={constants.every((v) => data.includes(v))} onChange={() => handleCheckbox("all")} />}
            label={<Typography sx={{ fontWeight: "bold" }}>{title}</Typography>}
            labelPlacement="start"
          />
        </Box>
      </Box>
      <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
        {constants.map((type: string, index: number) => {
          return (
            <FormControlLabel
              key={index}
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={data.includes(type)} onChange={() => handleCheckbox(type)} />}
              label={camelToNormal(type)}
              labelPlacement="start"
              sx={{ mr: 1, ml: 0 }}
            />
          );
        })}
      </FormGroup>
    </Box>
  );
};

interface ChildProps {
  data: string[];
  constants: string[];
  handleCheckbox: any;
  title: string;
}

export default ReportOptions;
