import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Typography, Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Container, FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectProject } from "../../../features/project/projectSlice";
import { IStep } from "../../../types/process";
import ActionList from "./ActionList";
import { selectUser } from "../../../features/auth/authSlice";

const Actions = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();

  const [steps, setSteps] = useState<IStep[]>([]);

  useQuery(GETSTEPS, {
    skip: !project,
    variables: {
      project: project?._id,
      user: user?._id,
      page: 1,
      perPage: 3,
      query: {
        completed: null,
        type: "regular",
        currentProcess: true,
        user: user?._id,
        sourceType: "all",
        processType: "all",
        dueDate: "mostRecent",
      },
    },
    onCompleted: (data) => {
      setSteps(data.getSteps.steps);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  return (
    <Container sx={{ height: "100%" }}>
      <FlexBetween>
        <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
          <strong>Your Actions</strong>
        </Typography>
        {steps.length || user?.type === 'Management' ? (
          <Button component={Link} to={`/dashboard/project/${project?._id}/actions?row=15&page=1`} color="primary" variant="contained">
            View All
          </Button>
        ) : null}
      </FlexBetween>
      {steps.length ? (
        <Box sx={{ mt: 2 }}>
          {steps.map((step: any, index: number) => {
            return <ActionList key={index} step={step._id} index={index} />;
          })}
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>There are currently no actions to take</Box>
      )}
    </Container>
  );
};

const GETSTEPS = gql`
  query getSteps($project: MongoID!, $user: MongoID, $page: Int!, $perPage: Int!, $query: StepsQueryInput) {
    getSteps(project: $project, user: $user, page: $page, perPage: $perPage, query: $query) {
      count
      steps {
        _id {
          name
          registrant {
            fullName
            _id
          }
          dueDate
          project {
            _id
          }
        }
      }
    }
  }
`;

export default Actions;
