import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";
import { Box, FormControl, InputLabel, MenuItem, TextField, Select, Grid, Button, Autocomplete, Skeleton } from "@mui/material";
import { IUser } from "../../../types/user";
import { IProjectAccess } from "../../../types/user";
import { IProject } from "../../../types/project";
import ReportPhoneTables from "./ReportPhoneTables";
import ReportProjectTables from "./ReportProjectTables";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useSalesQuery } from "../../../features/user/userHooks";
import { selectUsers } from "../../../features/user/userSlice";

const Reporting = (props: ChildProps) => {
  const { user, global, selectedProject, filter } = props;
  const users = useSelector(selectUsers);
  const { projectid } = useParams();
  const storeDispatch = useAppDispatch();

  const [project, setProject] = useState<IProject | null>(selectedProject ? selectedProject : null);
  const [dateStart, setDateStart] = useState<any>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<any>(new Date().setHours(23, 59, 59, 999));
  const [projectTotals, setProjectTotals] = useState<IProjectTotal[][]>([]);
  const [phoneTotals, setPhoneTotals] = useState<IPhoneTotal[][]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [show, setShow] = useState<boolean>(false);
  useSalesQuery("Admin");

  const [getUserStats, { loading }] = useLazyQuery(GETUSERSTATS, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setProjectTotals(data.userStats.projects);
      setPhoneTotals(Array.from({ length: data.userStats.projects.length }, (_, index) => []));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting stats"));
    },
  });

  const [getCallRailStats, { loading: callLoading }] = useLazyQuery(GETCALLRAILSTATS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (projectTotals[0][0].user === "Process") {
        setPhoneTotals([data.callRailStats.projects]);
      } else {
        let numIndex = projectTotals.findIndex((total: any) => total[0].user === data.callRailStats.projects[0].user);
        let totals = phoneTotals.map((phoneTotal: any, index: number) => {
          if (index === numIndex) {
            return data.callRailStats.projects;
          } else return phoneTotal;
        });
        setPhoneTotals(totals);
      }
    },
    onError: (err) => {
      setPhoneTotals([]);
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useEffect(() => {
    if (!global) {
      setSelectedUsers([user]);
    }
    if (projectid) {
      setProject(selectedProject!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectid]);

  useEffect(() => {
    if (dateStart && dateEnd && dateStart.toString() !== "Invalid Date" && dateEnd.toString() !== "Invalid Date") {
      if (selectedProject) {
        getUserStats({
          variables: {
            user:
              user?.type === "Manager"
                ? selectedUsers.length
                  ? [...selectedUsers.map((user: any) => user._id)]
                  : []
                : [...selectedUsers.map((user: any) => user._id)],
            project: selectedProject._id,
            dateStart,
            dateEnd,
            userFilter: "All",
          },
        });
      } else {
        getUserStats({
          variables: {
            user:
              user?.type === "Manager"
                ? selectedUsers.length
                  ? [...selectedUsers.map((user: any) => user._id)]
                  : []
                : [...selectedUsers.map((user: any) => user._id)],
            project: project ? project._id : null,
            dateStart,
            dateEnd,
            userFilter: "",
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, project, getUserStats, selectedUsers, filter, selectedProject, show]);

  const handleProject = (e: any) => {
    setShow(false);
    let selectedProject = user?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === e);
    if (selectedProject) {
      setProject(selectedProject.project);
    } else {
      setProject(null);
    }
  };

  const handleUser = (e: any, value: any) => {
    setShow(false);
    if (value) {
      setSelectedUsers(value);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleCallRail = async (username: any) => {
    let selectedUser = selectedUsers.find((user: IUser) => user.fullName === username[0].user);
    setShow(true);
    if (selectedProject && user?.type !== "Developer") {
      await getCallRailStats({
        variables: {
          user: user?.type === "Manager" ? (selectedUser ? selectedUser._id : user?._id) : user?._id,
          project: selectedProject._id,
          dateStart,
          dateEnd,
          userFilter: "All",
        },
      });
    }
    if (!selectedProject && user?.type !== "Developer") {
      await getCallRailStats({
        variables: {
          user: user?.type === "Manager" ? (selectedUser ? selectedUser._id : null) : user?._id,
          project: project ? project._id : null,
          dateStart,
          dateEnd,
          userFilter: "",
        },
      });
    }
  };

  return (
    <Box>
      {filter ? (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item sm={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={"Start Date (MM/DD/YYYY)"}
                value={dateStart}
                onChange={(newValue) => {
                  if (new Date(newValue).valueOf() > new Date(dateEnd).valueOf()) {
                    return storeDispatch(showErrorSnackbar("Start Date cannot be greater than End Date"));
                  }
                  setShow(false);
                  setDateStart(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={"End Date (YYYY/MM/DD)"}
                value={dateEnd}
                onChange={(newValue) => {
                  if (new Date(dateStart).valueOf() > new Date(newValue).valueOf()) {
                    return storeDispatch(showErrorSnackbar("Start Date cannot be greater than End Date"));
                  }
                  setShow(false);
                  setDateEnd(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={3}>
            <Button onClick={() => setDateStart(new Date("January 1, 2023 00:00:00"))} variant="contained">
              Overall
            </Button>
          </Grid>
          {!projectid ? (
            <Grid item sm={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-project">Project</InputLabel>
                <Select
                  label="Project"
                  sx={{ width: "100%" }}
                  fullWidth
                  labelId="demo-simple-select-project"
                  id="demo-simple-project"
                  value={project ? project._id : "All"}
                  onChange={(e: any) => {
                    handleProject(e.target.value);
                  }}
                  required
                >
                  <MenuItem disabled={!project} value="All">
                    All
                  </MenuItem>
                  {user?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
                    return (
                      <MenuItem key={index} value={projectAccess.project._id}>
                        {projectAccess.project.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {!projectid && user?.type === "Manager" ? (
            <Grid item sm={6}>
              <Autocomplete
                multiple
                id="users"
                options={users}
                freeSolo={false}
                getOptionLabel={(option: any) => option.fullName}
                isOptionEqualToValue={(option, value) => option === value}
                value={selectedUsers}
                onChange={(e, value) => handleUser(e, value)}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Users" />}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {!loading ? (
        projectTotals.map((projectTotal: any, index: number) => {
          return (
            <Box key={index}>
              <Box>
                <ReportProjectTables
                  loading={loading}
                  user={user!}
                  title={projectTotal[0].user}
                  projectTotals={projectTotal}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                />
              </Box>
              {callLoading ? (
                <>
                  <Skeleton height={100} animation="wave" />
                  <Skeleton height={400} sx={{ mt: -10 }} animation="wave" />
                </>
              ) : user?.type !== "Developer" && show && phoneTotals[index].length ? (
                <Box>
                  <ReportPhoneTables
                    loading={callLoading}
                    user={user!}
                    phoneTotals={phoneTotals[index]}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                  />
                </Box>
              ) : (
                <Box
                  sx={{ textAlign: "center", mt: 2 }}
                  onClick={() => {
                    handleCallRail(projectTotal);
                  }}
                >
                  <Button variant="contained">View Call Rail Report</Button>
                </Box>
              )}
            </Box>
          );
        })
      ) : (
        <>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={400} sx={{ mt: -10 }} animation="wave" />
        </>
      )}
    </Box>
  );
};

interface ChildProps {
  user: IUser;
  global?: boolean;
  selectedProject?: IProject;
  filter?: boolean;
}

export interface IProjectTotal {
  name: string;
  user: string;
  processesAssigned: number;
  stepsCompleted: number;
  stepsOverDue: number;
  appointmentsCreated: number;
  appointmentsBooked: number;
  appointmentsSales: number;
  cesInteractions: number;
  appointmentsShowedUp: number;
  notes: number;
}

export interface IPhoneTotal {
  name: string;
  user: string;
  outboundTotal: number;
  outboundBusiness: number;
  outboundNonBusiness: number;
  incomingTotal: number;
  incomingBusiness: number;
  incomingNonBusiness: number;
  incomingAnsweredBusiness: number;
  incomingAnsweredNonBusiness: number;
  incomingMissedBusiness: number;
  incomingMissedNonBusiness: number;
  averageDurationOutbound: number;
  averageDurationIncoming: number;
  callsNotReturned: ICallsReturned[];
  callsReturned: ICallsReturned[];
}

export interface ICallsReturned {
  businessPhone: string;
  customerPhone: string;
  startDate: Date;
  callBackDate: Date;
}

const GETUSERSTATS = gql`
  query userStats($user: [MongoID], $project: MongoID, $dateStart: Date!, $dateEnd: Date!, $userFilter: String) {
    userStats(user: $user, project: $project, dateStart: $dateStart, dateEnd: $dateEnd, userFilter: $userFilter) {
      projects {
        id
        name
        user
        processesAssigned
        stepsCompleted
        stepsOverDue
        appointmentsCreated
        appointmentsBooked
        appointmentsSales
        cesInteractions
        appointmentsShowedUp
        notes
      }
    }
  }
`;

const GETCALLRAILSTATS = gql`
  query callRailStats($user: MongoID, $project: MongoID, $dateStart: Date!, $dateEnd: Date!, $userFilter: String) {
    callRailStats(user: $user, project: $project, dateStart: $dateStart, dateEnd: $dateEnd, userFilter: $userFilter) {
      projects {
        id
        name
        user
        outboundTotal
        outboundBusiness
        outboundNonBusiness
        incomingTotal
        incomingBusiness
        incomingNonBusiness
        incomingAnsweredBusiness
        incomingAnsweredNonBusiness
        incomingMissedBusiness
        incomingMissedNonBusiness
        averageDurationOutbound
        averageDurationIncoming
        callsNotReturned {
          businessPhone
          customerPhone
          startDate
        }
        callsReturned {
          businessPhone
          customerPhone
          startDate
          callBackDate
        }
      }
    }
  }
`;

export default Reporting;
