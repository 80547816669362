import { useQuery, gql } from "@apollo/client";

import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import { setProjects, setProject } from "./projectSlice";

export const useProjectsQuery = (projects: string[], onCompletedFunc?: (data: any) => void) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECTS, {
    variables: { filter: { projectIds: projects }},
    onCompleted: (data) => {
      dispatch(setProjects(data.projectMany));
      if (onCompletedFunc) onCompletedFunc(data);
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useProjectQuery = (id: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECT, {
    skip: !id,
    variables: { _id: id },
    onCompleted: (data) => {
      dispatch(setProject(data.projectById));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETPROJECTS = gql`
  query projectMany($filter: FilterFindManyProjectInput) {
    projectMany(filter: $filter, limit: 100000) {
      _id
      name
    }
  }
`;

const GETPROJECT = gql`
  query projectById($_id: MongoID!) {
    projectById(_id: $_id) {
      _id
      name
      developerName
      logoGetUrl
      email
      addresses {
        streetAddress
        city
        province
        country
        postalCode
      }
      salesOffice
      portal {
        primaryColor
      }
    }
  }
`;
