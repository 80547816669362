import { useState, useMemo, useEffect } from "react";
import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLazyQuery, gql } from "@apollo/client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../../app/hooks";
import { IRegistrant } from "../../../types/registrant";
import { setRegistrants, updateRegistrants } from "../../../features/projectSetting/projectSettingSlice";
import { camelToNormal, convertAllDates, normalToCamel } from "../../../utils/function";
import { useSalesQuery } from "../../../features/user/userHooks";
import { useTemplatesQuery } from "../../../features/emailTemplate/emailTemplateHooks";
import RegistrantTable from "../../common/RegistrantTable";

const MailingTable = () => {
  const { projectid, mailid } = useParams();
  useSalesQuery();
  const [getEmails] = useTemplatesQuery(1, 100, "_ID_DESC");
  const storeDispatch = useAppDispatch();
  let navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(searchParams.get("page") ? parseInt(searchParams.get("page")!, 10) : 1);
  const [perPage, setPerPage] = useState<number>(searchParams.get("row") ? parseInt(searchParams.get("row")!, 10) : 15);
  const query = searchParams.get("type");
  const name = query ? query : "";

  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, []);

  const [getRegistrants, { loading }] = useLazyQuery(GETREGISTRANTSFROMMAIL, {
    onCompleted: (data) => {
      storeDispatch(setRegistrants(data.getRegistrantsFromMail));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getAllRegistrants, { loading: mailLoading }] = useLazyQuery(GETREGISTRANTSFROMMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setSelectedRegistrants(data.getRegistrantsFromMail.registrants.map((registrant: IRegistrant) => registrant._id));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    getRegistrants({
      variables: {
        project: projectid,
        mailId: mailid,
        query: normalToCamel(query!),
        page: searchParams ? parseInt(searchParams.get("page")!, 10) : pageNumber,
        perPage: searchParams ? parseInt(searchParams.get("row")!, 10) : perPage,
        search: "",
        registrants: [],
        download: false,
      },
    });
  }, [pageNumber, projectid, mailid, query, getRegistrants, perPage, searchParams]);

  const columns = useMemo(() => {
    const selectAll = () => {
      if (selectedRegistrants.length) {
        setSelectedRegistrants([]);
      } else {
        getAllRegistrants({
          variables: {
            project: projectid,
            mailId: mailid,
            query: normalToCamel(query!),
            page: 1,
            perPage: 30000,
            search: "",
            registrants: [],
            download: false,
          },
        });
      }
    };

    const handleSelectedRegistrants = (id: string) => {
      if (selectedRegistrants.includes(id)) {
        setSelectedRegistrants(selectedRegistrants.filter((regId: string) => regId !== id));
      } else {
        setSelectedRegistrants([...selectedRegistrants, id]);
      }
    };

    const handleRatingChange = (e: string, name: string, data: IRegistrant) => {
      storeDispatch(
        updateRegistrants({
          value: e,
          name: name,
          data: data,
        })
      );
    };

    return [
      {
        id: "selection",
        Header: <Checkbox sx={{ color: "#fff" }} onClick={() => selectAll()} checked={selectedRegistrants.length > 0} />,
        accessor: (rowData: IRegistrant) => {
          return (
            <Box>
              <Checkbox onChange={(e) => handleSelectedRegistrants(rowData._id)} checked={selectedRegistrants.includes(rowData._id)} />
            </Box>
          );
        },
      },
      {
        Header: "First Name",
        accessor: (rowData: IRegistrant) => {
          return (
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/dashboard/project/${rowData.project._id}/registrant/${rowData._id}`)}
            >
              {rowData.firstName}
            </strong>
          );
        },
      },
      {
        Header: "Last Name",
        accessor: (rowData: IRegistrant) => {
          return (
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/dashboard/project/${rowData.project._id}/registrant/${rowData._id}`)}
            >
              {rowData.lastName}
            </strong>
          );
        },
      },
      {
        Header: "Email",
        accessor: (rowData: IRegistrant) => {
          return rowData.email;
        },
      },
      {
        Header: "Phone Number",
        accessor: (rowData: IRegistrant) => {
          return rowData.primaryPhone;
        },
      },
      {
        Header: "Rating",
        accessor: (rowData: IRegistrant) => {
          return (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Rating</InputLabel>
              <Select
                labelId="ratings"
                name="rating"
                value={rowData.rating}
                label="Rating"
                onChange={(e) => handleRatingChange(e.target.value, e.target.name, rowData)}
              >
                <MenuItem value={"N"}>N</MenuItem>
                <MenuItem value={"A"}>A</MenuItem>
                <MenuItem value={"B"}>B</MenuItem>
                <MenuItem value={"C"}>C</MenuItem>
                <MenuItem value={"P"}>P</MenuItem>
                <MenuItem value={"R"}>R</MenuItem>
                <MenuItem value={"NQ"}>NQ</MenuItem>
                <MenuItem value={"Spam"}>Spam</MenuItem>
              </Select>
            </FormControl>
          );
        },
      },
      {
        Header: "Source",
        accessor: (rowData: IRegistrant) => {
          return camelToNormal(rowData.source);
        },
      },
      {
        Header: "Realtor Type",
        accessor: (rowData: IRegistrant) => {
          return camelToNormal(rowData.realtorType);
        },
      },
      {
        Header: "Subscribed",
        accessor: (rowData: IRegistrant) => {
          return rowData.subscribed ? "Yes" : "No";
        },
      },
      {
        Header: "Date Registered",
        accessor: (rowData: IRegistrant) => {
          return convertAllDates(rowData.createdAt, "PPpp");
        },
      },
    ];
  }, [selectedRegistrants, mailid, storeDispatch, navigate, projectid, getAllRegistrants, query]);

  // Functions

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getRegistrants({
      variables: { mailId: mailid, query: normalToCamel(query!), page: 1, perPage: 15, search: value, registrants: [], download: false },
    });
  };

  const handleQueryParams = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setSearchParams({ page: (newPage + 1).toString(), row: perPage.toString(), type: query! });
    setPageNumber(newPage);
  };

  const handlePerRows = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setSearchParams({ row: event.target.value, page: "1" });
    setPageNumber(1);
  };

  return (
    <div>
      <RegistrantTable
        purchasers={[]}
        handleGlobalFilterValue={handleGlobalFilterValue}
        selectedRegistrants={selectedRegistrants}
        columns={columns}
        loading={loading}
        id={mailid}
        name={name}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        query={query}
        handleQueryParams={handleQueryParams}
        perPage={perPage}
        handlePerRows={handlePerRows}
      />
    </div>
  );
};

const GETREGISTRANTSFROMMAIL = gql`
  query getRegistrantsFromMail(
    $project: MongoID
    $mailId: MongoID!
    $query: String!
    $page: Float!
    $perPage: Float!
    $search: String
    $registrants: [MongoID]
    $download: Boolean
  ) {
    getRegistrantsFromMail(
      project: $project
      mailId: $mailId
      query: $query
      page: $page
      perPage: $perPage
      search: $search
      registrants: $registrants
      download: $download
    ) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        primaryPhone
        rating
        source
        realtorType
        subscribed
        createdAt
      }
    }
  }
`;

export default MailingTable;
