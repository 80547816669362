/* Dependency Imports */
import React, { useState } from "react";
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { gql, useMutation } from "@apollo/client";

/* Project Imports */
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../app/hooks";

const ForgotPassword = ({ open, setOpen }: any) => {
  /* Redux */
  const dispatch = useAppDispatch();

  /* States */
  const [email, setEmail] = useState("");

  /* Queries/Mutations */
  const [emailPasswordReset] = useMutation(EMAILRESETPASSWORD, {
    variables: { email: email },
    onCompleted: (data) => {
      dispatch(showSuccessSnackbar(`Email sent to ${data.userEmailResetPassword.email}`));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar("Error"));
      console.log(err, "err");
    },
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    emailPasswordReset();
  };

  /* Functions */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Forgot Password
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogContent sx={{ py: 1, px: 3 }}>
          <DialogContentText>
            If you have forgotten your password, please enter your email address here. We will send you a recovery email.
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={handleEmailChange}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Send</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

/* Types */

/* GQL */
const EMAILRESETPASSWORD = gql`
  mutation userEmailResetPassword($email: String!) {
    userEmailResetPassword(email: $email)
  }
`;

export default ForgotPassword;
