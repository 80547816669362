/* Dependency Imports */
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Box } from "@mui/material";

/* Project Imports */
import Header from "./Header";
import { useProjectQuery } from "../../features/project/projectHooks";
import { useProjectSettingQuery } from "../../features/projectSetting/projectSettingHooks";
import { RegistrantNotificationProvider } from "../../context/RegistrantNotificationContext";

const Layout = () => {
  /* Redux */
  const { projectid } = useParams();
  useProjectQuery(projectid!);
  useProjectSettingQuery(projectid!);

  /* States */

  /* Queries/Mutations */

  /* Functions */

  return (
    <>
      <RegistrantNotificationProvider>
        <Header />
        <Box
          sx={{
            p: 3,
            minHeight: "calc(100vh - 80px)",
            backgroundColor: "#e5e5e5",
          }}
        >
          <Outlet />
        </Box>
      </RegistrantNotificationProvider>
    </>
  );
};

/* Types */

/* GQL */

export default Layout;
