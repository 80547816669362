import { Dispatch, SetStateAction, useState } from "react";
import { Typography, FormControl, InputLabel, Select, MenuItem, Button, Box } from "@mui/material";
import { gql, useMutation } from "@apollo/client";

import { customDisplay } from "../../utils/projectFunction";
import { validateEmail, validatePhoneNumber } from "../../utils/function";
import { IRegistrant } from "../../types/registrant";

const CheckIn = (props: ChildProps) => {
  const { type, projectId, formSubmitted, setFormSubmitted, registrant, setShowForm } = props;
  const [guests, setGuests] = useState<string>("");
  const [custom, setCustom] = useState("");
  const [custom1, setCustom1] = useState("");
  const [custom2, setCustom2] = useState("");
  const [scheduleWithRealtor, setScheduleWithRealtor] = useState<string>("");
  const [meetingWithRealtor, setMeetingWithRealtor] = useState<string>("");

  const [checkInRegistrant] = useMutation(CHECKIN, {
    onCompleted: () => {
      setShowForm(false);
    },
  });

  const handleCheckInSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (registrant.realtorType === "hasRealtor") {
      checkInRegistrant({
        variables: {
          email: validateEmail(registrant.email) ? registrant.email : "",
          phone: registrant.primaryPhone || (validatePhoneNumber(registrant.primaryPhone) ? registrant.primaryPhone : ""),
          project: projectId,
          guests: guests,
          custom: custom,
          meetingWithRealtor: meetingWithRealtor === "yes" ? true : false,
          scheduleWithRealtor: scheduleWithRealtor === "yes" ? true : false,
        },
      });
    } else {
      checkInRegistrant({
        variables: {
          email: validateEmail(registrant.email) ? registrant.email : "",
          phone: registrant.primaryPhone || (validatePhoneNumber(registrant.primaryPhone) ? registrant.primaryPhone : ""),
          project: projectId,
          guests: guests,
          custom: custom,
        },
      });
    }
  };

  return (
    <form onSubmit={handleCheckInSubmit}>
      <Typography variant="h5">
        <strong>Welcome!</strong>
      </Typography>
      {type === "walkin" ? customDisplay(projectId!, custom, setCustom, custom1, setCustom1, custom2, setCustom2) : null}
      {registrant?.realtorType === "hasRealtor" ? (
        <Box>
          <FormControl fullWidth required disabled={formSubmitted} sx={{ mt: 1, mb: 2 }}>
            <InputLabel>{"Did you schedule an appointment with your Realtor?"}</InputLabel>
            <Select
              required
              label={"Did you schedule an appointment with your Realtor?"}
              value={scheduleWithRealtor}
              onChange={(e) => setScheduleWithRealtor(e.target.value)}
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth required disabled={formSubmitted} sx={{ mt: 1, mb: 2 }}>
            <InputLabel>{"Is your realtor meeting with you at our office today?"}</InputLabel>
            <Select
              required
              label={"Is your realtor meeting with you at our office today?"}
              value={meetingWithRealtor}
              onChange={(e) => setMeetingWithRealtor(e.target.value)}
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : null}
      <FormControl fullWidth required disabled={formSubmitted} sx={{ mt: 1, mb: 2 }}>
        <InputLabel>{"How many guests are with you?"}</InputLabel>
        <Select required label={"How many guests are with you?"} value={guests} onChange={(e) => setGuests(e.target.value)}>
          {Array(7)
            .fill(0)
            .map((_, i) => (
              <MenuItem value={i}>{i}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button variant="contained" type="submit" disabled={formSubmitted}>
        {formSubmitted ? "Loading..." : "Submit"}
      </Button>
    </form>
  );
};

interface ChildProps {
  type: string;
  projectId: string;
  formSubmitted: boolean;
  setFormSubmitted: Dispatch<SetStateAction<boolean>>;
  registrant: IRegistrant;
  setShowForm: Dispatch<SetStateAction<boolean>>;
}

const CHECKIN = gql`
  mutation checkInRegistrant(
    $email: String!
    $phone: String!
    $project: MongoID!
    $guests: Int!
    $custom: String
    $custom1: String
    $custom2: String
    $meetingWithRealtor: Boolean
    $scheduleWithRealtor: Boolean
  ) {
    checkInRegistrant(
      email: $email
      phone: $phone
      project: $project
      guests: $guests
      custom: $custom
      custom1: $custom1
      custom2: $custom2
      meetingWithRealtor: $meetingWithRealtor
      scheduleWithRealtor: $scheduleWithRealtor
    )
  }
`;

export default CheckIn;
