import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, gql, useQuery } from "@apollo/client";
import { Typography, Box, Grid, Select, FormControl, InputLabel, MenuItem, TablePagination, Autocomplete, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectProject } from "../../../features/project/projectSlice";
import { IStep } from "../../../types/process";
import { selectUser } from "../../../features/auth/authSlice";
import StandardTable from "../../tables/StandardTable";
import { IUser } from "../../../types/user";
import { IProject } from "../../../types/project";
import { IProjectAccess } from "../../../types/user";
import { convertAllDates, camelToNormal, downloadExcel, downloadPdf } from "../../../utils/function";

const ActionTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState<number>(searchParams.get("page") ? parseInt(searchParams.get("page")!, 10) : 1);
  const [perPage, setPerPage] = useState<number>(searchParams.get("row") ? parseInt(searchParams.get("row")!, 10) : 15);
  const [count, setCount] = useState(0);
  const [sourceType, setSourceType] = useState<string>("all");
  const [processType, setProcessType] = useState<string>("all");
  const [dueDate, setDueDate] = useState<string>("mostRecent");
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

  const [steps, setSteps] = useState<IStep[]>([]);

  useQuery(GETUSERS, {
    skip: user?.type !== "Manager",
    variables: { filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false } },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getSteps, { loading }] = useLazyQuery(GETSTEPS, {
    onCompleted: (data) => {
      let sortSteps = data.getSteps.steps;
      setSteps(sortSteps.filter((step: any) => step._id.registrant));
      setCount(data.getSteps.count);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useEffect(() => {
    if (project?._id) {
      getSteps({
        variables: {
          project: selectedProject ? selectedProject._id : project?._id,
          user: selectedUser ? selectedUser._id : user?._id,
          page: pageNumber,
          perPage: perPage,
          query: {
            completed: null,
            type: "regular",
            currentProcess: true,
            user: user?._id,
            sourceType,
            processType,
            dueDate,
          },
        },
      });
      setPageNumber(searchParams ? parseInt(searchParams.get("page")!, 10) : pageNumber);
    }
  }, [project, getSteps, user?._id, pageNumber, sourceType, processType, dueDate, perPage, searchParams, selectedUser, selectedProject]);

  const handlePerRows = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setSearchParams({ row: event.target.value, page: "1" });
    setPageNumber(1);
  };

  const handleQueryParams = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setSearchParams({ page: (newPage + 1).toString(), row: perPage.toString() });
    setPageNumber(newPage);
  };

  const handleProject = (e: any) => {
    let selectedProject = user?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === e);
    if (selectedProject) {
      setSelectedProject(selectedProject.project);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: (rowData: any) => {
          if (rowData._id.registrant) {
            return (
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to={`/dashboard/project/${project?._id}/registrant/${rowData._id.registrant._id}`}
              >
                <strong>{rowData._id.name}</strong>
              </Link>
            );
          } else {
            return <strong>{rowData._id.name}</strong>;
          }
        },
      },
      {
        Header: "Project",
        accessor: (rowData: any) => rowData._id.project.name,
      },
      {
        Header: "Registrant",
        accessor: (rowData: any) => rowData._id.registrant.fullName,
      },
      {
        Header: "Process Type",
        accessor: (rowData: any) => camelToNormal(rowData.processType),
      },
      {
        Header: "Source Type",
        accessor: (rowData: any) => camelToNormal(rowData.sourceType),
      },
      {
        Header: "Due Date",
        accessor: (rowData: any) => convertAllDates(rowData._id.dueDate, "PP"),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getSteps({
      variables: {
        project: project?._id,
        user: user?._id,
        page: pageNumber,
        perPage: 10,
        query: {
          completed: null,
          type: "regular",
          currentProcess: true,
          user: user?._id,
          search: value,
          sourceType,
          processType,
          dueDate,
        },
      },
    });
  };

  const download = async (type: string, data: any) => {
    let datas = await getSteps({
      variables: {
        project: selectedProject ? selectedProject._id : project?._id,
        user: selectedUser ? selectedUser._id : user?._id,
        page: 1,
        perPage: 10000,
        query: {
          completed: null,
          type: "regular",
          currentProcess: true,
          user: user?._id,
          sourceType,
          processType,
          dueDate,
        },
      },
    });

    let columns = [
      {
        label: "Name",
        id: "name",
      },
      {
        label: "Project",
        id: "project",
      },
      {
        label: "Registrant",
        id: "registrant",
      },
      {
        label: "Process Type",
        id: "processType",
      },
      {
        label: "Source Type",
        id: "sourceType",
      },
      {
        label: "Due Date",
        id: "dueDate",
      },
    ];

    let widths = {
      name: 15,
      project: 15,
      registrant: 15,
      processType: 15,
      sourceType: 15,
      dueDate: 15,
    };

    let pdfWidths = {
      name: 200,
      project: 200,
      registrant: 200,
      processType: 200,
      sourceType: 200,
      dueDate: 200,
    };

    let allData = datas.data.getSteps.steps.map((data: any) => {
      return {
        name: data._id.name,
        project: data._id.project.name,
        registrant: data._id.registrant.fullName,
        processType: camelToNormal(data.processType),
        sourceType: camelToNormal(data.sourceType),
        dueDate: convertAllDates(data._id.dueDate, "PP"),
      };
    });

    let sheetTitle = `${selectedUser ? selectedUser.fullName : user?.fullName} Steps`;

    if (type === "excel") {
      downloadExcel([allData], [columns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [columns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box>
      <FlexBetween>
        <Box sx={{ display: "flex" }}>
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            sx={{ mr: 1, cursor: "pointer", color: "primary.main", alignSelf: "center" }}
            fontSize="small"
          />
          <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
            <strong>{selectedUser ? selectedUser.fullName : "Your"} Steps</strong>
          </Typography>
        </Box>
      </FlexBetween>
      <Grid sx={{ mt: 1 }} container spacing={2}>
        {user?.type === "Manager" ? (
          <>
            <Grid item xs={12} md={user?.type === "Manager" ? 3 : 4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Online or Onsite</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name={"sourceType"}
                  label={"Online or Onsite"}
                  value={sourceType}
                  onChange={(e) => setSourceType(e.target.value)}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"online"}>Online</MenuItem>
                  <MenuItem value={"onsite"}>Onsite</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={user?.type === "Manager" ? 3 : 4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Project</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name={"project"}
                  label={"Project"}
                  value={selectedProject ? selectedProject._id : ""}
                  onChange={(e: any) => {
                    handleProject(e.target.value);
                  }}
                >
                  {user?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
                    return (
                      <MenuItem key={index} value={projectAccess.project._id}>
                        {projectAccess.project.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} md={user?.type === "Manager" ? 3 : 4}>
          <FormControl fullWidth>
            <Autocomplete
              sx={{ mb: 1 }}
              options={users.map((user: IUser) => user.fullName)}
              onChange={(e, value: any) => {
                let selectedUser = users.find((user: IUser) => user.fullName === value);
                if (selectedUser) {
                  setSelectedUser(selectedUser);
                }
              }}
              value={selectedUser ? selectedUser.fullName : ""}
              renderInput={(params) => <TextField {...params} label="User" />}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={user?.type === "Manager" ? 3 : 4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Process Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name={"processType"}
              label={"Process Type"}
              value={processType}
              onChange={(e) => setProcessType(e.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"shortTerm"}>Short Term</MenuItem>
              <MenuItem value={"event"}>Event</MenuItem>
              <MenuItem value={"handover"}>Handover</MenuItem>
              <MenuItem value={"sales"}>Sales</MenuItem>
              <MenuItem value={"longTerm"}>Long Term</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={user?.type === "Manager" ? 3 : 4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Due Date</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name={"dueDate"}
              label={"Due Date"}
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            >
              <MenuItem value={"mostRecent"}>Most Recent</MenuItem>
              <MenuItem value={"oldest"}>Oldest</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <StandardTable
          user={user}
          download={download}
          loading={loading}
          count={count}
          handleGlobalFilterValue={handleGlobalFilterValue}
          columns={columns}
          data={steps}
        />
      </Box>
      <Box sx={{ textAlign: "center", mt: 2, mx: "auto" }}>
        <TablePagination
          component="div"
          count={count}
          page={pageNumber - 1}
          onPageChange={handleQueryParams}
          rowsPerPage={perPage!}
          rowsPerPageOptions={[15, 25, 50, 100]}
          onRowsPerPageChange={handlePerRows}
        />
      </Box>
    </Box>
  );
};

const GETSTEPS = gql`
  query getSteps($project: MongoID!, $user: MongoID, $page: Int!, $perPage: Int!, $query: StepsQueryInput) {
    getSteps(project: $project, user: $user, page: $page, perPage: $perPage, query: $query) {
      count
      steps {
        _id {
          name
          registrant {
            fullName
            _id
          }
          dueDate
          project {
            _id
            name
          }
          completed
          user {
            fullName
          }
          type
        }
        processType
        sourceType
      }
    }
  }
`;

const GETUSERS = gql`
  query userMany($filter: FilterFindManyUserInput!) {
    userMany(filter: $filter) {
      _id
      email
      fullName
    }
  }
`;

export default ActionTable;
