import { GoogleMap, useLoadScript } from "@react-google-maps/api";

const defaultCenter = { lat: 43.6532, lng: -79.3832 };

const options = {
  disableDefaultUI: true,
  scaleControl: true,
  mapTypeId: "roadmap",
  labels: true,
};

const Map = (props: any) => {
  const { setMap, children, apiKey } = props;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const renderMap = () => {
    const loadHandler = (map: any) => {
      setMap(map);
    };

    return (
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          minHeight: "500px",
        }}
        center={defaultCenter}
        options={options}
        onLoad={loadHandler}
      >
        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Loading...</div>;
};

export default Map;
