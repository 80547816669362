/* Dependency Imports */
import React, { useState, useReducer } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

import RegistrantInfo from "../common/RegistrantInfo";
import { IChoice } from "../../types/question";
import { customDisplay } from "../../utils/projectFunction";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { formatPhoneNumber } from "../../utils/function";

const SignupForm = ({
  questions,
  formEmail,
  formPhone,
  projectid,
  handleFormSubmit,
}: {
  questions: IQuestion[];
  formEmail: string;
  formPhone: string;
  projectid: string | undefined;
  handleFormSubmit: (props: any, registerFunction: (onCompleteFunction: () => void) => void) => void;
}) => {
  /* Redux/Hooks*/
  const storeDispatch = useAppDispatch();
  /* States */
  const [email, setEmail] = useState(formEmail);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(formPhone);
  const [workingWithRealtor, setWorkingWithRealtor] = useState("");
  const [realtorName, setRealtorName] = useState("");
  const [realtorEmail, setRealtorEmail] = useState("");
  const [meetingWithRealtor, setMeetingWithRealtor] = useState("");
  const [scheduleWithRealtor, setScheduleWithRealtor] = useState("");
  const [agree, setAgree] = useState(false);
  const [miscInfo, dispatch] = useReducer((state: IAnswer[], action: { payload: IAnswer; index: number }) => {
    const newState = [...state];
    newState[action.index] = action.payload;
    return newState;
  }, Array(questions.length).fill({ answer: "", questionId: null }));
  const [submitted, setSubmitted] = useState(false);
  const [custom, setCustom] = useState("");
  const [custom1, setCustom1] = useState("");
  const [custom2, setCustom2] = useState("");
  const [searchParams] = useSearchParams();
  const [confirmClient, setConfirmClient] = useState<string>("");
  const [clientFirstName, setClientFirstName] = useState<string>("");
  const [clientLastName, setClientLastName] = useState<string>("");
  const [clientEmail, setClientEmail] = useState<string>("");
  const [clientPhone, setClientPhone] = useState<string>("");
  const type = searchParams.get("type");
  const tag = searchParams.get("tag");

  /* Queries/Mutations */
  const [createRegistrant] = useMutation(CREATEREGISTRANT, {
    fetchPolicy: "network-only",
    onError: (err) => {
      console.log(err, "err");
    },
  });

  /* Functions */

  const handleChange = (e: any, i: number, id: string) => {
    dispatch({ payload: { answer: e.target.value, questionId: id }, index: i });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, i: number, j: number) => {
    const newCheck: any = miscInfo[i];
    let checkBoxAnswers = [...miscInfo[i].answer];

    if (checkBoxAnswers.includes(e.target.value)) {
      checkBoxAnswers.splice(checkBoxAnswers.indexOf(e.target.value), 1);
    } else {
      checkBoxAnswers = [...checkBoxAnswers, e.target.value];
    }

    newCheck[j] = { answer: checkBoxAnswers, questionId: e.target.name };
    dispatch({ payload: newCheck[j], index: i });
  };

  const handleQuestions = (question: IQuestion, i: number) => {
    switch (question.type) {
      case "text":
        return (
          <TextField
            required
            fullWidth
            name={question.name}
            id={question._id}
            label={question.question}
            variant="outlined"
            value={miscInfo[i].answer}
            onChange={(e) => handleChange(e, i, question._id)}
            disabled={submitted}
          />
        );
      case "dropdown":
        return (
          <FormControl fullWidth required disabled={submitted}>
            <InputLabel>{`${question.question}`}</InputLabel>
            <Select
              name={question.name}
              label={`${question.question}`}
              value={miscInfo[i].answer}
              onChange={(e) => handleChange(e, i, question._id)}
              required
            >
              {question.choices.map((choice, j) => (
                <MenuItem key={j} value={choice.choice}>
                  {choice.choice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "radio":
        return (
          <FormControl required fullWidth disabled={submitted}>
            <FormLabel>{`${question.question}`}</FormLabel>
            <RadioGroup row name={question.name} id={question._id} value={miscInfo[i]} onChange={(e) => handleChange(e, i, question._id)}>
              {question.choices.map((choice, j) => (
                <FormControlLabel key={j} value={choice.choice} control={<Radio required={true} />} label={choice.choice} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControl required fullWidth disabled={submitted}>
            <FormLabel>{question.question}</FormLabel>
            <FormGroup row>
              {question.choices.map((choice, j) => (
                <FormControlLabel
                  key={j}
                  id={question._id}
                  control={
                    <Checkbox
                      value={choice.choice}
                      name={question._id}
                      checked={miscInfo[i].answer.includes(choice.choice)}
                      onChange={(e) => handleCheck(e, i, j)}
                    />
                  }
                  label={choice.choice}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      default:
        return <></>;
    }
  };

  const handleSubQuestions = (question: IQuestion, i: number) => {
    let subQuestion = question.choices.find((choice: IChoice) => choice.choice === miscInfo[i].answer && choice.followUp.length > 0);

    if (subQuestion) {
      return subQuestion.followUp.map((question, i) => {
        return (
          <Grid item xs={12} key={i}>
            {handleQuestions(question, i)}
          </Grid>
        );
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let miscQuestions = miscInfo
      .filter((question: any) => question.questionId !== null)
      .map((misc: IAnswer) => {
        return {
          ...misc,
          answer: typeof misc.answer === "string" ? [misc.answer] : misc.answer,
        };
      });

    if (projectid === "6339946d6b2a3eadb397bf60" && !miscInfo[0].answer.length)
      return storeDispatch(showErrorSnackbar("A project has not been selected"));
    setSubmitted(true);
    handleFormSubmit({ variables: { email: email, phone: phoneNumber, project: projectid } }, (onCompleteFunction) =>
      createRegistrant({
        onCompleted: () => {
          onCompleteFunction();
        },
        variables: {
          record: {
            email: email.toLowerCase(),
            firstName: firstName,
            lastName: lastName,
            primaryPhone: phoneNumber,
            project: projectid,
            realtorName,
            realtorType: workingWithRealtor,
            realtorEmail,
            count:
              workingWithRealtor === "hasRealtor"
                ? [
                    {
                      reason: "",
                      guests: 0,
                      meetingWithRealtor: meetingWithRealtor === "yes" ? true : false,
                      scheduleWithRealtor: scheduleWithRealtor === "yes" ? true : false,
                    },
                  ]
                : [],
            source: type ? "walk in" : "online registration",
            questions: miscQuestions,
            tags: tag ? [tag] : [],
          },
          custom: custom,
          custom1: custom1,
          custom2: custom2,
          clientFirstName: clientFirstName,
          clientLastName: clientLastName,
          clientEmail: clientEmail,
          clientPhone: clientPhone,
        },
      })
    );
  };

  const handleClient = (value: string) => {
    setConfirmClient(value);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <p>Kindly complete the form below:</p>
      <h2>Registrant Information</h2>
      <RegistrantInfo
        email={email}
        setEmail={setEmail}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        workingWithRealtor={workingWithRealtor}
        setWorkingWithRealtor={setWorkingWithRealtor}
        realtorName={realtorName}
        setRealtorName={setRealtorName}
        realtorEmail={realtorEmail}
        setRealtorEmail={setRealtorEmail}
        submitted={false}
        phoneRequired={true}
      />
      {workingWithRealtor === "isRealtor" ? (
        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth required sx={{ my: 1 }}>
            <InputLabel>{"Is your client here with you today?"}</InputLabel>
            <Select
              required
              label={"Is your client here with you today?"}
              value={confirmClient}
              onChange={(e) => handleClient(e.target.value)}
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
          {confirmClient === "yes" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Client's First Name"
                  variant="outlined"
                  name="fname"
                  autoComplete="given-name"
                  value={clientFirstName}
                  onChange={(e) => setClientFirstName(e.target.value)}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Client's Last Name"
                  variant="outlined"
                  name="lname"
                  autoComplete="family-name"
                  value={clientLastName}
                  onChange={(e) => setClientLastName(e.target.value)}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Client's Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Client's Phone Number"
                  variant="outlined"
                  name="tel"
                  type="tel"
                  autoComplete="tel"
                  value={clientPhone}
                  onChange={(e) => setClientPhone(formatPhoneNumber(e.target.value))}
                  disabled={submitted}
                />
              </Grid>
            </Grid>
          ) : null}
        </Box>
      ) : null}
      {workingWithRealtor === "hasRealtor" ? (
        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth required sx={{ mt: 1, mb: 1 }}>
            <InputLabel>{"Did you schedule an appointment with your Realtor?"}</InputLabel>
            <Select
              required
              label={"Did you schedule an appointment with your Realtor?"}
              value={scheduleWithRealtor}
              onChange={(e) => setScheduleWithRealtor(e.target.value)}
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth required sx={{ mt: 1, mb: 1 }}>
            <InputLabel>{"Is your realtor meeting with you at our office today?"}</InputLabel>
            <Select
              required
              label={"Is your realtor meeting with you at our office today?"}
              value={meetingWithRealtor}
              onChange={(e) => setMeetingWithRealtor(e.target.value)}
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : null}
      {questions.length &&
      questions.filter((question: IQuestion) => {
        if (question.display !== "authenticated") {
          if (question.registrantType === "both") {
            return true;
          } else if (workingWithRealtor === "isRealtor") {
            return question.registrantType === "realtor";
          } else return question.registrantType === "purchaser";
        } else return question.display !== "authenticated";
      }).length ? (
        <>
          <h2>Let us know what you're looking for:</h2>
          <Grid container spacing={2}>
            {questions
              .filter((question: IQuestion) => {
                if (question.display !== "authenticated") {
                  if (question.registrantType === "both") {
                    return true;
                  } else if (workingWithRealtor === "isRealtor") {
                    return question.registrantType === "realtor";
                  } else return question.registrantType === "purchaser";
                } else return question.display !== "authenticated";
              })
              .map((question, i) => {
                if (!question.subQuestion) {
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={12} key={i}>
                        {handleQuestions(question, i)}
                      </Grid>
                      {question.choices.find((choice: IChoice) => choice.choice === miscInfo[i].answer && choice.followUp.length > 0)
                        ? handleSubQuestions(question, i)
                        : null}
                    </React.Fragment>
                  );
                } else return null;
              })}
          </Grid>
        </>
      ) : null}
      {type === "walkin" ? customDisplay(projectid!, custom, setCustom, custom1, setCustom1, custom2, setCustom2) : null}
      <Box sx={{ my: 2, display: "flex", alignItems: "flex-start" }}>
        <FormControl disabled={submitted} sx={{ mr: 3, alignSelf: "center" }}>
          <Checkbox checked={agree} required onChange={(e) => setAgree(e.target.checked)} />
        </FormControl>
        <Box sx={{ fontSize: "11px", alignSelf: "center" }}>
          By submitting this form, you are agreeing to receive communications from Residential Development Services and its clients and
          acknowledge that you may unsubscribe at any time.
        </Box>
      </Box>
      <Button
        variant="contained"
        type="submit"
        disabled={!agree || !firstName || !lastName || !phoneNumber || !workingWithRealtor || submitted}
      >
        {submitted ? "Loading..." : "Submit"}
      </Button>
    </Box>
  );
};

/* Types */
interface IQuestion {
  name: string;
  question: string;
  type: string;
  subQuestion: boolean;
  registrantType: string;
  display: string;
  choices: {
    choice: string;
    followUp: IQuestion[];
    _id: string;
  }[];
  _id: string;
}

interface IAnswer {
  answer: string | string[];
  questionId: string;
}

/* GQL */
const CREATEREGISTRANT = gql`
  mutation createRegistrant(
    $record: CreateOneRegistrantInput!
    $custom: String
    $custom1: String
    $custom2: String
    $clientFirstName: String
    $clientLastName: String
    $clientEmail: String
    $clientPhone: String
  ) {
    createRegistrant(
      record: $record
      custom: $custom
      custom1: $custom1
      custom2: $custom2
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      clientEmail: $clientEmail
      clientPhone: $clientPhone
    ) {
      _id
    }
  }
`;

export default SignupForm;
