import { Dispatch, SetStateAction } from "react";
import { Typography, Grid, FormControl, TextField } from "@mui/material";

const RealtorForm = (props: ChildProps) => {
  const {
    brokerage,
    setBrokerage,
    brokeragePhone,
    setBrokeragePhone,
    directPhone,
    setDirectPhone,
    brokerageFax,
    setBrokerageFax,
    streetAddress,
    setStreetAddress,
    city,
    setCity,
    province,
    setProvince,
    postalCode,
    setPostalCode,
    country,
    setCountry,
    recoNumber,
    setRecoNumber,
  } = props;
  return (
    <div>
      <Typography sx={{ mt: 2 }} variant="h3" component="div" gutterBottom>
        <strong>Realtor Info</strong>
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              fullWidth
              name="brokerageName"
              label="Brokerage Name"
              type="text"
              variant="outlined"
              value={brokerage}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBrokerage(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              fullWidth
              name="streetAddress"
              label="Brokerage Address"
              type="text"
              variant="outlined"
              value={streetAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStreetAddress(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              fullWidth
              name="city"
              label="City"
              type="text"
              variant="outlined"
              value={city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              fullWidth
              name="province"
              label="Province"
              type="text"
              variant="outlined"
              value={province}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProvince(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              fullWidth
              name="country"
              label="Country"
              type="text"
              variant="outlined"
              value={country}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              fullWidth
              name="postalCode"
              label="Postal Code"
              type="text"
              variant="outlined"
              value={postalCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              required
              name="brokeragePhone"
              label="Brokerage Phone"
              type="text"
              variant="outlined"
              value={brokeragePhone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBrokeragePhone(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              required
              name="directPhone"
              label="Direct Phone"
              type="text"
              variant="outlined"
              value={directPhone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDirectPhone(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              name="brokerageFax"
              label="Brokerage Fax"
              type="text"
              variant="outlined"
              value={brokerageFax}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBrokerageFax(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              name="recoNumber"
              label="RECO Number"
              type="text"
              variant="outlined"
              value={recoNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecoNumber(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

interface ChildProps {
  brokerage: string;
  setBrokerage: Dispatch<SetStateAction<string>>;
  brokeragePhone: string;
  setBrokeragePhone: Dispatch<SetStateAction<string>>;
  directPhone: string;
  setDirectPhone: Dispatch<SetStateAction<string>>;
  brokerageFax: string;
  setBrokerageFax: Dispatch<SetStateAction<string>>;
  streetAddress: string;
  setStreetAddress: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  province: string;
  setProvince: Dispatch<SetStateAction<string>>;
  postalCode: string;
  setPostalCode: Dispatch<SetStateAction<string>>;
  country: string;
  setCountry: Dispatch<SetStateAction<string>>;
  recoNumber: string;
  setRecoNumber: Dispatch<SetStateAction<string>>;
}

export default RealtorForm;
