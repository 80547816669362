import { useState } from "react";
import { Box } from "@mui/material";
import { SettingContainer } from "../../commonStyles";
import BasicTabs from "../common/BasicTabs";
import Events from "./Events";
import Other from "./Other";
import Stats from "./Stats";
import Appointments from "./Search";

const Schedule = () => {
  const [value, setValue] = useState(0);
  const tabs = [
    {
      label: "All",
      component: <Stats />,
    },
    {
      label: "Event",
      component: <Events />,
    },
    {
      label: "Non Event",
      component: <Other />,
    },
    {
      label: "Search",
      component: <Appointments />,
    },
  ];
  return (
    <SettingContainer sx={{ p: 0 }}>
      <Box>
        <BasicTabs color="#00142a" tabs={tabs} value={value} setValue={setValue} />
      </Box>
    </SettingContainer>
  );
};

export default Schedule;
