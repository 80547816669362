import React from "react";
import { Typography, Box, Button } from "@mui/material";

import { IProcess } from "../../../types/process";
import { IRegistrant } from "../../../types/registrant";
import StandardTable from "../../tables/StandardTable";

const HistoryProcessView = (props: ChildProps) => {
  const { registrant, historyColumns, handleProcess } = props;
  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Process History</strong>
      </Typography>
      <p>Below are the tables for all previous processes</p>
      {registrant.process.map((process: IProcess, index: number) => {
        return (
          <Box key={index} sx={{ mt: 2 }}>
            <Typography>
              <strong>
                <span style={{ cursor: "pointer" }} onClick={() => handleProcess("editProcess", null, true)}>
                  <strong>
                    {process?.name} {process?.currentProcess ? "(Current Process)" : null}
                  </strong>
                </span>
              </strong>
            </Typography>
            <StandardTable data={process?.steps || []} columns={historyColumns} />
          </Box>
        );
      })}
      <Box sx={{ mt: 2 }}>
        <Button onClick={() => handleProcess("", null, false)} color="info" variant="contained">
          Cancel
        </Button>
      </Box>
    </div>
  );
};

interface ChildProps {
  registrant: IRegistrant;
  historyColumns: any;
  handleProcess: (view: string, index: null, modalOpen: boolean) => void;
}

export default HistoryProcessView;
