import { useLazyQuery, useMutation, gql } from "@apollo/client";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTemplates, updateTemplate, selectTemplates } from "./emailTemplateSlice";
import { showSuccessSnackbar } from "../snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../auth/authSlice";

export const useSelectTemplates = () => useAppSelector(selectTemplates);

export const useGetEmailTemplate = (onCompletedFunc: any) => {
  return useLazyQuery(GETEMAILTEMPLATE, {
    onCompleted: onCompletedFunc,
  });
};

export const useTemplatesQuery = (
  page: number,
  perPage: number,
  sort: string,
  filterHtmlTemplatesObj?: any,
  step?: boolean,
  project?: string
) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  let userProjects: string[] = [];
  if (step && project) {
    userProjects = [project!];
  } else if (user) {
    userProjects = user?.projectAccess.map((projects: any) => projects.project._id);
  }
  let variables;
  if (filterHtmlTemplatesObj) {
    variables = {
      page: page,
      perPage: perPage,
      sort: sort,
      filter: { projects: userProjects, filterHtmlTemplates: filterHtmlTemplatesObj.filterHtmlTemplates, step: step },
    };
  } else {
    variables = { page: page, perPage: perPage, sort: sort, filter: { projects: userProjects, step: step } };
  }

  return useLazyQuery(GETEMAILTEMPLATES, {
    variables,
    onCompleted: (data) => {
      dispatch(setTemplates(data.emailTemplatePagination));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });
};

export const useUpdateTemplate = () => {
  const dispatch = useAppDispatch();

  return useMutation(UPDATEEMAILTEMPLATE, {
    onCompleted: (data) => {
      dispatch(updateTemplate(data.emailTemplateUpdateById.record));
      dispatch(showSuccessSnackbar("Email template saved!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });
};

const GETEMAILTEMPLATES = gql`
  query emailTemplatePagination(
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyEmailTemplateInput
    $filter: FilterFindManyEmailTemplateInput
  ) {
    emailTemplatePagination(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      items {
        name
        subject
        project {
          _id
          name
          tagLine
          developerName
          logoGetUrl
        }
        _id
        createdAt
        updatedAt
        step
      }
      count
    }
  }
`;

const UPDATEEMAILTEMPLATE = gql`
  mutation emailTemplateUpdateById($_id: MongoID!, $record: UpdateByIdEmailTemplateInput!) {
    emailTemplateUpdateById(_id: $_id, record: $record) {
      record {
        name
        subject
        _id
        createdAt
        updatedAt
        attachments {
          name
          s3Key
          getUrl
        }
        project {
          _id
          name
          tagLine
          developerName
          logoGetUrl
        }
        step
      }
    }
  }
`;

const GETEMAILTEMPLATE = gql`
  query getEmailTemplate($id: MongoID!) {
    emailTemplateById(_id: $id) {
      _id
      name
      subject
      html
      htmlTemplateJson
      attachments {
        _id
        name
        getUrl
      }
      project {
        _id
        name
        tagLine
        developerName
        logoGetUrl
      }
      step
    }
  }
`;
