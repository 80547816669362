import { useState, useMemo } from "react";
import { SettingContainer } from "../../commonStyles";
import { Box, Button, Typography } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { getRegistrantsFromExcel } from "../../utils/function";
import StandardTable from "../tables/StandardTable";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar, showWarningSnackbar } from "../../features/snackbar/snackbarSlice";
import { gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { selectProject } from "../../features/project/projectSlice";

const UploadRegistrant = () => {
  const [registrantsToUpload, setRegistrantsToUpload] = useState<any[]>([]);
  const [omittedRegistrants, setOmittedRegistrants] = useState<any[]>([]);
  const [savedRegistrants, setSavedRegistrants] = useState<any[]>([]);
  const [duplicateRegistrants, setDuplicateRegistrants] = useState<any[]>([]);

  const project = useSelector(selectProject);
  const dispatch = useAppDispatch();

  const [uploadRegistrants] = useMutation(UPLOADREGISTRANTS, {
    onCompleted: (data) => {
      if (data.createRegistrantsNoDuplicates.duplicates.length && data.createRegistrantsNoDuplicates.uploadedRegistrants.length) {
        dispatch(showSuccessSnackbar("Registrants successfully uploaded, but duplicates found."));
      } else if (data.createRegistrantsNoDuplicates.uploadedRegistrants.length) {
        dispatch(showSuccessSnackbar("Registrants successfully uploaded!"));
      } else {
        dispatch(showWarningSnackbar("Duplicates found."));
      }
    },
    onError: (e) => {
      dispatch(
        showErrorSnackbar(
          "There was an error uploading the registrants. Possible errors include a network error or an invalid value being entered into a field."
        )
      );
    },
  });

  const [updateRegistrant] = useMutation(UPDATEREGISTRANT, {
    onCompleted: () => {
      dispatch(showSuccessSnackbar("Registrant Successfully Updated!"));
    },
    onError: () => {
      dispatch(showWarningSnackbar("There was an error upating the registrant"));
    },
  });

  const handleUploadRegistrants = async () => {
    const response = (await uploadRegistrants({
      variables: {
        registrants: registrantsToUpload,
      },
    })) as any;

    const uploadedRegistrants = response.data.createRegistrantsNoDuplicates.uploadedRegistrants;
    const duplicates = response.data.createRegistrantsNoDuplicates.duplicates;

    setRegistrantsToUpload([]);
    setSavedRegistrants((savedRegistrants) => [...savedRegistrants, ...uploadedRegistrants]);
    setDuplicateRegistrants((duplicateRegistrants) =>
      [...duplicateRegistrants, ...duplicates].sort((duplicate1, duplicate2) => {
        return duplicate1.email.localeCompare(duplicate2.email);
      })
    );
  };

  const newRegistrantCols = useMemo(() => {
    return [
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Realtor Type",
        accessor: "realtorType",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Primary Phone",
        accessor: "primaryPhone",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Source",
        accessor: "source",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Postal Code",
        accessor: "postalCode",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Realtor Name",
        accessor: "realtorName",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Realtor Email",
        accessor: "realtorEmail",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
    ];
  }, []);

  const omittedRegistrantCols = useMemo(() => {
    const handleOverrideRegistrant = async (omittedRegistrant: any) => {
      let regToUpload = {
        ...omittedRegistrant.registrant,
      };

      delete regToUpload.rowNumber;

      const response = (await uploadRegistrants({
        variables: {
          registrants: [regToUpload],
        },
      })) as any;

      const uploadedRegistrants = response.data.createRegistrantsNoDuplicates.uploadedRegistrants;
      const duplicates = response.data.createRegistrantsNoDuplicates.duplicates;

      setOmittedRegistrants((omittedRegistrants) =>
        omittedRegistrants.filter(
          (omittedRegistrant) =>
            omittedRegistrant.registrant.email !== regToUpload.email &&
            omittedRegistrant.registrant.firstName !== regToUpload.firstName &&
            omittedRegistrant.registrant.lastName !== regToUpload.lastName
        )
      );
      setSavedRegistrants((savedRegistrants) => [...savedRegistrants, ...uploadedRegistrants]);
      setDuplicateRegistrants((duplicateRegistrants) =>
        [...duplicateRegistrants, ...duplicates].sort((duplicate1, duplicate2) => duplicate1.email.localeCompare(duplicate2.email))
      );
    };

    return [
      {
        Header: "Row Number",
        accessor: "registrant.rowNumber",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{original.registrant.rowNumber}</Typography>;
        },
      },
      {
        Header: "Override",
        accessor: "valid",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Button
              onClick={() => {
                handleOverrideRegistrant(original);
              }}
              variant="contained"
              color="success"
            >
              Override
            </Button>
          );
        },
      },
      {
        Header: "First Name",
        accessor: "registrant.firstName",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography sx={original.problemKeys.some((e: string) => e === "firstName") ? { color: "red" } : {}}>
              {value || "(empty)"}
            </Typography>
          );
        },
      },
      {
        Header: "Last Name",
        accessor: "registrant.lastName",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography sx={original.problemKeys.some((e: string) => e === "lastName") ? { color: "red" } : {}}>
              {value || "(empty)"}
            </Typography>
          );
        },
      },
      {
        Header: "Email",
        accessor: "registrant.email",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography sx={original.problemKeys.some((e: string) => e === "email") ? { color: "red" } : {}}>
              {value || "(empty)"}
            </Typography>
          );
        },
      },
      {
        Header: "Rating",
        accessor: "registrant.rating",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography sx={original.problemKeys.some((e: string) => e === "rating") ? { color: "red" } : {}}>{value}</Typography>;
        },
      },
      {
        Header: "Realtor Type",
        accessor: "registrant.realtorType",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography sx={original.problemKeys.some((e: string) => e === "realtorType") ? { color: "red" } : {}}>{value}</Typography>
          );
        },
      },
      {
        Header: "Primary Phone",
        accessor: "registrant.primaryPhone",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Source",
        accessor: "registrant.source",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Postal Code",
        accessor: "registrant.postalCode",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Realtor Name",
        accessor: "registrant.realtorName",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Realtor Email",
        accessor: "realtorEmail",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
    ];
  }, [uploadRegistrants]);

  const duplicateRegistrantCols = useMemo(() => {
    const handleUpdateRegistrant = async (registrant: any) => {
      const regToUpdate = { ...registrant };

      delete regToUpdate._id;

      const response = (await updateRegistrant({
        variables: {
          id: registrant._id,
          registrant: regToUpdate,
        },
      })) as any;

      const updatedRegistrant = response.data.registrantUpdateById.record;

      setSavedRegistrants((savedRegistrants) => {
        if (savedRegistrants.some((savedRegistrant) => savedRegistrant._id === updatedRegistrant._id)) {
          return savedRegistrants.map((savedRegistrant) => {
            if (savedRegistrant._id === updatedRegistrant._id) {
              return updatedRegistrant;
            }
            return savedRegistrant;
          });
        }

        return [...savedRegistrants, updatedRegistrant];
      });
      setDuplicateRegistrants((duplicatedRegistrants) =>
        duplicatedRegistrants.filter((duplicatedRegistrant) => duplicatedRegistrant._id !== updatedRegistrant._id)
      );
    };

    return [
      {
        Header: "Override",
        accessor: "override",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Button onClick={() => handleUpdateRegistrant(original)} variant="contained" color="success">
              Override
            </Button>
          );
        },
      },
      ...newRegistrantCols,
    ];
  }, [newRegistrantCols, updateRegistrant]);

  return (
    <SettingContainer>
      <Typography sx={{ fontSize: "1.5rem" }}>
        <strong>Upload Registrants</strong>
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ mb: 1, fontSize: "1.25rem" }}>
          <strong>Instructions</strong>
        </Typography>
        <Typography>
          -You can upload an excel file with the <strong>headers as the top row</strong>, and information for new registrants in the
          following rows
        </Typography>
        <Typography>
          -The <strong>order of the headers doesn't matter</strong>, as long as they are on the first row
        </Typography>
        <Typography>
          -Any headers not in the list (mandatory + optional) below will have those <strong>columns filtered out automatically</strong>
        </Typography>
        <Typography>
          -The table must be <strong>aligned to the top left</strong> of the document
        </Typography>
        <Typography>
          -Only the <strong>first worksheet</strong> in the workbook will be extracted
        </Typography>
        <Typography>
          -Any errors in the worksheet will be highlighted, and can be <strong>manually overidden</strong> and uploaded one by one if needed
        </Typography>
        <Typography>
          -Any duplicates will be shown in a separate table, and can be <strong>manually overridden</strong> one by one. Duplicates will be{" "}
          <strong>grouped by email</strong> if there are multiple duplicates with the same email, and you may pick one out that group. Once
          you do so, the <strong>whole group</strong> will be removed.
        </Typography>
        <Typography sx={{ mt: 2 }}>The following headers are mandatory:</Typography>
        <ul>
          <li>
            <em>First Name</em>
          </li>
          <li>
            <em>Last Name</em>
          </li>
          <li>
            <em>Email</em>
          </li>
        </ul>
        <Typography>
          The following headers are optional. If no value is provided, the default value listed beside the header is used:
        </Typography>
        <ul>
          <li>
            <em>Rating</em> (default = "N", options: [A, B, C, NQ, N, R, P])
          </li>
          <li>
            <em>Realtor Type</em> (default = "noRealtor", options: [noRealtor, isRealtor, hasRealtor])
          </li>
          <li>
            <em>Primary Phone</em> (default = "")
          </li>
          <li>
            <em>Source</em> (default = "online registration")
          </li>
          <li>
            <em>Postal Code</em> (default = "")
          </li>
          <li>
            <em>Realtor Name</em> (default = "")
          </li>
          <li>
            <em>Realtor Email</em> (default = "")
          </li>
        </ul>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        {savedRegistrants.length === 0 && (
          <label htmlFor="button-file">
            <input
              accept="*"
              id="button-file"
              style={{ display: "none" }}
              type="file"
              onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                const elem = e.target as HTMLInputElement;
                elem.value = "";
              }}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                if (!e.target.files || !e.target.files[0]) {
                  dispatch(showErrorSnackbar("A file was not selected."));
                  return;
                }

                const file = e.target.files[0];

                e.target.files = null;

                let { error, newRegistrants, omittedRegistrants } = await getRegistrantsFromExcel(file);

                if (error.length) {
                  dispatch(showWarningSnackbar(error));
                  return;
                }

                newRegistrants = newRegistrants.map((registrant) => {
                  return {
                    firstName: registrant.firstName,
                    lastName: registrant.lastName,
                    email: registrant.email,
                    rating: registrant.rating || "N",
                    realtorType: registrant.realtorType || "noRealtor",
                    primaryPhone: registrant.primaryPhone ? registrant.primaryPhone.toString() : "",
                    project: project?._id,
                    source: registrant.source || "online registration",
                    postalCode: registrant.postalCode,
                    realtorName: registrant.realtorName || "",
                    realtorEmail: registrant.realtorEmail || "",
                  };
                });

                omittedRegistrants = omittedRegistrants.map((omittedRegistrant) => {
                  return {
                    valid: omittedRegistrant.valid,
                    problemKeys: omittedRegistrant.problemKeys,
                    registrant: {
                      rowNumber: omittedRegistrant.registrant.rowNumber,
                      firstName: omittedRegistrant.registrant.firstName,
                      lastName: omittedRegistrant.registrant.lastName,
                      email: omittedRegistrant.registrant.email,
                      rating: omittedRegistrant.registrant.rating || "NR",
                      realtorType: omittedRegistrant.registrant.realtorType || "noRealtor",
                      primaryPhone: omittedRegistrant.registraint ? omittedRegistrant.registrant.primaryPhone.toString() : "",
                      project: project?._id,
                      source: omittedRegistrant.registrant.source || "online registration",
                      postalCode: omittedRegistrant.registrant.postalCode,
                      realtorName: omittedRegistrant.registrant.realtorName || "",
                      realtorEmail: omittedRegistrant.registrant.realtorEmail || "",
                    },
                  };
                });

                setRegistrantsToUpload(newRegistrants);
                setOmittedRegistrants(omittedRegistrants);
              }}
            />
            <Button variant="contained" component="span" startIcon={<Upload />}>
              Upload Excel File
            </Button>
          </label>
        )}
        {(registrantsToUpload.length > 0 ||
          omittedRegistrants.length > 0 ||
          savedRegistrants.length > 0 ||
          duplicateRegistrants.length > 0) && (
          <Button
            sx={savedRegistrants.length === 0 ? { ml: 2 } : {}}
            variant="contained"
            color="warning"
            onClick={() => {
              setRegistrantsToUpload([]);
              setOmittedRegistrants([]);
              setSavedRegistrants([]);
              setDuplicateRegistrants([]);
            }}
          >
            Clear
          </Button>
        )}
      </Box>

      {omittedRegistrants.length !== 0 && (
        <>
          <Typography sx={{ mb: 1, mt: 2, color: "red" }} variant="h3">
            <strong>Errors Found:</strong>
          </Typography>
          <StandardTable data={omittedRegistrants} loading={false} columns={omittedRegistrantCols} />
        </>
      )}
      {savedRegistrants.length !== 0 && (
        <>
          <Typography sx={{ mb: 1, mt: 2 }} variant="h3">
            <strong>Saved Registrants:</strong>
          </Typography>
          <StandardTable data={savedRegistrants} loading={false} columns={newRegistrantCols} />
        </>
      )}
      {duplicateRegistrants.length !== 0 && (
        <>
          <Typography sx={{ mb: 1, mt: 2 }} variant="h3">
            <strong>Duplicates Found:</strong>
          </Typography>
          <StandardTable data={duplicateRegistrants} loading={false} columns={duplicateRegistrantCols} />
        </>
      )}
      {registrantsToUpload.length !== 0 && (
        <>
          <Typography sx={{ mb: 1, mt: 2 }} variant="h3">
            <strong>Preview Before Save:</strong>
          </Typography>
          {registrantsToUpload.length > 0 && (
            <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="success" onClick={handleUploadRegistrants}>
              Save These Registrants
            </Button>
          )}
          <StandardTable data={registrantsToUpload} loading={false} columns={newRegistrantCols} />
        </>
      )}
    </SettingContainer>
  );
};

const UPLOADREGISTRANTS = gql`
  mutation uploadRegistrants($registrants: [createRegistrantsNoDuplicatesInput]!) {
    createRegistrantsNoDuplicates(registrants: $registrants) {
      uploadedRegistrants {
        _id
        email
        firstName
        lastName
        primaryPhone
        rating
        source
        postalCode
        realtorType
        realtorName
        realtorEmail
      }
      duplicates {
        _id
        email
        firstName
        lastName
        primaryPhone
        rating
        source
        postalCode
        realtorType
        realtorName
        realtorEmail
      }
    }
  }
`;

const UPDATEREGISTRANT = gql`
  mutation updateRegistrant($id: MongoID!, $registrant: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $id, record: $registrant) {
      record {
        _id
        email
        firstName
        lastName
        primaryPhone
        rating
        ethnicity
        statuses {
          name
          createdAt
        }
        salesRep {
          _id
          fullName
        }
        source
        postalCode
        realtorType
        realtorEmail
      }
    }
  }
`;

export default UploadRegistrant;
