import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Switch,
  SelectChangeEvent,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { FlexBetween } from "../../commonStyles";
import { IChoice } from "../../types/question";

const QuestionInfo = (props: ChildProps) => {
  const {
    name,
    type,
    display,
    registrantType,
    question,
    choices,
    setName,
    setDisplay,
    setQuestion,
    setType,
    setRegistrantType,
    handleFollowUpChange,
    getDefaultValues,
    questions,
    dispatch,
    disabled,
    subQuestion,
    setSubQuestion,
  } = props;

  return (
    <div>
      <FormControlLabel
        checked={subQuestion}
        disabled={disabled}
        control={
          <Switch
            onChange={setSubQuestion ? (e: React.ChangeEvent<HTMLInputElement>) => setSubQuestion(!subQuestion) : undefined}
            color="secondary"
          />
        }
        label="Sub Question"
      />
      <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 2 }}>
        <TextField
          required
          fullWidth
          name="name"
          label="Question Name"
          type="text"
          variant="outlined"
          disabled={disabled}
          value={name}
          onChange={setName ? (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value) : undefined}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="Question Type"
          disabled={disabled}
          onChange={setType ? (e: SelectChangeEvent<string>) => setType(e.target.value) : undefined}
        >
          <MenuItem value={"dropdown"}>Dropdown</MenuItem>
          <MenuItem value={"checkbox"}>Checkbox</MenuItem>
          <MenuItem value={"radio"}>Radio</MenuItem>
          <MenuItem value={"text"}>Text</MenuItem>
          <MenuItem value={"date"}>Date</MenuItem>
        </Select>
      </FormControl>
      {type === "dropdown" || type === "radio" || type === "checkbox" ? (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3" component="div" gutterBottom>
            <strong>Choices</strong>
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {choices.map((choice: IChoice, index: number) => {
              return (
                <FlexBetween
                  sx={{
                    mt: 2,
                    mb: { xs: 4, md: 0 },
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                  key={index}
                >
                  <FormControl fullWidth variant="outlined" sx={{ mb: 2, mr: 2 }}>
                    <TextField
                      required
                      fullWidth
                      name="choices"
                      label="Choices"
                      disabled={disabled}
                      type="text"
                      variant="outlined"
                      value={choice.choice}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch({ type: "UPDATE", payload: { index: index, field: "choice", value: e.target.value } })
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={questions}
                      freeSolo={false}
                      disabled={disabled}
                      getOptionLabel={(option: any) => option.question}
                      defaultValue={getDefaultValues(choice) as any}
                      onChange={(e, value) => handleFollowUpChange(e, value, index)}
                      renderInput={(params) => <TextField {...params} variant="standard" label="Followup Question(s)" />}
                    />
                  </FormControl>
                  {!disabled ? (
                    <DeleteForeverIcon
                      onClick={() => dispatch({ type: "DELETE", payload: index })}
                      sx={{ color: "error.main", cursor: "pointer", alignSelf: { xs: "start", md: "center" } }}
                    />
                  ) : null}
                </FlexBetween>
              );
            })}
          </Box>
          {!disabled ? (
            <Button
              sx={{ mb: 2 }}
              onClick={() => dispatch({ type: "ADD", payload: { choice: "", followUp: [] } })}
              color="primary"
              variant="contained"
            >
              Add Choice
            </Button>
          ) : null}
        </Box>
      ) : null}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="demo-simple-select-label">Display</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={display}
          label="Question Type"
          disabled={disabled}
          onChange={setDisplay ? (e: SelectChangeEvent<string>) => setDisplay(e.target.value) : undefined}
        >
          <MenuItem value={"both"}>Both</MenuItem>
          <MenuItem value={"public"}>Public</MenuItem>
          <MenuItem value={"authenticated"}>Authenticated</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="demo-simple-select-label">Registrant Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={registrantType}
          label="Question Type"
          disabled={disabled}
          onChange={setRegistrantType ? (e: SelectChangeEvent<string>) => setRegistrantType(e.target.value) : undefined}
        >
          <MenuItem value={"purchaser"}>Purchaser</MenuItem>
          <MenuItem value={"realtor"}>Realtor</MenuItem>
          <MenuItem value={"both"}>Both</MenuItem>
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        name="question"
        label="Question"
        type="text"
        variant="outlined"
        disabled={disabled}
        value={question}
        onChange={setQuestion ? (e: React.ChangeEvent<HTMLInputElement>) => setQuestion(e.target.value) : undefined}
        sx={{ mb: 2 }}
      />
    </div>
  );
};

interface ChildProps {
  name: string;
  subQuestion: boolean;
  question: string;
  display: string;
  registrantType: string;
  type: string;
  choices: any;
  setSubQuestion?: Dispatch<SetStateAction<boolean>>;
  setName?: Dispatch<SetStateAction<string>>;
  setType?: Dispatch<SetStateAction<string>>;
  setDisplay?: Dispatch<SetStateAction<string>>;
  setRegistrantType?: Dispatch<SetStateAction<string>>;
  setQuestion?: Dispatch<SetStateAction<string>>;
  handleFollowUpChange?: any;
  getDefaultValues?: any;
  questionId: string;
  questions: any[];
  dispatch?: any;
  disabled: boolean;
}

export default QuestionInfo;
