import { useState, useMemo } from "react";
import { useMutation, gql } from "@apollo/client";
import { Typography, Box, Button, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IUser } from "../../types/user";
import { SettingContainer, FlexBetween } from "../../commonStyles";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import StandardTable from "../tables/StandardTable";
import { useSelector } from "react-redux";
import { selectUser, addNote, updateNote } from "../../features/auth/authSlice";
import { GlobalModal } from "../../features/modal/Modal";
import { showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";

const Notes = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [note, setNote] = useState<INote>({
    index: null,
    note: "",
  });

  const [updateUserNote] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {
      if (note.index === null) {
        storeDispatch(addNote(note.note));
      } else {
        let updatedNote = [...user?.notes!];
        let newNote = updatedNote.map((savedNote: string, index: number) => {
          if (note.index === index) {
            return data.userUpdateById.record.notes[note.index];
          } else return savedNote;
        });
        storeDispatch(updateNote(newNote));
      }
      setNote({
        index: null,
        note: "",
      });
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Changes Saved!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [deleteUserNote] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {
      storeDispatch(updateNote(data.userUpdateById.record.notes));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Changes Saved!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Note",
        accessor: (rowData: string) => rowData,
      },
      {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ cell, row }: any) => {
          return (
            <EditIcon
              onClick={() => {
                setNote({
                  index: row.index,
                  note: row.values.Note,
                });
                storeDispatch(handleModal(true));
              }}
              sx={{ color: "primary.main", cursor: "pointer" }}
            />
          );
        },
      },
      {
        Header: "Delete",
        Cell: ({ cell, row }: any) => {
          return (
            <DeleteIcon
              onClick={() => {
                deleteNote(row.index);
              }}
              sx={{ color: "red", cursor: "pointer" }}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveNote = () => {
    if (note.index) {
      let userNotes = [...user?.notes!];
      let updateNotes = userNotes.map((savedNotes: string, index: number) => {
        if (index === note.index) {
          return note.note;
        } else return savedNotes;
      });

      updateUserNote({
        variables: {
          _id: user?._id,
          record: {
            notes: updateNotes,
          },
        },
      });
    } else {
      updateUserNote({
        variables: {
          _id: user?._id,
          record: {
            notes: [...user?.notes!, note.note],
          },
        },
      });
    }
  };

  const deleteNote = (index: number) => {
    let notes = [...user?.notes!];
    let deletedNote = notes.filter((deleteNote: string, numIndex: number) => index !== numIndex);
    deleteUserNote({
      variables: {
        _id: user?._id,
        record: {
          notes: deletedNote,
        },
      },
    });
  };

  return (
    <SettingContainer>
      <GlobalModal>
        <Typography variant="h2" sx={{ mb: 2 }}>
          <Box sx={{ alignSelf: "center" }}>
            <strong>{note.index !== null ? "Edit" : "Create"} Note</strong>
          </Box>
        </Typography>
        <TextField
          title={"Note"}
          name={"note"}
          fullWidth
          value={note.note}
          label={"Note"}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNote({
              index: note.index,
              note: e.target.value,
            })
          }
        />
        <FlexBetween sx={{ mt: 2 }}>
          <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
            Cancel
          </Button>
          <Button color="success" variant="contained" onClick={() => saveNote()}>
            Save
          </Button>
        </FlexBetween>
      </GlobalModal>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant="h2" sx={{ display: "flex" }}>
          <Box sx={{ alignSelf: "center" }}>
            <strong>Your Note Templates</strong>
          </Box>
        </Typography>
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              storeDispatch(handleModal(true));
            }}
          >
            Create Note Template
          </Button>
        </Box>
      </FlexBetween>
      {user ? <StandardTable columns={columns} data={user.notes} /> : null}
    </SettingContainer>
  );
};

interface ChildProps {
  user: IUser | null;
}

interface INote {
  index: number | null;
  note: string;
}

const UPDATEUSER = gql`
  mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
    userUpdateById(_id: $_id, record: $record) {
      record {
        _id
        notes
      }
    }
  }
`;

export default Notes;
