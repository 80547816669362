import { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, parse, startOfWeek, getDay, addDays, addMinutes } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Box, Popover, Typography, FormControlLabel, Checkbox, Tooltip, IconButton } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IAttendee, IEvent, ICalendarData, ICalendar } from "../../../types/calendar";

import "./reactBigCalendar.css";

import { capitalizeFirstLetter, convertAllDates, eventColour } from "../../../utils/function";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { FlexBetween } from "../../../commonStyles";
import GoogleCalendarForm from "./GoogleCalendarForm";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../../app/hooks";

const GoogleCalendar = () => {
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [calendar, setCalendar] = useState<ICalendarData[]>([]);
  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState<IEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorPosition, setAnchorPosition] = useState({
    top: 0,
    left: 0,
  });
  const [edit, setEdit] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [googleMeetsLink, setGoogleMeetsLink] = useState<boolean>(false);

  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const attendeeStatus = (attendeeStatus: string) => {
    if (attendeeStatus === "needsAction") {
      return (
        <Tooltip title="Needs Action">
          <ErrorOutlineIcon sx={{ color: "#efef3c" }} />
        </Tooltip>
      );
    }
    if (attendeeStatus === "accepted") {
      return (
        <Tooltip title="Accepted">
          <CheckCircleOutlineIcon sx={{ color: "#2fff2f" }} />
        </Tooltip>
      );
    }
    if (attendeeStatus === "declined") {
      return (
        <Tooltip title="Declined">
          <CancelIcon sx={{ color: "#f39393" }} />
        </Tooltip>
      );
    }
  };

  const [getCalendar, { loading }] = useLazyQuery(GETCALENDAR, {
    onCompleted: (data) => {
      setCalendar(data.getCalendar);
      let events = data.getCalendar.map((calendar: any, numIndex: number) => {
        let calendarEvents = calendar.events.map((event: any, index: number) => {
          return {
            id: event.id ? event.id : index,
            calendarName: calendar.name,
            calendarId: numIndex,
            location: event.location,
            title: event.summary ? event.summary : "Busy",
            start:
              event.status === "cancelled"
                ? null
                : event.start.dateTime
                ? new Date(event.start.dateTime)
                : addDays(new Date(event.start.date), 1),
            end: event.status === "cancelled" ? null : event.end.dateTime ? new Date(event.end.dateTime) : new Date(event.end.date),
            allDay: event.status === "cancelled" ? false : !event.start.dateTime ? true : false,
            link: event.hangoutLink,
            organizer: event.organizer,
            status: event.status,
            visibility: event.visibility,
            attendees: event.attendees,
            creator: event.creator,
          };
        });
        return calendarEvents;
      });
      setSelectedUsers(data.getCalendar.map((calendar: ICalendarData) => calendar.name));
      setSelectedEvents(events.flat());
      setEvents(events.flat());
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [editCalendar] = useMutation(EDITCALENDAR, {
    onCompleted: (data) => {
      setOpen(false);
      setRemove(false);
      setEdit(false);
      setGoogleMeetsLink(false);

      // Calendar ID
      if (data.editCalendar.status === "cancelled") {
        let events: any = selectedEvents.filter((item: any) => item.id !== data.editCalendar.id);
        setSelectedEvents(events);
        storeDispatch(showSuccessSnackbar("Event has been deleted!"));
        return;
      }

      let calendarIndex = calendar.findIndex((calendar) => calendar?.name === user?.email);

      let updatedEvent: any = {
        ...data.editCalendar,
        allDay: false,
        link: data.editCalendar.hangoutLink,
        title: data.editCalendar.summary,
        start: data.editCalendar.start.dateTime,
        end: data.editCalendar.end.dateTime,
        calendarName: user?.email,
        calendarId: calendarIndex,
      };
      let eventUpdate = selectedEvents.find((event: IEvent) => event.id === updatedEvent.id);
      if (eventUpdate) {
        let events: any = selectedEvents.map((item: any) => (item.id === updatedEvent.id ? updatedEvent : item));
        setSelectedEvents(events);
        storeDispatch(showSuccessSnackbar("Event has been updated!"));
      } else {
        setSelectedEvents([...selectedEvents, updatedEvent]);
        storeDispatch(showSuccessSnackbar("Event has been added!"));
      }
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  useEffect(() => {
    const handleEvents = () => {
      let allEvents = events.filter((event: IEvent) => selectedUsers.includes(event.calendarName));
      setSelectedEvents(allEvents);
    };
    handleEvents();
  }, [selectedUsers, events]);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar https://mail.google.com",
    onSuccess: async (code) => {
      getCalendar({ variables: { credential: code.code } });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  // Styles Events
  const styleEvent = (event: any) => {
    return {
      style: {
        backgroundColor: eventColour(event.calendarId),
      },
    };
  };

  const handleSelected = (data: any, event: any) => {
    setEdit(false);
    setSelectedEvent(data);
    setOpen(true);
    setGoogleMeetsLink(false);
    setAnchorEl(null);
    setAnchorPosition({
      top: 0,
      left: 0,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleSelectSlot = (data: any) => {
    setEdit(true);
    setRemove(false);
    setOpen(true);
    setGoogleMeetsLink(false);
    setAnchorPosition({
      left: data.box.x,
      top: data.box.y,
    });

    let attendees = [
      {
        email: "info@rdsre.ca",
        organizer: null,
        responseStatus: "needsAction",
        self: null,
      },
      {
        email: user?.email,
        organizer: null,
        responseStatus: "needsAction",
        self: true,
      },
    ];

    if (user?.email === "info@rdsre.ca") {
      attendees = [
        {
          email: user?.email,
          organizer: null,
          responseStatus: "needsAction",
          self: true,
        },
      ];
    }

    setSelectedEvent({
      id: null,
      title: "",
      start: data.slots[0],
      end: addMinutes(data.slots[0], 30),
      location: "",
      attendees: attendees,
    });
  };

  const handleSelectUser = (user: string) => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter((selectedUser: string) => user !== selectedUser));
    }
  };

  const editEvent = (e: any) => {
    e.preventDefault();
    let calendarId;

    if (selectedEvent.id) {
      for (const selectedCalendar of calendar) {
        let calendar = selectedCalendar?.events.find((event: ICalendar) => event.id === selectedEvent?.id);
        if (calendar) {
          calendarId = selectedCalendar.id;
          break;
        }
      }
    } else {
      let selectedCalendar = calendar.find((calendar: ICalendarData) => calendar.name === user?.email);
      if (selectedCalendar) {
        calendarId = selectedCalendar.id;
      }
    }

    editCalendar({
      variables: {
        calendarId: calendarId,
        eventId: selectedEvent?.id,
        summary: selectedEvent?.title,
        start: selectedEvent?.start,
        end: selectedEvent?.end,
        location: selectedEvent?.location,
        attendees: selectedEvent?.attendees,
        googleMeetsLink: googleMeetsLink,
        type: "edit",
      },
    });
  };

  const deleteEvent = () => {
    let calendarId;

    if (!selectedEvent) return;
    for (const selectedCalendar of calendar) {
      let calendar = selectedCalendar?.events.find((event: ICalendar) => event.id === selectedEvent?.id);
      if (calendar) {
        calendarId = selectedCalendar.id;
        break;
      }
    }

    editCalendar({
      variables: {
        calendarId: calendarId,
        eventId: selectedEvent?.id,
        summary: selectedEvent?.title,
        start: selectedEvent?.start,
        end: selectedEvent?.end,
        location: selectedEvent?.location,
        attendees: selectedEvent?.attendees,
        googleMeetsLink: googleMeetsLink,
        type: "delete",
      },
    });
  };

  return (
    <div>
      {!calendar.length && !loading ? (
        <Button
          sx={{ backgroundColor: "#fff", mb: 2 }}
          variant="outlined"
          onClick={() => googleLogin()}
          startIcon={<GoogleIcon sx={{ color: "red" }} />}
        >
          Sign in with Google
        </Button>
      ) : null}
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("xl")]: {
            flexDirection: "column",
          },
          [theme.breakpoints.up("xl")]: {
            flexDirection: "row",
          },
          display: "flex",
        })}
      >
        {calendar.length ? (
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("xl")]: {
                flexDirection: "row",
                flexWrap: "wrap",
                borderTop: "1px solid #000",
                borderLeft: "1px solid #000",
                borderRight: "1px solid #000",
                borderBottom: "none",
              },
              [theme.breakpoints.up("xl")]: {
                flexDirection: "column",
              },
              display: "flex",
              backgroundColor: "#fff",
              p: 2,
              borderTop: "1px solid #000",
              borderLeft: "1px solid #000",
              borderBottom: "1px solid #000",
            })}
          >
            {calendar.map((calendar: ICalendarData, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  id={calendar.name}
                  control={
                    <Checkbox
                      value={calendar.name}
                      name={calendar.name}
                      checked={selectedUsers.includes(calendar.name)}
                      onChange={(e) => handleSelectUser(calendar.name)}
                      sx={{
                        "&.Mui-checked": {
                          color: eventColour(index),
                        },
                      }}
                    />
                  }
                  label={calendar.name}
                />
              );
            })}
          </Box>
        ) : null}
        <Box sx={{ height: "1000px", flexGrow: 1, opacity: loading ? 0.4 : 1 }}>
          <Calendar
            style={{ backgroundColor: "#fff", padding: "16px" }}
            localizer={localizer}
            events={selectedEvents}
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectEvent={handleSelected}
            eventPropGetter={(event) => styleEvent(event)}
            onSelectSlot={handleSelectSlot}
            popup={true}
          />
          <Popover
            id={open ? "simple-popover" : undefined}
            open={open}
            anchorEl={anchorEl}
            anchorReference={anchorPosition.top ? "anchorPosition" : "anchorEl"}
            anchorPosition={anchorPosition}
            onClose={() => {
              setOpen(false);
              setRemove(false);
              setAnchorEl(null);
              setGoogleMeetsLink(false);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box sx={{ width: "450px", p: 2, backgroundColor: "#1f476f", color: "#fff" }}>
              {!edit ? (
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                    <Typography>
                      <strong>{selectedEvent?.title}</strong>
                    </Typography>
                    {selectedEvent?.calendarName === user?.email ? (
                      <EditIcon sx={{ cursor: "pointer" }} onClick={() => setEdit(!edit)} />
                    ) : null}
                  </Box>
                  {selectedEvent?.location ? <Box sx={{ mb: 1 }}>Location: {selectedEvent.location}</Box> : null}
                  {selectedEvent?.status ? <Box>Status: {capitalizeFirstLetter(selectedEvent.status)}</Box> : null}
                  {selectedEvent?.creator ? <Box>Creator: {selectedEvent.creator.email}</Box> : null}
                  {selectedEvent?.allDay ? (
                    <Box>Time: All Day</Box>
                  ) : selectedEvent?.start ? (
                    <Box>
                      Time: {convertAllDates(selectedEvent?.start, "PPpp")} - {convertAllDates(selectedEvent?.end, "pp")}
                    </Box>
                  ) : null}
                  {selectedEvent?.organizer ? (
                    <Box sx={{ mt: 2 }}>
                      Organizer: {selectedEvent.organizer.displayName ? selectedEvent.organizer.displayName : selectedEvent.organizer.email}
                    </Box>
                  ) : null}
                  {selectedEvent?.attendees ? (
                    <Box sx={{ display: "flex" }}>
                      <Box>Attendees:</Box>
                      <Box sx={{ ml: 1 }}>
                        {selectedEvent.attendees.map((attendee: IAttendee, index: number) => {
                          return (
                            <Box sx={{ display: "flex" }} key={index}>
                              <Box sx={{ mr: 1 }}>{attendeeStatus(attendee.responseStatus)}</Box>
                              <Box>{attendee.email}</Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : null}
                  {selectedEvent?.link ? (
                    <Box>
                      Link:{" "}
                      <a style={{ color: "#fff" }} href={selectedEvent.link} target="_blank" rel="noopener noreferrer">
                        {selectedEvent.link}
                      </a>
                    </Box>
                  ) : null}
                </Box>
              ) : !remove ? (
                <form onSubmit={editEvent}>
                  <FlexBetween sx={{ mb: 1 }}>
                    <ArrowBackIcon sx={{ color: "#fff", cursor: "pointer" }} onClick={() => setEdit(false)} />
                    <Box>
                      <IconButton type="submit">
                        <SaveIcon sx={{ color: "#2fff2f", cursor: "pointer" }} />
                      </IconButton>
                      {selectedEvent.id ? (
                        <IconButton onClick={() => setRemove(!remove)}>
                          <DeleteForeverIcon sx={{ color: "#e37c90", cursor: "pointer" }} />
                        </IconButton>
                      ) : null}
                    </Box>
                  </FlexBetween>
                  <GoogleCalendarForm
                    event={selectedEvent!}
                    setEvent={setSelectedEvent}
                    googleMeetsLink={googleMeetsLink}
                    setGoogleMeetsLink={setGoogleMeetsLink}
                  />
                </form>
              ) : (
                <Box>
                  <Box>Are you sure you would like to delete this event?</Box>
                  <Box sx={{ mt: 2 }}>{selectedEvent.title}</Box>
                  <FlexBetween sx={{ mt: 2 }}>
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => {
                        setRemove(!remove);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button onClick={() => deleteEvent()} color="error" variant="contained">
                      Delete
                    </Button>
                  </FlexBetween>
                </Box>
              )}
            </Box>
          </Popover>
        </Box>
      </Box>
    </div>
  );
};

const GETCALENDAR = gql`
  query getCalendar($credential: String) {
    getCalendar(credential: $credential) {
      id
      name
      events {
        id
        status
        location
        htmlLink
        summary
        created
        updated
        creator {
          email
        }
        organizer {
          email
          displayName
          self
        }
        start {
          date
          dateTime
          timeZone
        }
        end {
          date
          dateTime
          timeZone
        }
        attendees {
          email
          organizer
          self
          responseStatus
        }
        hangoutLink
        visibility
      }
    }
  }
`;

const EDITCALENDAR = gql`
  mutation editCalendar(
    $calendarId: String
    $eventId: String
    $summary: String!
    $start: Date!
    $end: Date!
    $attendees: [NewAttendeesInput]
    $googleMeetsLink: Boolean!
    $type: String!
  ) {
    editCalendar(
      calendarId: $calendarId
      eventId: $eventId
      summary: $summary
      start: $start
      end: $end
      attendees: $attendees
      googleMeetsLink: $googleMeetsLink
      type: $type
    ) {
      id
      status
      location
      htmlLink
      summary
      created
      updated
      creator {
        email
      }
      organizer {
        email
        displayName
        self
      }
      start {
        date
        dateTime
        timeZone
      }
      end {
        date
        dateTime
        timeZone
      }
      attendees {
        email
        organizer
        self
        responseStatus
      }
      hangoutLink
      visibility
    }
  }
`;

export default GoogleCalendar;
