/* Dependency Imports */
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

/* Project Imports */
import TextEditor from "../../common/textEditor/TextEditor";
import { SettingContainer } from "../../../commonStyles";
import StandardTable from "../../tables/StandardTable";
import { FlexBetween } from "../../../commonStyles";
import { useSelectTemplates, useTemplatesQuery, useUpdateTemplate } from "../../../features/emailTemplate/emailTemplateHooks";
import {
  addTemplate,
  updateTemplate,
  ITemplates,
  selectTemplateCount,
  setTemplates,
} from "../../../features/emailTemplate/emailTemplateSlice";
import { IAttachment } from "../../../types/process";
import { useAppDispatch } from "../../../app/hooks";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { convertAllDates, validateEmail } from "../../../utils/function";
import { AttachmentsDialog } from "../../common/textEditor/MenuDialogs";
import ReactRouterPrompt from "react-router-prompt";
import { selectUser } from "../../../features/auth/authSlice";
import Templates from "./Templates";

const EmailTemplate = () => {
  /* Redux */
  const user = useSelector(selectUser);
  const templates = useSelectTemplates();
  const count = useSelector(selectTemplateCount);
  const dispatch = useAppDispatch();

  /* States */
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [template, setTemplate] = useState<ITemplate | null>(null);
  const [viewHtmlTemplates, setViewHtmlTemplates] = useState<boolean>(false);
  const [testEmailContent, setTestEmailContent] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [formAttachments, setFormAttachments] = useState<any[]>([]);
  const [formAttachmentsObjs, setFormAttachmentObjs] = useState<any[]>([]);
  const [attachmentsDialogOpen, setAttachmentsDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!template) {
      setFormAttachments([]);
      setFormAttachmentObjs([]);
    }
  }, [template]);

  /* Queries/Mutations */
  const [getEmails, { loading }] = useTemplatesQuery(pageNumber, 15, "_ID_DESC", { filterHtmlTemplates: "editor" });

  useEffect(() => {
    getEmails();
  }, [pageNumber, getEmails]);

  const [getEmailTemplates] = useLazyQuery(GETEMAILTEMPLATES, {
    onCompleted: (data) => {
      dispatch(setTemplates(data.emailTemplatePagination));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });

  const [getEmailTemplate] = useLazyQuery(GETEMAILTEMPLATE, {
    onCompleted: (data) => {
      const template = data.emailTemplateById;
      setTemplate(template);
      setFormAttachments(template.attachments);
      setFormAttachmentObjs(Array.apply(null, Array(template.attachments.length)));
    },
    onError: (err) => {
      console.log(err, "err");
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [updateEmailTemplate, { loading: updateLoading }] = useUpdateTemplate();

  const [createTemplate, { loading: createLoading }] = useMutation(CREATETEMPLATE, {
    onCompleted: (data) => {
      dispatch(addTemplate(data.emailTemplateCreateOne.record));
      dispatch(showSuccessSnackbar("Email Template Created!"));
      setTemplate({ ...(template as ITemplate), _id: data.emailTemplateCreateOne.record._id as string });
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [sendTestEmail, { loading: testEmailLoading }] = useLazyQuery(TESTEMAIL, {
    onCompleted: (data) => {
      if (template?._id) {
        dispatch(updateTemplate(data.sendTestEmail));
      } else {
        dispatch(addTemplate(data.sendTestEmail));
        setTemplate({ ...(template as ITemplate), _id: data.sendTestEmail._id as string });
      }
      dispatch(showSuccessSnackbar("Test email sent!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
    fetchPolicy: "network-only",
  });

  const [, { refetch: refetchUploadAttachments }] = useLazyQuery(UPLOADATTACHMENTS, {
    onError: (e) => console.log(e.message),
  });
  const [, { refetch: refetchDeleteAttachments }] = useLazyQuery(DELETEATTACHMENTS, {
    onError: (e) => console.log(e.message),
  });

  /* Functions */
  const columns = [
    {
      Header: "Template Name",
      accessor: (rowData: ITemplates) => {
        return (
          <strong style={{ cursor: "pointer" }} onClick={() => getEmailTemplate({ variables: { _id: rowData._id } })}>
            {rowData.name}
          </strong>
        );
      },
    },
    {
      Header: "Subject",
      accessor: (rowData: ITemplates) => {
        return rowData.subject;
      },
    },
    {
      Header: "Project",
      accessor: (rowData: ITemplates) => {
        return rowData.project ? rowData.project.name : "";
      },
    },
    {
      Header: "Step Template",
      accessor: (rowData: ITemplates) => {
        return rowData.step ? "Yes" : "No";
      },
    },
    {
      Header: "Created Date",
      accessor: (rowData: ITemplates) => {
        return convertAllDates(rowData.createdAt, "PPpp");
      },
    },
    {
      Header: "Last Updated",
      accessor: (rowData: ITemplates) => {
        return convertAllDates(rowData.updatedAt, "PPpp");
      },
    },
  ];

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    let userProjects = user?.projectAccess.map((project: any) => project.project._id);
    getEmailTemplates({
      variables: {
        filter: { projects: userProjects, search: value, filterHtmlTemplates: "editor" },
        page: 1,
        perPage: 15,
        sort: "_ID_DESC",
      },
    });
  };

  const handleCloseEdit = () => {
    if (unsavedChanges) {
      setShowWarningDialog(true);
      return;
    }
    setTemplate(null);
  };

  const submitContent = async (content: string, backToList?: boolean) => {
    if (!template || updateLoading || createLoading) return;
    if (!template.project && !global) return dispatch(showErrorSnackbar("Project is missing"));
    setUnsavedChanges(false);

    const curAttachments = template?.attachments;

    let attachmentsToUpload = formAttachmentsObjs.filter((obj) => obj !== null && obj !== undefined);
    let attachmentsToDelete = curAttachments
      ?.filter((attachment) => {
        for (let newAttachment of formAttachments) {
          if (attachment.name === newAttachment.name) {
            return false;
          }
        }
        return true;
      })
      .map((attachment) => {
        return {
          name: attachment.name,
        };
      });

    if (!attachmentsToUpload) attachmentsToUpload = [];
    if (!attachmentsToDelete) attachmentsToDelete = [];

    const attachmentsToUpdateOrCreate = formAttachments.map((formAttachment) => {
      return { name: formAttachment.name };
    });

    let newTemplateId = "";
    if (template._id) {
      await refetchDeleteAttachments({ emailTemplateId: template._id, files: attachmentsToDelete });
      await updateEmailTemplate({
        variables: {
          _id: template._id,
          record: {
            name: template.name,
            subject: template.subject,
            html: content,
            attachments: attachmentsToUpdateOrCreate,
            project: template.project ? template.project._id : null,
            step: template.step,
          },
        },
      });
      await refetchUploadAttachments({
        emailTemplateId: template._id,
        files: attachmentsToUpload,
      });
      if (backToList) setTemplate(null);
      return template._id;
    } else {
      const response = await createTemplate({
        variables: {
          record: {
            name: template.name,
            subject: template.subject,
            html: content,
            attachments: attachmentsToUpdateOrCreate,
            project: template.project ? template.project._id : null,
            step: template.step,
          },
        },
      });
      newTemplateId = response.data.emailTemplateCreateOne.record._id;
      await refetchUploadAttachments({ emailTemplateId: newTemplateId, files: attachmentsToUpload });
      if (backToList) setTemplate(null);
      return newTemplateId;
    }
  };

  const submitContentAndGoBack = (content: string) => {
    submitContent(content, true);
  };

  const openTestEmailDialog = (content: string) => setTestEmailContent(content);

  const TestEmailDialog = () => {
    const [sender, setSender] = useState("");
    const [recipients, setRecipients] = useState("");
    const [testEmailError, setTestEmailError] = useState(false);

    const handleClose = () => setTestEmailContent("");

    const submitTestEmail = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!template || testEmailLoading) return;
      setUnsavedChanges(false);

      if (recipients.split(",").reduce((emailsValid, recipient) => emailsValid && validateEmail(recipient.trim()), true)) {
        setTestEmailError(false);
        const toSend = sender;
        const toReceieve = recipients;
        const content = testEmailContent;
        handleClose();
        const templateId = await submitContent(content, false);
        await sendTestEmail({
          variables: {
            _id: templateId,
            from: toSend.trim(),
            to: toReceieve.split(",").map((recipient) => recipient.trim()),
          },
        });

        setTemplate(null);
      } else {
        setTestEmailError(true);
      }
    };

    return (
      <Dialog open onClose={handleClose} fullWidth>
        <DialogTitle>
          Send Test Email
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <Box component="form" onSubmit={submitTestEmail}>
          <DialogContent sx={{ py: 1, px: 3, width: "100%" }}>
            <TextField
              variant="standard"
              label="Send As"
              fullWidth
              value={sender}
              onChange={(e) => setSender(e.target.value)}
              sx={{ mb: 1 }}
            />
            <DialogContentText sx={{ mb: 1 }} variant="caption">{`${sender || "no-reply@rdsre.ca"} <no-reply@rdsre.ca>`}</DialogContentText>
            <TextField
              variant="standard"
              label="Recipients"
              autoFocus
              required
              fullWidth
              value={recipients}
              onChange={(e) => setRecipients(e.target.value)}
              sx={{ mb: 1 }}
              error={testEmailError}
              helperText={testEmailError && "One or more invalid emails"}
            />
            <DialogContentText variant="caption">example@domain.com, example2@domain.com</DialogContentText>
          </DialogContent>
          <DialogActions>
            <FlexBetween sx={{ width: "100%" }}>
              <Button type="submit">Send</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </FlexBetween>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };

  return (
    <SettingContainer>
      {template && !viewHtmlTemplates ? (
        <>
          <AttachmentsDialog
            attachmentsDialogOpen={attachmentsDialogOpen}
            setAttachmentsDialogOpen={setAttachmentsDialogOpen}
            formAttachments={formAttachments}
            setFormAttachments={setFormAttachments}
            formAttachmentsObjs={formAttachmentsObjs}
            setFormAttachmentObjs={setFormAttachmentObjs}
            setUnsavedChanges={setUnsavedChanges}
          />
          {testEmailContent && <TestEmailDialog />}
          <ReactRouterPrompt when={unsavedChanges}>
            {({ isActive, onConfirm, onCancel }) => (
              <Dialog open={isActive || showWarningDialog}>
                <DialogTitle>Are you sure you want to leave? You have unsaved changes.</DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                  <Button
                    sx={{ mr: 2 }}
                    color="success"
                    variant="contained"
                    onClick={
                      showWarningDialog
                        ? () => {
                            setShowWarningDialog(false);
                          }
                        : onCancel
                    }
                  >
                    Stay
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    color="warning"
                    variant="contained"
                    onClick={
                      showWarningDialog
                        ? () => {
                            setTemplate(null);
                            setUnsavedChanges(false);
                            setShowWarningDialog(false);
                          }
                        : onConfirm
                    }
                  >
                    Leave
                  </Button>
                </Box>
              </Dialog>
            )}
          </ReactRouterPrompt>
          <Box sx={{ display: "flex", flexDirection: "flex-start", alignItems: "center", mb: 2 }}>
            <IconButton onClick={handleCloseEdit} sx={{ mr: 1 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h2" component="div">
              <strong>{template._id ? "Edit" : "Create"} Template</strong>
            </Typography>
          </Box>
          <Box component="form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}>
            <TextField
              sx={{ my: 1 }}
              label="Name"
              fullWidth
              required
              value={template.name}
              onChange={(e) => {
                setTemplate({ ...template, name: e.target.value });
                setUnsavedChanges(true);
              }}
            />
            <TextField
              sx={{ my: 1 }}
              label="Subject"
              fullWidth
              required
              value={template.subject}
              onChange={(e) => {
                setTemplate({ ...template, subject: e.target.value });
                setUnsavedChanges(true);
              }}
            />
            <FormControl fullWidth>
              <InputLabel>Project</InputLabel>
              <Select
                name={"project"}
                label={"Project"}
                value={template.project ? template.project._id : ""}
                onChange={(e) => {
                  let selectedProject = user?.projectAccess.find((projects: any) => projects.project._id === e.target.value);
                  setTemplate({ ...template, project: selectedProject ? selectedProject.project : null });
                  setUnsavedChanges(true);
                }}
              >
                {user?.projectAccess.map((project, index: number) => (
                  <MenuItem key={index} value={project.project._id}>
                    {project.project.name}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  id={"step"}
                  control={<Checkbox checked={template.step} onChange={() => setTemplate({ ...template, step: !template.step })} />}
                  label={"Step Template"}
                />
                <FormControlLabel
                  id={"global"}
                  control={
                    <Checkbox
                      checked={!template.project}
                      onChange={() => setTemplate({ ...template, project: template.project ? null : template.project })}
                    />
                  }
                  label={"Global"}
                />
              </Box>
            </FormControl>
            <TextEditor
              initContent={template.html}
              renderButtons={[
                { name: "Attachments", function: () => setAttachmentsDialogOpen(true) },
                { name: "Save", function: submitContentAndGoBack },
                { name: "Save and Send Test Email", function: openTestEmailDialog },
              ]}
              setUnsavedChanges={setUnsavedChanges}
            />
          </Box>
        </>
      ) : (
        <>
          {!viewHtmlTemplates && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: { xs: "flex-start", md: "space-between" },
                }}
              >
                <Typography variant="h2" component="div" gutterBottom>
                  <strong>Email Templates</strong>
                </Typography>
                <Box>
                  <Button
                    sx={{ width: "max-content", mr: 2 }}
                    onClick={() => {
                      setViewHtmlTemplates(true);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    HTML Templates
                  </Button>
                  <Button
                    sx={{ width: "max-content" }}
                    onClick={() => {
                      setTemplate({ _id: "", name: "", subject: "", project: "", html: "<p></p>", step: false });
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Create New Template
                  </Button>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                {loading ? (
                  <></>
                ) : (
                  <>
                    <StandardTable
                      data={templates}
                      columns={columns}
                      loading={loading}
                      handleGlobalFilterValue={handleGlobalFilterValue}
                      count={count}
                    />
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                      <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
                        {"<"}
                      </Button>
                      <span>{pageNumber}</span>
                      <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
                        {">"}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          {viewHtmlTemplates && <Templates viewHtmlTemplates={viewHtmlTemplates} setViewHtmlTemplates={setViewHtmlTemplates} />}
        </>
      )}
    </SettingContainer>
  );
};

/* Types */
export interface ITemplate {
  _id: string;
  name: string;
  subject: string;
  html: string;
  htmlTemplateJson?: string;
  attachments?: IAttachment[];
  project: any;
  step: boolean;
}

/* GQL */
const GETEMAILTEMPLATES = gql`
  query emailTemplatePagination(
    $filter: FilterFindManyEmailTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyEmailTemplateInput
  ) {
    emailTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        name
        subject
        project {
          _id
          name
          tagLine
          developerName
        }
        _id
        createdAt
        updatedAt
      }
      count
    }
  }
`;

const GETEMAILTEMPLATE = gql`
  query emailTemplateById($_id: MongoID!) {
    emailTemplateById(_id: $_id) {
      _id
      name
      subject
      html
      attachments {
        name
        getUrl
      }
      project {
        _id
        name
        tagLine
        developerName
      }
      step
    }
  }
`;

const CREATETEMPLATE = gql`
  mutation emailTemplateCreateOne($record: CreateOneEmailTemplateInput!) {
    emailTemplateCreateOne(record: $record) {
      record {
        name
        subject
        _id
        createdAt
        updatedAt
        attachments {
          name
        }
        project {
          _id
          name
          tagLine
          developerName
        }
        step
      }
    }
  }
`;

const TESTEMAIL = gql`
  query sendTestEmail($_id: MongoID!, $from: String!, $to: [String]) {
    sendTestEmail(_id: $_id, from: $from, to: $to) {
      name
      subject
      _id
      createdAt
      updatedAt
    }
  }
`;

const UPLOADATTACHMENTS = gql`
  query uploadAttachments($emailTemplateId: MongoID!, $files: [Upload]) {
    emailTemplateUploadAttachments(id: $emailTemplateId, files: $files) {
      name
    }
  }
`;

const DELETEATTACHMENTS = gql`
  query deleteAttachments($emailTemplateId: MongoID!, $files: [EmailTemplateAttachmentsInput]) {
    emailTemplateDeleteAttachments(id: $emailTemplateId, files: $files) {
      name
    }
  }
`;

export default EmailTemplate;
