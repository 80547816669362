export const ratings = ["N", "A", "B", "C", "NQ", "Spam"];

export const stepSpecialDates = ["Appointment Date", "Appointment 1 Days Before", "Appointment 2 Days Before"];

export const sources = [
  "call in",
  "walk in",
  "online registration",
  "livabl",
  "booked",
  "social media",
  "email",
  "agent",
  "google",
  "signage",
  "other",
  "sale",
  "mls",
  "broker bay",
];

export const ethnicities = [
  "Latin American",
  "Black",
  "White",
  "Chinese",
  "Indigenous",
  "European",
  "Eastern European",
  "Persian",
  "Filipino",
  "Japanese",
  "Korean",
  "South Asian",
  "Southeast Asian",
  "Arab/West Asian",
  "West Indian",
  "African",
  "Other",
  "Unidentified",
];

export const realtorTypes = ["isRealtor", "noRealtor", "hasRealtor"];

export const statuses = ["Registered", "Came In", "Booked", "Rescinded", "Purchased", "Reregistered"];

export const relationshipTypes = ["Spouse", "Parent", "Sibling", "Realtor", "Friend", "Partner", "Colleague", "Duplicate", "Other"];

export const fixedList = [
  {
    _id: "all-registrants",
    name: "All",
  },
  {
    _id: "realtors",
    name: "Realtors",
  },
  {
    _id: "has-realtor",
    name: "Have Realtors",
  },
  {
    _id: "no-realtor",
    name: "No Realtors",
  },
  {
    _id: "new-registrants",
    name: "Registrants Today",
  },
  {
    _id: "new-registrants-last-7-days",
    name: "Registrants Last 7 Days",
  },
  {
    _id: "step-due",
    name: "Registrants with Step Due",
  },
  {
    _id: "first-short-term-step-due",
    name: "Registrants with First Step Due",
  },
  {
    _id: "purchasers",
    name: "Purchasers",
  },
  {
    _id: "registrants-no-purchasers",
    name: "Registrants without Purchasers",
  },
  {
    _id: "be-backs",
    name: "Be Backs",
  },
  {
    _id: "no-email",
    name: "No Email",
  },
  {
    _id: "no-phone",
    name: "No Phone Number",
  },
];

export const tokens = [
  {
    category: "Registrant Tokens",
    items: [
      {
        name: "First Name",
        value: "registrantFirstName",
      },
      {
        name: "Last Name",
        value: "registrantLastName",
      },
      {
        name: "Email Address",
        value: "registrantEmail",
      },
      {
        name: "Phone Number",
        value: "registrantPhone",
      },
      {
        name: "Online Rep First Name",
        value: "registrantOnlineRepFirstName",
      },
      {
        name: "Online Rep Last Name",
        value: "registrantOnlineRepLastName",
      },
      {
        name: "Sales Rep First Name",
        value: "registrantSalesRepFirstName",
      },
      {
        name: "Sales Rep last Name",
        value: "registrantSalesRepLastName",
      },
      {
        name: "Project Tag Line",
        value: "projectTagLine",
      },
      {
        name: "Project Name",
        value: "registrantProject",
      },
      {
        name: "Developer",
        value: "developer",
      },
      {
        name: "Logo",
        value: "logo",
      },
      {
        name: "Appointment",
        value: "appointment",
      },
    ],
  },
  {
    category: "User Rep Tokens",
    items: [
      {
        name: "First Name",
        value: "userFirstName",
      },
      {
        name: "Last Name",
        value: "userLastName",
      },
      {
        name: "Email Address",
        value: "userEmail",
      },
      {
        name: "Phone Number",
        value: "userPhone",
      },
      {
        name: "Calendar Link",
        value: "userCalendarLink",
      },
      {
        name: "Virtual Office Link",
        value: "userVirtualOfficeLink",
      },
      {
        name: "Agent ID",
        value: "userAgentID",
      },
      {
        name: "Title",
        value: "userTitle",
      },
    ],
  },
];

export const userTokenValues = [
  {
    firstName: "{{userFirstName}}",
  },
  {
    lastName: "{{userLastName}}",
  },
  {
    email: "{{userEmail}}",
  },
  {
    project: "{{userProject}}",
  },
];

export const registrantTokenValues = [
  {
    firstName: "{{registrantFirstName}}",
  },
  {
    lastName: "{{registrantLastName}}",
  },
  {
    email: "{{registrantEmail}}",
  },
  {
    phone: "{{registrantPhone}}",
  },
  {
    project: "{{registrantProject}}",
  },
  {
    tagLine: "{{projectTagLine}}",
  },
  {
    developer: "{{developer}}",
  },
  {
    logo: "{{logo}}",
  },
  {
    appointment: "{{appointment}}",
  },
  {
    onlineRepFirstName: "{{registrantOnlineRepFirstName}}",
  },
  {
    onlineRepLastName: "{{registrantOnlineRepLastName}}",
  },
  {
    salesRepFirstName: "{{registrantSalesRepFirstName}}",
  },
  {
    salesRepLastName: "{{registrantSalesRepLastName}}",
  },
];

export const allTokenValues = [
  "{{registrantFirstName}}",
  "{{registrantLastName}}",
  "{{registrantEmail}}",
  "{{registrantPhone}}",
  "{{registrantProject}}",
  "{{registrantOnlineRepFirstName}}",
  "{{registrantOnlineRepLastName}}",
  "{{registrantSalesRepFirstName}}",
  "{{registrantSalesRepLastName}}",
  "{{projectTagLine}}",
  "{{developer}}",
  "{{userFirstName}}",
  "{{userLastName}}",
  "{{userEmail}}",
  "{{userProject}}",
  "{{appointment}}",
];

export const defaultNotes = ["Left voicemail", "Sent text message", "No Voicemail, sent text message", "Number not in service"];
