import { useLazyQuery, gql } from "@apollo/client";
import { IAppointment } from "../../types/project";

export const withinTwelveHours = (date: Date | null | undefined) => {
  if (!date) return false;
  const twelveHours = 43200000;
  if (!date) return false;
  return Math.abs(date.getTime() - Date.now()) < twelveHours;
};

export const getWeekIntoPrevMonth = (date: Date | null | undefined) => {
  const curDate = new Date(date || Date.now());
  const weekIntoPrevMonth = new Date(
    curDate.getMonth() === 0 ? curDate.getFullYear() - 1 : curDate.getFullYear(),
    curDate.getMonth() === 0 ? 11 : curDate.getMonth() - 1,
    23
  );
  return weekIntoPrevMonth;
};

export const getWeekIntoNextMonth = (date: Date | null | undefined) => {
  const curDate = new Date(date || Date.now());
  const weekIntoNextMonth = new Date(
    curDate.getMonth() === 11 ? curDate.getFullYear() + 1 : curDate.getFullYear(),
    curDate.getMonth() === 11 ? 0 : curDate.getMonth() + 1,
    7
  );
  return weekIntoNextMonth;
};

export const dateConflict = (date: Date | null | undefined, appointments: IAppointment[], curAppointment?: IAppointment) => {
  if (!date) return false;
  const thirtyMins = 1800000;
  for (let appointment of appointments) {
    if (curAppointment && appointment._id === curAppointment._id) continue;
    const appointmentDate = new Date(appointment.date);
    if (Math.abs(appointmentDate.getTime() - date.getTime()) < thirtyMins) {
      return true;
    }
  }
  return false;
};

export const useGetAppointmentsQuery = (
  salesRepId: string | undefined,
  date: Date | null | undefined,
  onCompletedFunc?: (data: any) => void
) => {
  return useLazyQuery(GETAPPOINTMENTS, {
    variables: {
      salesRepId: salesRepId,
      startDate: getWeekIntoPrevMonth(date),
      endDate: getWeekIntoNextMonth(date),
    },
    onCompleted: (data) => {
      console.log('heree');
      if (onCompletedFunc) onCompletedFunc(data);
    },
    onError: (err) => {
      console.log(err.message);
    },
  });
};

const GETAPPOINTMENTS = gql`
  query getAppointments($salesRepId: MongoID, $startDate: String, $endDate: String) {
    appointmentMany(
      filter: { salesRep: $salesRepId, dateGreaterThanEqual: $startDate, dateLessThanEqual: $endDate }
      limit: 200
      sort: DATE_ASC
    ) {
      _id
      date
      project {
        name
      }
      location
      purchaserInfo {
        firstName
        lastName
      }
      registrant {
        _id
        tags
      }
      cancelled
      virtual
    }
  }
`;
