import { useState, useReducer, useMemo, useEffect } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { FlexBetween, SettingContainer } from "../../commonStyles";
import StandardTable from "../tables/StandardTable";
import { GlobalModal } from "../../features/modal/Modal";
import { handleModal } from "../../features/modal/modalSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useQuestionsQuery } from "../../features/question/questionHooks";
import { useAppDispatch } from "../../app/hooks";
import { addQuestion, selectQuestions, updateQuestion, selectQuestionCount, setQuestions } from "../../features/question/questionSlice";
import QuestionInfo from "../common/QuestionInfo";
import { IQuestion, IChoice } from "../../types/question";

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "DELETE":
      return state.filter((state: any, index: number) => index !== action.payload);
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "GET":
      return action.payload;
    case "REMOVEALL":
      return action.payload;
    default:
      throw new Error();
  }
}

const Questionaire = () => {
  const storeDispatch = useAppDispatch();
  const { loading } = useQuestionsQuery();
  const questions = useSelector(selectQuestions);
  const count = useSelector(selectQuestionCount);

  const [questionId, setQuestionId] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [display, setDisplay] = useState<string>("");
  const [subQuestion, setSubQuestion] = useState<boolean>(false);
  const [registrantType, setRegistrantType] = useState<string>("");
  const choices: any[] = [];
  const [state, dispatch] = useReducer(reducer, choices);

  const [pageNumber, setPageNumber] = useState<number>(1);
  // Queries

  const [getQuestionInfo] = useLazyQuery(GETQUESTION, {
    onCompleted: (data) => {
      setQuestionId(data.questionById._id);
      setQuestion(data.questionById.question);
      setSubQuestion(data.questionById.subQuestion);
      setName(data.questionById.name);
      setDisplay(data.questionById.display);
      setRegistrantType(data.questionById.registrantType);
      setType(data.questionById.type);
      dispatch({ type: "GET", payload: data.questionById.choices });
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [createQuestion] = useMutation(CREATEQUESTION, {
    onCompleted: (data) => {
      storeDispatch(addQuestion(data.questionCreateOne.record));
      storeDispatch(showSuccessSnackbar("Question Created!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateQuestionId] = useMutation(UPDATEQUESTION, {
    onCompleted: (data) => {
      storeDispatch(updateQuestion(data.questionUpdateById.record));
      storeDispatch(showSuccessSnackbar("Question Updated!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getQuestions] = useLazyQuery(GETQUESTIONS, {
    onCompleted: (data) => {
      storeDispatch(setQuestions(data.questionPagination));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });

  // Table Columns
  // eslint-disable-next-line
  const getQuestion = (id: string) => {
    getQuestionInfo({ variables: { _id: id } });
    storeDispatch(handleModal(true));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (rowData: IQuestion) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => getQuestion(rowData._id)}>
              {rowData.name}
            </strong>
          );
        },
      },
      {
        Header: "Question",
        accessor: (rowData: IQuestion) => {
          return rowData.question;
        },
      },
    ],

    [getQuestion]
  );

  useEffect(() => {
    getQuestions({ variables: { page: pageNumber, perPage: 15, sort: "_ID_DESC" } });
  }, [pageNumber, getQuestions]);

  // Functions //

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getQuestions({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "_ID_DESC" } });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!name || !question || !display || !type || !registrantType) return storeDispatch(showErrorSnackbar("Missing Fields"));
    if (type === "dropdown" || type === "checkbox" || type === "radio") {
      if (!state.length) return storeDispatch(showErrorSnackbar("No choices selected"));
    }
    if (questionId) {
      let followUpIds = state.map((question: IChoice) => {
        return {
          ...question,
          followUp: question.followUp.map((followUp) => (followUp._id ? followUp._id : followUp)),
        };
      });

      updateQuestionId({
        variables: {
          _id: questionId,
          record: {
            name,
            question,
            subQuestion,
            type,
            display,
            registrantType,
            choices: followUpIds,
          },
        },
      });
    } else {
      createQuestion({
        variables: {
          record: {
            name,
            question,
            subQuestion,
            type,
            display,
            registrantType,
            choices: state,
          },
        },
      });
    }
    // create question
  };

  const handleFollowUpChange = (e: any, value: any, index: number) => {
    dispatch({ type: "UPDATE", payload: { index: index, field: "followUp", value: value.map((followup: IQuestion) => followup._id) } });
  };

  const createModal = () => {
    setQuestionId("");
    setSubQuestion(false);
    setQuestion("");
    setName("");
    setDisplay("");
    setType("");
    setRegistrantType("");
    dispatch({ type: "REMOVEALL", payload: [] });
    storeDispatch(handleModal(true));
  };

  const getDefaultValues = (choice: IChoice) => {
    let selectedQuestions = choice.followUp.map((followUp: IQuestion) => followUp);
    return selectedQuestions;
  };

  return (
    <SettingContainer>
      <GlobalModal>
        <div>
          <Typography variant="h2" component="div" gutterBottom>
            <strong>{questionId ? "Edit Question" : "Add Question"}</strong>
          </Typography>
          {questionId ? (
            <p>
              <strong>
                Please note these changes will affect questions for all projects. If you would like to edit a question for a specific
                project. Please create a new one.
              </strong>
            </p>
          ) : null}
          <form onSubmit={handleSubmit}>
            <QuestionInfo
              disabled={false}
              name={name}
              type={type}
              display={display}
              registrantType={registrantType}
              question={question}
              subQuestion={subQuestion}
              choices={state}
              setName={setName}
              setType={setType}
              setDisplay={setDisplay}
              setRegistrantType={setRegistrantType}
              setSubQuestion={setSubQuestion}
              setQuestion={setQuestion}
              handleFollowUpChange={handleFollowUpChange}
              getDefaultValues={getDefaultValues}
              questionId={questionId}
              questions={questions}
              dispatch={dispatch}
            />
            <FlexBetween>
              <Button color="success" variant="contained" type="submit">
                {questionId ? "Update" : "Create"}
              </Button>
              <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
                Cancel
              </Button>
            </FlexBetween>
          </form>
        </div>
      </GlobalModal>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "flex-start", md: "space-between" } }}>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>Questionnaire</strong>
        </Typography>
        <Button sx={{ width: "max-content" }} onClick={() => createModal()} color="primary" variant="contained">
          Create Question
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <StandardTable
          data={questions}
          columns={columns}
          loading={loading}
          handleGlobalFilterValue={handleGlobalFilterValue}
          count={count}
        />
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
            {"<"}
          </Button>
          <span>{pageNumber}</span>
          <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
            {">"}
          </Button>
        </Box>
      </Box>
    </SettingContainer>
  );
};

const GETQUESTIONS = gql`
  query questionPagination($filter: FilterFindManyQuestionInput, $page: Int!, $perPage: Int!, $sort: SortFindManyQuestionInput) {
    questionPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        name
        question
      }
      count
    }
  }
`;

const GETQUESTION = gql`
  query questionById($_id: MongoID!) {
    questionById(_id: $_id) {
      _id
      name
      question
      display
      registrantType
      type
      subQuestion
      choices {
        choice
        followUp {
          _id
          name
          question
        }
      }
    }
  }
`;

const CREATEQUESTION = gql`
  mutation questionCreateOne($record: CreateOneQuestionInput!) {
    questionCreateOne(record: $record) {
      record {
        _id
        name
        question
        display
        registrantType
        type
        subQuestion
        choices {
          choice
          followUp {
            _id
            question
          }
        }
      }
    }
  }
`;

const UPDATEQUESTION = gql`
  mutation questionUpdateById($_id: MongoID!, $record: UpdateByIdQuestionInput!) {
    questionUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        question
        display
        registrantType
        type
        subQuestion
        choices {
          choice
          followUp {
            _id
            question
          }
        }
      }
    }
  }
`;

export default Questionaire;
