import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";
import { IUser } from "../../types/user";

interface authState {
  user: IUser | null;
}

const initialState: authState = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateNote: (state, action: PayloadAction<any>) => {
      if (state.user?.notes) {
        state.user.notes = action.payload;
      }
    },
    addNote: (state, action: PayloadAction<any>) => {
      if (state.user?.notes) {
        state.user.notes = [...state.user.notes, action.payload];
      }
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { logout, setUser, addNote, updateNote } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
