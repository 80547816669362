import { SyntheticEvent } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SubTabs, SubTab } from "../../commonStyles";

const BasicTabs = (props: ChildProps) => {
  const { tabs, sub, value, setValue, color } = props;

  /* Functions */
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const viewTab = () => {
    if (sub) {
      return (
        <SubTabs
          sx={{ backgroundColor: color ? color : "#fff", mb: 2 }}
          value={value}
          variant="scrollable"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map(({ label }, index) => {
            return <SubTab key={index} label={label} {...a11yProps(index)} />;
          })}
        </SubTabs>
      );
    } else {
      return (
        <Tabs
          sx={{ backgroundColor: color ? color : "#fff" }}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map(({ label }, index) => {
            return <Tab key={index} label={label} {...a11yProps(index)} />;
          })}
        </Tabs>
      );
    }
  };

  return (
    <>
      <Box sx={{ borderColor: "divider", width: "100%" }}>{viewTab()}</Box>
      {tabs.map(({ component }, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {component}
          </TabPanel>
        );
      })}
    </>
  );
};

interface ChildProps {
  tabs: ITab[];
  sub?: boolean;
  value: number;
  setValue: any;
  color?: string;
}

interface ITab {
  label: string;
  component: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default BasicTabs;
