import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { Box, Typography, Grid, Button, Paper, Card, CircularProgress } from "@mui/material";
import { IRegistrant } from "../../types/registrant";
import { IComment } from "../../types/comment";

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("search");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [registrants, setRegistrant] = useState<IRegistrant[]>([]);
  const [count, setCount] = useState<number>(0);

  const [getRegistrants, { loading }] = useLazyQuery(GETREGISTRANTSFROMSEARCH, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setCount(data.getRegistrantsFromSearch.count);
      setRegistrant(data.getRegistrantsFromSearch.registrants);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    if (query && query.length > 2) {
      getRegistrants({
        variables: {
          page: pageNumber,
          perPage: 20,
          search: query,
        },
      });
    }
  }, [query, pageNumber, getRegistrants]);

  const getHighlightedText = (text: string, highlight: string) => {
    // Split text on higlight term, include term itself into parts, ignore case
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) => (
      <span key={index}>
        {part.toLowerCase() === highlight.toLowerCase() ? <strong style={{ backgroundColor: "#cfff00" }}>{part}</strong> : part}
      </span>
    ));
  };

  return (
    <Box>
      <Typography variant="h5">
        Search Results: <strong>{query ? query : "No Results"}</strong>
      </Typography>
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress sx={{ m: "0 auto" }} />
          <Box sx={{ mt: 2 }}>Searching...</Box>
        </Box>
      ) : registrants.length ? (
        <Box>
          <Grid sx={{ mt: 1 }} container spacing={2}>
            {registrants.map((registrant: IRegistrant, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <Link style={{ textDecoration: 'none' }}to={`/dashboard/project/${registrant.project._id}/registrant/${registrant._id}`}>
                    <Card
                      sx={{
                        boxShadow: `0px 0px 3px 3px ${registrant.project.portal.primaryColor || "rgba(0, 0, 0)"}`,
                        backgroundColor: registrant.project.portal.primaryColor
                          ? registrant.project.portal.primaryColor + "11"
                          : "rgba(0, 0, 0, 0.2)",
                        height: "100%",
                        cursor: 'pointer'
                      }}
                    >
                      <Paper sx={{ p: 2, height: "100%" }} elevation={12}>
                        <Box>
                          <strong>
                            {registrant.firstName} {registrant.lastName}
                          </strong>
                        </Box>
                        <Box>
                          <strong>{registrant.email}</strong>
                        </Box>
                        <Box>
                          <strong>{registrant.primaryPhone}</strong>
                        </Box>
                        <Box>
                          <strong>{registrant.project.name}</strong>
                        </Box>
                        <Box>
                          {registrant.comments?.map((comments: IComment, index: number) => {
                            return (
                              <Box key={index} sx={{ mt: 1 }}>
                                {index + 1}. {getHighlightedText(comments.comment, query!)}
                              </Box>
                            );
                          })}
                        </Box>
                      </Paper>
                    </Card>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 20)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>There were no results matching the query. Please try again.</Box>
      )}
    </Box>
  );
};

const GETREGISTRANTSFROMSEARCH = gql`
  query getRegistrantsFromSearch($page: Float!, $perPage: Float!, $search: String) {
    getRegistrantsFromSearch(page: $page, perPage: $perPage, search: $search) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        project {
          _id
          name
          portal {
            primaryColor
          }
        }
        primaryPhone
        comments {
          comment
        }
      }
    }
  }
`;

export default SearchPage;
