import { useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import { SettingContainer } from "../../../commonStyles";
import ReportingFilters from "../report/ReportingFilters";
import { IProject } from "../../../types/project";
import { IProcess } from "../../../types/process";
import { gql, useLazyQuery } from "@apollo/client";
import { downloadReport, getLabels } from "../../../utils/function";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const ProcessReporting = ({ project }: ProcessReportingProps) => {
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6)
  );
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
  );

  const numberOfProcessesRef = useRef<any>(null);
  const ongoingProcessesRef = useRef<any>(null);
  const completedProcessesRef = useRef<any>(null);
  const completedStepsRef = useRef<any>(null);
  const processProgressRef = useRef<any>(null);
  const processTypesRef = useRef<any>(null);

  const getChartData = (chartLabels: string[]) => {
    let dateRangeData: any = [];
    let results = {
      dateRangeData,
      stepProgressLeft: {
        "1": 0,
        "2": 0,
        "3+": 0,
      },
    };

    for (let i = 0; i < chartLabels.length; ++i) {
      let thisDateRangesData = {
        numberOfProcesses: 0,
        ongoingProcesses: 0,
        nonongoingProcesses: 0,
        completedProcesses: 0,
        uncompletedProcesses: 0,
        completedSteps: 0,
        uncompletedSteps: 0,
        type: {
          shortTerm: 0,
          event: 0,
          handover: 0,
          sales: 0,
          longTerm: 0,
        },
      };
      const curDateIndex = new Date(chartLabels[i]);
      const nextDateIndex = i !== chartLabels.length - 1 ? new Date(chartLabels[i + 1]) : undefined;

      for (let process of processes) {
        const inThisDateRange =
          (nextDateIndex &&
            curDateIndex &&
            process.createdAt &&
            new Date(process.createdAt).getTime() >= curDateIndex.getTime() &&
            new Date(process.createdAt).getTime() < nextDateIndex.getTime()) ||
          (!nextDateIndex && curDateIndex && process.createdAt && new Date(process.createdAt).getTime() >= curDateIndex.getTime());

        if (!inThisDateRange) continue;

        ++thisDateRangesData.numberOfProcesses;
        process.currentProcess && ++thisDateRangesData.ongoingProcesses;
        !process.currentProcess && ++thisDateRangesData.nonongoingProcesses;
        process.steps.every((step) => step?.completed) && ++thisDateRangesData.completedProcesses;
        !process.steps.every((step) => step?.completed) && ++thisDateRangesData.uncompletedProcesses;
        process.steps.map((step) => step?.completed && ++thisDateRangesData.completedSteps);
        process.steps.map((step) => !step?.completed && ++thisDateRangesData.uncompletedSteps);
        process.processType === "shortTerm" && ++thisDateRangesData.type.shortTerm;
        process.processType === "handover" && ++thisDateRangesData.type.handover;
        process.processType === "event" && ++thisDateRangesData.type.event;
        process.processType === "sales" && ++thisDateRangesData.type.sales;
        process.processType === "longTerm" && ++thisDateRangesData.type.longTerm;

        let latestStepCompletedIndex = 0;
        while (process.steps[latestStepCompletedIndex]?.completed) latestStepCompletedIndex++;
        const stepsLeft = process.steps.length - (latestStepCompletedIndex + 1);
        switch (stepsLeft) {
          case 1:
            ++results.stepProgressLeft["1"];
            break;
          case 2:
            ++results.stepProgressLeft["2"];
            break;
          default:
            ++results.stepProgressLeft["3+"];
            break;
        }
      }
      results.dateRangeData.push(thisDateRangesData);
    }

    return results;
  };

  const chartLabels = getLabels(startDate, endDate);
  const { dateRangeData: chartDateRangeData, stepProgressLeft: chartStepProgressLeftData } = getChartData(chartLabels);

  const numberOfProcessesOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Number of Processes",
      },
    },
  };
  const numberOfProcessesData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Number of Processes",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.numberOfProcesses),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const ongoingProcessesOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Ongoing Processes",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const ongoingProcessesData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Ongoing Processes",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.ongoingProcesses),
        backgroundColor: "rgb(255, 99, 132, 0.5)",
      },
      {
        label: "Non-Ongoing Processes",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.nonongoingProcesses),
        backgroundColor: "rgb(75, 192, 192, 0.5)",
      },
    ],
  };

  const completedProcessesOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Completed Processes",
      },
    },
  };
  const completedProcessesData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Completed Processes",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.completedProcesses),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Uncompleted Processes",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.uncompletedProcesses),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const completedStepsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Completed Steps",
      },
    },
  };
  const completedStepsData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Completed Steps",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.completedSteps),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Uncompleted Steps",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.uncompletedSteps),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const processProgressOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "# of Steps Left",
      },
    },
  };
  const processProgressData = {
    labels: ["1", "2", "3+"],
    datasets: [
      {
        label: "Number of Processes",
        data: [chartStepProgressLeftData["1"], chartStepProgressLeftData["2"], chartStepProgressLeftData["3+"]],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const processTypesOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Process Types",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const processTypesData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Short Term",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.type.shortTerm),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Event",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.type.event),
        borderColor: "rgb(53, 235, 120)",
        backgroundColor: "rgba(53, 235, 120, 0.5)",
      },
      {
        label: "Handover",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.type.handover),
        borderColor: "rgb(235, 158, 52)",
        backgroundColor: "rgba(235, 158, 52, 0.5)",
      },
      {
        label: "Sales",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.type.sales),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Long Term",
        data: chartDateRangeData.map((chartLabelData: any) => chartLabelData.type.longTerm),
        borderColor: "rgb(129, 53, 235)",
        backgroundColor: "rgba(129, 53, 235, 0.5)",
      },
    ],
  };

  const [getProcesses] = useLazyQuery(GETPROCESSES, {
    onCompleted: (data) => {},
    onError: (e) => console.log(e),
  });

  const getProcessData = async () => {
    const res = await getProcesses({
      variables: {
        filter: {
          project: project?._id,
          dateGreaterThanEqual: startDate,
          dateLessThanEqual: endDate,
        },
        limit: 1000,
      },
    });
    setProcesses(res.data.processMany);
  };
  const download = async () => {
    const numberOfProcessesImage = numberOfProcessesRef?.current.toBase64Image();
    const ongoingProcessesImage = ongoingProcessesRef?.current.toBase64Image();
    const completedProcessesImage = completedProcessesRef?.current.toBase64Image();
    const completedStepsImage = completedStepsRef?.current.toBase64Image();
    const processProgressImage = processProgressRef?.current.toBase64Image();

    let graphs = [
      { label: "", image: numberOfProcessesImage, table: null },
      { label: "", image: ongoingProcessesImage, table: null },
      { label: "", image: completedProcessesImage, table: null },
      { label: "", image: completedStepsImage, table: null },
      { label: "", image: processProgressImage, table: null },
    ];

    graphs = graphs.filter((graph) => graph.image !== undefined);
    await downloadReport(`${project?.name || "General"} Process Report ${startDate?.toDateString()} - ${endDate?.toDateString()}`, graphs);
  };

  return (
    <SettingContainer>
      <ReportingFilters
        download={download}
        dataArray={processes}
        setDataArray={setProcesses}
        queryData={getProcessData}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      {processes.length > 0 && <Typography sx={{ mt: 2, mb: 2 }}>Total Number of Processes in this Period: {processes.length}</Typography>}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Bar style={{ minHeight: "300px" }} ref={numberOfProcessesRef} options={numberOfProcessesOptions} data={numberOfProcessesData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Bar style={{ minHeight: "300px" }} ref={ongoingProcessesRef} options={ongoingProcessesOptions} data={ongoingProcessesData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Line
            style={{ minHeight: "300px" }}
            ref={completedProcessesRef}
            options={completedProcessesOptions}
            data={completedProcessesData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Line style={{ minHeight: "300px" }} ref={completedStepsRef} options={completedStepsOptions} data={completedStepsData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Pie style={{ minHeight: "300px" }} ref={processProgressRef} options={processProgressOptions} data={processProgressData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Bar style={{ minHeight: "300px" }} ref={processTypesRef} options={processTypesOptions} data={processTypesData} />
        </Grid>
      </Grid>
    </SettingContainer>
  );
};

interface ProcessReportingProps {
  project?: IProject;
}

const GETPROCESSES = gql`
  query getProcesses($filter: FilterFindManyProcessInput, $limit: Int) {
    processMany(filter: $filter, limit: $limit) {
      _id
      project {
        _id
        name
      }
      processType
      userType
      name
      currentProcess
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          _id
          name
        }
        user {
          firstName
          lastName
          email
        }
        name
      }
      users {
        _id
        firstName
        lastName
        email
      }
      timeBetweenSteps
      registrant {
        firstName
        lastName
        email
      }
      createdAt
    }
  }
`;

export default ProcessReporting;
