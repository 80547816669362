import { useState, useMemo, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Radio, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { SettingContainer } from "../../commonStyles";
import { showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { IProject } from "../../types/project";
import { IUser } from "../../types/user";
import StandardTable from "../tables/StandardTable";
import BasicTabs from "../common/BasicTabs";
import { downloadPdf, downloadExcel, normalToCamel } from "../../utils/function";
import { selectUser } from "../../features/auth/authSlice";

const RealtorTypes = ({ project }: RealtorTypesProps) => {
  const user = useSelector(selectUser);
  const [thisPageUsers, setThisPageUsers] = useState<IUser[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [usersCount, setUsersCount] = useState(0);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [updatedRealtorTypes, setUpdatedRealtorTypes] = useState<RealtorUpdate[]>([]);
  const [searchTermFilter, setSearchTermFilter] = useState<string | null>(null);
  const [tabsValue, setTabsValue] = useState<number>(0);

  const mappingRealtorTypes = useMemo(() => [null, "platinum", "vip", "regular"], []);
  const dispatch = useAppDispatch();

  const handleGlobalFilterValue = async (newSearch: string) => {
    if ((!newSearch && !searchTermFilter) || newSearch === searchTermFilter) return;
    setPageNumber(1);
    setMaxPage(0);
    setUpdatedRealtorTypes([]);
    setSearchTermFilter(newSearch ? newSearch : null);
  };
  const download = (type: string) => {
    let headers = thisPageUsersCols
      .filter((column: any) => typeof column.Header === "string")
      .map((column: any) => {
        return {
          label: column.Header,
          id: normalToCamel(column.Header),
        };
      });

    let data = thisPageUsers.map((user) => {
      return {
        user: `${user.firstName} ${user.lastName}`,
        email: user.email,
        platinum: user.projectAccess[0].realtorType === "platinum" ? "Yes" : " ",
        vip: user.projectAccess[0].realtorType === "vip" ? "Yes" : " ",
        regular: user.projectAccess[0].realtorType === "regular" ? "Yes" : " ",
      };
    });

    let columnWidths = thisPageUsers.map((realtor: any) => {
      if (type === "excel") {
        return {
          user: 15,
          email: 15,
          platinum: 15,
          vip: 15,
          regular: 15,
        };
      } else
        return {
          user: 200,
          email: 200,
          platinum: 200,
          vip: 200,
          regular: 200,
        };
    });

    let sheetTitle = [`Realtor Types - ${project?.name}`];

    if (type === "excel") {
      downloadExcel([data], [headers], [], [columnWidths], sheetTitle, `Realtor Types - ${project?.name}`);
    } else {
      downloadPdf([data], [headers], [], columnWidths, sheetTitle, `Realtor Types - ${project?.name}`);
    }
  };

  const queryUsersOnCompletedFunc = (data: any) => {
    const users = data.userPagination.items;
    const count = data.userPagination.count;
    const usersWithCorrectProjectAccessOnly = users.map((user: IUser) => {
      const projectAccess = [];
      const correctProjectAccess = user.projectAccess.find((projectAccess) => {
        if (!projectAccess.project) return false;
        return projectAccess.project._id === project?._id;
      });
      projectAccess.push(correctProjectAccess);
      return { ...user, projectAccess };
    });

    setThisPageUsers(usersWithCorrectProjectAccessOnly);
    setUsersCount(count);

    const updatedRealtorTypesForInitUsers = usersWithCorrectProjectAccessOnly.map((user: IUser) => {
      return { id: user._id, realtorType: user.projectAccess[0].realtorType };
    });

    if (pageNumber > maxPage) {
      setUpdatedRealtorTypes((prevRealtorTypes) => [...prevRealtorTypes, ...updatedRealtorTypesForInitUsers]);
      setMaxPage((maxPage) => maxPage + 1);
    }
  };

  const [, { loading: getUsersWithProjectAccessLoading, refetch: refetchGetUsersWithProjectAccess }] = useLazyQuery(
    USERSWITHPROJECTACCESS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        queryUsersOnCompletedFunc(data);
      },
      onError: (e) => console.log(e),
    }
  );
  const [updateRealtorTypes] = useMutation(UPDATEREALTORTYPES, {
    onCompleted: () => {
      dispatch(showSuccessSnackbar("Realtor Types Updated!"));
    },
    onError: () => {},
  });

  const saveRealtorUpdates = async () => {
    if (!project) return;

    setSaveDisabled(true);
    await updateRealtorTypes({ variables: { projectId: project._id, usersToUpdate: updatedRealtorTypes } });

    setSaveDisabled(false);
    setPageNumber(1);
    setMaxPage(0);
    setUpdatedRealtorTypes([]);

    refetchGetUsersWithProjectAccess({
      projectId: project?._id,
      page: pageNumber,
      perPage: 15,
      type: "Realtor",
      searchRealtorType: [project?._id, mappingRealtorTypes[tabsValue], searchTermFilter],
    });
  };

  const thisPageUsersCols = useMemo(() => {
    const isChecked = (id: string, realtorType: string) => {
      const existsInUpdates = updatedRealtorTypes.some(
        (updatedRealtorType) => updatedRealtorType.id === id && updatedRealtorType.realtorType === realtorType
      );
      if (existsInUpdates) {
        return true;
      }
      return false;
    };

    const addRealtorUpdate = (id: string, realtorType: string) => {
      const existsInUpdates = updatedRealtorTypes.some((updatedRealtorType) => updatedRealtorType.id === id);

      let newRealtorUpdates = [];

      if (existsInUpdates) {
        newRealtorUpdates = updatedRealtorTypes.map((updatedRealtorType) => {
          if (updatedRealtorType.id === id) {
            return { id: id, realtorType: realtorType };
          }
          return updatedRealtorType;
        });
      } else {
        const addedRealtorUpdate: RealtorUpdate = { id, realtorType };
        newRealtorUpdates = updatedRealtorTypes.concat([addedRealtorUpdate]);
      }
      setUpdatedRealtorTypes(newRealtorUpdates);
    };
    return [
      {
        Header: "User",
        accessor: "",
        Cell: ({ row: { original } }: any) => {
          return (
            <Typography>
              {original.firstName} {original.lastName}
            </Typography>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell: { value } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Platinum",
        accessor: "",
        Cell: ({ row: { original } }: any) => {
          return <Radio checked={isChecked(original._id, "platinum")} onChange={() => addRealtorUpdate(original._id, "platinum")} />;
        },
      },
      {
        Header: "VIP",
        accessor: "",
        Cell: ({ row: { original } }: any) => {
          return <Radio checked={isChecked(original._id, "vip")} onChange={() => addRealtorUpdate(original._id, "vip")} />;
        },
      },
      {
        Header: "Regular",
        accessor: "",
        Cell: ({ row: { original } }: any) => {
          return <Radio checked={isChecked(original._id, "regular")} onChange={() => addRealtorUpdate(original._id, "regular")} />;
        },
      },
    ];
  }, [updatedRealtorTypes]);

  const tableJSX = (data: any) => {
    return (
      <>
        <StandardTable
          data={data}
          loading={getUsersWithProjectAccessLoading}
          columns={thisPageUsersCols}
          handleGlobalFilterValue={handleGlobalFilterValue}
          count={usersCount}
          download={download}
          user={user}
        />
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button
            disabled={pageNumber === 1}
            onClick={() => {
              setPageNumber((prev) => prev - 1);
            }}
          >
            {"<"}
          </Button>
          <span>{pageNumber}</span>
          <Button
            disabled={pageNumber === Math.ceil(usersCount / 15) || usersCount === 0}
            onClick={() => {
              setPageNumber((prev) => prev + 1);
            }}
          >
            {">"}
          </Button>
        </Box>
      </>
    );
  };

  const tabs = [
    {
      label: "All",
      component: <></>,
    },
    {
      label: "Platinum",
      component: <></>,
    },
    {
      label: "VIP",
      component: <></>,
    },
    {
      label: "Regular",
      component: <></>,
    },
  ];
  useEffect(() => {
    setPageNumber(1);
    setMaxPage(0);
    setUpdatedRealtorTypes([]);
    const filterButton: any = document.querySelector(".std-table-remove-filter");
    if (filterButton.click) {
      filterButton.click();
    }
  }, [tabsValue]);
  useEffect(() => {
    refetchGetUsersWithProjectAccess({
      projectId: project?._id,
      page: pageNumber,
      perPage: 15,
      type: "Realtor",
      searchRealtorType: [project?._id, mappingRealtorTypes[tabsValue], searchTermFilter],
    });
  }, [refetchGetUsersWithProjectAccess, pageNumber, searchTermFilter, project?._id, mappingRealtorTypes, tabsValue]);

  return (
    <SettingContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="success" disabled={saveDisabled} onClick={saveRealtorUpdates}>
          Save
        </Button>
      </Box>
      <BasicTabs tabs={tabs} sub={true} value={tabsValue} setValue={setTabsValue}></BasicTabs>
      {tableJSX(thisPageUsers)}
    </SettingContainer>
  );
};

type RealtorTypesProps = {
  project: IProject | null;
};

type RealtorUpdate = {
  id: string;
  realtorType: string;
};

const USERSWITHPROJECTACCESS = gql`
  query usersWithProjectAccess($projectId: MongoID, $page: Int, $perPage: Int, $type: EnumUserType, $searchRealtorType: [String]) {
    userPagination(
      page: $page
      perPage: $perPage
      filter: { type: $type, projectAccess: { project: $projectId }, searchRealtorType: $searchRealtorType }
    ) {
      items {
        _id
        firstName
        lastName
        email
        projectAccess {
          project {
            _id
          }
          realtorType
        }
      }
      count
    }
  }
`;

const UPDATEREALTORTYPES = gql`
  mutation updateRealtorTypes($projectId: MongoID!, $usersToUpdate: [NewUserInput]) {
    updateRealtorTypes(userArray: $usersToUpdate, project: $projectId) {
      firstName
      lastName
    }
  }
`;

export default RealtorTypes;
