import { useState, useMemo, useEffect } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import StandardTable from "../tables/StandardTable";
import { SettingContainer } from "../../commonStyles";
import { IQuestion, IChoice } from "../../types/question";
import { handleModal } from "../../features/modal/modalSlice";
import { useAppDispatch } from "../../app/hooks";
import { useQuestionsQuery } from "../../features/question/questionHooks";
import { selectQuestions, selectQuestionCount, setQuestions } from "../../features/question/questionSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { FlexBetween } from "../../commonStyles";
import { IProject } from "../../types/project";
import { selectProjectQuestions, addRemoveQuestions } from "../../features/projectSetting/projectSettingSlice";
import { GlobalModal } from "../../features/modal/Modal";
import QuestionInfo from "../common/QuestionInfo";
import BasicTabs from "../common/BasicTabs";

const Questionaire = (props: ChildProps) => {
  const { project } = props;
  const storeDispatch = useAppDispatch();
  const { loading } = useQuestionsQuery();
  const questions = useSelector(selectQuestions);
  const projectQuestions = useSelector(selectProjectQuestions);
  const count = useSelector(selectQuestionCount);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [value, setValue] = useState(0);

  const [questionId, setQuestionId] = useState<string>("");
  const [subQuestion, setSubQuestion] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [display, setDisplay] = useState<string>("");
  const [registrantType, setRegistrantType] = useState<string>("");
  const [choices, setChoices] = useState<IChoice[]>([]);

  // Queries

  const [getQuestions] = useLazyQuery(GETQUESTIONS, {
    onCompleted: (data) => {
      storeDispatch(setQuestions(data.questionPagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getQuestionInfo] = useLazyQuery(GETQUESTION, {
    onCompleted: (data) => {
      setSubQuestion(data.questionById.subQuestion);
      setQuestionId(data.questionById._id);
      setQuestion(data.questionById.question);
      setName(data.questionById.name);
      setDisplay(data.questionById.display);
      setRegistrantType(data.questionById.registrantType);
      setType(data.questionById.type);
      setChoices(data.questionById.choices);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [addRemoveProjectQuestion] = useMutation(ADDREMOVEQUESTION, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setQuestionId("");
      setQuestion("");
      setName("");
      setDisplay("");
      setRegistrantType("");
      setType("");
      setSubQuestion(false);
      setChoices([]);
      storeDispatch(addRemoveQuestions(data.crmProjectSettingUpdateOne.record));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar(!value ? "Removed From Project" : "Added To Project"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    getQuestions({ variables: { page: pageNumber, perPage: 15, sort: "_ID_DESC" } });
  }, [pageNumber, getQuestions]);

  // Table

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getQuestions({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "_ID_DESC" } });
  };

  const columns = useMemo(() => {
    const getQuestion = (id: string) => {
      getQuestionInfo({ variables: { _id: id } });
      storeDispatch(handleModal(true));
    };
    return [
      {
        Header: "Name",
        accessor: (rowData: IQuestion) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => getQuestion(rowData._id)}>
              {rowData.name}
            </strong>
          );
        },
      },
      {
        Header: "Question",
        accessor: (rowData: IQuestion) => {
          return rowData.question;
        },
      },
    ];
  }, [getQuestionInfo, storeDispatch]);

  const addRemoveQuestion = (id: string) => {
    if (value) {
      let selectedQuestions: string[] = [];
      if (projectQuestions.length) {
        selectedQuestions = projectQuestions.map((question: IQuestion) => question._id);
      }
      selectedQuestions.push(id);
      addRemoveProjectQuestion({ variables: { record: { questions: selectedQuestions }, filter: { project: project?._id } } });
    } else {
      let removedQuestions = projectQuestions.filter((question: IQuestion) => question._id !== id).map((question) => question._id);
      addRemoveProjectQuestion({ variables: { record: { questions: removedQuestions }, filter: { project: project?._id } } });
    }
  };

  const getDefaultValues = (choice: IChoice) => {
    let selectedQuestions = choice.followUp.map((followUp: IQuestion) => followUp);
    return selectedQuestions;
  };

  const filteredQuestions = () => {
    if (questions.length) {
      return questions.filter(({ _id: id1 }) => !projectQuestions.some(({ _id: id2 }) => id2 === id1));
    } else return questions;
  };

  // Tabs

  const tabs = [
    {
      label: "Project Questions",
      component: <StandardTable data={projectQuestions} columns={columns} loading={loading} />,
    },
    {
      label: "All Questions",
      component: (
        <>
          <StandardTable
            data={filteredQuestions()}
            columns={columns}
            loading={loading}
            handleGlobalFilterValue={handleGlobalFilterValue}
            count={count}
          />
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </>
      ),
    },
  ];

  return (
    <SettingContainer>
      <GlobalModal>
        <div>
          <Typography variant="h2" component="div" gutterBottom>
            <strong>{name}</strong>
          </Typography>
          <p>
            <strong>If you would like to make changes, please head to Global Settings</strong>
          </p>
          <QuestionInfo
            name={name}
            subQuestion={subQuestion}
            type={type}
            disabled={true}
            display={display}
            registrantType={registrantType}
            question={question}
            choices={choices}
            questionId={questionId}
            getDefaultValues={getDefaultValues}
            questions={questions}
          />
          <FlexBetween>
            <Button color="success" variant="contained" onClick={() => addRemoveQuestion(questionId)}>
              {value ? "Add To Project" : "Remove From Project"}
            </Button>
            <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
              Cancel
            </Button>
          </FlexBetween>
        </div>
      </GlobalModal>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Questionnaire</strong>
      </Typography>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} />
    </SettingContainer>
  );
};

interface ChildProps {
  project: IProject | null;
}

const GETQUESTIONS = gql`
  query questionPagination($filter: FilterFindManyQuestionInput, $page: Int!, $perPage: Int!, $sort: SortFindManyQuestionInput) {
    questionPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        name
        question
      }
      count
    }
  }
`;

const GETQUESTION = gql`
  query questionById($_id: MongoID!) {
    questionById(_id: $_id) {
      _id
      name
      question
      display
      registrantType
      type
      subQuestion
      choices {
        choice
        followUp {
          _id
          name
          question
        }
      }
    }
  }
`;

const ADDREMOVEQUESTION = gql`
  mutation crmProjectSettingUpdateOne($record: UpdateOneCrmProjectSettingInput!, $filter: FilterUpdateOneCrmProjectSettingInput) {
    crmProjectSettingUpdateOne(record: $record, filter: $filter) {
      record {
        _id
        questions {
          _id
          name
          question
          display
          registrantType
          type
          subQuestion
          choices {
            choice
            followUp {
              _id
              name
              question
            }
          }
        }
      }
    }
  }
`;

export default Questionaire;
