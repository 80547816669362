import React, { Dispatch, SetStateAction } from "react";
import { Grid, TextField, Box, Button } from "@mui/material";
import { IAddress } from "../../types/project";

const ProjectInfo = (props: ChildProps) => {
  const { projectName, setProjectName, developerName, setDeveloperName, addresses, addAddress, handleAddress, email, setEmail, submitted } =
    props;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required={!projectName}
            label="Project Name"
            variant="outlined"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            disabled={submitted}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required={!developerName}
            label="Developer Name"
            variant="outlined"
            value={developerName}
            onChange={(e) => setDeveloperName(e.target.value)}
            disabled={submitted}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required={!email}
            type="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={submitted}
          />
        </Grid>
      </Grid>
      <h2>Addresses</h2>
      {addresses.length ? (
        <Grid container spacing={2}>
          {addresses.map((address: IAddress, index: number) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    label={"Address"}
                    title={"Address"}
                    name="streetAddress"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                    value={address.streetAddress}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    label={"City"}
                    title={"City"}
                    name="city"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                    value={address.city}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    label={"Province"}
                    title={"Province"}
                    name="province"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                    value={address.province}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    label={"Country"}
                    title={"Country"}
                    name="country"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                    value={address.country}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    label={"Postal Code"}
                    title={"Postal Code"}
                    name="postalCode"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                    value={address.postalCode}
                    required={true}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      ) : (
        <Box>
          <Box>
            <em>There are currently no addresses set up.</em>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => addAddress()}>
          Add an Address
        </Button>
      </Box>
    </Box>
  );
};

interface ChildProps {
  projectName: string | undefined;
  setProjectName: Dispatch<SetStateAction<string | undefined>>;
  developerName: string | undefined;
  setDeveloperName: Dispatch<SetStateAction<string | undefined>>;
  addresses: IAddress[];
  addAddress: any;
  handleAddress: any;
  email: string | undefined;
  setEmail: Dispatch<SetStateAction<string | undefined>>;
  submitted: boolean;
}

export default ProjectInfo;
