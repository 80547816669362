import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Typography, Box, Grid, TextField, Button, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { IUser } from "../../types/user";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { Container, FlexEnd } from "../../commonStyles";
import { validatePassword, validateEmail } from "../../utils/function";

const User = (props: ChildProps) => {
  const { user } = props;
  const storeDispatch = useAppDispatch();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [confirmError, setConfirmError] = useState<string>("");
  const [emails, setEmails] = useState<string[]>(user ? user?.emailAliases : []);

  const [updateUserId] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {
      setPassword("");
      setConfirmPassword("");
      storeDispatch(showSuccessSnackbar("Update Successful"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handlePassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error || confirmError) {
      return;
    }
    if (password !== confirmPassword) {
      return storeDispatch(showErrorSnackbar("Passwords are not the same!"));
    }

    updateUserId({
      variables: {
        _id: user?._id,
        record: {
          password: confirmPassword,
        },
      },
    });
  };

  const handleValidation = (type: string) => {
    if (type === "password") {
      if (!validatePassword(password)) {
        setError("Passwords needs to have minimum 8 characters, at least one numerical character and one special character!");
      } else {
        setError("");
      }
    }
    if (type === "confirmPassword") {
      if (!validatePassword(confirmPassword)) {
        setConfirmError("Passwords needs to have minimum 8 characters, at least one numerical character and one special character!");
      } else {
        setConfirmError("");
      }
    }
  };

  const addEmail = () => {
    setEmails([...emails, ""]);
  };

  const saveEmails = () => {
    if (emails.every((email: string) => !validateEmail(email))) {
      return storeDispatch(showErrorSnackbar("Not a Valid Email"));
    }
    updateUserId({
      variables: {
        _id: user?._id,
        record: {
          emailAliases: emails,
        },
      },
    });
  };

  const handleEmail = (e: any, numIndex: number) => {
    let emailArray = emails.map((email: string, index: number) => {
      if (numIndex === index) {
        return e.target.value;
      } else return email;
    });
    setEmails(emailArray);
  };

  return (
    <Box>
      <Container sx={{ mt: 2 }}>
        <Typography variant="h5">
          <strong>User Profile</strong>
        </Typography>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth label="First Name" variant="outlined" value={user ? user.firstName : ""} disabled sx={{ opacity: 0.6 }} />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth label="Last Name" variant="outlined" value={user ? user.lastName : ""} disabled sx={{ opacity: 0.6 }} />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth label="Email" variant="outlined" value={user ? user.email : ""} disabled sx={{ opacity: 0.6 }} />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth label="Phone" variant="outlined" value={user ? user.primaryPhone : ""} disabled sx={{ opacity: 0.6 }} />
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: 2 }}>
        <Typography variant="h5">
          <strong>Email Aliases</strong>
        </Typography>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          {emails.length
            ? emails.map((email: string, index: number) => {
                return (
                  <Grid key={index} item md={3} sm={6} xs={12}>
                    <TextField fullWidth label="Email" variant="outlined" value={email} onChange={(e) => handleEmail(e, index)} />
                  </Grid>
                );
              })
            : null}
        </Grid>
        <FlexEnd>
          <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => addEmail()}>
            Add Email
          </Button>
          <Button variant="contained" color="success" onClick={() => saveEmails()}>
            Save
          </Button>
        </FlexEnd>
      </Container>
      <Container sx={{ mt: 2 }}>
        <Typography variant="h5">
          <strong>Change Password</strong>
        </Typography>
        <form onSubmit={handlePassword}>
          <Grid sx={{ mt: 1 }} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                required
                value={password}
                type={showPassword ? "text" : "password"}
                onBlur={() => handleValidation("password")}
                error={error ? true : false}
                helperText={error ? error : ""}
                inputProps={{ minLength: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)} />
                      ) : (
                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)} />
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Confirm Password"
                variant="outlined"
                required
                value={confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                onBlur={() => handleValidation("confirmPassword")}
                error={confirmError ? true : false}
                helperText={confirmError ? confirmError : ""}
                inputProps={{ minLength: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showConfirmPassword ? (
                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                      ) : (
                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <FlexEnd sx={{ mt: 2 }}>
            <Button type="submit" color="success" variant="contained">
              Change Password
            </Button>
          </FlexEnd>
        </form>
      </Container>
    </Box>
  );
};

interface ChildProps {
  user: IUser | null;
}

const UPDATEUSER = gql`
  mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
    userUpdateById(_id: $_id, record: $record) {
      record {
        _id
        emailAliases
      }
    }
  }
`;

export default User;
