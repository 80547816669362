import { useState, useMemo, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Typography, Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Grid, Skeleton } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import { subDays } from "date-fns";

import BasicTabs from "../common/BasicTabs";
import StandardTable from "../tables/StandardTable";
import { capitalizeFirstLetter, convertAllDates } from "../../utils/function";
import Texts from "../registrant/Texts";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IText, ICall } from "../../types/callRail";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { IProjectAccess } from "../../types/user";
import { selectFilterProjects, setFilterProjects } from "../../features/project/projectSlice";
import { showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";

const Notifications = () => {
  const user = useSelector(selectUser);
  const filterProjects = useSelector(selectFilterProjects);
  const dispatch = useAppDispatch();
  if (filterProjects.length) dispatch(setFilterProjects([]));
  const storeDispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const [calls, setCalls] = useState<ICall[]>([]);
  const [texts, setTexts] = useState<IText[]>([]);
  const [textMessages, setTextMessages] = useState<IText[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [dateStart, setDateStart] = useState<Date | null>(subDays(new Date(), 3));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [answerStatus, setAnswerStatus] = useState<string>("");
  const [direction, setDirection] = useState<string>("");
  const [callType, setCallType] = useState<string>("");
  const [projects, setProjects] = useState<any[]>(filterProjects);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalTexts, setTotalTexts] = useState<number>(0);

  const [getPhoneDetails, { loading }] = useLazyQuery(GETPHONEDETAILS, {
    onCompleted: (data) => {
      setCalls(data.callRailRegistrant.calls);
      setTexts(data.callRailRegistrant.texts);
      setTotalRecords(data.callRailRegistrant.totalCallRecords);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [downloadAudio] = useLazyQuery(DOWNLOADAUDIO, {
    onCompleted: (data) => {
      const element = document.createElement("a");
      element.href = data.downloadAudio;
      element.target = "_blank";
      element.click();
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [getTextMessages, { loading: textLoading }] = useLazyQuery(TEXTMESSAGES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      let textMessage = data.getTextMessageGroup.texts;
      if (data.getTextMessageGroup.texts.length) {
        textMessage = data.getTextMessageGroup.texts.map((group: any) => {
          return {
            messages: group.messages.map((text: any) => {
              return {
                content: text.content,
                direction: text.direction,
                created_at: text.createdAt,
                registrant: group._id,
                user: text.user,
              };
            }),
            lastMessage: group.messages[group.messages.length - 1].createdAt,
            name: group.project.name,
            phoneNumber: group._id.primaryPhone,
          };
        });
      }
      setTotalTexts(data.getTextMessageGroup.count);
      setTextMessages(textMessage);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting texts"));
    },
  });

  useEffect(() => {
    if (value === 1) {
      getTextMessages({ variables: { user: user?._id, projects, page: pageNumber + 1 } });
    } else if (value === 2) {
      getTextMessages({ variables: { user: null, projects, page: pageNumber + 1 } });
    }
  }, [pageNumber, projects, value, getTextMessages, user?._id]);

  const [callRegistrant] = useLazyQuery(CALLREGISTRANT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(data.callRegistrant));
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const addTexts = (data: any) => {
    let formattedPhone = data.phoneNumber.replace(/\D/g, "");
    if (formattedPhone.length === 11) {
      formattedPhone = formattedPhone.slice(1);
    }
    if (textMessages.length) {
      let textMessageArray = textMessages.map((text: IText) => {
        if (text.phoneNumber.replace(/\D/g, "") === formattedPhone && data.name === text.name) {
          return {
            ...text,
            messages: [
              ...text.messages,
              {
                content: data.messages[data.messages.length - 1].content,
                direction: data.messages[data.messages.length - 1].direction,
                created_at: data.messages[data.messages.length - 1].created_at,
                registrant: text.messages[0].registrant ? text.messages[0].registrant : null,
                user: user,
              },
            ],
          };
        } else return text;
      });
      setTextMessages(textMessageArray);
    }
    let newText = texts.map((text: IText) => {
      if (text.phoneNumber === data.phoneNumber) {
        return data;
      } else return text;
    });
    setTexts(newText);
  };

  useEffect(() => {
    if (user?.type !== "Developer") {
      getPhoneDetails({ variables: { dateStart, dateEnd, answerStatus, direction, projects, type: "project", page: pageNumber + 1 } });
    }
    // eslint-disable-next-line
  }, [pageNumber]);

  const columns = useMemo(() => {
    const handleCall = (data: ICall) => {
      let phone = data.customer_phone_number.replace(/\D/g, "");
      if (phone.length < 10 && phone.length > 11) {
        return storeDispatch(showErrorSnackbar("Only US/Canadian Phone Numbers are allowed"));
      }

      let projectId = user?.projectAccess.find((project: IProjectAccess) => project.project.name === data.name);

      callRegistrant({ variables: { project: projectId?.project._id, customerPhone: parseInt(phone, 10) } });
    };

    return [
      {
        Header: "Project",
        accessor: (rowData: ICall) => rowData.name,
      },
      {
        Header: "Phone Number",
        accessor: (rowData: ICall) => rowData.customer_phone_number,
      },
      {
        Header: "Date",
        accessor: (rowData: ICall) => convertAllDates(rowData.start_time, "PPpp"),
      },
      {
        Header: "Answered",
        accessor: (rowData: ICall) => (rowData.answered ? <CheckIcon color="success" /> : <CloseIcon color="error" />),
      },
      {
        Header: "Direction",
        accessor: (rowData: ICall) => capitalizeFirstLetter(rowData.direction),
      },
      {
        Header: "Duration(s)",
        accessor: (rowData: ICall) => rowData.recording_duration,
      },
      {
        Header: "Call",
        accessor: (rowData: ICall) => <PhoneIcon onClick={() => handleCall(rowData)} color="success" sx={{ cursor: "pointer" }} />,
      },
      {
        Header: "Listen",
        accessor: (rowData: ICall) => {
          if (rowData.recording_player) {
            return (
              <Button variant="contained" color="primary" rel="noopener noreferrer" target="_blank" href={rowData.recording_player}>
                Listen
              </Button>
            );
          } else return;
        },
      },
      {
        Header: "Download",
        accessor: (rowData: ICall) => {
          if (rowData.recording) {
            return (
              <Button variant="contained" color="primary" onClick={() => downloadAudio({ variables: { link: rowData.recording } })}>
                Download
              </Button>
            );
          } else return;
        },
      },
    ];
  }, [downloadAudio, callRegistrant, storeDispatch, user?.projectAccess]);

  const tabs = useMemo(() => {
    return [
      {
        label: "Calls",
        component: (
          <Box sx={{ mt: 2 }}>
            <StandardTable data={calls} columns={columns} loading={loading} />
          </Box>
        ),
      },
      {
        label: "Your Texts",
        component: textLoading ? (
          <Grid container spacing={2}>
            {Array.from(Array(12).keys()).map((item, index) => {
              return (
                <Grid item xs={12} sm={6} xl={4} key={index}>
                  <Skeleton height={300} animation="wave" />
                </Grid>
              );
            })}
          </Grid>
        ) : textMessages.length ? (
          <Grid container spacing={2} sx={{ mt: 2, height: "100%" }}>
            {textMessages.map((text: any, index: number) => {
              return (
                <Grid sx={{ minHeight: "100%" }} key={index} item xs={12} sm={6} xl={4}>
                  <Texts key={index} data={text} handleTexts={addTexts} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box sx={{ p: 2 }}>
            <em>There are no text conversations found.</em>
          </Box>
        ),
      },
      {
        label: "All Texts",
        component: textLoading ? (
          <Grid container spacing={2}>
            {Array.from(Array(12).keys()).map((item, index) => {
              return (
                <Grid item xs={12} sm={6} xl={4} key={index}>
                  <Skeleton height={300} animation="wave" />
                </Grid>
              );
            })}
          </Grid>
        ) : textMessages.length ? (
          <Grid container spacing={2} sx={{ mt: 2, height: "100%" }}>
            {textMessages.map((text: any, index: number) => {
              return (
                <Grid
                  sx={{
                    minHeight: "100%",
                    height: {
                      sm: "100%",
                      md: "auto",
                    },
                  }}
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  xl={4}
                >
                  <Texts key={index} data={text} handleTexts={addTexts} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box sx={{ p: 2 }}>
            <em>There are no text conversations found.</em>
          </Box>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textMessages, loading, calls, texts, textLoading]);

  return (
    <Box>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Messages/Phone Calls</strong>
      </Typography>
      <Grid container spacing={2}>
        {!value ? (
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date Start"
                value={dateStart ? new Date(dateStart!) : null}
                onChange={(newValue) => {
                  setDateStart(newValue);
                }}
                maxDate={dateEnd ? new Date(dateEnd!) : null}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        ) : null}
        {!value ? (
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date End"
                value={dateEnd ? new Date(dateEnd!) : null}
                onChange={(newValue) => {
                  setDateEnd(newValue);
                }}
                minDate={dateStart ? new Date(dateStart!) : null}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project.name!)].flat()}
            freeSolo={false}
            value={projects}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: any) => option}
            onChange={(e, value) => {
              setPageNumber(0);
              setProjects(value);
            }}
            renderInput={(params) => <TextField {...params} label="Projects" />}
          />
        </Grid>
        {!value ? (
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Call Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={"type"}
                label={"Call Type"}
                value={callType}
                onChange={(e) => setCallType(e.target.value)}
              >
                <MenuItem value={"Sales"}>Sales</MenuItem>
                <MenuItem value={"Administration"}>Administration</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        {!value ? (
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Answer Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={"answerType"}
                label={"Answer Type"}
                value={answerStatus}
                onChange={(e) => setAnswerStatus(e.target.value)}
              >
                <MenuItem value={"answered"}>Answered</MenuItem>
                <MenuItem value={"missed"}>Missed</MenuItem>
                <MenuItem value={"voicemail"}>Voicemail</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        {!value ? (
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Direction</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={"direction"}
                label={"Direction"}
                value={direction}
                onChange={(e) => setDirection(e.target.value)}
              >
                <MenuItem value={"inbound"}>Inbound</MenuItem>
                <MenuItem value={"outbound"}>Outbound</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
      {!value ? (
        <Box sx={{ mt: 2 }}>
          <Button
            onClick={() =>
              getPhoneDetails({ variables: { dateStart, dateEnd, answerStatus, direction, callType, projects, pageNumber: 1 } })
            }
            sx={{ mt: { xs: 2, md: 0 } }}
            color="primary"
            variant="contained"
          >
            Search
          </Button>
        </Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        <BasicTabs color="#00142a" tabs={tabs} sub={false} value={value} setValue={setValue} />
      </Box>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          disabled={pageNumber === 0}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber - 1);
          }}
        >
          {"<"}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button
          disabled={pageNumber + 1 === Math.ceil(!value ? totalRecords / 35 : totalTexts / 12) || (!value ? !totalRecords : !totalTexts)}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber + 1);
          }}
        >
          {">"}
        </Button>
      </Box>
    </Box>
  );
};
const GETPHONEDETAILS = gql`
  query callRailRegistrant(
    $registrant: MongoID
    $dateStart: Date
    $dateEnd: Date
    $answerStatus: String
    $direction: String
    $projects: [String]
    $callType: String
    $page: Float!
    $type: String!
  ) {
    callRailRegistrant(
      registrant: $registrant
      dateStart: $dateStart
      dateEnd: $dateEnd
      answerStatus: $answerStatus
      direction: $direction
      projects: $projects
      callType: $callType
      page: $page
      type: $type
    ) {
      totalCallRecords
      calls {
        name
        customer_phone_number
        answered
        customer_city
        direction
        recording
        recording_duration
        recording_player
        start_time
        voicemail
      }
      texts {
        lastMessage
        phoneNumber
        messages {
          direction
          content
          created_at
        }
        name
      }
    }
  }
`;

const DOWNLOADAUDIO = gql`
  query downloadAudio($link: String!) {
    downloadAudio(link: $link)
  }
`;

const CALLREGISTRANT = gql`
  query callRegistrant($project: MongoID!, $customerPhone: Float!) {
    callRegistrant(project: $project, customerPhone: $customerPhone)
  }
`;

const TEXTMESSAGES = gql`
  query getTextMessageGroup($user: MongoID, $projects: [String], $page: Float!) {
    getTextMessageGroup(user: $user, projects: $projects, page: $page) {
      count
      texts {
        _id {
          _id
          firstName
          lastName
          fullName
          primaryPhone
          project {
            _id
          }
          subscribed
        }
        project {
          _id
          name
        }
        messages {
          content
          direction
          user {
            fullName
          }
          createdAt
        }
      }
    }
  }
`;

export default Notifications;
