import { Button, Stack, Box, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router";

import Header from "../layout/Header";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", border: 0 }}>
        <Stack spacing={2} sx={{ mx: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              sx={{ width: { xs: "10rem", sm: "20rem" }, height: { xs: "10rem", sm: "20rem", mr: "1.5rem" } }}
              alt="404 Not Found"
              src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/PageNotFoundImage.jpg"
            />
            ;
          </Box>
          <Typography sx={{ textAlign: "center" }}>
            Sorry, this page doesn't exist. If there are any issues, please contact{" "}
            <Link sx={{ fontWeight: "550" }} href="mailto:info@rdsre.ca">
              info@rdsre.ca
            </Link>
            . Thank you!
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
            >
              Back to Home
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default PageNotFound;
