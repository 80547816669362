/* Dependency Imports */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  IconButton,
  List,
  ListItemButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowRight, Close, DriveFolderUpload, Lock, LockOpen, Upload, ContentPaste, DeleteForever } from "@mui/icons-material";
import { Editor } from "@tiptap/react";
import { useLazyQuery, gql } from "@apollo/client";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import DescriptionIcon from "@mui/icons-material/Description";
import ArticleIcon from "@mui/icons-material/Article";

import { FlexBetween, FlexEnd } from "../../../commonStyles";
import { tokens } from "../../../utils/constant";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar, showWarningSnackbar } from "../../../features/snackbar/snackbarSlice";
import { IAttachment } from "../../../types/process";
import { ITemplate } from "../../settings/emails/EmailTemplates";
import { validateEmail, hyphenToTitle, dataURItoBlob, getScaledDim } from "../../../utils/function";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import ReactRouterPrompt from "react-router-prompt";

const CloseButton = ({ setOpen }: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => (
  <IconButton
    aria-label="close"
    onClick={() => setOpen(false)}
    sx={{
      position: "absolute",
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <Close />
  </IconButton>
);

export const ImageDialog = ({ editor, setOpen, imgQuery }: IDialogProps & { imgQuery: any }) => {
  const [url, setUrl] = useState("");
  const [alt, setAlt] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [ratio, setRatio] = useState(0);
  const [constrain, setConstrain] = useState(true);
  const [showUpload, setShowUpload] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    editor
      .chain()
      .focus()
      .setImage({ src: url, alt: alt, width: parseInt(width, 10), height: parseInt(height, 10) })
      .run();
    setOpen(false);
  };

  const handleRatio = () => {
    if (width && height && height !== "0") {
      setRatio(Number(width) / Number(height));
    } else {
      setRatio(0);
    }
  };

  const img = new Image();

  img.onload = () => {
    setWidth(img.naturalWidth.toString());
    setHeight(img.naturalHeight.toString());
    if (img.naturalHeight !== 0) {
      setRatio(img.naturalWidth / img.naturalHeight);
    } else setRatio(0);
  };

  return (
    <Dialog open onClose={() => setOpen(false)} fullWidth>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>
          Insert Image
          <CloseButton setOpen={setOpen} />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            <TextField
              margin="dense"
              sx={{ mr: 1 }}
              size="small"
              label="Source"
              fullWidth
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                img.src = e.target.value;
              }}
            />
            <Tooltip title="Source">
              <IconButton aria-label="upload" onClick={() => setShowUpload(true)}>
                <DriveFolderUpload />
              </IconButton>
            </Tooltip>
          </Box>
          <TextField
            margin="dense"
            size="small"
            label="Alternative Description"
            fullWidth
            value={alt}
            onChange={(e) => setAlt(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              margin="dense"
              fullWidth
              size="small"
              label="Width"
              value={width}
              onBlur={handleRatio}
              onChange={(e) => {
                if (constrain && ratio) {
                  setHeight(Math.round(Number(e.target.value) / ratio).toString());
                  img.height = Math.round(Number(e.target.value) * ratio);
                }
                setWidth(e.target.value);
                img.width = parseInt(e.target.value, 10);
              }}
            />
            <TextField
              margin="dense"
              fullWidth
              sx={{ mx: 1 }}
              size="small"
              label="Height"
              value={height}
              onBlur={handleRatio}
              onChange={(e) => {
                if (constrain && ratio) {
                  setWidth(Math.round(Number(e.target.value) * ratio).toString());
                  img.width = Math.round(Number(e.target.value) * ratio);
                }
                setHeight(e.target.value);
                img.height = parseInt(e.target.value, 10);
              }}
            />
            <Tooltip title="Constrain Proportions">
              <IconButton
                aria-label="constrain proportions"
                onClick={() => {
                  if (!constrain) {
                    handleRatio();
                  }
                  setConstrain(!constrain);
                }}
              >
                {constrain ? <Lock /> : <LockOpen />}
              </IconButton>
            </Tooltip>
          </Box>
          {showUpload && <ImageUploadDialog type={"image"} setOpen={setShowUpload} setUrl={setUrl} img={img} imgQuery={imgQuery} />}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <FlexBetween sx={{ width: "100%" }}>
            <Button variant="contained" color="success" type="submit">
              Insert
            </Button>
            <Button variant="contained" color="info" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </FlexBetween>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const ImageUploadDialog = ({ setOpen, setUrl, img, imgQuery, type }: IImageUploadDialogProps) => {
  const [fileName, setFileName] = useState("");
  const [folder, setFolder] = useState("global/");
  const [images, setImages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const storeDispatch = useAppDispatch();

  const [getImages] = useLazyQuery(GETIMAGES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setImages(data.getEmailImages);
    },
  });

  const [uploadImage, { loading: uploadLoading }] = useLazyQuery(UPLOADIMAGE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getImages({ variables: { name: folder } });
      storeDispatch(showSuccessSnackbar("Image has been uploaded"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [deleteImage] = useLazyQuery(DELETEIMAGE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getImages({ variables: { name: folder } });
      storeDispatch(showSuccessSnackbar("Image has been deleted"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    getImages({ variables: { name: folder } });
    setFileName("");
  }, [folder, getImages]);
  /* Functions */

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const filename = e.target.value.replace(/.*[/\\]/, "");
      if (folder.includes("recorded-images")) {
        const canvas: any = document.createElement("canvas");
        let img: any = new Image();
        img.src = URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          var scaled = getScaledDim(img, canvas.getContext("2d").canvas.dataMaxWidth, canvas.getContext("2d").canvas.dataMaxHeight);
          // scale canvas to image
          canvas.getContext("2d").canvas.width = scaled.width;
          canvas.getContext("2d").canvas.height = scaled.height;
          canvas?.getContext("2d").drawImage(img, 0, 0, canvas.getContext("2d").canvas.width, canvas.getContext("2d").canvas.height);
          canvas.getContext("2d").strokeStyle = "black";
          canvas?.getContext("2d")?.beginPath();
          //I'm making sure the circle will always fit - therefore Math.min
          canvas
            ?.getContext("2d")
            ?.arc(canvas.width / 2, canvas.height / 2, Math.min(60, canvas.width / 2 - 20, canvas.height / 2 - 20), 0, 2 * Math.PI, false);
          canvas.getContext("2d").fillStyle = "black";
          canvas?.getContext("2d")?.fill();
          canvas.getContext("2d").lineWidth = 1;
          canvas.getContext("2d").strokeStyle = "black";
          canvas?.getContext("2d")?.stroke();
          canvas?.getContext("2d")?.closePath();
          canvas.getContext("2d").strokeStyle = "white";
          canvas.getContext("2d").fillStyle = "white";
          canvas?.getContext("2d")?.beginPath();
          canvas.getContext("2d").lineWidth = 1;
          canvas.getContext("2d").moveTo(canvas.width / 2 - 25, canvas.height / 2 - 40);
          canvas.getContext("2d").lineTo(canvas.width / 2 - 25, canvas.height / 2 + 40);
          canvas.getContext("2d").lineTo(canvas.width / 2 + 40, canvas.height / 2);
          canvas.getContext("2d").fill();
          let canvasUrl = canvas.toDataURL();
          let imageBlob = dataURItoBlob(canvasUrl);
          uploadImage({ variables: { file: imageBlob, name: filename, destination: folder } });
        };
      } else {
        uploadImage({ variables: { file: e.target.files[0], name: filename, destination: folder } });
      }
      setFileName(filename);
    }
  };

  const getImage = (url: string) => {
    if (url.includes("recorded-videos")) {
      return "https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png";
    } else return url;
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <Dialog open={true} onClose={() => setOpen(false)} fullWidth maxWidth="xl">
      <DialogContent>
        {imgQuery.loading ? (
          <Box sx={{ display: "flex", height: 200 }}>
            <CircularProgress size={100} sx={{ m: "auto" }} />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex" }}>
              <List sx={{ border: "1px solid #000", width: "20%" }}>
                {imgQuery.data?.getFolders?.map((item: any, i: number) => {
                  return (
                    <ListItemButton
                      sx={{
                        backgroundColor: folder === item ? "#00142a !important" : "#fff",
                        color: folder === item ? "#fff" : "#000",
                      }}
                      onClick={() => setFolder(item)}
                    >
                      {hyphenToTitle(item.split("/")[1].replace(/-/g, " "))}
                    </ListItemButton>
                  );
                })}
              </List>
              {images.length ? (
                <ImageList cols={4} rowHeight={200} sx={{ my: 0, width: "80%", ml: 1 }}>
                  {images.slice((pageNumber - 1) * 8, pageNumber * 8).map((item: any, i: number) => (
                    <ImageListItem
                      key={i}
                      sx={{
                        opacity: 1,
                        transition: "opacity .2s linear",
                        cursor: "pointer",
                        "&:hover": { opacity: setUrl ? 0.8 : 1 },
                        backgroundColor: (theme) => theme.palette.grey[300],
                      }}
                    >
                      <img
                        onClick={() => {
                          if (setUrl) {
                            setUrl(item.url, type);
                            setOpen(false);
                          }
                          img.src = item.url;
                        }}
                        src={getImage(item.url)}
                        alt={item.name}
                        loading="lazy"
                        style={{ height: "200px", objectFit: "cover" }}
                      />
                      <ImageListItemBar
                        title={item.name}
                        actionIcon={
                          <Box sx={{ display: "flex" }}>
                            <Tooltip title="Copy to Clipboard">
                              <ContentPaste
                                sx={{ color: "#fff", mr: 1 }}
                                onClick={() => {
                                  navigator.clipboard.writeText(item.url);
                                  storeDispatch(showSuccessSnackbar("URL copied to clipboard!"));
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete">
                              <DeleteForever
                                sx={{ color: "#fff", mr: 1 }}
                                onClick={() => {
                                  deleteImage({
                                    variables: { name: item.name, url: `${item.url.split("/")[3]}/${item.url.split("/")[4]}` },
                                  });
                                }}
                              />
                            </Tooltip>
                          </Box>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <Box sx={{ ml: 2, textAlign: "center", width: "100%", alignSelf: "center" }}>
                  <em>There are currently no images found.</em>
                </Box>
              )}
            </Box>
            {images.length ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <Pagination count={Math.ceil(images.length / 8)} page={pageNumber} onChange={handlePagination} />
              </Box>
            ) : null}
            <FlexEnd sx={{ mt: 2 }}>
              <label htmlFor="button-file">
                <input accept="image/*" id="button-file" style={{ display: "none" }} type="file" onChange={handleUpload} />
                <Button variant="contained" component="span" startIcon={<Upload />}>
                  Upload
                </Button>
              </label>
            </FlexEnd>
          </>
        )}
      </DialogContent>
      {fileName && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[300],
            borderTopColor: (theme) => theme.palette.grey[500],
            borderTopStyle: "solid",
          }}
        >
          <Typography align="center" sx={{ py: 1, mx: "auto" }}>
            {uploadLoading ? `Uploading "${fileName}"...` : `Upload "${fileName}" completed.`}
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export const LinkDialog = ({ editor, setOpen, type, imgQuery }: ILinkProps & { imgQuery: any }) => {
  const link = editor.getAttributes("link").href;
  if (link) editor.commands.extendMarkRange("link");
  const from = editor.view.state.selection.$from.pos;
  const to = editor.view.state.selection.$to.pos;

  const [url, setUrl] = useState(link || "");
  const [imageUrl, setImageUrl] = useState("");
  const [text, setText] = useState(editor.view.state.doc.textBetween(from, to));
  const [target, setTarget] = useState(editor.getAttributes("link").target || "_blank");
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<string>("");
  // Image
  const [alt, setAlt] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [ratio, setRatio] = useState(0);
  const [constrain, setConstrain] = useState(true);

  const handleRatio = () => {
    if (width && height && height !== "0") {
      setRatio(Number(width) / Number(height));
    } else {
      setRatio(0);
    }
  };

  const img = new Image();

  img.onload = () => {
    setWidth(img.naturalWidth.toString());
    setHeight(img.naturalHeight.toString());
    if (img.naturalHeight !== 0) {
      setRatio(img.naturalWidth / img.naturalHeight);
    } else setRatio(0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (type === "text" && url === "") {
      editor.chain().focus().unsetLink().run();
    } else {
      if (type === "text") {
        editor
          .chain()
          .focus()
          .insertContent(text)
          .setTextSelection({ from: from, to: from + text.length })
          .setLink({ href: url, target: target })
          .run();
      } else if (type === "image") {
        editor
          .chain()
          .focus()
          .insertContent(
            `<img src=${imageUrl.replace(/ /g, "+")} alt=${alt} width=${parseInt(width, 10)} height=${parseInt(height, 10)} />`
          )
          .setNodeSelection(from)
          .setLink({ href: url, target: target })
          .run();
      }
    }
    setOpen(false);
  };

  const handleUploadType = (showUpload: boolean, uploadType: string) => {
    setShowUpload(showUpload);
    setUploadType(uploadType);
  };

  const handleUrl = (src: string, type: string) => {
    if (type === "link") {
      setUrl(src);
    } else setImageUrl(src);
  };

  return (
    <Dialog open onClose={() => setOpen(false)}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>
          {link ? "Edit" : "Insert"} Link
          <CloseButton setOpen={setOpen} />
        </DialogTitle>
        <DialogContent>
          <FlexBetween>
            <TextField
              margin="dense"
              size="small"
              label="Link URL"
              fullWidth
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                if (!text || text === url) {
                  setText(e.target.value);
                }
              }}
            />
            <Tooltip title="Source">
              <IconButton aria-label="upload" onClick={() => handleUploadType(true, "link")}>
                <DriveFolderUpload />
              </IconButton>
            </Tooltip>
          </FlexBetween>
          {type === "image" ? (
            <>
              <FlexBetween>
                <TextField
                  margin="dense"
                  size="small"
                  label="Image URL"
                  fullWidth
                  value={imageUrl}
                  onChange={(e) => {
                    setImageUrl(e.target.value);
                    if (!text || text === imageUrl) {
                      setText(e.target.value);
                    }
                  }}
                />
                <Tooltip title="Source">
                  <IconButton aria-label="upload" onClick={() => handleUploadType(true, "image")}>
                    <DriveFolderUpload />
                  </IconButton>
                </Tooltip>
              </FlexBetween>
              <TextField
                margin="dense"
                size="small"
                label="Alternative Description"
                fullWidth
                value={alt}
                onChange={(e) => setAlt(e.target.value)}
              />
              <FlexBetween>
                <TextField
                  margin="dense"
                  fullWidth
                  size="small"
                  label="Width"
                  value={width}
                  onBlur={handleRatio}
                  onChange={(e) => {
                    if (constrain && ratio) {
                      setHeight(Math.round(Number(e.target.value) / ratio).toString());
                      img.height = Math.round(Number(e.target.value) * ratio);
                    }
                    setWidth(e.target.value);
                    img.width = parseInt(e.target.value, 10);
                  }}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  sx={{ mx: 1 }}
                  size="small"
                  label="Height"
                  value={height}
                  onBlur={handleRatio}
                  onChange={(e) => {
                    if (constrain && ratio) {
                      setWidth(Math.round(Number(e.target.value) * ratio).toString());
                      img.width = Math.round(Number(e.target.value) * ratio);
                    }
                    setHeight(e.target.value);
                    img.height = parseInt(e.target.value, 10);
                  }}
                />
                <Tooltip title="Constrain Proportions">
                  <IconButton
                    aria-label="constrain proportions"
                    onClick={() => {
                      if (!constrain) {
                        handleRatio();
                      }
                      setConstrain(!constrain);
                    }}
                  >
                    {constrain ? <Lock /> : <LockOpen />}
                  </IconButton>
                </Tooltip>
                {showUpload && (
                  <ImageUploadDialog type={uploadType} setOpen={setShowUpload} setUrl={handleUrl} img={img} imgQuery={imgQuery} />
                )}
              </FlexBetween>
            </>
          ) : null}
          {type === "text" ? (
            <TextField
              margin="dense"
              size="small"
              label="Text to display"
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          ) : null}
          <FormControl size="small" fullWidth margin="dense">
            <InputLabel>Open link in...</InputLabel>
            <Select value={target} onChange={(e) => setTarget(e.target.value)} label="Open link in...">
              <MenuItem value="_blank">New Window</MenuItem>
              <MenuItem value="_self">Current Window</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 3 }}>
          <FlexBetween sx={{ width: "100%" }}>
            <Button color="success" variant="contained" type="submit">
              Save
            </Button>
            <Button color="info" variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </FlexBetween>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const SourceDialog = ({ editor, setOpen }: IDialogProps) => {
  const [code, setCode] = useState(editor.getHTML());

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>
        Source Code
        <CloseButton setOpen={setOpen} />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", height: "85vh" }}>
        <textarea
          value={code}
          spellCheck="false"
          onChange={(e) => setCode(e.target.value)}
          style={{ resize: "none", overflow: "auto", width: "100%", height: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <FlexBetween sx={{ width: "100%" }}>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              editor.commands.setContent(code);
              setOpen(false);
            }}
          >
            Save
          </Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </FlexBetween>
      </DialogActions>
    </Dialog>
  );
};

export const TokenMenu = ({ editor, closeMenu, anchorEl }: { editor: Editor; closeMenu: () => void; anchorEl: HTMLElement | null }) => {
  const [tab, setTab] = useState<null | number>(null);

  const CascadingMenu = (props: { label: string; value: number; children: React.ReactNode }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    return (
      <MenuItem
        onMouseOver={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
          setTab(props.value);
        }}
      >
        <FlexBetween sx={{ width: "100%" }}>
          {props.label} <ArrowRight />
        </FlexBetween>
        <Popper
          anchorEl={anchorEl}
          open={open && tab === props.value}
          placement="right"
          modifiers={[
            {
              name: "flip",
              enabled: true,
            },
            {
              name: "preventOverflow",
              enabled: true,
            },
          ]}
        >
          <Paper>{props.children}</Paper>
        </Popper>
      </MenuItem>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => closeMenu()}>
      <Popper
        open
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: "flip",
            enabled: true,
          },
          {
            name: "preventOverflow",
            enabled: true,
          },
        ]}
      >
        <Paper>
          {tokens.map((category, i) => (
            <CascadingMenu label={category.category} value={i} key={category.category}>
              {category.items.map((item) => (
                <MenuItem
                  key={item.value}
                  onClick={() => {
                    editor.chain().focus().insertContent(`{{${item.value}}}`).run();
                    closeMenu();
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </CascadingMenu>
          ))}
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

const AttachmentPreviewCard = ({
  fileType,
  keyNum,
  attachment,
  setAttachments,
  attachmentsObjs,
  setAttachmentObjs,
  setUnsavedChanges,
  imageConstructedUrl,
}: AttachmentPreviewCardProps) => {
  return (
    <Paper elevation={12}>
      <Card sx={{ width: "100%", height: "220px" }}>
        {fileType === "pdf" && (
          <Document file={attachment.getUrl || attachmentsObjs[keyNum]}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "lightgrey" }}>
              <Page pageNumber={1} height={150} />
            </Box>
          </Document>
        )}
        {(fileType === "doc" || fileType === "docx") && (
          <Box
            sx={{
              width: "100%",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#95d1ed",
            }}
          >
            <ArticleIcon sx={{ color: "#5f8596", fontSize: 80 }} />
          </Box>
        )}
        {fileType === "xlsx" && (
          <Box
            sx={{
              width: "100%",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#cef578",
            }}
          >
            <DescriptionIcon sx={{ color: "#8ba651", fontSize: 80 }} />
          </Box>
        )}
        {(fileType === "jpeg" || fileType === "jpg" || fileType === "png") && (
          <CardMedia component="img" height="150" width="200" image={attachment.getUrl || imageConstructedUrl} alt={attachment.name} />
        )}
        <CardContent>
          <Box sx={{ ml: 2, mr: 2, width: "90%", display: "flex", justifyContent: "space-between" }}>
            {attachment.getUrl && (
              <Box sx={{ alignSelf: "center", width: "90%" }}>
                <a
                  style={{ display: "block", minWidth: "0px", textDecoration: "none", color: "#000" }}
                  href={`${attachment.getUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {attachment.name}
                  </Typography>
                </a>
              </Box>
            )}

            {!attachment.getUrl && (
              <Box sx={{ alignSelf: "center" }}>
                <span style={{ display: "block", minWidth: "0px" }}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {attachment.name}
                  </Typography>
                </span>
              </Box>
            )}
            <IconButton
              onClick={() => {
                setAttachments((attachments: IAttachment[]) => attachments.slice(0, keyNum).concat(attachments.slice(keyNum + 1)));
                setAttachmentObjs((attachmentObjs: any[]) => attachmentObjs.slice(0, keyNum).concat(attachmentObjs.slice(keyNum + 1)));
                if (setUnsavedChanges) setUnsavedChanges(true);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

interface AttachmentPreviewCardProps {
  fileType: string;
  keyNum: number;
  attachment: IAttachment;
  setAttachments: any;
  attachmentsObjs: any[];
  setAttachmentObjs: any;
  setUnsavedChanges: ((change: boolean) => void) | undefined;
  imageConstructedUrl: string;
}

export const AttachmentsDialog = ({
  attachmentsDialogOpen,
  setAttachmentsDialogOpen,
  formAttachments,
  setFormAttachments,
  formAttachmentsObjs,
  setFormAttachmentObjs,
  setUnsavedChanges,
}: IAttachmentDialogProps) => {
  const [attachments, setAttachments] = useState<any[]>(formAttachments);
  const [attachmentsObjs, setAttachmentObjs] = useState<any[]>(formAttachmentsObjs);

  const dispatch = useAppDispatch();

  const handBackState = () => {
    setFormAttachments(attachments);
    setFormAttachmentObjs(attachmentsObjs);
    setAttachmentsDialogOpen(false);
  };

  const allowedAttachments = [
    "xlsx",
    "pdf",
    "jpeg",
    "png",
    "docx",
    "doc",
    "pptx",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  return (
    <Dialog open={attachmentsDialogOpen} onClose={handBackState} fullWidth>
      <DialogTitle>
        Attachments
        <IconButton aria-label="close" onClick={handBackState} sx={{ position: "absolute", right: 8, top: 8 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {attachments.length === 0 && <p>There are currently no attachments on this email template.</p>}
        {attachments.length > 0 && (
          <Grid container spacing={2}>
            {attachments.map((attachment, key) => {
              const fileTypeSplit = attachment.name.split(".");
              const fileType = fileTypeSplit[fileTypeSplit.length - 1];
              let imageConstructedUrl = "";

              if (!attachment.getUrl && (fileType === "jpeg" || fileType === "jpg" || fileType === "png")) {
                imageConstructedUrl = URL.createObjectURL(attachmentsObjs[key]);
              }

              return (
                <Grid item key={key} xs={12} sm={6}>
                  <AttachmentPreviewCard
                    fileType={fileType}
                    keyNum={key}
                    attachment={attachment}
                    setAttachments={setAttachments}
                    attachmentsObjs={attachmentsObjs}
                    setAttachmentObjs={setAttachmentObjs}
                    setUnsavedChanges={setUnsavedChanges}
                    imageConstructedUrl={imageConstructedUrl}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Box>
          <label htmlFor="button-file">
            <input
              accept="*"
              id="button-file"
              style={{ display: "none" }}
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const fileExtension = e.target.files ? e.target.files[0].type.split("/")[1] : "";
                if (!e.target.files || e.target.files[0].size > 8000000) {
                  dispatch(showWarningSnackbar("The file selected is too big. The limit is 8MB"));
                  return;
                }
                if (!allowedAttachments.some((extension) => extension === fileExtension)) {
                  dispatch(
                    showWarningSnackbar(
                      "You cannot upload a file of this type. This allowed formats are: .xslx, .pdf, .png, .jpeg, .docx, .doc"
                    )
                  );
                  return;
                }
                setAttachments((prevAttachments) => {
                  if (e.target.files) {
                    const newAttachment = {
                      name: e.target.files[0].name,
                    };
                    return [...prevAttachments, newAttachment];
                  }
                  return prevAttachments;
                });
                setAttachmentObjs((prevFormAttachmentObjs: any) => [...prevFormAttachmentObjs, e.target.files ? e.target.files[0] : []]);
                if (setUnsavedChanges) setUnsavedChanges(true);
              }}
            />
            <Button variant="contained" component="span" startIcon={<Upload />}>
              Add Attachment
            </Button>
          </label>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export const TestEmailDialog = ({
  showTestEmailDialog,
  setShowTestEmailDialog,
  saveTemplateHandler,
  htmlTemplate,
}: TestEmailDialogProps) => {
  const [recipients, setRecipients] = useState("");
  const [testEmailError, setTestEmailError] = useState(false);

  const dispatch = useAppDispatch();

  const handleClose = () => setShowTestEmailDialog(false);

  const [sendTestEmail, { loading: sendTestEmailLoading }] = useLazyQuery(TESTEMAIL, {
    onCompleted: (data) => {
      dispatch(showSuccessSnackbar("Test email sent!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
    fetchPolicy: "network-only",
  });

  const submitTestEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!htmlTemplate || sendTestEmailLoading) return;

    if (recipients.split(",").reduce((emailsValid, recipient) => emailsValid && validateEmail(recipient.trim()), true)) {
      setTestEmailError(false);
      const toReceieve = recipients;
      handleClose();
      let htmlTemplateId;
      try {
        htmlTemplateId = await saveTemplateHandler();
      } catch (e) {
        dispatch(showErrorSnackbar("Please make sure all fields are filled."));
        return;
      }

      await sendTestEmail({
        variables: {
          _id: htmlTemplateId,
          from: "no-reply@rdsre.ca",
          to: toReceieve.split(",").map((recipient) => recipient.trim()),
        },
      });
    } else {
      setTestEmailError(true);
    }
  };

  return (
    <Dialog open={showTestEmailDialog} onClose={handleClose} fullWidth>
      <DialogTitle>
        Send Test Email
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Box component="form" onSubmit={submitTestEmail}>
        <DialogContent sx={{ py: 1, width: "100%" }}>
          <TextField
            variant="standard"
            label="Recipients"
            autoFocus
            required
            fullWidth
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            sx={{ mb: 1 }}
            error={testEmailError}
            helperText={testEmailError && "One or more invalid emails"}
          />
          <DialogContentText variant="caption">example@domain.com, example2@domain.com</DialogContentText>
          <DialogActions sx={{ px: 0, py: 2 }}>
            <FlexBetween sx={{ width: "100%" }}>
              <Button color="success" variant="contained" type="submit">
                Send
              </Button>
              <Button color="info" variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </FlexBetween>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export const UnsavedPromptDialog = ({ unsavedChanges, showWarningDialog, setShowWarningDialog, onLeave }: UnsavedPromptDialogProps) => {
  return (
    <ReactRouterPrompt when={unsavedChanges}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog open={isActive || showWarningDialog}>
          <DialogTitle>Are you sure you want to leave? You have unsaved changes.</DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <Button
              sx={{ mr: 2 }}
              color="success"
              variant="contained"
              onClick={
                showWarningDialog
                  ? () => {
                      setShowWarningDialog(false);
                    }
                  : onCancel
              }
            >
              Stay
            </Button>
            <Button
              sx={{ ml: 2 }}
              color="warning"
              variant="contained"
              onClick={
                showWarningDialog
                  ? () => {
                      onLeave();
                    }
                  : onConfirm
              }
            >
              Leave
            </Button>
          </Box>
        </Dialog>
      )}
    </ReactRouterPrompt>
  );
};

export const FormatHtmlDialog = ({ formatHtml, setFormatHtml }: IFormatDialogProps) => {
  const [pastedHtml, setPastedHtml] = useState<string>("");

  const dispatch = useAppDispatch();

  return (
    <Dialog open={formatHtml} onClose={() => setFormatHtml(false)} fullWidth>
      <DialogTitle>Format Html</DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <TextareaAutosize
          style={{ width: "100%" }}
          minRows={10}
          onChange={(e) => {
            setPastedHtml(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <FlexBetween sx={{ width: "100%" }}>
          <Button color="warning" variant="contained" onClick={() => setFormatHtml(false)}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              const temp = document.createElement("div");
              temp.innerHTML = pastedHtml;
              const imageNodes = temp.querySelectorAll("img");
              for (let imageNode of imageNodes) {
                if (imageNode.style.width.length === 0) imageNode.style.width = `${imageNode.width}px`;
                if (imageNode.style.height.length === 0) imageNode.style.height = `${imageNode.height}px`;
                if (imageNode.parentElement?.tagName === "TD" && imageNode.parentElement.children.length === 1) {
                  imageNode.parentElement.style.lineHeight = "0px";
                  imageNode.parentElement.style.fontSize = "0px";
                  imageNode.style.display = "block";
                }
                if (
                  imageNode.parentElement?.parentElement?.tagName === "TD" &&
                  imageNode.parentElement.parentElement.children.length === 1 &&
                  imageNode.parentElement.tagName === "A" &&
                  imageNode.parentElement.children.length === 1
                ) {
                  imageNode.parentElement.parentElement.style.lineHeight = "0px";
                  imageNode.parentElement.parentElement.style.fontSize = "0px";
                  imageNode.style.display = "block";
                }
              }
              navigator.clipboard.writeText(temp.innerHTML);
              setFormatHtml(false);
              dispatch(showSuccessSnackbar("Formatted and copied!"));
            }}
          >
            Format and Copy
          </Button>
        </FlexBetween>
      </DialogActions>
    </Dialog>
  );
};

interface IFormatDialogProps {
  formatHtml: boolean;
  setFormatHtml: (newFormat: boolean) => void;
}

interface IAttachmentDialogProps {
  attachmentsDialogOpen: boolean;
  setAttachmentsDialogOpen: (open: boolean) => void;
  formAttachments: any[];
  setFormAttachments: (newAttachments: any[]) => void;
  formAttachmentsObjs: any[];
  setFormAttachmentObjs: (newAttachmentsObjs: any[]) => void;
  setUnsavedChanges?: (changes: boolean) => void;
}

interface ImageDialogProps {
  showImageDialog: boolean;
  setShowImageDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TestEmailDialogProps {
  showTestEmailDialog: boolean;
  setShowTestEmailDialog: (newShow: boolean) => void;
  saveTemplateHandler: any;
  htmlTemplate: ITemplate | null;
}

interface IDialogProps {
  editor: Editor;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ILinkProps {
  editor: Editor;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  imgQuery: any;
}

interface IImageUploadDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUrl?: any;
  img: HTMLImageElement;
  imgQuery: any;
  type: string;
}

interface UnsavedPromptDialogProps {
  unsavedChanges: boolean;
  showWarningDialog: boolean;
  setShowWarningDialog: (newBool: boolean) => void;
  onLeave: any;
}

const GETIMAGES = gql`
  query getEmailImages($name: String!) {
    getEmailImages(name: $name) {
      name
      url
    }
  }
`;

const UPLOADIMAGE = gql`
  query uploadImage($file: Upload!, $name: String!, $destination: String!) {
    uploadImage(file: $file, name: $name, destination: $destination) {
      url
    }
  }
`;

const DELETEIMAGE = gql`
  query deleteImage($name: String!, $url: String!) {
    deleteImage(name: $name, url: $url) {
      name
      url
    }
  }
`;

const TESTEMAIL = gql`
  query sendTestEmail($_id: MongoID!, $from: String!, $to: [String]) {
    sendTestEmail(_id: $_id, from: $from, to: $to) {
      name
      subject
      _id
      createdAt
      updatedAt
    }
  }
`;
