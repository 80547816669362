import { IUser } from "../../types/user";
import { FlexBetween, SettingContainer } from "../../commonStyles";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  Typography,
  CardActions,
  IconButton,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { convertAllDates } from "../../utils/function";
import { useRef, useState } from "react";
import { IRegistrant } from "../../types/registrant";
import SearchIcon from "@mui/icons-material/Search";
import { gql, useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { setUser } from "../../features/auth/authSlice";
import { useAddUserFavouritesMutation, useRemoveUserFavouritesMutation } from "../../features/user/userHooks";
import { useNavigate } from "react-router";
import { ratings } from "../../utils/constant";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import { useUserQuery } from "../../features/auth/authHooks";

const Favourites = ({ user }: FavouritesProps) => {
  const [addFavouriteDialogOpen, setAddFavouriteDialogOpen] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<IRegistrant[]>([]);
  const [favouritesToAdd, setFavouritesToAdd] = useState<IRegistrant[]>([]);

  const [filterProject, setFilterProject] = useState<any>("Any");
  const [filterRating, setFilterRating] = useState<any>("Any");
  const [filterName, setFilterName] = useState<string>("");
  const [sortMethod, setSortMethod] = useState<string>("None");
  const [reverseSort, setReverseSort] = useState<boolean>(false);
  const [randomKey, setRandomKey] = useState<number>(0);

  const buttonRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useUserQuery(true);
  const [searchRegistrants] = useLazyQuery(SEARCHREGISTRANTS, {
    onCompleted: (data) => {
      const registrants = data.registrantMany;
      setSearchResults(registrants);
    },
    onError: (e) => console.log(e),
  });

  const [addUserFavourites] = useAddUserFavouritesMutation((data: any) => {
    const newFavourites = data.userAddFavourites.favourites;
    dispatch(setUser({ ...user, favourites: newFavourites }));
    setFavouritesToAdd([]);
    setAddFavouriteDialogOpen(false);
    dispatch(showSuccessSnackbar("Successfully Favourited."));
  });
  const [removeUserFavourites] = useRemoveUserFavouritesMutation((data: any) => {
    const newFavourites = data.userRemoveFavourites.favourites;
    dispatch(setUser({ ...user, favourites: newFavourites }));
    dispatch(showSuccessSnackbar("Successfully Removed."));
  });

  const getLastCompletedStep = (fav: IRegistrant) => {
    const currentProcess = fav.process?.find((p) => p?.currentProcess);
    let latestCompletedStepIndex = -1;
    if (currentProcess) {
      latestCompletedStepIndex = currentProcess.steps.length - 1;
      while (currentProcess.steps[latestCompletedStepIndex]?.completed === null) --latestCompletedStepIndex;
    }
    return latestCompletedStepIndex !== -1 ? currentProcess?.steps[latestCompletedStepIndex] : null;
  };
  const getNextStep = (fav: IRegistrant) => {
    const currentProcess = fav.process?.find((p) => p?.currentProcess);
    return currentProcess?.steps.find((step) => step.completed === null) || null;
  };

  const getFilteredFavourites = () => {
    if (!user?.favourites) return [];
    return user?.favourites.filter((fav) => {
      if (filterProject !== "Any" && fav.project._id !== filterProject._id) return false;
      if (filterRating !== "Any" && fav.rating !== filterRating) return false;
      if (
        filterName !== "" &&
        !(
          fav.firstName.toLowerCase().includes(filterName.toLowerCase()) ||
          fav.lastName.toLowerCase().includes(filterName.toLowerCase()) ||
          fav.email.toLowerCase().includes(filterName.toLowerCase())
        )
      )
        return false;
      return true;
    });
  };

  const getSortedFavourites = (favs: IRegistrant[]) => {
    if (!favs) return [];
    if (sortMethod === "None") return favs;
    let retval = favs.sort((e1: IRegistrant, e2: IRegistrant) => {
      if (sortMethod === "Most Recently Registered") {
        const e1Time = e1?.createdAt ? new Date(e1.createdAt).getTime() : 0;
        const e2Time = e2?.createdAt ? new Date(e2.createdAt).getTime() : 0;
        return e2Time - e1Time;
      }
      if (sortMethod === "Most Urgent Next Step") {
        const e1NextStep = getNextStep(e1);
        const e2NextStep = getNextStep(e2);
        const e1NextStepTime = e1NextStep?.dueDate ? new Date(e1NextStep.dueDate).getTime() : 0;
        const e2NextStepTime = e2NextStep?.dueDate ? new Date(e2NextStep.dueDate).getTime() : 0;
        if (e1NextStepTime && e2NextStepTime) return e1NextStepTime - e2NextStepTime;
        return e2NextStepTime - e1NextStepTime;
      }
      if (sortMethod === "Most Recently Completed Last Step") {
        const e1LastStep = getLastCompletedStep(e1);
        const e2LastStep = getLastCompletedStep(e2);
        const e1LastStepTime = e1LastStep?.completed ? new Date(e1LastStep.completed).getTime() : 0;
        const e2LastStepTime = e2LastStep?.completed ? new Date(e2LastStep.completed).getTime() : 0;
        return e2LastStepTime - e1LastStepTime;
      }
      return 1;
    });
    if (reverseSort) retval = retval.reverse();
    return retval;
  };

  const handleChange = (e: any, value: any) => {
    if (user?.favourites.find((fav) => fav._id === value._id))
      return dispatch(showErrorSnackbar("Already have that registrant favourited."));
    setFavouritesToAdd((old) => [...old, value]);
    setRandomKey(Math.floor(Math.random() * 100000 + 1));
  };

  return (
    <>
      <Dialog open={addFavouriteDialogOpen} fullWidth>
        <DialogTitle>
          <strong>Add New Favourite</strong>
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: "100%" }}>
            <Autocomplete
              key={randomKey}
              options={searchResults}
              sx={{ pt: 1 }}
              freeSolo={false}
              onInputChange={(e, value) =>
                searchRegistrants({ variables: { search: value, limit: 20, projectIds: user?.projectAccess.map((pa) => pa.project._id) } })
              }
              onChange={(e: any, value) => handleChange(e, value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option.email} [${option.project.name}]`}
            />
          </FormControl>
          {favouritesToAdd.length > 0 && (
            <Box sx={{ mt: 2, ml: 2 }}>
              Adding:
              {favouritesToAdd.map((favourite, i) => {
                return (
                  <Box key={i} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography>
                      {i + 1}. {favourite.firstName} {favourite.lastName} ({favourite.email}) - {favourite.project.name}
                    </Typography>
                    <IconButton onClick={() => setFavouritesToAdd((old) => [...old.slice(0, i), ...old.slice(i + 1, old.length)])}>
                      <ClearIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", pl: 2, pb: 1, pr: 2 }}>
            <Button variant="contained" color="info" onClick={() => setAddFavouriteDialogOpen(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => addUserFavourites({ variables: { userId: user?._id, registrantIds: favouritesToAdd.map((fav) => fav._id) } })}
            >
              Add
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <SettingContainer>
        <FlexBetween sx={{ mb: 2 }}>
          <Typography variant="h2" sx={{ display: "flex" }}>
            <Box sx={{ alignSelf: "center" }}>
              <strong>Your Registrants</strong>
            </Box>
          </Typography>
          <Button ref={buttonRef} variant="contained" color="success" onClick={() => setAddFavouriteDialogOpen(true)}>
            Add New Favourite
          </Button>
        </FlexBetween>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{ my: 1 }}
                label="Filter Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={11}>
              <Autocomplete
                key={randomKey}
                fullWidth
                options={["None", "Most Recently Registered", "Most Urgent Next Step", "Most Recently Completed Last Step"]}
                value={sortMethod}
                renderInput={(params) => {
                  return <TextField label="Sort" {...params}></TextField>;
                }}
                getOptionLabel={(option: any) => option}
                onChange={(e, value: any) => setSortMethod(value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <Button variant="contained" onClick={() => setReverseSort((old) => !old)}>
                  <FlipCameraAndroidIcon sx={{ color: reverseSort ? "red" : "white" }} />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                key={randomKey}
                options={user?.projectAccess ? ["Any", ...user?.projectAccess.map((pa) => pa.project)] : ["Any"]}
                value={filterProject}
                renderInput={(params) => {
                  return <TextField label="Project" {...params}></TextField>;
                }}
                getOptionLabel={(option: any) => (option === "Any" ? "Any" : option.name)}
                onChange={(e, value: any) => {
                  setFilterProject(value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                key={randomKey}
                options={["Any", ...ratings]}
                value={filterRating}
                renderInput={(params) => {
                  return <TextField label="Rating" {...params}></TextField>;
                }}
                getOptionLabel={(option: any) => option}
                onChange={(e, value: any) => setFilterRating(value)}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          {user?.favourites?.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 2 }}>
              <em>No Registrants Favourited</em>
            </Box>
          )}
          {getSortedFavourites(getFilteredFavourites())?.map((favouriteReg, index) => {
            const latestStepCompleted = getLastCompletedStep(favouriteReg);
            const upcomingStep = getNextStep(favouriteReg);

            let dateColour = "black";
            if (upcomingStep?.dueDate) {
              const upcomingStepDate = new Date(upcomingStep.dueDate).getTime();
              if (upcomingStepDate - Date.now() < 2 * 1000 * 3600 * 24) dateColour = "green";
              if (upcomingStepDate - Date.now() < 1000 * 3600 * 24) dateColour = "#cbe635";
              if (upcomingStepDate - Date.now() < 0) dateColour = "red";
            }
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100%",
                    boxShadow: `0px 0px 3px 3px ${favouriteReg.project.portal.primaryColor || "rgba(0, 0, 0)"}`,
                    backgroundColor: favouriteReg.project.portal.primaryColor
                      ? favouriteReg.project.portal.primaryColor + "11"
                      : "rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <CardContent
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/dashboard/project/${favouriteReg.project._id}/registrant/${favouriteReg._id}`)}
                  >
                    <Typography variant="h5">
                      {favouriteReg.firstName} {favouriteReg.lastName}
                    </Typography>
                    <Box sx={{ mb: 1, mt: 1 }}>
                      {favouriteReg.email && (
                        <Typography color="text.secondary" sx={{ fontSize: "15px" }}>
                          {favouriteReg.email}
                        </Typography>
                      )}
                      {favouriteReg.primaryPhone && (
                        <Typography color="text.secondary" sx={{ fontSize: "15px" }}>
                          {favouriteReg.primaryPhone}
                        </Typography>
                      )}
                      {favouriteReg.createdAt && (
                        <Typography color="text.secondary" sx={{ fontSize: "15px" }}>
                          {convertAllDates(favouriteReg.createdAt, "PP")}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Typography variant="body2">
                        Project: <strong>{favouriteReg.project.name}</strong>
                      </Typography>
                      <Typography variant="body2">
                        Rating: <strong>{favouriteReg.rating}</strong>
                      </Typography>
                      <Typography variant="body2">
                        Last Step Completed:{" "}
                        <strong>
                          {latestStepCompleted
                            ? `${latestStepCompleted.name} [${convertAllDates(latestStepCompleted.dueDate, "PP")}]`
                            : "N/A"}
                        </strong>
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="body2" sx={{ mr: 0.5 }}>
                          Next Step: <strong>{upcomingStep ? `${upcomingStep.name}` : "N/A"}</strong>
                        </Typography>
                        <Typography variant="body2" sx={{ color: dateColour }}>
                          {upcomingStep && `[${convertAllDates(upcomingStep.dueDate, "PP")}]`}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "lightgrey",
                      p: 0,
                      m: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => removeUserFavourites({ variables: { userId: user?._id, registrantIds: [favouriteReg._id] } })}
                    >
                      <ClearIcon sx={{ color: "black" }} />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </SettingContainer>
    </>
  );
};

const SEARCHREGISTRANTS = gql`
  query searchRegistrants($search: String, $limit: Int, $projectIds: [MongoID!]) {
    registrantMany(filter: { search: $search, projectIds: $projectIds }, limit: $limit) {
      _id
      firstName
      lastName
      email
      primaryPhone
      statuses {
        name
      }
      project {
        _id
        name
        portal {
          primaryColor
        }
      }
      createdAt
    }
  }
`;

interface FavouritesProps {
  user: IUser | null;
}

export default Favourites;
