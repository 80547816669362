import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface NewNotification {
  message: string;
  duration: number;
  colour: string;
  type: string;
  link: string;
}

interface Notification {
  id: number;
  message: string;
  duration: number;
  colour: string;
  type: string;
  link: string;
}

interface NotificationsState {
  notifications: Notification[];
  curId: number;
}

const initialState: NotificationsState = {
  notifications: [],
  curId: 0,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NewNotification>) => {
      state.notifications.push({
        id: state.curId,
        message: action.payload.message,
        duration: action.payload.duration,
        colour: action.payload.colour,
        type: action.payload.type,
        link: action.payload.link,
      });
      ++state.curId;
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      const i = action.payload;
      state.notifications = state.notifications.filter((notification) => notification.id !== i);
    },
    removeAllNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const { addNotification, removeNotification, removeAllNotifications } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications.notifications;

export default notificationsSlice.reducer;
