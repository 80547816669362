import { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import BasicTabs from "../common/BasicTabs";
import { selectUser } from "../../features/auth/authSlice";
import User from "./User";
import Favourites from "./Favourites";
import Texts from "./Texts";
import Notes from "./Notes";
import Reporting from "./reporting/Reporting";
import { SettingContainer } from "../../commonStyles";

const UserProfile = () => {
  const user = useSelector(selectUser);
  const [value, setValue] = useState(0);
  const tabs = [
    {
      label: "User",
      component: <User user={user} />,
    },
    {
      label: "Registrants",
      component: <Favourites user={user} />,
    },
    {
      label: "SMS Templates",
      component: <Texts user={user} />,
    },
    {
      label: "Note Templates",
      component: <Notes user={user} />,
    },
    {
      label: "Reporting",
      component: <SettingContainer>
        <Reporting user={user!} filter={true}  />
      </SettingContainer>,
    },
  ];

  return (
    <Box>
      <BasicTabs color="#00142a" tabs={tabs} value={value} setValue={setValue} />
    </Box>
  );
};

export default UserProfile;
