import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
} from "@mui/material";
import { IAppointment } from "../../../types/project";
import { IUser } from "../../../types/user";
import { filterUsersProjectAccess, useUsersCountQuery, useUsersQuery, getSalesRepObj } from "../../../features/user/userHooks";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectUsers } from "../../../features/user/userSlice";
import { useMutation, gql } from "@apollo/client";
import AppointmentCalendar from "./AppointmentCalendar";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { ISchedule } from "../../../types/schedule";

const EditAnAppointmentDialog = (props: EditAnAppointmentDialogProps) => {
  /* Redux */
  const storeDispatch = useAppDispatch();
  const curUser = useSelector(selectUser);

  let listOfManagersAndSales = useAppSelector(selectUsers).slice();
  const statusOptions = ["Pending", "Confirmed", "Cancelled", "No Show", "Attended"];

  let users: string[] = [];
  if (listOfManagersAndSales) {
    listOfManagersAndSales = filterUsersProjectAccess(listOfManagersAndSales, props.appointment?.project);
    users = listOfManagersAndSales.map((user: IUser) => `${user.firstName} ${user.lastName} (${user.type})`);
  }

  /* States */
  const [firstName, setFirstName] = useState<string | undefined>(props.appointment?.purchaserInfo.firstName);
  const [lastName, setLastName] = useState<string | undefined>(props.appointment?.purchaserInfo.lastName);
  const [email, setEmail] = useState<string | undefined>(props.appointment?.purchaserInfo.email);
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState<string | undefined>(props.appointment?.purchaserInfo.primaryPhone);
  const [numberOfGuests, setNumberOfGuests] = useState<string | undefined>(props.appointment?.purchaserInfo.numberOfGuests.toString());
  const [location, setLocation] = useState<string | undefined>(props.appointment?.location);
  const [date, setDate] = useState<Date | null | undefined>(
    props.appointment?.date ? new Date(props.appointment.date) : new Date(Date.now())
  );
  const [formUser, setFormUser] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [salesRep, setSalesRep] = useState<IUser | undefined>();
  const [virtual, setVirtual] = useState<boolean>(props.appointment?.virtual || false);
  const [formStatus, setFormStatus] = useState<string | undefined>(
    props?.appointment?.status === "No_Show" ? "No Show" : props.appointment?.status
  );
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [schedule, setSchedule] = useState<ISchedule | null>(null);

  //let locations: string[] = [];

  /* Misc Functions */

  /* Queries/Mutations */
  const { data: numManagersAndSales } = useUsersCountQuery("Manager", "Sales");
  useUsersQuery(
    1,
    numManagersAndSales?.userCount,
    (data) => {
      const userForThisAppointment = data.userPagination.items.find((user: IUser) => user._id === props.appointment?.salesRep?._id);
      if (!userForThisAppointment) {
        return;
      }
      const newFormUser = `${userForThisAppointment.firstName} ${userForThisAppointment.lastName} (${userForThisAppointment.type})`;
      setFormUser(newFormUser);
      setSalesRep(userForThisAppointment);
    },
    "Manager",
    "Sales"
  );

  const [saveAppointment] = useMutation(SAVEAPPOINTMENT, {
    variables: {
      appointmentId: props.appointment?._id,
      user: curUser?._id,
      purchaserInfo: {
        firstName,
        lastName,
        email,
        primaryPhone: primaryPhoneNumber,
        numberOfGuests: parseInt(numberOfGuests || "0"),
      },
      location: virtual ? "Virtual" : location,
      date: date?.toISOString(),
      salesRepId: getSalesRepObj(listOfManagersAndSales, formUser)?._id,
      status: formStatus === "No Show" ? "No_Show" : formStatus,
      virtual,
    },
    onCompleted: () => {
      updateStatusAndSendEmail();
      setDisabled(false);
      props.setOpen(false);
      storeDispatch(showSuccessSnackbar("Appointment successfully updated!"));
    },
  });

  const [deleteAppointment] = useMutation(DELETEAPPOINTMENT, {
    variables: {
      appointmentId: props.appointment?._id,
    },
    onCompleted: () => {
      setDisabled(false);
      props.setOpen(false);
      storeDispatch(showSuccessSnackbar("Appointment successfully deleted!"));
    },
  });

  const [updateStatusAndSendEmail] = useMutation(UPDATESTATUSANDSENDEMAIL, {
    variables: {
      appointmentId: props.appointment?._id,
      field: formStatus?.toLowerCase(),
      newBool: props.appointment?.status.toLowerCase() !== formStatus ? true : false,
      sendMail:
        props.appointment?.status.toLowerCase() !== formStatus && (formStatus === "Confirmed" || formStatus === "Cancelled") ? true : false,
    },
  });

  return (
    <Backdrop sx={{ backgroundColor: "rgb(100, 100, 100, 0)", zIndex: 1 }} open={props.open} onClick={() => props.setOpen(false)}>
      <Dialog sx={{ zIndex: 2 }} open={props.open}>
        <DialogTitle onClick={(e) => e.stopPropagation()}>
          <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" } }}>
            <Typography sx={{ fontSize: 20 }}>
              <strong>Edit Appointment</strong>
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent onClick={(e) => e.stopPropagation()} dividers={true} sx={{ pt: 0 }}>
          <DialogActions>
            <form>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={virtual}
                      disabled={disabled}
                      onChange={(e) => {
                        setVirtual(e.target.checked);
                      }}
                    />
                  }
                  label="Virtual"
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Accordion sx={{ border: "1px solid black", borderRadius: "5px" }}>
                    <AccordionSummary sx={{ borderBottom: "1px solid black" }} expandIcon={<ExpandMore />}>
                      <strong>Basic Info</strong>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required={!firstName}
                            label="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled={disabled}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required={!lastName}
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled={disabled}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required={!email}
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={disabled}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required={!primaryPhoneNumber}
                            label="Primary Phone Number"
                            value={primaryPhoneNumber}
                            onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
                            disabled={disabled}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required={!numberOfGuests}
                            type="number"
                            label="Number of Additional Guests"
                            value={numberOfGuests}
                            onChange={(e) => setNumberOfGuests(e.target.value)}
                            disabled={disabled}
                            inputProps={{ min: 0, max: 999 }}
                          ></TextField>
                        </Grid>
                        {virtual && <Box sx={{ width: "100%" }} />}
                        {!virtual && (
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              required={!location}
                              label="Location"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              disabled={disabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={statusOptions}
                    onChange={(e) => setFormStatus((e.target as HTMLInputElement).innerText)}
                    renderInput={(params) => <TextField {...params} required={!formStatus} disabled={disabled} label="Status"></TextField>}
                    value={formStatus}
                    fullWidth
                    disabled={disabled}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ mb: 1 }}
                    options={users}
                    onChange={(e) => {
                      setFormUser((e.target as HTMLInputElement).innerText);
                      setSalesRep(getSalesRepObj(listOfManagersAndSales, (e.target as HTMLInputElement).innerText));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={props.appointment?.salesRep ? !formUser : false}
                        disabled={disabled}
                        label="Specialist"
                      ></TextField>
                    )}
                    value={formUser}
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                {formUser && props.open && (
                  <Grid item xs={12}>
                    <AppointmentCalendar
                      user={salesRep}
                      setDate={setDate}
                      setFormError={setDisabled}
                      appointment={props.appointment}
                      schedules={schedules}
                      schedule={schedule}
                      setSchedule={setSchedule}
                      project={null}
                    />
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
                  type="submit"
                  variant="contained"
                  disabled={disabled}
                  color="success"
                  onClick={() => {
                    if (props.appointment?.salesRep && !formUser) return;
                    if (!formStatus || !date) return;
                    if (
                      !firstName ||
                      !lastName ||
                      !email ||
                      !primaryPhoneNumber ||
                      !numberOfGuests ||
                      !location ||
                      !formStatus ||
                      !salesRep ||
                      !date
                    )
                      return storeDispatch(showErrorSnackbar("Missing fields."));
                    setDisabled(true);
                    saveAppointment();
                  }}
                >
                  Save
                </Button>
                <Button
                  sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
                  variant="contained"
                  color="error"
                  disabled={disabled}
                  onClick={() => {
                    setDisabled(true);
                    deleteAppointment();
                  }}
                >
                  Delete
                </Button>
              </Box>
            </form>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
};

type EditAnAppointmentDialogProps = {
  open: boolean;
  setOpen: (newOpen: boolean) => void;
  appointment: IAppointment | undefined;
};

const SAVEAPPOINTMENT = gql`
  mutation saveAppointment(
    $appointmentId: MongoID!
    $user: MongoID
    $purchaserInfo: UpdateByIdAppointmentPurchaserInfoInput
    $location: String
    $date: Date
    $salesRepId: MongoID
    $status: EnumAppointmentStatus
    $virtual: Boolean
  ) {
    appointmentUpdateById(
      _id: $appointmentId
      record: {
        user: $user
        purchaserInfo: $purchaserInfo
        location: $location
        date: $date
        salesRep: $salesRepId
        virtual: $virtual
        status: $status
      }
    ) {
      recordId
    }
  }
`;

const DELETEAPPOINTMENT = gql`
  mutation deleteAppointment($appointmentId: MongoID!) {
    appointmentRemoveById(_id: $appointmentId) {
      recordId
    }
  }
`;

const UPDATESTATUSANDSENDEMAIL = gql`
  mutation updateStatusAndSendEmail($appointmentId: MongoID!, $field: String!, $newBool: Boolean!, $sendMail: Boolean) {
    updateBoolean(id: $appointmentId, field: $field, value: $newBool, sendMail: $sendMail) {
      date
    }
  }
`;

export default EditAnAppointmentDialog;
