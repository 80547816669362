import React from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../features/auth/authSlice'
import Reporting from '../../userProfile/reporting/Reporting'
import { selectProject } from '../../../features/project/projectSlice'
import { SettingContainer } from '../../../commonStyles'

const UserReporting = () => {
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  return (
    <SettingContainer>
      <Reporting user={user!} global={true} selectedProject={project!} filter={true}  />
    </SettingContainer>
  )
}

export default UserReporting