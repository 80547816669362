import React from "react";
import { List, ListItem, ListItemText, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

import { Container, FlexBetween } from "../../../commonStyles";
import { useNavigate } from "react-router";
import { useListsQuery } from "../../../features/projectSetting/projectSettingHooks";
import { selectList, IList } from "../../../features/projectSetting/projectSettingSlice";
import { fixedList } from "../../../utils/constant";
import { selectUser } from "../../../features/auth/authSlice";

const Lists = (props: ChildProps) => {
  const { projectId, custom } = props;
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  useListsQuery(projectId!, user!);

  let lists: any[] = useSelector(selectList);

  if (!custom) {
    lists = fixedList;
  }

  return (
    <Container sx={{ height: "100%" }}>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween>
          <strong>{custom ? "Custom Lists" : "Prebuilt Lists"}</strong>
          {custom ? <AddIcon sx={{ cursor: "pointer" }} onClick={() => navigate(`/dashboard/project/${projectId}/create-list`)} /> : null}
        </FlexBetween>
      </Typography>
      {lists.length ? (
        <List>
          {lists.map((list: IList, index: number) => {
            return (
              <ListItem
                key={index}
                sx={{ p: 0 }}
                secondaryAction={
                  custom ? (
                    <IconButton
                      onClick={() => navigate(`/dashboard/project/${projectId}/edit-list/${list._id}`)}
                      sx={{ p: 0 }}
                      edge="end"
                      aria-label="delete"
                    >
                      <EditIcon sx={{ color: "info.main" }} />
                    </IconButton>
                  ) : null
                }
              >
                <ListItemText>
                  <strong
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/dashboard/project/${projectId}/list/${list._id}?row=15&page=1`)}
                  >
                    {list.name}
                  </strong>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <div>
          There are currently no custom lists. Please click on <strong>+</strong> to add one.
        </div>
      )}
    </Container>
  );
};

interface ChildProps {
  projectId?: string;
  custom: boolean;
}

export default Lists;
