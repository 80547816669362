import { useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Typography, TextField, Button, FormControl, InputLabel, MenuItem, Select, Grid, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";

import { FlexBetween } from "../../../commonStyles";
import RegistrantSection from "./RegistrantSection";
import QuestionSection from "./QuestionSection";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { addList, updateList } from "../../../features/projectSetting/projectSettingSlice";
import { IAnswer } from "../../../types/registrant";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";

const CreateList = () => {
  const { projectid, listid } = useParams();
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [name, setName] = useState<string>("");
  const [view, setView] = useState<string>("");

  // Registrant States
  const [registrantStart, setRegistrantStart] = useState<Date | null>(null);
  const [registrantEnd, setRegistrantEnd] = useState<Date | null>(null);
  const [beBackStart, setBeBackStart] = useState<Date | null>(null);
  const [beBackEnd, setBeBackEnd] = useState<Date | null>(null);
  const [email, setEmail] = useState<string>("");
  const [primaryPhone, setPrimaryPhone] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [rating, setRating] = useState<string[]>([]);
  const [ethnicity, setEthnicity] = useState<string[]>([]);
  const [source, setSource] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [realtorType, setRealtorType] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [connected, setConnected] = useState<string>("");
  const [subscribed, setSubscribed] = useState<string>("");
  //  Question States
  const [questions, setQuestions] = useState<IQuestionAnswer[]>([]);

  let navigate = useNavigate();

  const [createList] = useMutation(CREATELIST, {
    onCompleted: (data) => {
      storeDispatch(addList(data.listCreateOne.record));
      navigate(`/dashboard/project/${projectid}`);
      storeDispatch(showSuccessSnackbar("Custom List Created!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [removeList] = useMutation(DELETELIST, {
    onCompleted: (data) => {
      navigate(`/dashboard/project/${projectid}`);
      storeDispatch(showSuccessSnackbar("List Removed!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [updateListId] = useMutation(UPDATELIST, {
    onCompleted: (data) => {
      storeDispatch(updateList(data.listUpdateById.record));
      navigate(`/dashboard/project/${projectid}`);
      storeDispatch(showSuccessSnackbar("List Updated!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const { loading } = useQuery(GETLIST, {
    skip: !listid,
    variables: { _id: listid },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.listById.connected === true) {
        setConnected("Yes");
      } else if (data.listById.connected === false) {
        setConnected("No");
      } else {
        setConnected("");
      }
      if (data.listById.subscribed === true) {
        setSubscribed("Yes");
      } else if (data.listById.subscribed === false) {
        setSubscribed("No");
      } else {
        setSubscribed("");
      }
      setName(data.listById.name);
      setView(data.listById.view);
      setRegistrantStart(data.listById.registrantStart);
      setRegistrantEnd(data.listById.registrantEnd);
      setBeBackStart(data.listById.beBackStart);
      setBeBackEnd(data.listById.beBackEnd);
      setEmail(data.listById.email);
      setPrimaryPhone(data.listById.primaryPhone);
      setPostalCode(data.listById.postalCode);
      setRating(data.listById.ratings);
      setEthnicity(data.listById.ethnicities);
      setSource(data.listById.sources);
      setStatus(data.listById.statuses);
      setRealtorType(data.listById.realtorTypes);
      setQuestions(data.listById.questions);
      setTags(data.listById.tags);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleDelete = () => {
    if (listid) {
      removeList({
        variables: {
          _id: listid,
        },
      });
    }
  };

  const handleSave = () => {
    if (!name) return storeDispatch(showErrorSnackbar("List Name is missing"));
    if (!view) return storeDispatch(showErrorSnackbar("View is missing"));

    let questionIds: IAnswer[] = [];

    if (questions.length) {
      questionIds = questions.map((question: any) => {
        return {
          ...question,
          questionId: question.questionId._id ? question.questionId._id : question.questionId,
        };
      });
    }

    let record: any = {
      name,
      view,
      project: projectid,
      registrantStart,
      registrantEnd,
      beBackStart,
      beBackEnd,
      email,
      primaryPhone,
      postalCode,
      ratings: rating,
      ethnicities: ethnicity,
      sources: source,
      statuses: status,
      realtorTypes: realtorType,
      questions: questionIds,
      tags: tags,
    };

    if (connected === "Yes") {
      record.connected = true;
    }
    if (connected === "No") {
      record.connected = false;
    }

    if (subscribed === "Yes") {
      record.subscribed = true;
    }
    if (subscribed === "No") {
      record.subscribed = false;
    }

    if (listid) {
      updateListId({
        variables: {
          _id: listid,
          record: record,
        },
      });
    } else {
      createList({
        variables: {
          record: record,
        },
      });
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween>
          <div>
            <ArrowBackIcon
              onClick={() => navigate(`/dashboard/project/${projectid}`)}
              sx={{ mr: 1, cursor: "pointer", color: "primary.main" }}
              fontSize="small"
            />
            <strong>{listid ? "Edit List" : "Create New List"}</strong>
          </div>
          <Box>
            {listid ? (
              <Button sx={{ mr: 1 }} onClick={() => handleDelete()} color="error" variant="contained">
                Delete
              </Button>
            ) : null}
            <Button onClick={() => handleSave()} color="success" variant="contained">
              Save
            </Button>
          </Box>
        </FlexBetween>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            name="name"
            label="List Name"
            type="text"
            variant="outlined"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            sx={{ mb: 2, backgroundColor: "#fff" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl required fullWidth>
            <InputLabel id="demo-simple-select-label">Viewable</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              required
              name={"view"}
              label={"Viewable"}
              value={view}
              fullWidth
              onChange={(e) => setView(e.target.value)}
              sx={{ backgroundColor: "#fff" }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {user?.type === "Admin" || user?.type === "Manager" ? <MenuItem value={"Admin"}>Admin</MenuItem> : null}
              {user?.type === "Sales" || user?.type === "Manager" ? <MenuItem value={"Sales"}>Sales</MenuItem> : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <RegistrantSection
        projectId={projectid!}
        registrantStart={registrantStart}
        setRegistrantStart={setRegistrantStart}
        registrantEnd={registrantEnd}
        setRegistrantEnd={setRegistrantEnd}
        beBackStart={beBackStart}
        setBeBackStart={setBeBackStart}
        beBackEnd={beBackEnd}
        setBeBackEnd={setBeBackEnd}
        email={email}
        setEmail={setEmail}
        primaryPhone={primaryPhone}
        setPrimaryPhone={setPrimaryPhone}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        ethnicity={ethnicity}
        setEthnicity={setEthnicity}
        rating={rating}
        setRating={setRating}
        source={source}
        setSource={setSource}
        status={status}
        setStatus={setStatus}
        tags={tags}
        setTags={setTags}
        realtorType={realtorType}
        setRealtorType={setRealtorType}
        connected={connected}
        setConnected={setConnected}
        subscribed={subscribed}
        setSubscribed={setSubscribed}
        loading={loading}
      />
      <QuestionSection questions={questions} setQuestions={setQuestions} loading={loading} />
    </div>
  );
};

interface IQuestionAnswer {
  answer: string[];
  questionId: string;
}

const DELETELIST = gql`
  mutation listRemoveById($_id: MongoID!) {
    listRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const UPDATELIST = gql`
  mutation listUpdateById($_id: MongoID!, $record: UpdateByIdListInput!) {
    listUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
      }
    }
  }
`;

const CREATELIST = gql`
  mutation listCreateOne($record: CreateOneListInput!) {
    listCreateOne(record: $record) {
      record {
        _id
        name
      }
    }
  }
`;

const GETLIST = gql`
  query listById($_id: MongoID!) {
    listById(_id: $_id) {
      _id
      name
      view
      registrantStart
      registrantEnd
      beBackStart
      beBackEnd
      ratings
      sources
      statuses
      realtorTypes
      name
      email
      primaryPhone
      postalCode
      ethnicities
      tags
      connected
      subscribed
      project {
        _id
      }
      questions {
        answer
        questionId {
          _id
          name
          question
        }
      }
    }
  }
`;

export default CreateList;
