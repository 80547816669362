import React from "react";

import { useNavigate } from "react-router";
import Templates from "../settings/emails/Templates";
import { SettingContainer } from "../../commonStyles";

const EmailTemplates = () => {
  const navigate = useNavigate();

  const handleBack = (back: boolean) => {
    if (!back) {
      navigate(-1);
    }
  };

  return (
    <SettingContainer>
      <Templates viewHtmlTemplates={true} setViewHtmlTemplates={handleBack} />
    </SettingContainer>
  );
};

export default EmailTemplates;
