/* Dependency Imports */
import React, { useState } from "react";
import { useTransition, animated } from "react-spring";
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import { AppBar, Box, Button, Paper, TextField, Toolbar, Typography, styled } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useParams, useSearchParams, Navigate } from "react-router-dom";

/* Project Imports */
import SignupForm from "./SignupForm";
import { formatPhoneNumber, validateEmail, validatePhoneNumber } from "../../utils/function";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { IProject } from "../../types/project";
import CheckIn from "./CheckIn";
import { IRegistrant } from "../../types/registrant";

const RegistrantSignup = () => {
  const dispatch = useAppDispatch();

  /* States */
  const [contact, setContact] = useState("");
  const [contactSubmitted, setContactSubmitted] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [registerQuestions, setRegisterQuestions] = useState<any>();
  const [registrant, setRegistrant] = useState<IRegistrant | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [project, setProject] = useState<IProject | null>(null);
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  /* Hooks */
  const { projectid } = useParams();

  const transitions = useTransition(!showForm, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  /* Queries/Mutations */
  const { loading, data } = useQuery(GETPROJECT, {
    variables: { id: projectid },
    onCompleted: (data) => {
      setProject(data.projectOne);
    },
  });

  const [getRegistrant] = useLazyQuery(GETREGISTRANT, {
    onCompleted: (data) => {
      setShowForm(true);
      setRegisterQuestions(data.verifyRegistrant.settings);
      setRegistrant(data.verifyRegistrant.registrant);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  /* Functions */
  const urlName = (string: string) => string.replace(/\s+/g, "-").toLowerCase();

  const handleContactSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateEmail(contact)) {
      setContactError(false);
      getRegistrant({ variables: { email: contact, phone: "", project: projectid } });
      setContactSubmitted(true);
    } else if (validatePhoneNumber(contact)) {
      setContactError(false);
      getRegistrant({ variables: { email: "", phone: contact, project: projectid } });
      setContactSubmitted(true);
    } else {
      setContactError(true);
      dispatch(showErrorSnackbar("Please enter a valid email address or phone number."));
    }
  };

  const handleFormSubmit = (props: any, registerFunction: (onCompleteFunction: () => void) => void) => {
    getRegistrant({
      ...props,
      onCompleted: (data) => {
        if (data.verifyRegistrant) {
          setFormSubmitted(true);
          registerFunction(() => setShowForm(false));
        } else {
          setContact(props.variables.email);
          setRegisterQuestions(null);
        }
      },
    });
  };

  return loading ? (
    <div></div>
  ) : data.projectOne ? (
    <Box
      sx={{
        background: `rgba(0,0,0,0.5)url(${project ? project.imageGetUrl : "https://rdsimages.s3.ca-central-1.amazonaws.com/cielo.jpg"})`,
        backgroundBlendMode: "darken",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        position: "absolute",
        overflow: "auto",
      }}
    >
      <AppBar position="relative">
        <Toolbar sx={{ display: "flex" }}>
          <img src={require("../../assets/rds-logo-transparent.png")} alt="logo" width="50px" />
          <Typography variant="h5" sx={{ ml: 3 }}>
            {data.projectOne.name}
          </Typography>
        </Toolbar>
      </AppBar>
      {transitions(({ opacity }, item) =>
        item ? (
          <AnimatedPaper style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }) }}>
            {formSubmitted ? ( 
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5">Thank you for visiting!</Typography>
                <CheckCircleOutlineIcon sx={{ fontSize: '5rem' }} color="success" /> 
                {project?.portal && project?.portal.active ? (
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      href={`https://portal.rdsre.ca/${urlName(data.projectOne.developerName)}/${urlName(data.projectOne.name)}/public`}
                    >
                      View {project?.name!} Portal
                    </Button>
                  </Box>
                ) : null}
              </Box>
            ) : (
              <Box component="form" onSubmit={handleContactSubmit} sx={{ width: "100%", display: "inline-block" }}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  label="Email or Phone Number"
                  variant="outlined"
                  type="text"
                  name="email or phone"
                  autoComplete="email"
                  value={contact}
                  error={contactError}
                  disabled={contactSubmitted}
                  onChange={(e) => setContact(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" type="submit" disabled={contactSubmitted}>
                  {contactSubmitted ? "Loading..." : "Next"}
                </Button>
              </Box>
            )}
          </AnimatedPaper>
        ) : (
          <AnimatedPaper style={{ opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }) }}>
            {registerQuestions ? (
              <>
                <SignupForm
                  questions={(registerQuestions as any).questions || []}
                  formEmail={validateEmail(contact) ? contact : ""}
                  formPhone={validatePhoneNumber(contact) ? formatPhoneNumber(contact) : ""}
                  projectid={projectid}
                  handleFormSubmit={handleFormSubmit}
                />
              </>
            ) : (
              <CheckIn
                type={type!}
                projectId={projectid!}
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
                registrant={registrant!}
                setShowForm={setShowForm}
              />
            )}
          </AnimatedPaper>
        )
      )}
    </Box>
  ) : (
    <Navigate to={"/"} />
  );
};

/* Styled Components */
const AnimatedPaper = styled(animated(Paper))`
  position: absolute;
  inset: auto 0;
  margin: 24px auto;
  width: 80%;
  max-width: 750px;
  padding: 24px;
  overflow: auto;
`;

/* Types */

/* GQL */
const GETPROJECT = gql`
  query projectOne($id: MongoID!) {
    projectOne(filter: { _id: $id }) {
      name
      developerName
      imageGetUrl
      portal {
        active
      }
    }
  }
`;

const GETREGISTRANT = gql`
  query verifyRegistrant($email: String!, $phone: String!, $project: MongoID!) {
    verifyRegistrant(email: $email, phone: $phone, project: $project) {
      registrant {
        _id
        realtorType
        primaryPhone
        email
      }
      settings {
        questions {
          name
          question
          type
          subQuestion
          registrantType
          display
          choices {
            choice
            followUp {
              _id
              name
              question
              type
              subQuestion
              display
            }
            _id
          }
          _id
        }
      }
    }
  }
`;

export default RegistrantSignup;
