import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IEmailTemplate } from "../../types/process";
import { IProject } from "../../types/project";

const initialState: IEmailTemplateState = {
  templates: [],
  count: 0,
};

export const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload.items;
      state.count = action.payload.count;
    },
    addTemplate: (state, action) => {
      state.templates.push(action.payload);
    },
    updateTemplate: (state, action) => {
      state.templates[state.templates.findIndex((template) => template._id === action.payload._id)] = action.payload;
    },
  },
});

export const { setTemplates, addTemplate, updateTemplate } = emailTemplateSlice.actions;

export const selectTemplates = (state: RootState) => state.emailTemplate.templates;
export const selectTemplateCount = (state: RootState) => state.emailTemplate.count;

interface IEmailTemplateState {
  templates: IEmailTemplate[];
  count: number;
}

export interface ITemplates {
  _id: string;
  name: string;
  subject: string;
  html: string;
  htmlTemplateJson?: string;
  step: boolean;
  project: IProject;
  attachments: any[];
  createdAt: Date;
  updatedAt: Date;
}

export default emailTemplateSlice.reducer;
