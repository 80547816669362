import React from "react";
import { Box, Divider } from "@mui/material";
import { differenceInDays } from "date-fns";
import { IStep } from "../../../types/process";
import { FlexBetween } from "../../../commonStyles";
import { convertAllDates } from "../../../utils/function";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProject } from "../../../features/project/projectSlice";

const ActionList = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { step } = props;

  const calculateDate = (date: Date | null) => {
    let days = differenceInDays(new Date(), new Date(date!));

    if (!days) {
      return <Box sx={{ color: "#ff7601" }}>Due Today</Box>;
    } else if (days === 1) {
      return <Box sx={{ color: "#c17f07" }}>Due Yesterday</Box>;
    } else if (days > 1 && days < 8) {
      return <Box sx={{ color: "#9f4b1a" }}>Due {days} days ago</Box>;
    } else return <Box sx={{ color: "red" }}>Due {convertAllDates(date, "PP")}</Box>;
  };

  return (
    <Box sx={{ backgroundColor: "#e9e9e9", p: 2, mt: 2, borderRadius: "8px" }}>
      <Link style={{ textDecoration: "none", color: "#000" }} to={`/dashboard/project/${project?._id}/registrant/${step.registrant._id}`}>
        <strong>{step.name}</strong>
      </Link>
      <Divider sx={{ mt: 1 }} />
      <FlexBetween sx={{ fontSize: "0.75rem", mt: 1 }}>
        <Box>Registrant: {step.registrant.fullName}</Box>
        {calculateDate(step.dueDate)}
      </FlexBetween>
    </Box>
  );
};

interface ChildProps {
  index: number;
  step: IStep;
}

export default ActionList;
