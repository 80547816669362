/* Dependency Imports */
import { useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import storage from 'redux-persist/lib/storage';
import { ApolloClient, InMemoryCache, ApolloProvider, split, ApolloLink } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "graphql-ws";
/* Project Imports */
import env from "../../config/env";
import { useAppDispatch } from "../../app/hooks";
import { setUser } from "./authSlice";
import { useSelector } from "react-redux";
import { selectUser } from "./authSlice";
import UserAuth from "../../components/layout/UserAuth";

const RequireAuth = () => {
  /* Redux */
  const user = useSelector(selectUser);
  /* Router */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uploadLink = createUploadLink({
    uri: `${env.basePath}/graphql`,
    credentials: "include",
    headers: { "Apollo-Require-Preflight": "true" },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${env.socketPath}/graphql`,
    })
  );

  const link = split(
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    uploadLink
  );

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        // Here you may display a message to indicate graphql error
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (extensions.code === 'UNAUTHENTICATED') {
          localStorage.clear();
          storage.removeItem('persist:root');
          client.cache.reset();
          client.clearStore().then(() => {
            dispatch(setUser(null));
            navigate('/');
          });
        }
        // props.history.push({
        //   pathname: '/error',
        //   state: {
        //     error: message,
        //     locations: locations,
        //     path: path,
        //     extensions: extensions?.code
        //   }
        // })
      });
      return;
    }
    if (networkError) {
      // Here you may display a message to indicate network error
      console.log(`[Network error]: ${networkError}`);
      if (networkError && !user) {
        navigate('/');
      }
      if (networkError.message.includes('401')) {
        dispatch(setUser(null));
        navigate('/');
      }
      // props.history.push({
      //   pathname: "/error",
      //   state: {
      //     error: networkError,
      //   },
      // });
    }
    return;
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([errorLink as any, link]),
        cache: new InMemoryCache({
          addTypename: false,
        }),
        credentials: "include",
      }),
    [errorLink, link]
  );

  return (
    <ApolloProvider client={client}>
      <UserAuth>
      <Outlet />
      </UserAuth>
    </ApolloProvider>
  )
};

interface Definition {
  kind: string;
  operation?: string;
}

export default RequireAuth;
