import React from "react";
import { Box, Divider } from "@mui/material";
import { FlexBetween } from "../../../commonStyles";
import { camelToNormal } from "../../../utils/function";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProject } from "../../../features/project/projectSlice";
import { IProcess } from "../../../types/process";

const ProcessList = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const { process } = props;

  return (
    <Box sx={{ backgroundColor: "#e9e9e9", p: 2, mt: 2, borderRadius: "8px" }}>
      <Link
        style={{ textDecoration: "none", color: "#000" }}
        to={`/dashboard/project/${project?._id}/registrant/${process.registrant._id}`}
      >
        <strong>{process.sourceType === "onsite" ? "Onsite" : "Online"} Process</strong>
      </Link>
      <Divider sx={{ mt: 1 }} />
      <FlexBetween sx={{ fontSize: "0.75rem", mt: 1 }}>
        <Box>Registrant: {process.registrant.fullName}</Box>
        <Box>Process Type: {camelToNormal(process.processType)}</Box>
      </FlexBetween>
    </Box>
  );
};

interface ChildProps {
  process: IProcess;
  index: number;
}

export default ProcessList;
