import { useRef, MutableRefObject, useEffect } from "react";
import { useDrag } from "@use-gesture/react";
import { useSprings, animated, config } from "react-spring";
import { Box, Button } from "@mui/material";
import clamp from "lodash.clamp";
import swap from "lodash-move";

const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: curIndex * 50 + y,
          scale: 1.1,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === "zIndex",
          config: (key: string) => (key === "y" ? config.stiff : config.default),
        }
      : {
          y: order.indexOf(index) * 50,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

const DraggableList = (props: ChildProps) => {
  const { items, itemProps, handleSave, close } = props;
  const order = useRef(items.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
  const [springs, api] = useSprings(items.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, items.length - 1);
    const newOrder = swap(order.current, curIndex, curRow);
    api.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) order.current = newOrder;
  });

  useEffect(() => {
    order.current = items.map((_, index) => index);
  }, [items]);

  return (
    <Box sx={{ height: "500px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Button onClick={() => close()} color="info" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleSave(order)} color="success" variant="contained">
          Save
        </Button>
      </Box>
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            zIndex,
            boxShadow: shadow.to((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
            y,
            scale,
            position: "absolute",
            transformOrigin: "50% 50% 0px",
            border: "1px solid #000",
            borderRadius: "8px",
            backgroundColor: itemProps[i].completed ? "#a4ffa4" : "#fff",
            width: "85%",
            letterSpacing: "2px",
            touchAction: "none",
            padding: "5px 10px",
            cursor: "pointer",
          }}
          children={items[i]}
        />
      ))}
    </Box>
  );
};

interface ChildProps {
  items: any[];
  itemProps: any[];
  handleSave: (order: MutableRefObject<number[]>) => void;
  close: () => void;
}

export default DraggableList;
