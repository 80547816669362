import { useQuery, gql, useMutation } from "@apollo/client";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import { setUsers } from "./userSlice";
import { IUser } from "../../types/user";
import { IProject } from "../../types/project";

export const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [
        ...state,
        {
          project: action.payload,
          access: [],
        },
      ];
    case "DELETE":
      return state.filter((state: any, index: number) => state.project._id !== action.payload);
    case "ADDACCESS":
      return state.map((state: any, index: number) => {
        if (state.project._id === action.payload._id) {
          return {
            ...state,
            access: [...state.access, action.payload.value],
          };
        } else return state;
      });
    case "DELETEACCESS":
      return state.map((state: any, index: number) => {
        if (state.project._id === action.payload._id) {
          let accessState = [...state.access];
          accessState.splice(accessState.indexOf(action.payload.value), 1);
          return {
            ...state,
            access: accessState,
          };
        } else return state;
      });
    case "GET":
      return action.payload;
    case "REMOVEALL":
      return action.payload;
    default:
      throw new Error();
  }
};

export const useUsersQuery = (
  page: number = 1,
  perPage: number = 100,
  onCompletedFunc?: (data: any) => void,
  type1?: string,
  type2?: string
) => {
  const dispatch = useAppDispatch();

  return useQuery(GETUSERS, {
    fetchPolicy: "network-only",
    variables: { page: page, perPage: perPage, type1, type2 },
    onCompleted: (data) => {
      dispatch(setUsers(data.userPagination));
      if (onCompletedFunc) onCompletedFunc(data);
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useUsersCountQuery = (type1?: string, type2?: string, onCompletedFunc?: (data: any) => void) => {
  const dispatch = useAppDispatch();

  return useQuery(USERSCOUNT, {
    variables: { type1, type2 },
    onCompleted: (data) => {
      if (onCompletedFunc) onCompletedFunc(data);
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useSalesQuery = (type: string = '') => {
  const dispatch = useAppDispatch();

  let query = { page: 1, perPage: 50, sort: "_ID_DESC", filter: { OR: [{ type: "Sales" }, { type: "Manager" }], locked: false } }

  if (type) {
    query = { page: 1, perPage: 50, sort: "_ID_DESC", filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false } }
  }

  return useQuery(GETSALES, {
    variables: query,
    onCompleted: (data) => {
      dispatch(setUsers(data.userPagination));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useAddUserFavouritesMutation = (onCompletedFunc?: any) => {
  return useMutation(ADDFAVOURITES, {
    onCompleted: (data) => {
      if (onCompletedFunc) onCompletedFunc(data);
    },
    onError: (e) => console.log(e),
  });
};

export const useRemoveUserFavouritesMutation = (onCompletedFunc?: any) => {
  return useMutation(REMOVEFAVOURITES, {
    onCompleted: (data) => {
      if (onCompletedFunc) onCompletedFunc(data);
    },
    onError: (e) => console.log(e),
  });
};

export const filterUsersProjectAccess = (users: IUser[], project: IProject | undefined | null) => {
  let usersCopy = users;
  usersCopy.sort((user1: IUser, user2: IUser) => (user1.type === "Manager" && user2.type === "Sales" ? 1 : -1));
  usersCopy = usersCopy.filter((user) => {
    if (!user.projectAccess) return false;
    for (let projectAccess of user.projectAccess) {
      if (projectAccess.project.name && project?.name && projectAccess.project.name === project.name) {
        return true;
      }
    }
    return false;
  });

  return usersCopy;
};

export const getSalesRepObj = (users: IUser[], formUser: string) => {
  if (users.length === 0) return;
  const split = formUser?.split(" ");
  if (!split) return;
  const salesRepFirstName = split[0];
  const salesRepLastName = split[1];
  const salesRepObj = users.find((user: IUser) => user.firstName === salesRepFirstName && user.lastName === salesRepLastName);
  return salesRepObj;
};

const GETUSERS = gql`
  query userPagination($page: Int!, $perPage: Int!, $type1: EnumUserType, $type2: EnumUserType) {
    userPagination(page: $page, perPage: $perPage, filter: { OR: [{ type: $type1 }, { type: $type2 }] }) {
      items {
        firstName
        lastName
        email
        type
        projectAccess {
          project {
            name
            email
          }
        }
        _id
      }
      count
    }
  }
`;

const GETSALES = gql`
  query userPagination($filter: FilterFindManyUserInput, $page: Int!, $perPage: Int!, $sort: SortFindManyUserInput) {
    userPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        firstName
        lastName
        fullName
        email
        projectAccess {
          project {
            name
          }
        }
      }
      count
    }
  }
`;

const USERSCOUNT = gql`
  query usersCount($type1: EnumUserType, $type2: EnumUserType) {
    userCount(filter: { OR: [{ type: $type1 }, { type: $type2 }] })
  }
`;

const ADDFAVOURITES = gql`
  mutation userAddFavourites($userId: MongoID, $registrantIds: [MongoID]) {
    userAddFavourites(userId: $userId, registrantIds: $registrantIds) {
      _id
      firstName
      lastName
      favourites {
        _id
        firstName
        lastName
        email
        primaryPhone
        rating
        ethnicity
        realtorType
        process {
          currentProcess
          steps {
            completed
            dueDate
            name
          }
        }
        statuses {
          name
        }
        project {
          _id
          name
          portal {
            primaryColor
          }
        }
        createdAt
      }
    }
  }
`;

const REMOVEFAVOURITES = gql`
  mutation userRemoveFavourites($userId: MongoID, $registrantIds: [MongoID]) {
    userRemoveFavourites(userId: $userId, registrantIds: $registrantIds) {
      _id
      firstName
      lastName
      favourites {
        _id
        firstName
        lastName
        email
        primaryPhone
        rating
        ethnicity
        process {
          currentProcess
          steps {
            completed
            dueDate
            name
          }
        }
        realtorType
        statuses {
          name
        }
        project {
          _id
          name
          portal {
            primaryColor
          }
        }
        createdAt
      }
    }
  }
`;
