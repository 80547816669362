import { useLazyQuery, useMutation, gql } from "@apollo/client";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTemplates, updateTemplate, selectTextTemplates } from "./textTemplateSlice";
import { showSuccessSnackbar } from "../snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../auth/authSlice";

export const useSelectTextTemplates = () => useAppSelector(selectTextTemplates);

export const useGettextTemplate = (onCompletedFunc: any) => {
  return useLazyQuery(GETTEXTTEMPLATE, {
    onCompleted: onCompletedFunc,
  });
};

export const useTextTemplatesQuery = (page: number, perPage: number, sort: string, userId?: string | null, step?: boolean) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  let userProjects = [];
  if (user) {
    userProjects = user?.projectAccess.map((projects: any) => projects.project._id);
  }

  let variables = {
    page: page,
    perPage: perPage,
    sort: sort,
    filter: { projects: userId ? null : userProjects, step: step, user: userId },
  };

  return useLazyQuery(GETTEXTTEMPLATES, {
    variables,
    onCompleted: (data) => {
      dispatch(setTemplates(data.textTemplatePagination));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });
};

export const useUpdateTemplate = () => {
  const dispatch = useAppDispatch();

  return useMutation(UPDATETEXTTEMPLATE, {
    onCompleted: (data) => {
      dispatch(updateTemplate(data.textTemplateUpdateById.record));
      dispatch(showSuccessSnackbar("Text template saved!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });
};

const GETTEXTTEMPLATES = gql`
  query textTemplatePagination(
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyTextTemplateInput
    $filter: FilterFindManyTextTemplateInput
  ) {
    textTemplatePagination(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      items {
        _id
        name
        text
        project {
          _id
          name
        }
        createdAt
        updatedAt
        step
      }
      count
    }
  }
`;

const UPDATETEXTTEMPLATE = gql`
  mutation textTemplateUpdateById($_id: MongoID!, $record: UpdateByIdTextTemplateInput!) {
    textTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        text
        createdAt
        updatedAt
        project {
          _id
          name
        }
        step
      }
    }
  }
`;

const GETTEXTTEMPLATE = gql`
  query getTextTemplate($id: MongoID!) {
    textTemplateById(_id: $id) {
      _id
      name
      text
      createdAt
      updatedAt
      project {
        _id
        name
      }
      step
    }
  }
`;
