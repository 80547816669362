import { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Button, Box, TextField, Autocomplete, List, ListItem, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import subDays from "date-fns/subDays";
import { IAttendee, IEvent } from "../../../types/calendar";
import GooglePlaces from "../../common/GooglePlaces";
import { IRealtor } from "../../../types/realtor";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../../app/hooks";
import { validateEmail } from "../../../utils/function";

const GoogleCalendarForm = (props: IChildProps) => {
  const { event, setEvent, googleMeetsLink, setGoogleMeetsLink } = props;
  const storeDispatch = useAppDispatch();
  const [searchInfo, setSearchInfo] = useState<IRealtor[]>([]);
  const [random, setRandom] = useState<number>(Math.floor(Math.random() * 1000000 + 1));
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const handleStreetAddress = async (value: string, type: number) => {
    setEvent({
      ...event!,
      location: value,
    });
  };

  const [getAllPurchasers, { loading: realtorLoading }] = useLazyQuery(REALTORS, {
    onCompleted: (data) => {
      setSearchInfo(data.realtorMany);
    },
  });

  const handleSearchInput = (newEvent: any, values: any) => {
    if (event.attendees) {
      if (event.attendees.map((attendee: IAttendee) => attendee.email).includes(values.email)) return;
      setEvent({
        ...event,
        attendees: [
          ...event.attendees,
          {
            email: values.email,
            organizer: null,
            responseStatus: "needsAction",
            self: null,
          },
        ],
      });
    } else {
      setEvent({
        ...event,
        attendees: [
          {
            email: newEvent.target.defaultValue,
            organizer: null,
            responseStatus: "needsAction",
            self: null,
          },
        ],
      });
    }
    setRandom(Math.floor(Math.random() * 1000000 + 1));
  };

  const handleAttendeeBlur = (newEvent: any) => {
    if (!validateEmail(newEvent.target.defaultValue)) return storeDispatch(showErrorSnackbar("Not a Valid Email"));
    if (event.attendees) {
      if (event.attendees.map((attendee: IAttendee) => attendee.email).includes(newEvent.target.defaultValue)) return;
      setEvent({
        ...event,
        attendees: [
          ...event.attendees,
          {
            email: newEvent.target.defaultValue,
            organizer: null,
            responseStatus: "needsAction",
            self: null,
          },
        ],
      });
    } else {
      setEvent({
        ...event,
        attendees: [
          {
            email: newEvent.target.defaultValue,
            organizer: null,
            responseStatus: "needsAction",
            self: null,
          },
        ],
      });
    }
    setRandom(Math.floor(Math.random() * 1000000 + 1));
  };

  const deleteAttendee = (email: string) => {
    setEvent({
      ...event,
      attendees: event.attendees.filter((attendee: IAttendee) => attendee.email !== email || attendee.responseStatus === "accepted"),
    });
  };

  return (
    <div>
      <TextField
        sx={{
          ".MuiInputBase-root-MuiOutlinedInput-root:hover": {
            borderColor: "#fff",
          },
          ".MuiInputLabel-root": {
            color: "#fff",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff !important",
            color: "#fff",
          },
          input: {
            color: "#fff",
          },
          color: "#fff",
          borderColor: "#fff",
          mb: 2,
        }}
        fullWidth
        required={true}
        label="Title"
        variant="outlined"
        value={event?.title}
        onChange={(e) =>
          setEvent({
            ...event!,
            title: e.target.value,
          })
        }
      />
      <Box sx={{ mb: 2 }}>
        <GooglePlaces disabled={false} name={"location"} handleStreetAddress={handleStreetAddress} address={event?.location!} />
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box>
            <DateTimePicker
              label="Event Start Time"
              value={event?.start}
              onChange={(newValue) => {
                setEvent({
                  ...event!,
                  start: newValue!,
                });
              }}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  sx={{
                    ".MuiInputBase-root-MuiOutlinedInput-root:hover": {
                      borderColor: "#fff",
                    },
                    ".MuiInputLabel-root": {
                      color: "#fff",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff !important",
                      color: "#fff",
                    },
                    input: {
                      color: "#fff",
                    },
                    svg: { color: "#fff" },
                    color: "#fff",
                    borderColor: "#fff",
                    mb: 2,
                  }}
                  {...params}
                />
              )}
            />
            <DateTimePicker
              label="Event End Time"
              value={event?.end}
              onChange={(newValue) => {
                setEvent({
                  ...event!,
                  end: newValue,
                });
              }}
              minDate={subDays(new Date(), 1)}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  sx={{
                    ".MuiInputBase-root-MuiOutlinedInput-root:hover": {
                      borderColor: "#fff",
                    },
                    ".MuiInputLabel-root": {
                      color: "#fff",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff !important",
                      color: "#fff",
                    },
                    input: {
                      color: "#fff",
                    },
                    svg: { color: "#fff" },
                    color: "#fff",
                    borderColor: "#fff",
                  }}
                  {...params}
                />
              )}
            />
            {!event.id ? (
              <Box>
                <FormControlLabel
                  id={"googleMeets"}
                  control={
                    <Checkbox
                      value={googleMeetsLink}
                      name={"googleMeetsLink"}
                      checked={googleMeetsLink!}
                      onChange={(e) => setGoogleMeetsLink(!googleMeetsLink)}
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#fff !important",
                        },
                      }}
                    />
                  }
                  label={"Create Google Meets Link"}
                />
              </Box>
            ) : null}
            <Box sx={{ mt: 1 }}>Attendees</Box>
            {event.attendees && event.attendees.length ? (
              <List>
                {event.attendees.map((attendee: IAttendee, index: number) => {
                  return (
                    <ListItem
                      key={index}
                      sx={{ p: 0, mb: 1 }}
                      secondaryAction={
                        <IconButton onClick={() => deleteAttendee(attendee.email)} edge="end" aria-label="delete">
                          <DeleteIcon sx={{ color: "#e37c90" }} />
                        </IconButton>
                      }
                    >
                      {attendee.email}
                    </ListItem>
                  );
                })}
              </List>
            ) : null}
            {showAdd ? (
              <Box>
                <Autocomplete
                  key={random}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    fontSize: "14px",
                  }}
                  id={"search"}
                  disableClearable
                  noOptionsText={realtorLoading ? `Loading...` : "No Options"}
                  freeSolo={true}
                  options={searchInfo}
                  getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} - ${option.email}`}
                  onChange={handleSearchInput}
                  onBlur={handleAttendeeBlur}
                  renderInput={(params) => (
                    <TextField
                      onFocus={() => (!realtorLoading ? getAllPurchasers() : null)}
                      required={false}
                      {...params}
                      size="small"
                      label={"Search or Enter Email"}
                      margin="normal"
                    />
                  )}
                />
                <Button onClick={() => setShowAdd(false)} color="info" variant="contained">
                  Hide
                </Button>
              </Box>
            ) : (
              <Button onClick={() => setShowAdd(true)} color="info" variant="contained">
                Add Attendee
              </Button>
            )}
          </Box>
        </LocalizationProvider>
      </Box>
    </div>
  );
};

interface IChildProps {
  event: IEvent;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | null>>;
  googleMeetsLink: boolean;
  setGoogleMeetsLink: React.Dispatch<React.SetStateAction<boolean>>;
}

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      fullName
    }
  }
`;

export default GoogleCalendarForm;
