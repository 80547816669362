import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Typography, Skeleton, Grid, Box, Divider, Button } from "@mui/material";
import { useParams, Link } from "react-router-dom";

import { Container, FlexBetween } from "../../../commonStyles";
import MailingStats from "./MailingStats";

const Mailing = () => {
  const { projectid } = useParams();
  const [mailings, setMailings] = useState([]);

  const { loading } = useQuery(GETMAILINGS, {
    fetchPolicy: "cache-and-network",
    skip: !projectid,
    variables: { project: projectid, page: 0, limit: 2, type: 'all' },
    onCompleted: (data) => {
      setMailings(data.getMailingStats.items);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  let array = new Array(2).fill(0);

  return (
    <Container>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
          <strong>Mail Campaign</strong>
        </Typography>
        {mailings.length ? (
          <Button component={Link} to={`/dashboard/project/${projectid}/mail/all-mail`} color="primary" variant="contained">
            View All
          </Button>
        ) : null}
      </FlexBetween>
      <Divider />
      {!loading ? (
        mailings.length ? (
          mailings.map((mailing, index) => {
            return (
              <div key={index}>
                <MailingStats mailing={mailing} />
                <Divider />
              </div>
            );
          })
        ) : (
          <Box sx={{ mt: 2 }}>There are currently no mass mails sent out</Box>
        )
      ) : (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {array.map((number: any, index: number) => {
              return (
                <Grid item xs={12} lg={6} key={index}>
                  <Container sx={{ height: "100%" }}>
                    <Skeleton height={40} animation="wave" />
                    <Skeleton height={200} variant="circular" animation="wave" />
                  </Container>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

const GETMAILINGS = gql`
  query getMailingStats($project: MongoID!, $page: Int!, $type: String, $limit: Int!) {
    getMailingStats(project: $project, page: $page, type: $type, limit: $limit) {
      items {
        mailSchedule {
          _id
          emailTemplate {
            subject
          }
          mailDate
        }
        sent
        clicked
        opened
        permanentBounce
        transientBounce
        complaints
      }
    }
  }
`;

export default Mailing;
