import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

const initialState: IQuestionState = {
  questions: [],
  count: 0,
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload.items;
      state.count = action.payload.count;
    },
    addQuestion: (state, action) => {
      state.questions.push(action.payload);
    },
    updateQuestion: (state, action) => {
      let question = state.questions.findIndex((question: IQuestion) => question._id === action.payload._id);
      if (question >= 0) {
        // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
        state.questions[question] = action.payload;
      }
    },
  },
});

export const { addQuestion, setQuestions, updateQuestion } = questionSlice.actions;

export const selectQuestions = (state: RootState) => state.question.questions;
export const selectQuestionCount = (state: RootState) => state.question.count;

interface IQuestionState {
  questions: IQuestion[];
  count: number;
}

export interface IQuestion {
  _id: string;
  name: string;
  subQuestion: boolean;
  registrantType: string;
  questionType: string;
  type: string;
  display: string;
  question: string;
  choices: IChoice[];
}

export interface IChoice {
  choice: string;
  followUp: IQuestion[];
}

export default questionSlice.reducer;
