import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IProject } from "../../types/project";

const initialState: IProjectState = {
  projects: [],
  project: null,
  filterProjects: [],
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setFilterProjects: (state, action) => {
      state.filterProjects = action.payload;
    },
  },
});

export const { setProjects, setProject, setFilterProjects } = projectSlice.actions;

export const selectProject = (state: RootState) => state.project.project;
export const selectProjects = (state: RootState) => state.project.projects;
export const selectFilterProjects = (state: RootState) => state.project.filterProjects;

interface IProjectState {
  projects: IProject[];
  project: IProject | null;
  filterProjects: string[];
}

export default projectSlice.reducer;
