import { Box, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionContainer = styled(Box)`
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 15px;
`;

export const SettingContainer = styled(Box)`
  background-color: #fff;
  border: 1px solid #000;
  padding: 15px;
`;

export const Container = styled(Box)`
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 15px;
  overflow-wrap: break-word;
`;

export const FlexEnd = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const FlexBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
  "@media (max-width:600px)": {
    flex-direction: column;
  }
`;

// Sub Tabs

export const SubTabs = styled(Tabs)`
  background-color: #fff;
  border-bottom: none;
`;

export const SubTab = styled(Tab)`
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  &.Mui-selected {
    background-color: #00142a;
  }
`;
