import { useState } from "react";
import { Typography } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useLocation } from "react-router";

import BasicTabs from "../common/BasicTabs";
import GoogleCalendar from "./calendar/GoogleCalendar";
import Schedule from "./Schedule";

const Appointments = () => {
  const location: any = useLocation();
  const [value, setValue] = useState(location.state?.tab || 0);

  const tabs = [
    {
      label: "Appointments",
      component: <Schedule />,
    },
    {
      label: "Your Calendar",
      component: (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID!}>
          <GoogleCalendar />
        </GoogleOAuthProvider>
      ),
    },
  ];

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Personal Calendar and Appointments</strong>
      </Typography>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={"#e5e5e5"} />
    </div>
  );
};

export default Appointments;
