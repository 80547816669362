import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { IUser } from "../types/user";
import { useSearchParams, useParams } from "react-router-dom";
import { subDays } from "date-fns";

const RegistrantFilterContext = React.createContext<any>(null);

const RegistrantFilterProvider = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [realtorTypeFilter, setRealtorTypeFilter] = useState<string>("any");
  const [statusFilter, setStatusFilter] = useState<string>("any");
  const [ratingFilter, setRatingFilter] = useState<string>("any");
  const [salesRepFilter, setSalesRepFilter] = useState<IUser | string>("any");
  const [sourceFilter, setSourceFilter] = useState<string>("any");
  const [subscribedFilter, setSubscribedFilter] = useState<boolean | string>("any");
  const [connectedFilter, setConnectedFilter] = useState<boolean | string>("any");
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(
    null
  );
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(
    null
  );

  return (
    <RegistrantFilterContext.Provider
      value={{
        realtorTypeFilter,
        setRealtorTypeFilter,
        statusFilter,
        setStatusFilter,
        ratingFilter,
        setRatingFilter,
        salesRepFilter,
        setSalesRepFilter,
        sourceFilter,
        setSourceFilter,
        subscribedFilter,
        setSubscribedFilter,
        connectedFilter,
        setConnectedFilter,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
      }}
    >
      <Outlet />
    </RegistrantFilterContext.Provider>
  );
};

export { RegistrantFilterContext, RegistrantFilterProvider };
