import { useState, useMemo } from "react";
// Project Imports //
import Processes from "./process/Processes";
import Questionaire from "./Questionaire";
import EmailTemplates from "./emails/EmailTemplates";
import TextMessaging from "./textMessaging/TextMessaging";
import Users from "./users/Users";
import BasicTabs from "../common/BasicTabs";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

const Settings = () => {
  const user = useSelector(selectUser);
  const [value, setValue] = useState(0);
  const tabs = useMemo(() => {
    let settingsTab = [
      {
        label: "Processes",
        component: <Processes />,
      },
      {
        label: "Questionnaire",
        component: <Questionaire />,
      },
      {
        label: "Email Templates",
        component: <EmailTemplates />,
      },
      {
        label: "Text Messaging",
        component: <TextMessaging userId={null} />,
      },
    ];

    if (user?.type === "Manager" || user?.type === 'Admin') {
      settingsTab.push({
        label: "Users",
        component: <Users />,
      });
    }

    return settingsTab;
  }, [user]);

  return (
    <Box>
      <BasicTabs color="#00142a" tabs={tabs} value={value} setValue={setValue} />
    </Box>
  );
};

export default Settings;
